import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { UserProfileService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-invite-with-message',
  templateUrl: './invite-with-message.component.html',
  styleUrls: ['./invite-with-message.component.scss']
})
export class InviteWithMessageComponent implements OnInit {
  emails: any = [];
  message: any;
  
  public validators = [this.must_be_email];

  public errorMessages = {
    'must_be_email': 'Please be sure to use a valid email format'
  };

  private must_be_email(control: FormControl) {
    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
    if (control.value.length != "" && !EMAIL_REGEXP.test(control.value)) {
      return { "must_be_email": true };
    }
    return null;
  }

  constructor(
    private modalService: NgbModal,
    private userService: UserProfileService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.emails.length > 0) {
      let emailList = this.emails.map((value) => {
        return value.value
      });

      let data = {
        emails: emailList,
        message: this.message
      }

      this.userService.inviteContact(data)
        .subscribe((res) => {
          if(res.statusCode === 200) {
            this.modalService.dismissAll();
            this.toasterService.success('Mail has been sent', 'Success')
          }
        });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
