/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConferenceAlertDialogModel {

    constructor(public title: string, public message: string, public isCinfirmType: boolean) {
    }
}
