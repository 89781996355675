import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getNotification(data) {
    let params = new HttpParams();
    params = params.append('limit', data.limit);
    params = params.append('offset', data.offset);
    params = params.append('sorting', data.sorting);

    return this.http.get<any>(`@api-nest/notification/get-notification`, { params }).pipe(retry(0), catchError(this.handleError));
  }

  updateNotificationReadStatus(data) {
    return this.http.put<any>(`@api-nest/notification/update-read-notification`, data).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
