
<div title="conference signal status"  >
    <span [class]="conferenceConnectionStatus?'blink conf-connection-status online':'conf-connection-status offline' "></span>
</div>


<div id="side-menu-block" class="side-menu flex-lg-column me-lg-1">
    <div class="navbar-brand-box">
        <a routerlink="/" class="logo logo-dark" href="/">
            <span class="logo-sm">
                <img src="assets/images/dhumi.jpg" alt="" height="30">
            </span>
        </a>
    </div>

    <!-- Start side-menu nav -->
    <perfect-scrollbar class="d-flex flex-lg-column my-auto side-menu-block">
        <div class="responsive-top-bar-menu px-4">
            <div class="responsive-top-bar-inner">
                <div class="navbar-brand-box">
                    <a routerlink="/" class="logo logo-dark" href="/">
                        <span class="logo-sm">
                            <img src="assets/images/dhumi.jpg" alt="" height="30">
                        </span>
                    </a>
                </div>
                <div class="flex-lg-column profile-btn">
                    <ul class="nav justify-content-center responsive-menu-wrap">
                        <li class="nav-item btn-group profile-user-dropdown" ngbDropdown placement="bottom-right">
                            <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" ngbDropdownToggle
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img *ngIf="currentUser?.profilePic" src="{{currentUser?.profilePic}}"
                                     class=" profile-user rounded-circle avatar-xs" alt="" />
                                <div *ngIf="currentUser?.profilePic === null" class="profile-user rounded-circle avatar-xs">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                        {{currentUser?.firstname | firstCharacter}}
                                    </span>
                                </div>
                            </a>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" [routerLink]="['/pages/profile']" href="javascript: void(0);">
                                    {{
                  "chat.profiledropdown.profile" | translate
                                    }}
                                    <i class="ri-profile-line float-end text-muted"></i>
                                </a>
                                <a class="dropdown-item" [routerLink]="['/pages/setting']" href="javascript: void(0);">
                                    {{
                  "chat.profiledropdown.setting" | translate
                                    }}
                                    <i class="ri-settings-3-line float-end text-muted"></i>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
                                    {{
 "chat.profiledropdown.logout"
                  |
                  translate
                                    }}
                                    <i class="ri-logout-circle-r-line float-end text-muted"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">
            <!-- <li class="nav-item" ngbTooltip="Profile">
              <a class="nav-link" id="pills-user-tab" [routerLink]="['/pages/profile']" routerLinkActive="active">
                <i class="ri-user-2-line"></i>
              </a>
            </li> -->
            <li class="nav-item" ngbTooltip="Activity" placement="top" container="body">
                <a class="nav-link" id="pills-chat-tab" [routerLink]="['/pages/activity']" routerLinkActive="active">
                    <!-- <i class="ri-file-list-3-line"></i> -->
                    <span class="icon-relative">
                        <img src="./../../../assets/images/menu-activity-icon.png" alt="">
                        <!-- <label class="count-badge">9+</label> -->
                    </span>
                    <span>Activity</span>
                </a>
            </li>
            <li class="nav-item" ngbTooltip="Chats" placement="top" container="body">
                <a class="nav-link" id="pills-chat-tab" [routerLink]="['/pages/chat']" routerLinkActive="active">
                    <!-- <i class="ri-message-3-line"></i> -->
                    <span class="icon-relative">
                        <img src="./../../../assets/images/chat-icon.png" alt="">
                        <label class="count-badge" *ngIf="totalUserUnReadMessage != 0">{{totalUserUnReadMessage}}</label>
                    </span>
                    <span>Chats</span>
                </a>
            </li>
            <li class="nav-item" ngbTooltip="Groups" placement="top" container="body">
                <a class="nav-link" id="pills-groups-tab" [routerLink]="['/pages/team']" routerLinkActive="active">
                    <!-- <i class="ri-group-line"></i> -->
                    <span class="icon-relative">
                        <img src="./../../../assets/images/groups-icon.png" alt="">
                        <label class="count-badge" *ngIf="totalTeamUnReadMessage != 0">{{totalTeamUnReadMessage}}</label>
                    </span>
                    <span>Groups</span>
                </a>
            </li>
            <li class="nav-item" ngbTooltip="Contacts" placement="top" container="body">
                <a class="nav-link" id="pills-contacts-tab" [routerLink]="['/pages/contact']" routerLinkActive="active">
                    <!-- <i class="ri-contacts-line"></i> -->
                    <img src="./../../../assets/images/audio-video-icon.png" alt="">
                    <span>Contacts</span>
                </a>
            </li>
            <!-- <li class="nav-item" ngbTooltip="Settings">
              <a class="nav-link" id="pills-setting-tab" [routerLink]="['/pages/setting']" routerLinkActive="active">
                <i class="ri-settings-2-line"></i>
              </a>
            </li> -->
            <li class="nav-item" ngbTooltip="Meetings" placement="top" container="body">
                <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/meetings']" routerLinkActive="active">
                    <!-- <i class="ri-team-line"></i> -->
                    <img src="./../../../assets/images/meetings-icon.png" alt="">
                    <span>Meetings</span>
                </a>
            </li>
            <!-- <li class="nav-item" ngbTooltip="Docs" placement="top" container="body">
              <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/docs']" routerLinkActive="active">
                <i class="ri-file-text-line"></i>
                <span>Docs</span>
              </a>
            </li> -->
            <li class="nav-item" ngbTooltip="Whiteboard" placement="top" container="body">
                <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/whiteboard']" routerLinkActive="active">
                    <!-- <i class="ri-clapperboard-line"></i> -->
                    <img src="./../../../assets/images/whiteboard-icon.png" alt="">
                    <span>Whiteboard</span>
                </a>
            </li>
            <li class="nav-item" ngbTooltip="Notification" placement="top" container="body">
                <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/notification']" routerLinkActive="active">
                    <i class="ri-notification-line"></i>
                    <span>Notification</span>
                </a>
            </li>
            <!-- <li class="nav-item" ngbTooltip="Meetings">
              <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/meetings']" routerLinkActive="active">
                <i class="ri-team-line"></i>
              </a>
            </li> -->
            <li class="nav-item" ngbTooltip="Docs">
                <a class="nav-link" id="pills-teams-tab" [routerLink]="['/pages/docs']" routerLinkActive="active">
                    <!-- <i class="ri-file-text-line"></i> -->
                    <!-- <i class="ri-file-text-line"></i> -->
                    <img src="./../../../assets/images/documents-icon.png" alt="">
                    <span>Docs</span>
                </a>
            </li>
        </ul>
    </perfect-scrollbar>

    <div class="flex-lg-column d-none d-lg-block profile-btn">
        <ul class="nav side-menu-nav justify-content-center">
            <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown container="body" placement="top-left">
                <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" ngbDropdownToggle
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img *ngIf="currentUser?.profilePic" src="{{currentUser?.profilePic}}"
                         class=" profile-user rounded-circle avatar-xs" alt="" />
                    <div *ngIf="currentUser?.profilePic === null" class="profile-user rounded-circle avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                            {{currentUser?.firstname | firstCharacter}}
                        </span>
                    </div>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                    <a class="dropdown-item" [routerLink]="['/pages/profile']" href="javascript: void(0);">
                        {{
            "chat.profiledropdown.profile" | translate
                        }}
                        <i class="ri-profile-line float-end text-muted"></i>
                    </a>
                    <a class="dropdown-item" [routerLink]="['/pages/setting']" href="javascript: void(0);">
                        {{
            "chat.profiledropdown.setting" | translate
                        }}
                        <i class="ri-settings-3-line float-end text-muted"></i>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
                        {{
 "chat.profiledropdown.logout" |
            translate
                        }}
                        <i class="ri-logout-circle-r-line float-end text-muted"></i>
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <!-- end side-menu nav -->
</div>