import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserStatusEnum } from 'src/app/core/models/userStatusEnum.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

import { UserProfileService } from 'src/app/core/services/user.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { TeamService } from 'src/app/core/services/team.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ConferenceMiddlewareService } from '../../conference/service/conference.middleware.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

    currentUser;
    totalUserUnReadMessage: any = 0;
    totalTeamUnReadMessage = 0;

    conferenceConnectionStatus: boolean = false;
    constructor(
        private router: Router,
        private tokenService: TokenStorageService,
        private socketService: SocketService,
        private authService: AuthenticationService,
        private userService: UserProfileService,
        private chatService: ChatService,
        private teamService: TeamService,
        private observableService: ObservableService,
        private conferenceMiddlewareService: ConferenceMiddlewareService
    ) {
        this.currentUser = this.tokenService.getUser();

        this.socketService.totalUnreadChatMessage().subscribe((unReadCount: any) => {
            if (this.currentUser?.id === unReadCount.receiverId) {
                this.totalUserUnReadMessage = unReadCount.totalCount
            }
        });
    }

   

    ngOnInit(): void {

       
        this.conferenceMiddlewareService.$connectionStatus.subscribe(x => {
            this.conferenceConnectionStatus = x;
        });


   

        this.getTotalNumberOfUnreadMessage();
        this.getTotalNumberTeamOfUnreadMessage();

        this.observableService.sidebarTotalTeamUnreadMessage.subscribe((result: any) => {
            this.totalTeamUnReadMessage = result;
        });

    }

    async getTotalNumberOfUnreadMessage() {
        this.chatService.getTotalNumberOfUnreadMessage().subscribe((res) => {
            if (res.statusCode === 200) {
                this.totalUserUnReadMessage = res.data;
            }
        });
    }

    getTotalNumberTeamOfUnreadMessage() {
        this.teamService.getTotalNumberOfUnreadMessage()
            .subscribe((res) => {
                if (res.statusCode === 200) {
                    this.totalTeamUnReadMessage = res.data;
                }
            })
    }



    callWindowTimer = null;
    callWindowTimer_accepted = false;

  
    
    getParticipantsNames(conf) {
        var name = "";
        if (conf)
            if (conf.participants && conf.participants.length) {
                name = conf.participants[0].name;
                var _participants = conf.participants.filter(x => x.name != this.currentUser.userName);


                if (_participants.length > 1)
                    name = name + "," + _participants[1].name;
                if (_participants.length > 2)
                    name = name + " + " + (_participants.length - 2) + " others";
            }
        return name;
    }

    logout() {
        this.socketService.sendUserStatus({
            uId: this.authService.getCurrentUser().id,
            statusType: UserStatusEnum.OFFLINE,
        });
        this.authService.logout();
        this.router.navigate(["/account/login"]);
    }

}
