import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ChatService } from 'src/app/core/services/chat.service';
import { ChatUserModel } from '../chatModel';
import { BookmarkMessage, ChatMessageModel } from 'src/app/core/models/chat-message.model';
import * as moment from 'moment';
import * as _ from "lodash";
import { TokenStorageService } from 'src/app/core/services/token.service';
import { isWebUri } from 'valid-url';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ObservableService } from 'src/app/core/services/observable.service';

@Component({
  selector: 'app-important-messages',
  templateUrl: './important-messages.component.html',
  styleUrls: ['./important-messages.component.scss']
})
export class ImportantMessagesComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  offset = 0;
  limit = 10;
  totalRecord: number;
  totalPages: number;
  scrollPosition: any;
  currentUser;

  selectedUser = new ChatUserModel();
  messageList = [];
  messageFormate = new BookmarkMessage();

  constructor(
    private chatService: ChatService,
    private cd: ChangeDetectorRef,
    private tokenService: TokenStorageService,
    private obService: ObservableService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.getMessages();
  }

  onScrollEvent() {
    this.scrollPosition = this.componentRef.directiveRef.position(true).y;
  }

  loadMessage() {
    if ((this.totalRecord / this.limit) > this.offset) {
      this.offset += 1;
      console.log('here');
      this.getMessages();
    }
  }

  getMessages() {
    return new Promise((resolve, reject) => {
      try {
        this.messageList = [];
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ];

        this.messageFormate.userId = this.currentUser.id;
        this.messageFormate.limit = this.limit;
        this.messageFormate.offset = this.offset;

        this.chatService.getImportantMessages(this.messageFormate).subscribe((res) => {
          if (res.statusCode === 200) {
            res.data.message.forEach((value) => {
              value.user.profilePic = value.user.profilePic != null ? environment.s3BucketProfileUrl + "/" + value.user.profilePic : null;
              this.messageList = insert(this.messageList, 0, value);
            });

            console.log(this.messageList)
            this.addTodayYesterdayMessageLabel()
              .then(() => {
                if (this.componentRef != undefined) {
                  this.componentRef.directiveRef.scrollToTop((res.data.message.length * 100), 10)
                }
                this.cd.detectChanges();
                this.totalRecord = res.data.totalCount;
                this.totalPages = Math.ceil(this.totalRecord / this.limit);
                resolve('Done')
              });
          }
        });

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  resetTodayYesterdayMessageLabel() {
    if (this.messageList.length > 0) {
      this.messageList = _.reject(this.messageList, (o) => o.date);
      this.messageList = _.reject(this.messageList, (o) => o.isToday);
      this.messageList = _.reject(this.messageList, (o) => o.isYesterday);
    }
  }

  addTodayYesterdayMessageLabel() {
    return new Promise((resolve, reject) => {
      try {
        if (this.messageList.length > 0) {
          this.resetTodayYesterdayMessageLabel();

          let today = moment().format("YYYY-MM-DD");
          let yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

          const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

          const findIndex = (date) => {
            return this.messageList.findIndex((e) => moment(e.updatedAt).format("YYYY-MM-DD") === date);
          };

          const setLabelToMessage = (index, label) => {
            if (index != -1) {
              this.messageList = insert(this.messageList, index, label);
            }
          };

          let yesterdayIndex = findIndex(yesterday);
          let todayIndex = findIndex(today);

          setLabelToMessage(todayIndex, { isToday: true });
          setLabelToMessage(yesterdayIndex, { isYesterday: true });

          this.messageList = this.messageList.map((e) => {
            e.messageDate = moment(e.updatedAt).format("YYYY-MM-DD");
            return e;
          });
          let uniqDateList = _.uniq(_.map(this.messageList, "messageDate"));

          uniqDateList = _.remove(uniqDateList, function (c) {
            delete c.messageDate;
            return c != today && c != yesterday;
          });

          uniqDateList.forEach((value) => {
            let index = findIndex(value);
            setLabelToMessage(index, { date: value });
          });

          resolve('Done');
        } else {
          resolve('Done');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  isURL(str) {
    return isWebUri(str)
  }

  removeBookmark(data, i){
    this.chatService.updateImportantMessage({
      id: data.id || data.lastMessageId,
      isImportant: !data.isImportant
    })
      .subscribe((res) => {
        this.messageList[i].isImportant = !data.isImportant;
        this.messageList.splice(i, 1);
      });
  }

  closeImportantMessage() {
    this.obService.isOpenImportantMessage.next(false);
  }

  fileUrl(file) {
    return environment.s3BucketChatUrl + "/" + file;
  }

}
