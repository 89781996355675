
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Observable } from 'rxjs';


@Component({
    templateUrl: './conference.selectuserdialogdialog.component.html',
    styleUrls: ['./conference.selectuserdialogdialog.component.css']
})
export class ConferenceSelectUserDialogComponent implements OnInit, OnDestroy {

  companyid;
  currentUser;
  /** control for the selected user for server side filtering */
  public userServerSideCtrl: FormControl = new FormControl();

  /** control for filter for server side. */
  public userServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching = false;

  /** list of users filtered after simulating server side search */
  public filteredServerSideusers: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();



  constructor(

      public dialogRef: MatDialogRef<ConferenceSelectUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // Update view with given values

  }

  ngOnInit() {
    // this.authentcationService.getCurrentUser().subscribe(_user => {

    //  // this.currentUser = user;
    //   this.companyid = _user.company_id;
    //   // listen for search field value changes
    //   this.userServerSideFilteringCtrl.valueChanges
    //     .pipe(
    //       filter(search => !!search),
    //       tap(() => this.searching = true),
    //       takeUntil(this._onDestroy),
    //       debounceTime(200),
    //       map(search => {
    //         // if (!this.users) {
    //         //   return [];
    //         //  }

    //         // simulate server fetching and filtering data
    //         //return this.users.filter(user => user.username.toLowerCase().indexOf(search) > -1);
    //         return this.api.getRegisterUsersSearch(search, this.companyid);//.pipe(map(res => <UserModel[]>res ))
    //       }),
    //       delay(500),
    //       takeUntil(this._onDestroy)
    //     )
    //     .subscribe(filteredusers => {
    //       this.searching = false;

    //       //this.filteredServerSideusers.next(filteredusers);
    //       filteredusers.subscribe(x => {
    //         this.filteredServerSideusers.next(x);
    //       });
    //     },
    //       error => {
    //         // no errors in our simulated example
    //         this.searching = false;
    //         // handle error...
    //       });

    // });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.userServerSideCtrl.value);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(null);
  }
}






