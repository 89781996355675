<!-- Start chats content -->
<div class="chat-leftsidebar me-lg-1">
  <div class="tab-content">
    <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
      <div>
        <div class="px-4 pt-4">
          <h4 class="mb-4">{{'chat.tabs.chats.title' | translate}}</h4>
          <div class="search-box chat-search-box">
            <div class="input-group mb-3 rounded-3">
              <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                <i class="ri-search-line search-icon font-size-18"></i>
              </span>
              <input (search)="searchUser()" type="search" class="form-control bg-light"
                placeholder="{{'chat.tabs.chats.search.placeholder' | translate}}" aria-label="Search users"
                aria-describedby="basic-addon1" name="search" [(ngModel)]="searchUserKeyword">
            </div>
          </div>
        </div> <!-- .p-4 -->

        <!-- Start user status -->
        <div class="px-4 pb-4" dir="ltr">
          <div *ngIf="onlineUsers?.length > 0">
            <drag-scroll [scroll-x-wheel-enabled]="true" class="online-users-list">
              <div drag-scroll-item *ngFor="let user of onlineUsers">
                <div class="online-users-list-item" container="body" [ngbTooltip]="user?.onlineStatus">
                  <a href="javascript:void(0);" (click)="onSelectUser(user)" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img " [ngClass]="{
                      'online': user?.onlineStatus === 'Online',
                      'away': user?.onlineStatus === 'Away',
                      'dnd': user?.onlineStatus === 'Do not disturb',
                      'offline': user?.onlineStatus === 'Offline'
                    }">
                      <img *ngIf="user?.profilePic" src="{{user?.profilePic}}" alt="user-img"
                        class="img-fluid rounded-circle avatar-xs user-image-fit">
                      <span class="user-status "></span>
                      <div class="avatar-xs" *ngIf="!user?.profilePic">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{user?.firstname | firstCharacter}}
                        </span>
                      </div>
                    </div>
                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{user?.firstname}}</h5>
                  </a>
                </div>
              </div>
            </drag-scroll>
          </div>
          <div *ngIf="onlineUsers?.length == 0">
            <span>No any user online</span>
          </div>
        </div>
        <!-- end user status -->

        <!-- Start user-list -->
        <div class="px-2">
          <h5 class="mb-3 px-3 font-size-16">{{'chat.tabs.chats.recent.title' | translate}}</h5>
          <div class="chat-message-list container" *ngIf="userList.length > 0" ngxInfiniteScroll
            [immediateCheck]="false" [scrollWindow]="false" (scroll)="fetchUser()">

            <ul class="list-unstyled chat-list chat-user-list">
              <li *ngFor="let u of userList" [ngClass]="{
                'typing': u.isTyping, 
                'active': u?.user?.id === selectedUser?.id
              }" (click)="showChat()">
                <a href="javascript:void(0);" (click)="onSelectUser(u)">
                  <div class="d-flex">
                    <div class="chat-user-img align-self-center me-3 ms-0" container="body"
                      [ngbTooltip]="u?.user?.onlineStatus" [ngClass]="{
                      'online': u?.user?.onlineStatus === 'Online',
                      'away': u?.user?.onlineStatus === 'Away', 
                      'dnd': u?.user?.onlineStatus === 'Do not disturb', 
                      'offline': u?.user?.onlineStatus === 'Offline'
                    }">
                      <img *ngIf="u?.user?.profilePic" src="{{u?.user?.profilePic}}"
                        class="rounded-circle avatar-xs user-image-fit" alt="">
                      <div class="avatar-xs" *ngIf="!u?.user?.profilePic || u?.user?.profilePic == null">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{u?.user?.firstname | firstCharacter}}
                        </span>
                      </div>
                      <span class="user-status"></span>
                    </div>

                    <div class="flex-1 overflow-hidden">
                      <h5 class="text-truncate font-size-15 mb-1">{{u?.user?.firstname}} {{u?.user?.lastname}}
                      </h5>
                      <p class="chat-user-message text-truncate mb-0" *ngIf="u?.lastMessage?.messageType === 'text'">
                        {{u?.lastMessage?.message}}</p>
                      <p class="chat-user-message text-truncate mb-0" *ngIf="u?.lastMessage?.messageType === 'file'">
                        {{u?.lastMessage?.userFileName}}
                      </p>
                      <p class="chat-user-message text-truncate mb-0" *ngIf="u?.lastMessage?.messageType === 'image'">
                        image
                      </p>
                    </div>
                    <div class="font-size-11">
                      {{u?.lastMessage?.createdAt | date: 'mediumDate': 'UTC'}}
                    </div>
                    <div class="unread-message" *ngIf="u?.totalUnreadMessage > 0 && selectedUser?.user?.id != u?.user?.id">
                      <span class="badge badge-soft-danger rounded-pill">
                        {{u?.totalUnreadMessage}}
                      </span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="list-unstyled chat-list chat-user-list no-user-selected" *ngIf="userList.length == 0">
            No any user found in your company.
          </div>
        </div>
        <!-- End user-list -->

      </div>
    </div>
  </div>
</div>

<!-- <div class="user-chat w-100" id="chat-room">
  <app-user-conversion></app-user-conversion>
</div> -->