import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  private activeTabNumber = new Subject();
  public selectUser = new Subject();
  public selectedTeam = new Subject();
  public loginUser = new Subject();
  public setFileArrayNullOnTeamChange = new Subject();
  public selectUserPostTab = new BehaviorSubject('all-post');
  public selectScheduleMeetingTab = new BehaviorSubject('schedule-meeting')
  public isShowMeetingInfo = new BehaviorSubject(false)
  public notificationModule = new BehaviorSubject({})
  public onMeetingChange = new BehaviorSubject({})
  public fileEleObs = new Subject();
  public isOpenImportantMessage = new Subject<boolean>();
  public isOpenTeamImportantMessage = new Subject<boolean>();

  public docsSidebarTab = new Subject();

  public _success = new Subject<string>();

  public teamModalOpen = new Subject<boolean>();
  public previewFile = new Subject<any>();

  public sidebarTotalUnreadMessage = new Subject();
  public sidebarTotalTeamUnreadMessage = new Subject();

  constructor() { }

  setChangeTabNumber(number) {
    this.activeTabNumber.next(number);
  }
  getChangeTabNumber() {
    return this.activeTabNumber;
  }
  
}
