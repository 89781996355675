import { ChangeDetectorRef, Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { UserPostService } from 'src/app/core/services/user-post.service';
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/core/services/socket.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import * as _ from 'lodash'
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-activity-content',
  templateUrl: './activity-content.component.html',
  styleUrls: ['./activity-content.component.scss']
})
export class ActivityContentComponent implements OnInit {
  currentUser: any;
  public isCollapsed = true;
  public activityComment = true;
  public fileList = [];
  public userPostForm: FormGroup;
  emojiToggled = false;
  public comment: any;
  isLoadingData = false;
  isNewPostSectionShow = true;
  selectedTab;


  currentRoute: string;
  offset = 0;
  limit = 6;
  totalRecord: 0;
  pageNumber = 0;

  notificationSubscriber: any;
  userSelectedTabSubscriber: any;

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  postData = [];
  userPostMode = 'create';
  updatePost: any = {};
  public EMPTY = new Object();


  handleSelection(event) {
    if (this.userPostForm.value.message == null) {
      this.userPostForm.value.message = event.emoji.native ? event.emoji.native : "";
    } else {
      this.userPostForm.value.message += event.emoji.native ? event.emoji.native : "";
    }
    this.userPostForm.patchValue({
      message: this.userPostForm.value.message
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let emojiPanel = document.getElementById("emoji-palette"); // this is emoji panel element
    let buttonEmoji = document.getElementById("button-emoji"); //this is button to triggered emoji panel
   
    const path = event.composedPath();

    if (emojiPanel && !path.includes(buttonEmoji)) {
      if (!path.includes(emojiPanel)) {
        emojiPanel.style.display = "none";
        this.emojiToggled = false;
      }
    }
  }

  constructor(
    private modalService: NgbModal,
    private tokenService: TokenStorageService,
    private formBuilder: FormBuilder,
    private userPostService: UserPostService,
    private socketService: SocketService,
    private obs: ObservableService,
    private toasterService: ToasterService,
    private cd: ChangeDetectorRef,
    private observableService: ObservableService,
    public router: Router,
  ) {
    this.currentRoute = this.router.url;

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.ngOnDestroy();
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    if (this.currentUser) {
      this.initUserPostForm();
    }

    this.socketService.userPost().subscribe((data: any) => {
      if (this.postData.length > 0 && data) {
        let index = this.postData.findIndex((e) => e.id === data.id);

        if (index >= 0) {
          Object.assign(this.postData[index], data)
        }

        if (index === -1) {
          const insert = (arr, index, newItem) => [
            ...arr.slice(0, index),
            newItem,
            ...arr.slice(index)
          ];
          this.postData = insert(this.postData, 0, data);
        }
        this.addPropertyForCollapse();
      }
    });

    this.postData = [];
    this.offset = 0;
    this.limit = 6;
    this.pageNumber = 0;
    this.totalRecord = 0;

    if (this.currentRoute === '/pages/activity') {
      this.onTabChange();
      this.observableService.notificationModule.next(this.EMPTY)
    }

    if (this.currentRoute === '/pages/notification') {
      this.onNotificationChange();
    }
  }

  onTabChange() {
    this.isNewPostSectionShow = true;
    this.userSelectedTabSubscriber = this.obs.selectUserPostTab.subscribe((value) => {
      this.postData = [];
      this.offset = 0;
      this.limit = 6;
      this.pageNumber = 0;
      this.totalRecord = 0;
      this.selectedTab = value;
      if (value === 'all-post') {
        this.getAllPost();
      } else if (value === 'my-post') {
        this.getSavedPost();
      } else {
        this.getUserPost();
      }
    });
  }

  onNotificationChange() {
    this.isNewPostSectionShow = false;
    this.notificationSubscriber = this.observableService.notificationModule.subscribe((value: any) => {
      this.postData = [];
      this.offset = 0;
      this.limit = 6;
      this.pageNumber = 0;
      this.totalRecord = 0;
      if (value && !_.isEmpty(value)) {
        return new Promise((resolve, reject) => {
          this.userPostService.getSinglePost({
            id: value.id
          })
            .subscribe((res) => {
              if (res.statusCode === 200) {
                if (res.data.length > 0) {
                  res.data.forEach((value) => {
                    this.postData.push(value);
                    this.addPropertyForCollapse();
                  });
                }
              }
              this.cd.detectChanges();
              this.isLoadingData = (this.postData.length < this.totalRecord) ? true : false;
              resolve('Done');
            });
        });
      }
    });

  }

  initUserPostForm() {
    this.userPostForm = this.formBuilder.group({
      userId: [this.currentUser.id],
      message: [null, [Validators.required]],
      file: this.formBuilder.array([])
    });
  }

  async openCreatePostModal(content: any) {
    this.userPostForm.reset();
    this.userPostMode = 'create';
    this.fileList = [];
    this.updatePost = {};
    await this.modalService.open(content, { centered: true });
  }

  userImagePostPreview(event): void {
    if (event.target.files && event.target.files.length > 0) {
      this.userPostForm.controls['message'].setErrors(null);

      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.fileList.push({
            file: event.target.files[i],
            url: e.target.result
          });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  removeFile(index) {
    this.fileList.splice(index, 1);
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.userPostForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  }

  uploadPost() {
    this.findInvalidControls();
    if (this.userPostForm.invalid) {
      return
    }

    const formData = new FormData();
    formData.append("message", this.userPostForm.value.message);
    formData.append("userId", this.currentUser.id);

    if (this.fileList && this.fileList.length > 0) {
      this.fileList.forEach((value) => {
        formData.append("file", value.file);
      });
    }

    if (this.userPostMode === 'create') {
      this.userPostService.createPost(formData)
        .subscribe((res) => {
          if (res) {
            if (res.statusCode === 200) {
              this.toasterService.success('Your Activity Updated.', 'Success');
              this.modalService.dismissAll();
              this.userPostForm.reset();
            }
          }
        });
    }

    if (this.userPostMode === 'update') {
      if (this.updatePost.images.length > 0) {
        let ides = this.updatePost.images.map((value) => {
          return value.id
        });
        
        formData.append("images", JSON.stringify(ides));
      }
      this.userPostService.updatePost(formData, this.updatePost.id)
        .subscribe((res) => {
          if (res) {
            if (res.statusCode === 200) {
              this.modalService.dismissAll();
              this.userPostForm.reset();
              this.toasterService.success('Your Activity Updated.', 'Success');
            }
          }
        });
    }
  }

  getAllPost() {
    return new Promise((resolve, reject) => {
      this.userPostService.getAllPost({
        limit: this.limit,
        offset: this.offset
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.totalRecord = res.data.totalCount;
            if (res.data.post.length > 0) {
              res.data.post.forEach((value) => {
                this.postData.push(value);
                this.addPropertyForCollapse();
              });
            }
          }
          this.cd.detectChanges();
          this.isLoadingData = (this.postData.length < this.totalRecord) ? true : false;
          resolve('Done');
        });
    });
  }

  async loadPost() {
    if (this.isLoadingData) {
      this.pageNumber = this.pageNumber + 1;
      this.offset = this.pageNumber * 6;
      this.limit = 6;
      if (this.selectedTab === 'all-post') {
        this.getAllPost()
          .then(() => {
            if (this.componentRef && this.componentRef.directiveRef) {
              this.cd.detectChanges();
            }
          });
      } else if (this.selectedTab === 'my-post') {
        this.getSavedPost().then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
      } else {
        this.getUserPost().then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
      }
    }
  }

  addPropertyForCollapse() {
    this.postData = this.postData.map((e) => {
      if (e.activityComment || e.isCollapsed) {
        if (e.activityComment) {
          e.activityComment = e.activityComment;
        }
        if (e.isCollapsed) {
          e.isCollapsed = e.isCollapsed;
        }
      } else {
        e.activityComment = true;
        e.isCollapsed = true;
      }

      if (e.comments.length > 0) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
      return e
    });
  }

  getUserPost() {
    return new Promise((resolve, reject) => {
      this.userPostService.getUserPost({
        limit: this.limit,
        offset: this.offset
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.totalRecord = res.data.totalCount;
            if (res.data.post.length > 0) {
              res.data.post.forEach((value) => {
                this.postData.push(value);
                this.addPropertyForCollapse();
              });
            }
          }
          this.cd.detectChanges();
          this.isLoadingData = (this.postData.length < this.totalRecord) ? true : false;
          resolve('Done');
        });
    });
  }

  getSavedPost() {
    return new Promise((resolve, reject) => {

      this.userPostService.getSavedPost({
        limit: this.limit,
        offset: this.offset
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.totalRecord = res.data.totalCount;
            if (res.data.post.length > 0) {
              res.data.post.forEach((value) => {
                this.postData.push(value);
                this.addPropertyForCollapse();
              });
            }
          }
          this.cd.detectChanges();
          this.isLoadingData = (this.postData.length < this.totalRecord) ? true : false;
          resolve('Done');
        });
    });
  }

  userPostImagePath(image) {
    return `${environment.s3BucketUserPostUrl}/${image}`;
  }

  userProfilePath(image) {
    return `${environment.s3BucketProfileUrl}/${image}`;
  }

  async editPost(data, index, content) {
    this.userPostForm.reset();
    this.fileList = [];
    this.userPostForm.patchValue({
      message: data.message,
      userId: this.currentUser.id
    });
    this.userPostMode = 'update';
    this.updatePost = {};
    this.updatePost = _.merge({}, data)
    await this.modalService.open(content, { centered: true });
  }

  removePostImage(index) {
    this.updatePost.images.splice(index, 1);
  }

  deleteUserPost(post, index) {
    this.userPostService.deletePost(post.id)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.postData.splice(index, 1);
          this.toasterService.success('Your post deleted.', 'Success')
        }
      });
  }

  postReactionClass(post) {
    if (post.like.length > 0) {
      let currentUserLike = post.like.find((e) => this.currentUser.id === e.userId);
      if (currentUserLike && currentUserLike != undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  savePostClass(post) {
    if (post.savedPost.length > 0) {
      let currentUserSavedPost = post.savedPost.find((e) => this.currentUser.id === e.userId);
      if (currentUserSavedPost && currentUserSavedPost != undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  updateReaction(post) {
    let isLiked = this.postReactionClass(post);
    let temp: any = {};
    temp.postId = post.id;
    temp.userId = this.currentUser.id;
    temp.isLiked = isLiked;
    temp.postOwnerId = post.user.id

    this.userPostService.postReaction(temp)
      .subscribe((res) => {
        if (res.statusCode === 200) {
        }
      });
  }

  updateSavedPost(post, index) {
    let isSaved = this.savePostClass(post);
    let temp: any = {};
    temp.postId = post.id;
    temp.userId = this.currentUser.id;
    temp.isSaved = isSaved;

    this.userPostService.savedPost(temp)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (isSaved) {
            this.postData.splice(index, 1);
          }
        }
      });
  }

  addComment(postId, post) {
    if (this.comment) {
      this.userPostService.addComment({
        postId: postId,
        userId: this.currentUser.id,
        comment: this.comment,
        postOwnerId: post.user.id
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.comment = null;
            let index = this.postData.findIndex((e) => e.id === postId);

            if (index >= 0) {
              this.setCollapseValueOfComment(this.postData[index]);
            }
          }
        });
    }
  }

  setCollapseValueOfComment(post) {
    post.activityComment = !post.activityComment;
    if (post.comments.length > 0) {
      post.isCollapsed = false;
    } else {
      post.isCollapsed = true;
    }
  }


  closeActivitySection() {
    document.getElementById("user-activity").classList.remove("user-chat-show");
    document.getElementById("side-menu-block").classList.remove("hide-sidebar-mobile");
  }

  ngOnDestroy() {
    if (this.notificationSubscriber) {
      this.notificationSubscriber.unsubscribe();
    }
    if (this.userSelectedTabSubscriber) {
      this.userSelectedTabSubscriber.unsubscribe();
    }
  }
}
