export const environment = {
    production: true,
    version: 'sfu_1.0',
    callinit: 'singalr',//"firebase"
    jitsi: 'logo.dhumi.com',
    domain: 'https://harish.iokonic.in',
    // domain: 'http://localhost:4300',
    subDomain: 'dhumi.com',
    cookieUrl: null,
    docFileUrl: "https://192.168.29.161:4021",
    apiEndPoint: 'https://backend.dhumi.com',
    documentApiEndPoint: 'https://windows.dhumi.com',

    s3BucketProfileUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/profile',
    s3BucketTeamUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/teams',
    s3BucketChatUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/chat',
    s3BucketUserPostUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/newsfeed',

    // firebase: {
    //   apiKey: "AIzaSyBg8FGZI1sgtJGa6oQso_n_Pu0q4XubV3I",
    //   authDomain: "dhumi-app.firebaseapp.com",
    //   databaseURL: "https://dhumi-app.firebaseio.com",
    //   projectId: "dhumi-app",
    //   storageBucket: "dhumi-app.appspot.com",
    //   messagingSenderId: "1017562562721",
    //   appId: "1:1017562562721:web:b8ac3a7d1691b1ceb1f0e0",
    //   measurementId: "G-02ZH1VB40F"
    // },
    firebase: {
        apiKey: "AIzaSyA--iHNHccmy9YD2Zude0C3oiTnrVau4pA",
        authDomain: "dhumifirebase.firebaseapp.com",
        databaseURL: "https://dhumifirebase-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "dhumifirebase",
        storageBucket: "dhumifirebase.appspot.com",
        messagingSenderId: "779467821780",
        appId: "1:779467821780:web:a5b1584b0d89f6aefaa141",
        measurementId: "G-HRTJCYM77F"
    },

    signalRUrl: 'https://signal.dhumi.com/',
    wopiUrl: "http://localhost:81/",
    ICE_SERVERS: [
        // { urls: 'stun:stun.l.google.com:19302' },
        // { urls: 'stun:stun.l.google.com:19302' },
        {
            urls: ['stun:34.227.21.117:3478'],
            credential: 'root',
            username: 'hardik123'
        },
        {
            urls: ['turn:34.227.21.117:3478'],
            credential: 'root',
            username: 'hardik123'
        }
    ],
};
