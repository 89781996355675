import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConferenceCallDialogModel } from '../model/conference.call.dialog.model';
import { ConferenceCallDialogComponent } from '../component/dialog/conference-call-dialog/conference.call.dialog.component';
import { ConferenceAlertDialogComponent } from '../component/dialog/conference-alert-dialog/conference.alert.dialog.component';
import { ConferenceSelectUserDialogComponent } from '../component/dialog/conference-select-user-dialog/conference.selectuserdialogdialog.component';
import { ConferenceCallLogDetilsDialogComponent } from '../component/dialog/conference-call-logs-detials-dialog/conference.calllogsdetials.dialog.component';
import { ConferenceAlertDialogModel } from '../model/conference.alert.dialog.model';
import { ConferenceCallLogDetailsDialogModel } from '../model/conference.calllogdetail.dialog.model';

@Injectable({
  providedIn: 'root'
})
export class  ConferenceDialogService {
  // isExtraLarge: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  constructor(
    // private breakpointObserver: BreakpointObserver,
    private _calldialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

    callDialog(type: string, conferenceName: string, creatorUserName: string,  actioncallback, maxWidth = "30%"): any {

    if (type == "incomingCall") {
        const dialogData = new ConferenceCallDialogModel(conferenceName, creatorUserName);

        const dialogRef = this._calldialog.open(ConferenceCallDialogComponent, {
        width:"37%",
        maxWidth: "583px",
        height: "66%",
        maxHeight:"521px",
        data: dialogData,
        disableClose: true,
       
        panelClass: 'modalbox__calldialog'
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        setTimeout(() => {
          if (actioncallback)
            actioncallback(dialogResult);
        }, 100);
      });

      return dialogRef;
    }

  }


  alertDialog(title: string, message: string,isCinfirmType:boolean, actioncallback, maxWidth = "30%"): any {

      const dialogData = new ConferenceAlertDialogModel(title, message, isCinfirmType);

      const dialogRef = this._calldialog.open(ConferenceAlertDialogComponent, {
      maxWidth: maxWidth,
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (actioncallback)
        actioncallback(dialogResult);
    });

    return dialogRef;
  }

  selectUserDialog( actioncallback): any {

      const dialogData = {};// new UserModel();

      const dialogRef = this._calldialog.open(ConferenceSelectUserDialogComponent, {
      
      maxWidth: '40vh',
      data: dialogData,
      disableClose: true,
     
    });
    // const largeDialogSubscription = this.isExtraLarge.subscribe(size => {
    //           if (size.matches) {
    //             dialogRef.updateSize('100%', '100%');
    //           } else {
    //             dialogRef.updateSize('40%', '40%');
    //           }
    //         })
    dialogRef.afterClosed().subscribe(dialogResult => {
      // largeDialogSubscription.unsubscribe();
      if (actioncallback)
        actioncallback(dialogResult);
    });

    return dialogRef;
  }

  callLogDetailsDialog(title: string, type: string, username: string, logs: any [],currentusename): any {

      const dialogData = new ConferenceCallLogDetailsDialogModel(title, type, username, logs, currentusename);

      const dialogRef = this._calldialog.open(ConferenceCallLogDetilsDialogComponent, {
      height:'85%',
      minWidth: '60vh',
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      
    });

    return dialogRef;
  }



  alert(message: string) {
    this._snackBar.open(message, "Alert", {
       // duration: 10000,
    });
  }

  warning(message: string) {
    this._snackBar.open(message, "Warning", {
      duration: 10000,
    });
  }

  error(message: string) {
    this._snackBar.open(message, "Error", {
        duration: 10000,
    });
  }
  
}
