import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    public loginSuccess: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private cookieService: CookieService,
        private router: Router,
    ) {
    }

    signOut(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.loginSuccess.next(false);
        try {
            this.cookieService.delete(TOKEN_KEY, '/', environment.cookieUrl);
            this.cookieService.delete(USER_KEY, '/', environment.cookieUrl);
        } catch (error) {
            console.log(error)
        }
    }

    getExpiryFromToken(token): Date {
        let decode: any = jwt_decode(token);
        return moment.unix(decode.exp).toDate();
    }

    public saveToken(token: string): void {
        this.cookieService.set(TOKEN_KEY, token, {
            expires: this.getExpiryFromToken(token),
            path: '/',
            domain: environment.cookieUrl,
            secure: environment.production,
            sameSite: 'Lax'
        });
    }

    public saveUser(user: any): void {
        this.cookieService.set(USER_KEY, JSON.stringify(user), {
            expires: this.getExpiryFromToken(user.token),
            path: '/',
            domain: environment.cookieUrl,
            secure: environment.production,
            sameSite: 'Lax'
        });
    }

    public getToken(): string | null {
        return this.cookieService.get(TOKEN_KEY);
    }

    public getUser(): any {
        const user: string = this.cookieService.get(USER_KEY);
        if (user != '' && user.length > 0) {
            return JSON.parse(user);
        } else {
            this.router.navigate(['/account/login']);
            this.signOut();
            return null;
        }
    }

    public getCurrentUserEmail() {
        const user: string = this.cookieService.get(USER_KEY);
        if (user != '' && user.length > 0) {
            let temp = JSON.parse(user);
            return temp.email;
        }
    }
    setCookiesStorage(name, data) {
        let dataJSON = JSON.parse(data);
        this.cookieService.set(name, data);
    }
}