import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './conference.callend.component.component.html',
    styleUrls: ['./conference.callend.component.component.scss']
})
export class ConferenceCallEndComponent implements OnInit {

  constructor() { }

    ngOnInit(): void {
        setTimeout(() => {
            window.close();
        }, 5000)
  }
    close() {
        window.close();
    }
}
