import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConferenceService {

  url_meeting = `conference-meeting/`;
  constructor(private http: HttpClient) { }

  getConferenceLog(data) {
    let params = new HttpParams();
    params = params.append('username', data.username);
    params = params.append('mode', data.mode);
    params = params.append('offset', data.offset);
    params = params.append('sorting', data.sorting);
    return this.http.get(`@api-nest/conference/get-conference-user-logs-by-username`, { params }).pipe(retry(1), catchError(this.handleError));
    // params = params.append('pageIndex', data.pageIndex);
    // params = params.append('pageSize', data.pageSize);
    // return this.http.get(`@api-nest/conference/get-conference-user-logs-by-username`, { params }).pipe(retry(1), catchError(this.handleError));
  }

  getConferenceLogByConfId(data) {
    let params = new HttpParams();
    params = params.append('confId', data.confId);
    params = params.append('mode', data.mode);
    // params = params.append('offset', data.offset);
    // params = params.append('sorting', data.sorting);
    return this.http.get(`@api-nest/conference/get-conference-by-confId`, { params }).pipe(retry(0), catchError(this.handleError));
  }

  updateConferenceLog(data) {
    return this.http.put<any>(`@api-nest/conference/update-conference`, data).pipe(retry(1), catchError(this.handleError));
  }
  saveGuestUsername(data) {
    return this.http.put<any>(`@api-nest/conference/save-guest-username`, data).pipe(retry(1), catchError(this.handleError));
  }
  createConferenceLog(data) {
    return this.http.post<any>(`@api-nest/conference/create-conference`, data).pipe(retry(1), catchError(this.handleError));
  }
  getConferenceLogByConfid(confid) {
    return this.http.get<any>(`@api-nest/conference/get-conference-by-confId/` + confid).pipe(retry(1), catchError(this.handleError));
  }
  getConferenceLogByUserName(username) {
    return this.http.get<any>(`@api-nest/conference/getConferenceLogByUserName/` + username).pipe(retry(1), catchError(this.handleError));
  }
  createConferenceUserLog(data) {
    return this.http.post<any>(`@api-nest/conference/create-conference-user-log`, data).pipe(retry(1), catchError(this.handleError));
  }
  updateConferenceUserLog(data) {
    return this.http.put<any>(`@api-nest/conference/update-conference-user-log`, data).pipe(retry(1), catchError(this.handleError));
  }
  getConferenceUserLogByUername(data) {
    return this.http.get<any>(`@api-nest/conference/get-conference-user-logs-by-username`, data).pipe(retry(1), catchError(this.handleError));
  }

  // **********************meetingURL*********************
  get_uniqueconferencemeetingnumber(confnumber: string): Observable<any> {
    if (confnumber)
      return new Observable<any[]>(observer => {
        observer.next([{ rnd: confnumber }]);
      });
    return this.http.get('@api-nest/' + this.url_meeting + 'get_uniqueconferencemeetingnumber');
  }

  getConferenceMeetingByNumber(meetingnumber) {
    let params = new HttpParams().set('meetingNumber', meetingnumber);
    return this.http.get<any>('@api-nest/' + this.url_meeting + 'get_conferencemeeting_bynumber', { params }).pipe(retry(1), catchError(this.handleError));
  }
  
  getConferenceMeetingRestrictionsByNumber(meetingnumber) {
    let params = new HttpParams().set('meetingNumber', meetingnumber);
    return this.http.get<any>('@api-nest/' + this.url_meeting + 'get_conferencemeetingrestriction_bynumber', { params }).pipe(retry(1), catchError(this.handleError))
  }
  createConferenceMeeting(data) {
    console.log("createConferenceMeeting");
    return this.http.post<any>('@api-nest/' + this.url_meeting + 'create_conference_meeting', data).pipe(retry(1), catchError(this.handleError));
  }
  updateConferenceMeeting(data) {
    console.log('udateconferencelog');
    return this.http.post<any>('@api-nest/' + this.url_meeting + 'update_conference_meeting', data).pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    console.log(error)
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
