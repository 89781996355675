import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum AccessType {
  CanRead,
  CanWrite,
  CanDelete,
  CanDownload,
  CanChangePermission,

}
@Injectable({
  providedIn: 'root'
})
export class DocumentUtilsService {
  public msOfficeExts = ["doc", "docx", "dot", "wbk", "docm", "dotm", "dotx", "docb",
    "xls", "xlsx", "xlsm", "xltx", "xltm", "xlsb", "xla", "xlam", "xll", "xlw",
    "ppt", "pot", "pps", "pptx", "pptm", "potx", "potm", "ppam", "ppsx", "ppsm", "sldx", "sldm",
    "pup", "wopitest"
  ];
  public imageExts = ["png", "jpeg", "jpg", "webp", "tiff", "bmp", "pgm", "gif"];


  constructor() { }

  public checkAccess(accesslist, currentUser_username: string, accessType: AccessType, CanCallback, CannotCallback) {
    if (!accesslist) {
      CannotCallback();
      return;
    }

    if (accesslist.length == 0) {
      CanCallback();
      return;
    }

    console.log('accesslist', accesslist)


    var myAcess = accesslist.filter(x => x.username == currentUser_username);
    var everyonefilterAcess = accesslist.filter(x => x.username == "everyone");

    if (myAcess.length == 0 && everyonefilterAcess.length == 0) {
      CannotCallback();
      return;
    }

    for (var i = 0; i < myAcess.length; i++) {
      var access = myAcess[i];
      if (accessType == AccessType.CanRead) {
        access.canRead == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanWrite) {
        access.canWrite == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanDelete) {
        access.canDelete == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanDownload) {
        access.canDownload == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanChangePermission) {
        access.canChangePermission == 1 ? CanCallback() : CannotCallback();
        return;
      }
    }


    for (var i = 0; i < everyonefilterAcess.length; i++) {
      var access = everyonefilterAcess[i];
      if (accessType == AccessType.CanRead) {
        access.canRead == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanWrite) {
        access.canWrite == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanDelete) {
        access.canDelete == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanDownload) {
        access.canDownload == 1 ? CanCallback() : CannotCallback();
        return;
      }
      if (accessType == AccessType.CanChangePermission) {
        access.canChangePermission == 1 ? CanCallback() : CannotCallback();
        return;
      }
    }
  }

  public cacheDecodeIdentifier(fileId, serviceObservable): Observable<any> {
    return new Observable(observer => {
      var decodeIdentifier = localStorage.getItem('decodeIdentifier');
      if (decodeIdentifier) {
        var fileNames = JSON.parse(localStorage.getItem('decodeIdentifier'));
        if (fileNames) {

          for (var i = 0; i < fileNames.length; i++) {
            if (fileNames[i].fileId == fileId) {
              observer.next(fileNames[i].name);
              observer.complete();
              return;
            }
          }
        }
      }
      serviceObservable.subscribe(res => {
        if (!decodeIdentifier) {
          localStorage.setItem('decodeIdentifier', JSON.stringify([]));
        }
        var fileNames = JSON.parse(localStorage.getItem('decodeIdentifier'));
        fileNames.push({ fileId: fileId, name: res });
        localStorage.setItem('decodeIdentifier', JSON.stringify(fileNames));
        observer.next(res);
        observer.complete();
      });
    });
  }

  public getNameOfHashedPath(company_id, hashPath: string) {
    if (!hashPath)
      return "root";
    hashPath = hashPath.replace('/', '\\');
    var paths = hashPath.split('\\');
    var root = "root";
    if (paths.length == 2) {
      root = root + "\\" + this.displayFullFileName(paths[0]);
    } else {
      paths.forEach(path => {
        root = root + "\\" + this.displayFullFileName(path);
      });
    }
    return root.replace('\\.\\.\\' + company_id, '');
  }

  public displayFullFileName(name: string) {
    if (!name)
      return "";
    var splitstr = name.split('#');
    var str = "";
    if (splitstr.length == 1)
      str = this.getNameFromPath(splitstr[0]);
    else
      str = this.getNameFromPath(splitstr[1]);
    return str;
  }

  public isSystemFolder(id) {
    return id === 'myshared' || id == 'commonshared';
  }


  public getNameFromPath(name: string) {
    var namepart = name.split('\\');
    if (namepart.length > 1)
      return namepart[namepart.length - 1];
    else
      return name
  }

  public getExtensionIcon(ext: string) {

    var iconList = ["doc", "docx", "xls", "xlsx", "avi", "pdf", "pptx", "ppt", "mp4", "txt", "xls", "xlsx"];

    var imageiconList = ["png", "jpg", "jpeg", "bmp"];

    var fileicon = iconList.find(x => x == ext);

    if (fileicon)
      return fileicon;

    fileicon = imageiconList.find(x => x == ext);

    if (fileicon)
      return fileicon;

    return "other";
  }

  public cache_decodeIdentifier(fileId, sericeObservable): Observable<any> {

    return new Observable(observer => {
      var decodeIdentifier = localStorage.getItem('decodeIdentifier');

      if (decodeIdentifier) {
        var fileNames = JSON.parse(localStorage.getItem('decodeIdentifier'));
        if (fileNames) {

          for (var i = 0; i < fileNames.length; i++) {
            if (fileNames[i].fileId == fileId) {
              observer.next(fileNames[i].name);
              observer.complete();
              return;
            }
          }
        }
      }

      sericeObservable.subscribe(res => {
        if (!decodeIdentifier) {
          localStorage.setItem('decodeIdentifier', JSON.stringify([]));
        }
        var fileNames = JSON.parse(localStorage.getItem('decodeIdentifier'));
        fileNames.push({ fileId: fileId, name: res });
        localStorage.setItem('decodeIdentifier', JSON.stringify(fileNames));

        observer.next(res);
        observer.complete();

      });
    });
  }

  public displayFileSize(size: number) {

    let kb: number = size / 1000;


    if (kb > 1000) {
      var mb = kb / 1000;
      if (mb > 1000) {
        var gb = mb / 1000;
        if (gb > 1000)
          return gb + " GB";
        else
          return gb + " GB";
      }
      else
        return mb + " MB";
    }
    else
      return kb + " KB";

  }

  public displayFileName(name: string) {
    if (!name)
      return "";

    var splitstr = name.split('#');
    var str = "";
    if (splitstr.length == 1)
      str = this.getNameFromPath(splitstr[0]);
    else
      str = this.getNameFromPath(splitstr[1]);

    var fileExt = str.split('.');

    str = fileExt[0].substr(0, 15);

    if (fileExt[0].length > 15)
      str += "..." + (fileExt.length > 1 ? "." + fileExt[1] : '');
    else
      str += (fileExt.length > 1 ? "." + fileExt[1] : '');

    return str;
  }

}
