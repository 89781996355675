import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AccessType, DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { FileElement } from 'src/app/core/services/comman/file.model';
import { DocumentService } from 'src/app/core/services/document.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { ClipboardService } from 'ngx-clipboard'
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertyModalComponent } from '../property-modal/property-modal.component';
import { ObservableService } from 'src/app/core/services/observable.service';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent implements OnInit {

  @Input() isUnderSystemDirectory = true;
  @Input() isInRootDirectory = true;
  @Input() currentUser: any = {};
  @Input() currentfolderid: string;
  @Input() fileElements = [];
  @Input() canNavigateUp: string;
  @Input() path: string;
  @Input() pathid: string;
  @Input() cliboard: any;

  @Input() clearSelection = new EventEmitter<any>();
  @Input() favouriteElement = new EventEmitter<any>();
  @Input() clickOutSide = new EventEmitter<any>();

  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() downloadElement = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() updateFolder = new EventEmitter();
  @Output() uploadFile = new EventEmitter();
  @Output() selectFileElementEmitter = new EventEmitter<FileElement>();
  @Output() elementCut = new EventEmitter<FileElement>();
  @Output() elementCopied = new EventEmitter<FileElement>();
  @Output() elementPaste = new EventEmitter<FileElement>();
  @Output() refreshed = new EventEmitter<FileElement>();
  selectFileElement: FileElement = null;

  allFileFolderList;

  renameForm: FormGroup;

  rightClickMenuOption = {
    open: true,
    copy: true,
    cut: true,
    paste: true,
    favorite: true,
    rename: true,
    delete: true,
    copyLink: true,
    properties: true,
    download: true,
    restore: false,
  }

  staticAlertClosed = false;
  successMessage = '';
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  constructor(
    private serverDocumentService: ServerDocumentService,
    private documentService: DocumentService,
    private toasterService: ToasterService,
    private documentUtils: DocumentUtilsService,
    private _clipboardService: ClipboardService,
    private tokenService: TokenStorageService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private obs: ObservableService
  ) { }

  ngOnInit(): void {
    if (this.staticAlert) {
      setTimeout(() => this.staticAlert.close(), 20000);
    }

    this.obs._success.subscribe(message => this.successMessage = message);

    this.obs._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this.currentUser = this.tokenService.getUser();

    this.clearSelection.subscribe(x => {
      this.selectFileElement = null;
    });

    this.favouriteElement.subscribe(x => {
      this.dofavouriteElement(this.selectFileElement);
    });

    this.clickOutSide.subscribe(x => {
      setTimeout(() => {
        this.selectFileElement = null;
        this.selectFileElementEmitter.emit(this.selectFileElement);
      });
    });

    this.allFileFolderList = this.fileElements;

    this.obs.docsSidebarTab.subscribe((value: any) => {
      if (value.tab === 'favorite' || value.tab === "recent delete") {
        this.fileElements = [];
        value.data.forEach((e) => {
          // console.log(e)
          this.fileElements.push({
            id: e.id,
            fileId: e.fileId,
            isFolder: e.isFolder,
            name: e.fileName,
            parent: '',
            action: e.action,
            actionParam: e.actionParam
          });
        })
      } else if (value.tab === 'file') {
        this.updateFolder.emit();
        setTimeout(() => {
          this.fileElements = this.fileElements.filter((e) => !e.isFolder)
        }, 500);
      } else if (value.tab === 'folder') {
        this.updateFolder.emit();
        setTimeout(() => {
          console.log(this.fileElements)
          this.fileElements = this.fileElements.filter((e) => e.isFolder)
        }, 500);
      } else if (value.tab === 'all') {
        this.updateFolder.emit();
      }

      if (value.tab === 'recent delete') {
        this.rightClickMenuOption = {
          open: false,
          copy: false,
          cut: false,
          paste: false,
          favorite: false,
          rename: false,
          delete: false,
          copyLink: false,
          properties: false,
          download: false,
          restore: true,
        }
      } else if (value.tab === 'favorite') {
        this.rightClickMenuOption = {
          open: true,
          copy: true,
          cut: true,
          paste: false,
          favorite: false,
          rename: true,
          delete: true,
          copyLink: true,
          properties: true,
          download: true,
          restore: false,
        }
      } else if (value.tab === 'file' || value.tab === 'folder' || value.tab === 'all') {
        this.rightClickMenuOption = {
          open: true,
          copy: true,
          cut: true,
          paste: true,
          favorite: true,
          rename: true,
          delete: true,
          copyLink: true,
          properties: true,
          download: true,
          restore: false,
        }
      }
    });
  }

  get renameF() { return this.renameForm.controls; }

  dofavouriteElement(element: FileElement) {
    this.documentService.getFavoriteDocumentByFileId(element.id).subscribe(x => {

      if (x.data.length != 0) {
        // this.toasterService.error("Already In Favorite List  !!", "Error")
        this.obs._success.next('Already In Favorite List  !!');
        return;
      }

      this.documentService.createDocumentFavorite({
        companyId: this.currentUser.companyId, isFolder: element.isFolder,
        fileId: element.id, favoriteBy: this.currentUser.username
      }).subscribe(x => {
        // this.toasterService.success("Favorite Completed!!", "Success");
        this.obs._success.next('Favorite Completed!!');
        this.refresh();
      });
    });
  }

  selectElement($event, element: FileElement) {
    if ($event) {
      event.stopPropagation();
    }
    this.selectFileElement = element;
    this.selectFileElementEmitter.emit(this.selectFileElement);
  }

  selectElementCss(element: FileElement) {
    if (!this.selectFileElement)
      return "";
    else
      return element.id == this.selectFileElement.id ? 'folder-card_selected' : ''
  }

  displayFullFileName(name: string) {
    return this.documentUtils.displayFullFileName(name);
  }

  navigate(element: FileElement) {
    this.navigatedDown.emit(element);
  }

  isSystemFolder(id) {
    return this.documentUtils.isSystemFolder(id);
  }

  getExtensionIcon(ext: string) {
    return this.documentUtils.getExtensionIcon(ext);
  }

  refresh() {
    this.refreshed.emit();
  }

  copyFileLink(element: FileElement) {
    //http://192.168.1.5:4021/#/external-link?fileid=Llw1XDI2ZTEwNGQzLTU3ZDMtNDk5MC05OWY3LTQ3YTZiOGVhOGQ5YSNSQUdFU0ggUFVOQVRISUwtIDIwMTkwOTE4LnBkZg%3D%3D
    var fileLink = environment.docFileUrl + "#/external-link?fileid=" + encodeURIComponent(element.id);
    this._clipboardService.copyFromContent(fileLink);
    // this.toasterService.success("Link Copied To Clipboard", "Success");
    this.obs._success.next('Link Copied To Clipboard');
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  deleteElement(element: FileElement) {
    this.elementRemoved.emit(element);
  }

  openRenameDialog(element, html) {
    this.documentService.getDocumentAccessByFileId(element.id).subscribe(async accesslist => {
      await this.documentUtils.checkAccess(accesslist.data, this.currentUser.username, AccessType.CanDelete, () => {
        this.modalService.open(html, { centered: true, modalDialogClass: 'create-file-modal' });
        this.renameForm = this.formBuilder.group({
          name: ['', [Validators.required]],
        });
      }, () => {
        // this.toasterService.error("Permission denied", "Error");
        this.obs._success.next('Permission denied');
      });
    });
  }

  submitRenameDialog() {
    if (this.renameForm.invalid) {
      return;
    }
    let element = this.selectFileElement;
    element.name = this.renameForm.value.name;
    this.elementRenamed.emit(element);
  }

  propertiesModal(html) {
    let element = this.selectFileElement;
    let modal = this.modalService.open(PropertyModalComponent, {
      centered: true,
      modalDialogClass: 'create-file-modal',
    });

    modal.componentInstance.data = {
      elementId: element.id, isFolder: element.isFolder
    }
  }

  downloadClick(element) {
    this.downloadElement.emit(element)
  }

  copyElementDialog(element) {
    this.elementCopied.emit(element)
  }

  pasteElement(element) {
    this.elementPaste.emit(element)
  }

  cutElement(element) {
    this.elementCut.emit(element);
  }

}
