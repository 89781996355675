<div class="user-profile-sidebar" id="profile-detail" style="display: none;">
  <div class="px-3 px-lg-4 pt-3 pt-lg-4">
    <div class="user-chat-nav text-end">
      <button type="button" class="btn nav-btn" id="user-profile-hide" (click)="hideUserProfile()">
        <i class="ri-close-line"></i>
      </button>
    </div>
  </div>

  <div class="p-4 user-profile-desc">
    <div id="profile-user-accordion" class="custom-accordion">
      <ngb-accordion #acc="ngbAccordion" (panelChange)="beforeChange($event)" activeIds="file" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-0" id="file">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>
              {{'chat.rightpanel.attachedfile.text' | translate}}
            </h5>
          </ng-template>
          <ng-template ngbPanelContent *ngIf="files.length > 0">
            <perfect-scrollbar class="file-list-scroll" (psYReachEnd)="loadFiles()">
              <div class="card p-2 border mb-2" *ngFor="let file of files">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0 flex-shrink-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill" *ngIf="file?.messageType === 'file'"></i>
                      <i class="ri-image-fill" *ngIf="file?.messageType === 'image'"></i>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1 accordion-file-title">{{file?.userFileName}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{file?.updatedAt | date: 'short': 'UTC'}}
                      </p>
                    </div>
                  </div>
                  <div class="ml-4">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a [href]="fileUrl(file?.s3FileName)" [download]="file?.userFileName" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </ng-template>
          <ng-template ngbPanelContent *ngIf="files.length === 0">
            <span>No file found.</span>
          </ng-template>
        </ngb-panel>


      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </div>
  <!-- end user-profile-desc -->
</div>