<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="addContact-exampleModalLabel">
            {{'chat.tabs.contacts.modal.title' | translate}}</h5>
        <button type="button" class="btn-close" (click)="closeModal()">
        </button>
    </div>
    <div class="modal-body p-4">
        <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <div class="mb-3">
                <label class="form-label" for="addcontactemail-input">{{'chat.tabs.contacts.modal.form.email.label'
                    | translate}}</label>
                <tag-input [(ngModel)]='emails' name="emails" #email="ngModel" [errorMessages]="errorMessages"
                    [validators]="validators" [editable]='true' [separatorKeyCodes]="[32,188,186,13,9]"
                    [placeholder]="'Add email'" [secondaryPlaceholder]="'Enter email address(es)'" [clearOnBlur]="true"
                    [addOnPaste]="true" [addOnBlur]="true" theme='bootstrap' required>
                </tag-input>
            </div>
            <div class="mb-3">
                <label class="form-label"
                    for="addcontact-invitemessage-input">{{'chat.tabs.contacts.modal.form.message.label' | translate}}
                </label>
                <textarea [(ngModel)]="message"  name="message" required class="form-control" rows="3"
                    placeholder="{{'chat.tabs.contacts.modal.form.message.placeholder' | translate}}"></textarea>

                <div *ngIf="f.submitted && message.invalid" class="invalid-feedback">
                    <div *ngIf="message.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link">{{'chat.tabs.contacts.modal.form.button.close' |
                    translate}}</button>
                <button type="submit" class="btn btn-primary">{{'chat.tabs.contacts.modal.form.button.contact' |
                    translate}}</button>
            </div>
        </form>
    </div>
</div>