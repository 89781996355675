import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  getRecentDocument(data) {
    let params = new HttpParams();
    params = params.append('action', data.action);
    params = params.append('companyId', data.companyId);
    params = params.append('limit', data.limit);
    return this.http.get<any>(`@api-nest/document/get-recent-document-log`, { params }).pipe(retry(0), catchError(this.handleError));
  }

  getDocumentAccessByFileId(fileId) {
    let params = new HttpParams().set("fileId", fileId);
    return this.http.get<any>(`@api-nest/document/get-document-access-by-fileId`, { params: params }).pipe(retry(0), catchError(this.handleError));

  }

  getFavoriteDocumentByFileId(fileId) {
    let params = new HttpParams().set("fileId", fileId);
    return this.http.get<any>(`@api-nest/document/get-document-favorite-by-fileId`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  createDocumentsAccess(data) {
    return this.http.post<any>(`@api-nest/document/create-document-access`, data).pipe(retry(0), catchError(this.handleError));
  }

  createDocumentLog(data) {
    return this.http.post<any>(`@api-nest/document/create-document-log`, data).pipe(retry(0), catchError(this.handleError));
  }

  createDocumentFavorite(data) {
    return this.http.post<any>(`@api-nest/document/create-document-favorite`, data).pipe(retry(0), catchError(this.handleError));
  }

  updateDocumentAccess(data) {
    return this.http.put<any>(`@api-nest/document/update-document-access-file-name`, data).pipe(retry(0), catchError(this.handleError));
  }

  updateDocumentLog(data) {
    return this.http.put<any>(`@api-nest/document/update-document-log`, data).pipe(retry(0), catchError(this.handleError));
  }

  getAllFavoritesFilesByCompanyId(data) {
    let params = new HttpParams().set("favoriteBy", data.favoriteBy ? data.favoriteBy : '');
    params = params.append('companyId', data.companyId);
    return this.http.get<any>(`@api-nest/document/get-conference-document-favorite-by-username-companyId`, { params }).pipe(retry(0), catchError(this.handleError));
  }

  deleteDocumentShared(id) {
    let params = new HttpParams();
    params = params.append('fileId', id);

    return this.http.delete<any>(`@api-nest/document/delete-document-shared`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
