
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ConferenceAlertDialogModel } from '../../../model/conference.alert.dialog.model';

@Component({
    templateUrl: './conference.alert.dialog.component.html',
    styleUrls: ['./conference.alert.dialog.component.css']
})
export class ConferenceAlertDialogComponent implements OnInit {
  title: string;
  message: string;

    constructor(public dialogRef: MatDialogRef<ConferenceAlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConferenceAlertDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

