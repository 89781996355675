<!-- Start profile content -->
<perfect-scrollbar class="chat-scroll">
  <div class="chat-leftsidebar me-lg-1">
    <div class="tab-content">
      <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
        <div>
          <div class="px-4 pt-4">
            <div class="user-chat-nav float-end">
              <div class="dropdown" ngbDropdown>
                <a href="javascript: void(0);" class="font-size-18 text-muted dropdown-toggle" ngbDropdownToggle
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript:void(0);"
                    (click)="editProfile()">{{'chat.tabs.profile.dropdown.edit' | translate}}</a>
                  <!-- <a class="dropdown-item" href="#">{{'chat.tabs.profile.dropdown.action' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{'chat.tabs.profile.dropdown.another' | translate}}n</a> -->
                </div>
              </div>
            </div>
            <h4 class="mb-0">{{'chat.tabs.profile.title' | translate }}</h4>
          </div>

          <div class="text-center p-4 border-bottom">
            <div class="mb-4">
              <img id="profilePic" *ngIf="currentUser?.profilePic" [src]="currentUser?.profilePic"
                (error)="setDefaultPic($event)" class="rounded-circle avatar-lg img-thumbnail" alt="">
              <img id="profilePic" *ngIf="!currentUser?.profilePic" src="assets/images/users/profile.png"
                (error)="setDefaultPic($event)" class="rounded-circle avatar-lg img-thumbnail" alt="">
            </div>

            <h5 class="font-size-16 mb-1 text-truncate">{{currentUser?.firstname}} {{currentUser?.lastname}}</h5>
            <p class="text-muted text-truncate mb-1">
              <i class="ri-record-circle-fill font-size-10 text-danger d-inline-block ms-1" container="body"
                [ngbTooltip]="currentUser?.onlineStatus" [ngClass]="{
          'text-success': currentUser?.onlineStatus === 'Online',
          'text-warning': currentUser?.onlineStatus === 'Away', 
          'text-danger': currentUser?.onlineStatus === 'Do not disturb', 
          'text-body': currentUser?.onlineStatus === 'Offline'}"></i>
              {{currentUser?.onlineStatus}}
            </p>
          </div>
          <!-- End profile user -->

          <!-- Start user-profile-desc -->
          <perfect-scrollbar class="p-4 user-profile-desc">
            <!-- 
      <div class="text-muted">
        <p class="mb-4">{{'chat.tabs.profile.message'| translate}}</p>
      </div> -->

            <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
              <ngb-panel cardClass="card shadow-none border mb-2" id="about">
                <ng-template ngbPanelTitle>
                  <h5 class="font-size-14 m-0">
                    <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
                    {{'chat.tabs.profile.about.text' | translate}}
                  </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <p class="text-muted mb-1">Company</p>
                    <h5 class="font-size-14">{{currentUser?.company?.name}}</h5>
                  </div>

                  <div class="mt-4">
                    <p class="text-muted mb-1">Name</p>
                    <h5 class="font-size-14">{{currentUser?.firstname}} {{currentUser?.lastname}}</h5>
                  </div>

                  <div class="mt-4">
                    <p class="text-muted mb-1">Username</p>
                    <h5 class="font-size-14">{{ currentUser?.username}}</h5>
                  </div>

                  <div class="mt-4">
                    <p class="text-muted mb-1">Email</p>
                    <h5 class="font-size-14">{{ currentUser?.email}}</h5>
                  </div>

                  <div class="mt-4">
                    <p class="text-muted mb-1">Mobile</p>
                    <h5 class="font-size-14">{{ currentUser?.mobile}}</h5>
                  </div>

                  <div class="mt-4">
                    <p class="text-muted mb-1">Joining Date</p>
                    <h5 class="font-size-14">{{ currentUser?.createdAt | date: 'short': 'UTC'}}</h5>
                  </div>

                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel cardClass="card shadow-none border mb-2" id="file">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>
              {{'chat.tabs.profile.attachedfile.text' | translate}}
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file1.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file1.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file2.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file2.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="javascript: void(0);" ngbDropdownToggle role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file3.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file3.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="#" ngbDropdownToggle role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
    

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.tabs.profile.attachedfile.file4.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.tabs.profile.attachedfile.file4.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" href="#" ngbDropdownToggle role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">{{'chat.tabs.profile.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        
          </ng-template>
        </ngb-panel> -->
            </ngb-accordion>
            <!-- end profile-user-accordion -->

          </perfect-scrollbar>
          <!-- end user-profile-desc -->
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>

<!-- End profile content -->