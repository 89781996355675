import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject } from 'rxjs';
import { AccessType, DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { FileService } from 'src/app/core/services/comman/file.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-property-modal',
  templateUrl: './property-modal.component.html',
  styleUrls: ['./property-modal.component.scss']
})
export class PropertyModalComponent implements OnInit {
  @Input() public data;

  elementId: any;
  isFolder: boolean;
  accessList: any[];
  name: string;
  size: number;
  actualPath: string;
  currentUser: any;
  loading: Boolean;
  canChangePermission: boolean;
  isCollapsed: boolean = false;


  /** control for the selected user for server side filtering */
  public userServerSideCtrl: FormControl = new FormControl();

  /** control for filter for server side. */
  public userServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching = false;

  /** list of users filtered after simulating server side search */
  public filteredServerSideusers: ReplaySubject<any> = new ReplaySubject<any>(1);
  protected _onDestroy = new Subject<void>();
  userList: any = [];


  constructor(
    public dialogRef: MatDialogRef<PropertyModalComponent>,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private documentUtils: DocumentUtilsService,
    private tokenService: TokenStorageService,
    private documentService: DocumentService,
    private fileService: FileService,
    private serverDocumentService: ServerDocumentService,
    private userService: UserProfileService,
  ) {

  }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.canChangePermission = false;

    if (this.data.isFolder == false) {
      this.serverDocumentService.getWopiDetail(this.data.elementId, this.currentUser.username, this.currentUser.email)
        .subscribe(fileDetails => {
          this.name = fileDetails.file.Name;
          this.size = fileDetails.file.Size;
          this.actualPath = this.getNameOfHashedPath(fileDetails.ActualPath);

          this.documentService.getDocumentAccessByFileId(this.data.elementId).subscribe(res => {
            this.accessList = res.data;
            if (res.data.length == 0)
              this.accessList.push({
                id: 0, isfolder: false, fileId: this.data.elementId, username: "everyone", companyId: this.currentUser.companyId,
                canRead: 1, canWrite: 1, canDelete: 1, canDownload: 1, canChangePermission: 1
              });

            this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanChangePermission, () => {
              this.canChangePermission = true;
            }, () => {
              this.canChangePermission = false;
            });
          });
        });
    } else {
      this.serverDocumentService.getwopifolderdetail(this.data.elementId).subscribe(fileDetails => {
        this.loading = false;
        this.name = fileDetails.details.Name;
        this.size = fileDetails.details.Size;
        this.actualPath = this.getNameOfHashedPath(fileDetails.actualPath);
        this.documentService.getDocumentAccessByFileId(this.data.elementId).subscribe(res => {
          this.accessList = res.data;
          if (res.data.length == 0)
            this.accessList.push({
              id: 0, isFolder: false, fileId: this.data.elementId, username: "everyone", companyId: this.currentUser.companyId,
              canRead: 1, canWrite: 1, canDelete: 1, canDownload: 1, canChangePermission: 1
            });


          this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanChangePermission, () => {

            this.canChangePermission = true;
          }, () => {
            this.canChangePermission = false;
          });
        });
      });
    }

    this.getUserList();
  }

  async getUserList() {
    try {
      this.userService.getUserList().subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            this.userList = res.data;
            this.userList = _.groupBy(this.userList, function (item) { return item.firstname[0].toUpperCase(); });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  onCheckboxChange(e, userDetails) {
    if (e.target.checked) {
      this.accessList.push({
        id: 0, isFolder: this.accessList[0].isFolder, fileId: this.accessList[0].fileId, username: userDetails.username, companyId: this.currentUser.companyId,
        canRead: 0, canWrite: 0, canDelete: 0, canDownload: 0, canChangePermission: 0
      });
    }
  }

  removeUser(username) {
    this.accessList.forEach((value, index) => {
      if ((value as any).username == username) {
        this.accessList.splice(index, 1);
      }
    });
  }

  displayFileSize(size: number) {
    return this.documentUtils.displayFileSize(size);
  }

  displayFileName(name: string) {
    return this.documentUtils.displayFileName(name);
  }

  getNameOfHashedPath(hashPath: string) {
    return this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, hashPath);

  }
  displayFullFileName(name: string) {
    return this.documentUtils.displayFullFileName(name);
  }

  onConfirm(): void {
    this.documentService.createDocumentsAccess(this.accessList).subscribe();
    this.loading = false;

    if (this.canChangePermission) {
      this.documentService.createDocumentLog({
        isFolder: this.data.isFolder, fileId: this.data.elementId, companyId: this.currentUser.companyId,
        action: "permission changes", actionParam: "", createdBy: this.currentUser.username
      }).subscribe();
    }

    this.modalService.dismissAll();
  }

}
