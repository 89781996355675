/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConferenceCallLogDetailsDialogModel {

    constructor(public title: string, public type: string, public username: string, public logs: any[], public currentusename: string) {
    }
}
