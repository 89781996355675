import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { UserProfileService } from "src/app/core/services/user.service";
import { environment } from "src/environments/environment";

import * as _ from "lodash";
import { Subscription } from "rxjs";

import { SocketService } from "src/app/core/services/socket.service";
import { ObservableService } from "src/app/core/services/observable.service";
import { TokenStorageService } from "src/app/core/services/token.service";
import { ChatService } from "src/app/core/services/chat.service";
import { ChatUserModel, UserListWithLastMessage } from "./chatModel";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  offset = 0;
  limit = 6;
  page = 1;
  totalUserRecord = 0;

  userList: UserListWithLastMessage[] = [];
  onlineUsers: ChatUserModel[] = [];
  searchUserKeyword;

  selectedUser: any;
  currentUser: any;
  getUserStatusSubscriber: Subscription;
  getUserMessageSubscriber: Subscription;

  constructor(
    public translate: TranslateService,
    public userService: UserProfileService,
    public socketService: SocketService,
    private observableService: ObservableService,
    private tokenStorageService: TokenStorageService,
    private chatService: ChatService,
    private cd: ChangeDetectorRef
  ) { }

  sortingUserListByLastMessage() {
    this.userList = this.userList.sort((a, b) => {
      const aValue = a.lastMessage?.id;
      const bValue = b.lastMessage?.id;

      // Handle null values
      if (aValue === null) return 1;
      if (bValue === null) return -1;
      if (aValue === undefined) return 1;
      if (bValue === undefined) return -1;

      // Sort by descending order
      if (aValue < bValue) return 1;
      if (aValue > bValue) return -1;
    });
  }

  unreadMessageForSideBar() {
    const result = _.sumBy(this.userList, function (o) { return o.totalUnreadMessage; });
    this.observableService.sidebarTotalUnreadMessage.next(result);
  }

  async ngOnInit() {
    this.currentUser = this.tokenStorageService.getUser();
    this.getOnlineUser();
    await this.getUserList();
    this.getUserStatusSubscriber = this.socketService.getUserStatus().subscribe((user: ChatUserModel) => {
      var index = _.findIndex(this.userList, (u) => {
        return u.user.id === user.id
      });
      this.userList[index].user = user;
      this.getOnlineUser();
    });

    this.socketService.getUserMessage().subscribe((msg: any) => {
      let message;
      if (msg.length === 1) {
        message = msg[0];
      } else if (msg.length > 1) {
        message = msg[msg.length - 1]
      }

      this.userList.forEach((e: any) => {
        let lastMessage = {
          id: message.id,
          userFileName: message.userFileName,
          message: message.message,
          messageType: message.messageType,
          isRead: message.isRead,
          createdAt: message.createdAt
        }

        if (this.currentUser.id === message.receiverId && e.user.id === message.senderId) {
          e.totalUnreadMessage = message.totalUnReadMessage;
          e.lastMessage = lastMessage;
        }

        if ((this.currentUser.id === message.senderId && e.user.id === message.receiverId)) {
          e.totalUnreadMessage = 0;
          e.lastMessage = lastMessage;
        }

        if (this.selectedUser.user.id === message.senderId) {
          e.totalUnreadMessage = 0;
        }
      });

      this.sortingUserListByLastMessage();
      this.unreadMessageForSideBar();
    });

    this.socketService.totalUnreadChatMessage().subscribe((unReadCount: any) => {
      var index = _.findIndex(this.userList, (u) => this.currentUser.id == unReadCount.receiverId && u.user.id === unReadCount.senderId);
      if (index >= 0) {
        this.userList[index].totalUnreadMessage = unReadCount.totalCount;
      }
    });

    this.unreadMessageForSideBar();
    this.getOnlineUser();
  }

  async getOnlineUser() {
    try {
      this.onlineUsers = [];
      await this.userService.getOnlineUser().subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            res.data.forEach((value: ChatUserModel) => {
              if (value.profilePic) {
                value.profilePic = environment.s3BucketProfileUrl + "/" + value.profilePic;
              }
              this.onlineUsers.push(value);
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getUserList() {
    return new Promise((resolve, rejects) => {
      try {
        this.userService.getUserListWithLastMessage({
          offset: this.offset,
          limit: this.limit
        })
          .subscribe((res) => {
            if (res.statusCode === 200) {
              let userList = res?.data?.data;
              this.totalUserRecord = res?.data?.totalUserCount;

              if (userList.length > 0) {
                userList.forEach((user: UserListWithLastMessage) => {
                  if (user.user.profilePic) {
                    user.user.profilePic = environment.s3BucketProfileUrl + "/" + user.user.profilePic;
                  }
                  this.userList.push(user);
                  this.sortingUserListByLastMessage();
                  this.page++;
                });
                this.onSelectUser(this.userList[0]);
              }
              resolve('Done');
            }
          });
      } catch (error) {
        rejects(error);
      }
    });
  }

  fetchUser() {
    if (this.totalUserRecord > this.limit) {
      this.offset = this.limit + 1;
      this.limit = this.page * 2;
      this.getUserList();
    }
  }

  async searchUser() {
    try {
      this.userList = [];
      if (this.searchUserKeyword != "" && this.searchUserKeyword) {
        await this.userService.searchUser(this.searchUserKeyword).subscribe((res) => {
          if (res.statusCode === 200) {
            let userList = res.data.data;
            this.totalUserRecord = res.data.totalUserCount;

            if (userList.length > 0) {
              userList.forEach((user: UserListWithLastMessage) => {
                if (user.user.profilePic) {
                  user.user.profilePic = environment.s3BucketProfileUrl + "/" + user.user.profilePic;
                }
                this.userList.push(user);
                this.sortingUserListByLastMessage();
                this.page++;
              });
            }
          }
        });
      } else {
        this.offset = 0;
        this.limit = 8;
        this.getUserList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  showChat() {
    document.getElementById("chat-room").classList.add("user-chat-show");
    document.getElementById("side-menu-block").classList.add("hide-sidebar-mobile");
  }

  async onSelectUser(user) {
    this.selectedUser = user;
    this.observableService.selectUser.next(user);
    this.chatService.updateMessageStatus({
      senderId: (user?.user?.id || user?.id),
      receiverId: this.currentUser.id
    })
      .subscribe();
    this.unreadMessageForSideBar();
  }

  ngOnDestroy() {
    if (this.getUserStatusSubscriber)
      this.getUserStatusSubscriber.unsubscribe();

    if (this.getUserMessageSubscriber)
      this.getUserMessageSubscriber.unsubscribe();
  }
}
