import {BrowserModule, Meta} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {NgSelectModule} from '@ng-select/ng-select';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment.prod';
import {errorInterceptorProviders} from './core/interceptor/error.interceptor';
import {CustomTranslateLoader} from './core/interceptor/custom-translate.interceptor';
import {apiInterceptorProviders} from './core/interceptor/baseurl.interceptor';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {PagesModule} from './pages/pages.module';
import {CookieService} from 'ngx-cookie-service';
import {NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule} from 'ngx-ui-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TagInputModule} from 'ngx-chips';
// import { ConferenceComponent } from './conference/conference.component';
import {ClipboardModule} from 'ngx-clipboard';
import {StoreModule} from '@ngrx/store';
import {domainReducer} from './store/reducers';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ConferenceModule} from './conference/conference.module';


export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    'bgsColor': 'red',
    'bgsOpacity': 0.5,
    'bgsPosition': 'bottom-right',
    'bgsSize': 60,
    'bgsType': 'ball-spin-clockwise',

    'blur': 15,
    'delay': 0,
    'fastFadeOut': true,
    'fgsColor': 'red',
    'fgsPosition': 'center-center',
    'fgsSize': 0,
    'fgsType': 'ball-spin-clockwise',
    'gap': 24,

    'masterLoaderId': 'master',
    'overlayBorderRadius': '0',
    'overlayColor': 'rgba(40, 40, 40, 0.8)',

    'pbColor': '#0081fa',
    'pbDirection': 'ltr',
    'pbThickness': 7,

    'hasProgressBar': true,
    'maxTime': -1,
    'minTime': 300
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        TagInputModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        PagesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgbModule,
        PasswordStrengthMeterModule.forRoot(),
        NgxIntlTelInputModule,
        ToastrModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({
            showForeground: true,
            exclude: [
                '@api-nest/chat/send-message',
                '@api-nest/team/send-message',
                '@api-nest/user-post/reaction',
                '@api-nest/user-post/add-comment',
                '@api-nest/user-post/saved-post',
                '@api-nest/user/user-list-with-last-message',
                '@api-nest/user/online-user-list',
                '@api-nest/chat/get-messages'
            ]
        }),
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,
        StoreModule.forRoot({domainNameReducer: domainReducer}),
        PdfViewerModule,
        ConferenceModule
    ],
    providers: [
        errorInterceptorProviders,
        apiInterceptorProviders,
        CookieService,
        Meta
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
