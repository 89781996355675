
<perfect-scrollbar class="client-activity-leftsidebar me-lg-1">
    <div class="client-activity-inner">
        <div class="activity-list-wrap pb-3 p-4">
            <h4 class="mb-4">Docs</h4>
            <ul>
                <!-- <li>
                    <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': tabName === 'all'}" (click)="selectTab('all')">
                        <span>
                            <img src="../../../assets/images/files.png" alt="" />
                        </span>
                        <h5 class="text-truncate font-size-14 mb-0">All Files & Folders</h5>
                    </a>
                </li> -->
                <li>
                    <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': tabName === 'file'}" (click)="selectTab('file')">
                        <span>
                            <img src="../../../assets/images/files.png" alt="" />
                        </span>
                        <h5 class="text-truncate font-size-14 mb-0">Files</h5>
                    </a>
                </li>
                <li>
                    <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': tabName === 'folder'}"  (click)="selectTab('folder')">
                        <span>
                            <img src="../../../assets/images/folders.png" alt="" />
                        </span>
                        <h5 class="text-truncate font-size-14 mb-0">Folders</h5>
                    </a>
                </li>
                <li>
                    <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': tabName === 'favorite'}"  (click)="selectTab('favorite')">
                        <span>
                            <img src="../../../assets/images/favoruite.png" alt="" />
                        </span>
                        <h5 class="text-truncate font-size-14 mb-0">Favorite</h5>
                    </a>
                </li>
                <li>
                    <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': tabName === 'recent deleted'}"  (click)="selectTab('recent deleted')">
                        <span>
                            <img src="../../../assets/images/trash.png" alt="" />
                        </span>
                        <h5 class="text-truncate font-size-14 mb-0">Recent Deleted</h5>
                    </a>
                </li>
            </ul>
        </div>

        <div class="meetings-list-main">
            <div class="meetings-list-wrap p-4 pb-0">
                <h4 class="mb-4">Last Activity</h4>
                <perfect-scrollbar>
                    <ul *ngIf="recentActivitiesFiles.length > 0">
                        <li *ngFor="let ra of recentActivitiesFiles">
                            <div class="file-title">
                                <p class="mb-1"> {{ra.action}} <b
                                        class="text-underline">{{displayFullFileName(ra.fileName)}}
                                    </b> {{ra.isFolder?"folder":"file"}} </p>
                                <small>{{ra.createdAt | date: 'mediumDate': 'UTC'}}<br>By {{ra.user?.firstname}}
                                    {{ra?.user?.lastname}}</small>
                            </div>
                            <div class="file-icon" (click)="showFileFromRecent(ra)">
                                <img src="assets/file-system-icons/{{getExtensionIcon((ra.fileName?ra.fileName:'').split('.').pop())}}.png"
                                    *ngIf="!ra.isFolder" alt="" />
                                <img src="assets/file-system-icons/folder.png" *ngIf="ra.isFolder" alt="folder"
                                    style="cursor:pointer" width="40px">
                            </div>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</perfect-scrollbar>