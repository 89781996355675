<div class="user-profile-sidebar" id="profile-detail">
  <div class="px-3 px-lg-4 pt-3 pt-lg-4">
    <div class="user-chat-nav text-end">
      <button type="button" class="btn nav-btn" id="user-profile-hide" (click)="hideUserProfile()">
        <i class="ri-close-line"></i>
      </button>
    </div>
  </div>

  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
      <img src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">{{'chat.rightpanel.name' | translate}}</h5>
    <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 text-success me-1"></i>
      {{'chat.rightpanel.status' | translate}}</p>
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-4 user-profile-desc">
    <div class="text-muted">
      <p class="mb-4"> {{'chat.rightpanel.message' | translate}}</p>
    </div>

    <div id="profile-user-accordion" class="custom-accordion">

      <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
              {{'chat.rightpanel.about.text' | translate}}
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div>
              <p class="text-muted mb-1">{{'chat.rightpanel.about.name.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.rightpanel.about.name.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.rightpanel.about.email.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.rightpanel.about.email.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.rightpanel.about.time.text' | translate}}</p>
              <h5 class="font-size-14">{{'chat.rightpanel.about.time.value' | translate}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">{{'chat.rightpanel.about.location.text' | translate}}</p>
              <h5 class="font-size-14 mb-0">{{'chat.rightpanel.about.location.value' | translate}}</h5>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel cardClass="card shadow-none border mb-2" id="file">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i
                class="ri-attachment-line me-2 align-middle d-inline-block"></i>{{'chat.rightpanel.attachedfile.text' | translate}}
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0 flex-shrink-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file1.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file1.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="#" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file2.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file2.name' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file3.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file3.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file4.name' | translate}}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file4.size' | translate}}
                    </p>
                  </div>
                </div>

                <div class="ml-4">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="#" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="#" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.action' | translate}}</a>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.another' | translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="#">{{'chat.rightpanel.attachedfile.dropdown.delete' | translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>
  <!-- end user-profile-desc -->
</div>
