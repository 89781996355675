import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder, HubConnectionState, HttpTransportType }
    from '@aspnet/signalr';
import { map } from 'rxjs/operators';
import { ConferenceDialogService } from './conference.dialog.service';
import { ConferenceMiddlewareService } from './conference.middleware.service';

@Injectable({ providedIn: 'root' })
export class ConferenceSignalrJitsiOtt {

    connectionEstablished = new EventEmitter<boolean>();
    error = new EventEmitter<any>();
    private loginToken: string;
    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;
    public get IsConnected(): boolean {
        return this._hubConnection ? this._hubConnection.state == HubConnectionState.Connected : false;
    }

    constructor(
        private http: HttpClient, private conferenceDialogService: ConferenceDialogService,
        private conferenceMiddlewareService: ConferenceMiddlewareService
    ) {

    }

    async init(token: string): Promise<void> {
        if (!this._hubConnection || this._hubConnection.state != HubConnectionState.Connected) {
            this.loginToken = token;
            this.createConnection();
            this.registerOnServerEvents();
            await this.startConnection();
            setTimeout(() => {
                this.connectionEstablished.emit(this.connectionIsEstablished);
            }, 1000);
        }
    }

    console(data) {
        if (!environment.production) {
            if (data)
            console.log(data);
           // console.trace();
        }
    }

    //Auth
    conferencebynumber(confnumber: string) {
        return this.http.post<any>(environment.signalRUrl + "users/conferencebynumber", { data: confnumber })
            .pipe(map(conf => {

                return conf;
            }));
    }


    //reconnectHub() {
    //    this._hubConnection.stop().then(async () => {
    //        await this.startConnection();
    //    });

    //}



    getConference(confid: string) {
        this.console("getConference");
        return this._hubConnection.invoke('getConference', confid).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    getConnectionId(username: string) {
        this.console("getConnectionId");
        return this._hubConnection.invoke('GetConnectionId', username).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    checkOnGoingCall(username: string) {
        this.console("checkOnGoingCall");
        return this._hubConnection.invoke('CheckOnGoingCall', username).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    createNewConference(username: string, mode: string, conferenceNumber: string, conferenceName: string, isSheduled: boolean) {
        this.console("createNewConference");
        //this.currentUser.username, data.mode, data.conferenceNumber, data.conferenceName, data.isSheduled
        return this._hubConnection.invoke('createNewConference', username, mode, conferenceNumber, conferenceName, isSheduled).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    exitConferenceCall(confid: string) {
        this.console("ExitConferenceCall");
        return this._hubConnection.invoke('exitConferenceCall', confid).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    addPartnersToConference(targetusers: string[], username: string, confid: string, mode: string) {
        this.console("addPartnersToConference");
        return this._hubConnection.invoke('addPartnersToConference', targetusers, username, confid, mode).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    answerConfCall(acceptCall: boolean, confid: string, mode: string) {
        this.console("answerConfCall");
        return this._hubConnection.invoke('answerConfCall', acceptCall, confid, mode).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

   
    JoinConf(confid: string, username: string, mode: string, isCreater:boolean) {
        this.console("joinConf");
        return this._hubConnection.invoke('joinConf', confid, username, mode, isCreater).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    receivedincomingCall(confid: string, userName: string) {
        this.console("receivedincomingCall");
        return this._hubConnection.invoke('ReceivedincomingCall', confid, userName).catch((err) => {
            this.console(err);
            this.error.emit({ message: err });
        });
    }

    async stopConnection() {
        if (this._hubConnection)
            if (this._hubConnection.state == HubConnectionState.Connected) {
                await this._hubConnection.stop();
                // this.error.unsubscribe();
                //this.error.unsubscribe();
                // this.client.serverException.unsubscribe();
                //this.client.missedcallalert.unsubscribe();

            }
    }

    private createConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(environment.signalRUrl + "OttHub", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => this.loginToken
            })
            .build();

        this._hubConnection.onclose((error) => {
            if (error)
            this.console(error);
            this.conferenceMiddlewareService.$connectionStatus.next(false);

            setTimeout(() => {
                if (this.loginToken)
                    this.init(this.loginToken);
            }, 10000);
        });



    }

    private startConnection(): Promise<void> {
        return new Promise((resolve, reject) => {

            if (this._hubConnection && this._hubConnection.state == HubConnectionState.Connected) {
                resolve();
                this.connectionIsEstablished = true;

            }
            else {
                this._hubConnection
                    .start()
                    .then((x) => {

                        var isconnected = this._hubConnection.state == HubConnectionState.Connected;
                        this.console('Hub connection started : ' + isconnected);
                        this.connectionIsEstablished = true;
                        //this.connectionId = this._hubConnection.id;
                        resolve();
                    })
                    .catch(err => {
                        this.connectionIsEstablished = false;

                        this.console('Error while establishing connection, ...');
                        this.conferenceDialogService.error("Call Signal Server Down ");
                        //setTimeout(function () { this.startConnection(); }, 5000);
                        reject(err);
                    });

            }

        });


    }



    client = {
        receivedincomingCallSignal: new EventEmitter<{ confid: string, connectionid: string }>(),
        partnerActionToincomingCall: new EventEmitter<{ confid: string, mode: string, status: string }>(),
        incomingcall: new EventEmitter<{ confid: any, mode: string, conferenceName: string, creatorUserName: string, conferenceNumber: string }>(),
        closeincomingcall: new EventEmitter<{ confid: any }>(),
        serverException: new EventEmitter<any>(),
        exitConferenceCallNotify: new EventEmitter<any>()
    };


    private registerOnServerEvents(): void {

        this._hubConnection.on('$receivedincomingCallSignal', (confid: string, connectionid: string) => {
            this.console('SingalrWebrtc.receivedincomingCallSignal confid:' + confid + ',connectionid' + connectionid);
            this.client.receivedincomingCallSignal.emit({ confid, connectionid });
        });
        this._hubConnection.on('$partnerActionToincomingCall', (confid: string, mode: string, status: string) => {
            this.console('SingalrWebrtc.partnerActionToincomingCall confid:' + confid);
            this.client.partnerActionToincomingCall.emit({ confid, mode, status });
        });

        this._hubConnection.on('$incomingcall', (confid: any, mode: string, conferenceName: string, creatorUserName: string, conferenceNumber: string) => {
            this.console('SingalrWebrtc.incomingcall confid:' + confid);
            this.client.incomingcall.emit({ confid, mode, conferenceName, creatorUserName, conferenceNumber });
        });

        this._hubConnection.on('$closeincomingcall', (confid: any) => {
            this.console('SingalrWebrtc.closeincomingcall confid:' + confid);
            this.client.closeincomingcall.emit({ confid });
        });

        this._hubConnection.on('$serverException', (message: string) => {
            this.console('SingalrWebrtc.serverException');
            this.client.serverException.emit({ message });
        });
        this._hubConnection.on('$exitConferenceCallNotify', (confid: string) => {
            this.console('SingalrWebrtc.$exitConferenceCallNotify');
            this.client.exitConferenceCallNotify.emit({ confid });
        });
    }
}    
