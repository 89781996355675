export enum UserStatusEnum {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  AWAY = 'Away',
  DND = 'Do not disturb'
}

export class UserStatusSocket {
  uId: number;
  statusType: UserStatusEnum;
}
