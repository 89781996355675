<!-- Start Settings content -->
<perfect-scrollbar class="chat-scroll">
  <div class="chat-leftsidebar me-lg-1">
    <div class="tab-content">
      <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
        <div>
          <div class="px-4 pt-4">
            <h4 class="mb-0">{{'chat.tabs.settings.title' | translate}}</h4>
          </div>

          <div class="text-center border-bottom p-4">
            <div class="mb-4 profile-user">
              <img src="" id="profile" class="rounded-circle avatar-lg img-thumbnail" alt=""
                (error)="setDefaultPic($event)">
              <button type="button" class="btn bg-light avatar-xs p-0 rounded-circle profile-photo-edit shadow-none">
                <label role="button" for="file-input">
                  <i class="ri-pencil-fill">
                    <input type="file" id="file-input" class="d-none" accept="image/gif,image/jpeg,image/jpg,image/png"
                      (change)="uploadProfilePic($event)" #fileUpload>
                  </i>
                </label>
              </button>
            </div>

            <h5 class="font-size-20 mb-1 text-truncate">{{currentUser?.firstname}} {{currentUser?.lastname}} </h5>
            <div>
              <h6>{{currentUser?.username}}</h6>
            </div>
            <div class="dropdown d-inline-block mb-1" ngbDropdown>
              <a class="text-muted dropdown-toggle pb-1 d-block" href="javascript: void(0);" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                {{currentUser?.onlineStatus }} <i class="mdi mdi-chevron-down"></i>
              </a>

              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0);" (click)="updateOnlineStatus('Online')">Online</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="updateOnlineStatus('Offline')">Offline</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="updateOnlineStatus('Away')">Away</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="updateOnlineStatus('Do not disturb')">Do not
                  disturb</a>
              </div>
            </div>
          </div>
          <!-- End profile user -->

          <!-- Start User profile description -->
          <perfect-scrollbar class="p-4 user-profile-desc" data-simplebar>
            <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
              <ngb-panel cardClass="card shadow-none border mb-2" id="perfonal-info">
                <ng-template ngbPanelTitle>
                  <h5 class="font-size-14 m-0">
                    {{'chat.tabs.settings.info.title' | translate}}
                  </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <!-- <div class="float-end">
              <button type="button" class="btn btn-light btn-sm shadow-none"><i class="ri-edit-fill me-1 align-middle"></i>
                {{'chat.tabs.settings.info.edit' | translate}}</button>
            </div> -->
                  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <div class="mb-3">
                      <label class="form-label">First name</label>
                      <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text border-light text-muted">
                            <i class="ri-user-2-line"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control bg-soft-light border-light" placeholder="Firstname"
                          formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                          <div *ngIf="f.firstname.errors.required">Firstname is required.</div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Last name</label>
                      <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text border-light text-muted">
                            <i class="ri-user-2-line"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control bg-soft-light border-light" placeholder="Lastname"
                          formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                          <div *ngIf="f.lastname.errors.required">Lastname is required.</div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="mb-3">
                <label class="form-label">Email</label>
                <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                  <div class="input-group-prepend">
                    <span class="input-group-text border-light text-muted">
                      <i class="ri-user-2-line"></i>
                    </span>
                  </div>
                  <input type="email" class="form-control bg-soft-light border-light" placeholder="Email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (change)="checkEmailValidation()">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required.</div>
                    <div *ngIf="f.email.errors.email">Enter valid email.</div>
                    <div *ngIf="f.email.errors.isEmailUsed">{{'signup.form.email.validation.emailExits' | translate}}</div>
                  </div>
                </div>
              </div> -->

                    <!-- <div class="mb-4">
                <label class="form-label">Mobile</label>
                <div class="input-group mb-3 bg-soft-light mb-3 rounded-3 rounded-lg">
                  <ngx-intl-tel-input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                    [cssClass]="'form-control bg-soft-light border-light'" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedKingdom" [maxLength]="15" phoneValidation="true" [separateDialCode]="true"
                    (change)="checkMobileValidation()" [numberFormat]="PhoneNumberFormat.National" name="mobile" formControlName="mobile">
                  </ngx-intl-tel-input>
                  <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required">Mobile is required.</div>
                    <div *ngIf="f.mobile.errors.isMobileUsed">Mobile alredy used.</div>
                  </div>
                </div>
              </div> -->

                    <div class="d-grid">
                      <button class="btn btn-primary" type="submit">Update</button>
                    </div>

                  </form>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="card shadow-none border mb-2" id="privacy">
                <ng-template ngbPanelTitle>
                  <h5 class="font-size-14 m-0">
                    {{'chat.tabs.settings.privacy.title' | translate}}
                  </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="py-3">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.photo' | translate}}
                        </h5>

                      </div>
                      <div class="dropdown ms-2" ngbDropdown>
                        <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" type="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                          {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                            translate}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="py-3 border-top">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.seen' | translate}}</h5>

                      </div>
                      <div class="ms-2 me-0">
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="privacy-lastseenSwitch" checked="">
                          <label class="form-check-label" for="privacy-lastseenSwitch"></label>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="py-3 border-top">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.status' | translate}}
                        </h5>
                      </div>
                      <div class="dropdown ms-2" ngbDropdown>
                        <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" type="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                          {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                            translate}}</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="py-3 border-top">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.receipt' | translate}}
                        </h5>

                      </div>
                      <div class="ms-2">
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="privacy-readreceiptSwitch" checked="">
                          <label class="form-check-label" for="privacy-readreceiptSwitch"></label>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="py-3 border-top">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.groups' | translate}}
                        </h5>

                      </div>
                      <div class="dropdown ms-2" ngbDropdown>
                        <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" ngbDropdownToggle
                          type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{ 'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' |
                            translate}}</a>
                          <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' |
                            translate}}</a>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel cardClass="card shadow-none border mb-2" id="security">
                <ng-template ngbPanelTitle>
                  <h5 class="font-size-14 m-0">
                    {{'chat.tabs.settings.security.title' | translate}}
                  </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden">
                        <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.security.text' | translate}}
                        </h5>
                      </div>
                      <div class="ms-2">
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="security-notificationswitch">
                          <label class="form-check-label" for="security-notificationswitch"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
              <ngb-panel cardClass="card shadow-none border mb-2" id="help">
                <ng-template ngbPanelTitle>
                  <h5 class="font-size-14 m-0">
                    {{'chat.tabs.settings.help.title' | translate}}
                  </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <div class="py-3">
                      <h5 class="font-size-13 mb-0"><a href="#" class="text-body d-block">{{'chat.tabs.settings.help.faqs'
                          | translate}}</a>
                      </h5>
                    </div>
                    <div class="py-3 border-top">
                      <h5 class="font-size-13 mb-0"><a href="#"
                          class="text-body d-block">{{'chat.tabs.settings.help.contact' | translate}}</a></h5>
                    </div>
                    <div class="py-3 border-top">
                      <h5 class="font-size-13 mb-0"><a href="#"
                          class="text-body d-block">{{'chat.tabs.settings.help.policy' | translate}}</a>
                      </h5>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <!-- end profile-setting-accordion -->
          </perfect-scrollbar>
          <!-- End User profile description -->
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
<!-- Start Settings content -->