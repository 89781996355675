import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ChatMessageModel } from '../models/chat-message.model';
import { UserStatusSocket } from '../models/userStatusEnum.models';
import { ChatUserModel } from 'src/app/pages/user-list/chatModel';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private serverURL = environment.apiEndPoint;
  private socket;
  constructor() {
    this.socket = io(this.serverURL);
  }

  /**
   * 
   * @param message user chat method define here
   */

  sendUserStatus(message: UserStatusSocket) {
    this.socket.emit('userStatus', {
      socketId: 'userStatus',
      message: message
    });
  }

  getUserStatus(): Observable<ChatUserModel> {
    return new Observable((observer) => {
      this.socket.on('userStatus', (message) => {
        observer.next(message);
      });
    });
  }

  getUserMessage(): Observable<Array<ChatMessageModel>> {
    return new Observable((observer) => {
      this.socket.on('userMessage', (message) => {
        observer.next(message);
      });
    });
  }
  
  deleteUserMessage(): Observable<Array<ChatMessageModel>> {
    return new Observable((observer) => {
      this.socket.on('deleteMessage', (message) => {
        observer.next(message);
      });
    });
  }

  deleteTeamMessage(): Observable<Array<ChatMessageModel>> {
    return new Observable((observer) => {
      this.socket.on('deleteTeamMessage', (message) => {
        observer.next(message);
      });
    });
  }

  /**
   * 
   * @returns team module method define here
   */

  getTeamMessage(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('teamMessage', (message) => {
        observer.next(message);
      });
    });
  }
  
  editTeamMessage(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('editTeamMessage', (message) => {
        observer.next(message);
      });
    });
  }

  createTeam(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('newTeam', (data) => {
        observer.next(data);
      });
    });
  }

  userPost(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('userPost', (data) => {
        observer.next(data);
      });
    });
  }

  getNotification(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('sendSingleNotification', (data) => {
        observer.next(data);
      });
    });
  }

  totalUnreadChatMessage(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('totalUnreadChatMessage', (data) => {
        observer.next(data);
      });
    });
  }
  totalUnreadTeamMessage(): Observable<Array<any>> {
    return new Observable((observer) => {
      this.socket.on('totalCountOfUnreadTeamMessage', (data) => {
        observer.next(data);
      });
    });
  }

  sendFirebaseToken(data) {
    this.socket.emit('firebaseToken', data)
  }


}
