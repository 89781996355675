import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPostService {

  constructor(private http: HttpClient) { }

  createPost(data) {
    return this.http.post<any>(`@api-nest/user-post/create-post`, data).pipe(retry(0), catchError(this.handleError));
  }

  postReaction(data) {
    return this.http.post<any>(`@api-nest/user-post/reaction`, data).pipe(retry(0), catchError(this.handleError));
  }
 
  savedPost(data) {
    return this.http.post<any>(`@api-nest/user-post/saved-post`, data).pipe(retry(0), catchError(this.handleError));
  }

  updatePost(data, id) {
    return this.http.put<any>(`@api-nest/user-post/update-post/${id}`, data).pipe(retry(0), catchError(this.handleError));
  }

  deletePost(id) {
    return this.http.delete<any>(`@api-nest/user-post/delete-post/${id}`).pipe(retry(0), catchError(this.handleError));
  }

  getAllPost(data) {
    let params = new HttpParams();
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/user-post/all-post`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getSinglePost(data) {
    let params = new HttpParams();
    params = params.set('id', data.id);
    return this.http.get<any>(`@api-nest/user-post/get-single-post`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getUserPost(data) {
    let params = new HttpParams();
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/user-post/user-post`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }
  
  getSavedPost(data) {
    let params = new HttpParams();
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/user-post/user-saved-post`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  addComment(data) {
    return this.http.post<any>(`@api-nest/user-post/add-comment`, data).pipe(retry(0), catchError(this.handleError));
  }

  createEvent(data) {
    return this.http.post<any>(`@api-nest/user-post/create-event`, data).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
