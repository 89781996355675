import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
// import * as moment from 'moment';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { TeamService } from 'src/app/core/services/team.service';
// import {
//   PerfectScrollbarComponent,
//   PerfectScrollbarConfigInterface,
//   PerfectScrollbarDirective,
// } from "ngx-perfect-scrollbar";
import { ObservableService } from 'src/app/core/services/observable.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { CreateTeamModalComponent } from './create-team-modal/create-team-modal.component';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit {
  teamList: any = [];
  offset = 0;
  limit = 10;
  page = 1;
  totalTeamRecord = 0;

  searchTeamKeyword;

  selectedTeam;

  public currentUser: any;
  userList: any = [];

  constructor(
    private modalService: NgbModal,
    private tokenService: TokenStorageService,
    private teamService: TeamService,
    private observableService: ObservableService,
    private socketService: SocketService,
  ) {
    this.socketService.getTeamMessage().subscribe(async (messages: any) => {
      //** If message is text or single file */
      if (messages.length >= 0) {
        let index = this.teamList.findIndex((team) => team.id === messages[0].teamId);

        //** Count total unread message */
        await Promise.all(messages.map(async () => {
          await Promise.all(messages[0].userStatus.map(async (user) => {
            if (messages[0]?.senderId === this.currentUser.id || this.selectedTeam.id === messages[0].teamId) {
              this.teamList[index].totalUnreadMessage = 0;
            }

            if (user.receiverId === this.currentUser.id && this.selectedTeam.id != messages[0].teamId) {
              this.teamList[index].totalUnreadMessage = user.totalUnreadMessage;
            }

          }));

        }));

        //** Set last message */
        if (index === 0) {
          this.teamList[index].lastMessage = messages[0];
          this.sortingUserListByLastMessage();
        }
      }

      //** If message is images or multiple files */
      if (messages.length > 1) {
        let index = this.teamList.findIndex((team) => team.id === messages[messages.length - 1].teamId);

        //** Count total unread message */
        await Promise.all(messages.map(async () => {
          await Promise.all(messages[0].userStatus.map(async (user) => {
            if (messages.senderId === this.currentUser.id || this.selectedTeam.id === messages[0].teamId) {
              this.teamList[index].totalUnreadMessage = 0;
            }

            if (user.receiverId === this.currentUser.id || this.selectedTeam.id != messages[0].teamId) {
              this.teamList[index].totalUnreadMessage = user.totalUnreadMessage;
            }

          }));
        }));

        if (index >= 0) {
          this.teamList[index].lastMessage = messages[messages.length - 1];
          delete this.teamList[index].lastMessage.userStatus;
          this.sortingUserListByLastMessage();
        }
      }

      this.unreadMessageForSideBar();
    });

    this.socketService.editTeamMessage().subscribe((message: any) => {
      let index = this.teamList.findIndex((team) => team.lastMessage.id === message.id);
      if (index >= 0) {
        this.teamList[index].lastMessage = message;
      }
    });
  }

  unreadMessageForSideBar() {
    const result = _.sumBy(this.teamList, function (o) { return o.totalUnreadMessage; });
    this.observableService.sidebarTotalTeamUnreadMessage.next(result);
  }

  sortingUserListByLastMessage() {
    this.teamList = this.teamList.sort((a, b) => {
      const aValue = a.lastMessage?.id;
      const bValue = b.lastMessage?.id;

      // Handle null values
      if (aValue === null) return 1;
      if (bValue === null) return -1;
      if (aValue === undefined) return 1;
      if (bValue === undefined) return -1;

      // Sort by descending order
      if (aValue < bValue) return 1;
      if (aValue > bValue) return -1;
    });
  }


  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.getTeamList();
  }

  async openCreateTeamModal() {
    let modalRef = await this.modalService.open(CreateTeamModalComponent, { centered: true });
    modalRef.result.then((re) => { }, (reason) => {
      if (reason.isUpdate) {
        this.offset = 0;
        this.limit = 10;
        this.getTeamList();
      }
    });
  }

  async getTeamList() {
    this.teamService
      .getTeamList({
        offset: this.offset,
        limit: this.limit
      })
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if(res.data.teamData.length > 0) {
            this.totalTeamRecord = res.data.totalRecords;
  
            res.data.teamData.forEach((team) => {
              this.teamList.push(team);
            });
            this.onSelectTeam(this.teamList[0]);
            this.page++;
            this.sortingUserListByLastMessage();
          }
        }
      });
  }

  fetchTeam() {
    if (this.totalTeamRecord > this.limit) {
      this.offset = this.limit;
      this.limit = this.page * 10;
      this.getTeamList();
    }
  }

  onSelectTeam(data) {
    console.log('selected team', data)
    this.selectedTeam = data;
    this.observableService.selectedTeam.next(data);

    this.teamService.updateMessageStatus({
      teamId: data.id,
      receiverId: this.currentUser.id
    })
      .subscribe((res) => {
        if (res.statusCode === 200) {
          data.totalUnreadMessage = 0;
          this.unreadMessageForSideBar();
        }
      });
  }

  // updateLastMessage(message) {
  //   let index = this.teamList.findIndex((e) => e.team.id === message.teamId);
  //   if (index >= 0) {
  //     this.teamList[index].lastMessage = message;
  //     let messageCount = message.unReadMessageCount.find((e) => e.userId === this.currentUser.id)

  //     this.teamList[index].unReadMessageCount = messageCount.readCount;

  //     if (message.updatedAt) {
  //       let lastMessageDate = moment(message.updatedAt).format('DD-MM-YYYY');
  //       if (lastMessageDate === moment().format('DD-MM-YYYY')) {
  //         this.teamList[index].lastMessage.lastMessageUpdatedAt = moment(message.updatedAt).format('HH:mm');
  //       } else {
  //         this.teamList[index].lastMessage.lastMessageUpdatedAt = lastMessageDate;
  //       }
  //     }

  //     this.teamList = _.orderBy(this.teamList, item => item?.lastMessage?.id, ['desc']);
  //   }
  // }

  async searchTeam() {
    try {
      this.teamList = [];
      if (this.searchTeamKeyword != "" && this.searchTeamKeyword) {
        await this.teamService.searchTeam(this.searchTeamKeyword)
          .subscribe((res) => {
            if (res.statusCode === 200) {
              if (res.data.length > 0) {
                res.data.forEach((value: any) => {
                  this.teamList.push(value);
                });
              }
            }
          });
      } else {
        this.offset = 0;
        this.limit = 10;
        this.getTeamList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  showChat() {
    document.getElementById("chat-room").classList.add("user-chat-show");
    document.getElementById("side-menu-block").classList.add("hide-sidebar-mobile");
  }

  closeUserChat() {
    document.getElementById("chat-room").classList.remove("user-chat-show");
    document.getElementById("side-menu-block").classList.remove("hide-sidebar-mobile");
  }

}
