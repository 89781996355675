import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from 'lodash';
import { isWebUri } from 'valid-url';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { MessageType, TeamChatMessageModel } from 'src/app/core/models/chat-message.model';
import { ObservableService } from 'src/app/core/services/observable.service';
import { TeamService } from 'src/app/core/services/team.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { CommanService } from "src/app/core/services/comman.service";
import { environment } from 'src/environments/environment';
import { SocketService } from 'src/app/core/services/socket.service';
import { CreateTeamModalComponent } from '../create-team-modal/create-team-modal.component';

@Component({
  selector: 'app-team-conversion',
  templateUrl: './team-conversion.component.html',
  styleUrls: ['./team-conversion.component.scss']
})
export class TeamConversionComponent implements OnInit {
  public currentUser: any;
  isFileListOpen = false;
  fileList = [];
  isEditMessage = false;


  selectedTeam: any = {};
  selectedTeamObservable;

  updateTeamForm: FormGroup;
  emojiToggled = false;

  submitted = false;
  chatForm: FormGroup;
  scrollPosition: any;
  offset = 1;
  limit = 10;
  totalRecord: number;
  totalPages: number;
  public type: string = 'component';
  public disabled: boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  imageSrc: any;
  messageList = [];
  messageFormate = new TeamChatMessageModel();

  teamMembers = [];

  isCurrentUserIsTeamAdmin = false;
  isImportantMessageOpen = false;

  handleSelection(event) {
    if (this.chatForm.value.message == null) {
      this.chatForm.value.message = event.emoji.native ? event.emoji.native : "";
    } else {
      this.chatForm.value.message += event.emoji.native ? event.emoji.native : "";
    }
    this.chatForm.patchValue({
      message: this.chatForm.value.message
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let emojiPanel = document.getElementById("emoji-palette"); // this is emoji panel element
    let buttonEmoji = document.getElementById("button-emoji"); //this is button to triggered emoji panel
    
    const path = event.composedPath();


    if (emojiPanel && !path.includes(buttonEmoji)) {
      if (!path.includes(emojiPanel)) {
        emojiPanel.style.display = "none";
        this.emojiToggled = false;
      }
    }
  }

  constructor(
    private observableService: ObservableService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
    private teamService: TeamService,
    private socketService: SocketService,
    public commanService: CommanService,
    private cd: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();

    this.observableService.isOpenTeamImportantMessage.subscribe((value) => {
      this.isImportantMessageOpen = value;
    })

    new Promise((resolve) => {
      this.selectedTeamObservable = this.observableService.selectedTeam.subscribe((value) => {
        this.fileList = [];
        this.selectedTeam = value;
        this.offset = 0;
        this.limit = 10;
        this.messageList = [];
        this.setDefaultValueForMessage();
        this.initMessageSentForm();
        this.getMessages().then(() => {
          this.scrollToBottom();
          resolve("Done");
        });
      });
    }).then(() => {
      this.socketService.getTeamMessage().subscribe((messages: any) => {
        console.log(messages)
        if (messages.length > 0) {
          if (messages[0].isEdit) {
            let index = this.messageList.findIndex((e) => e.id === messages[0].id);
            this.messageList[index].message = messages[0].message;
          } else {
            messages.forEach((message) => {
              if (message.teamId == this.selectedTeam.id) {
                this.messageList.push(message);
                this.addTodayYesterdayMessageLabel();
                this.scrollToBottom();
              }
            });
          }
        }
      });
    })

    this.socketService.deleteTeamMessage().subscribe((value) => {
      let index = this.messageList.findIndex((e) => (e.id || e.lastMessageId) === value)
      this.messageList.splice(index, 1);
    })

    this.socketService.createTeam().subscribe((value: any) => {
      if (value) {
        if (this.selectedTeam.id === value[0].team.id) {
          this.selectedTeam = value[0].team;
        }
      }
    });


    this.socketService.editTeamMessage().subscribe((message: any) => {
      let index = this.messageList.findIndex((m) => m.id === message.id);
      if (index >= 0) {
        this.messageList[index] = {
          ...this.messageList[index],
          ...message
        };
      }
    });
  }

  async openTeamEditModal(content) {
    await this.modalService.open(content, { centered: true });
    this.initUpdateTeamForm();
  }

  initUpdateTeamForm() {
    this.updateTeamForm = this.formBuilder.group({
      name: [this.selectedTeam.name, [Validators.required]],
      description: [this.selectedTeam.description, [Validators.required]],
      createdBy: [this.selectedTeam.createdBy],
      updatedBy: [this.currentUser.id],
      id: [this.selectedTeam.id],
    });
  }

  get f() { return this.updateTeamForm.controls; }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.chatForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  }

  onTeamInfoUpdate() {
    this.findInvalidControls();
    if (this.updateTeamForm.invalid) {
      return;
    }
    this.teamService.updateTeam(this.updateTeamForm.value)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.modalService.dismissAll();
        }
      });
  }

  async checkDuplicateTeamName() {
    await this.teamService.checkDuplicateTeamName(this.updateTeamForm.value.name)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data != 0) {
            this.updateTeamForm.get('name').setErrors({ isTeamAvailable: true, msg: 'Team exits.' });
          }
        }
      });
  }

  initMessageSentForm() {
    this.chatForm = this.formBuilder.group({
      message: [null],
      id: [null],
      teamId: [this.selectedTeam.id, [Validators.required]],
      senderId: [this.messageFormate.senderId, [Validators.required]],
      messageType: this.messageFormate.messageType,
      file: this.formBuilder.array([])
    });
  }

  setDefaultValueForMessage() {
    this.messageFormate.senderId = this.currentUser.id;
    this.messageFormate.teamId = this.selectedTeam.id;
    this.messageFormate.messageType = MessageType.TEXT;
    this.messageFormate.offset = this.offset;
    this.messageFormate.limit = this.limit
  }

  removeFile(index) {
    this.fileList.splice(index, 1);
  }

  readURL(event): void {
    if (event.target.files && event.target.files.length > 0) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (this.commanService.isImageFile(event.target.files[i].name)) {
          var reader = new FileReader();
          reader.onload = (e: any) => {
            this.fileList.push({
              messageType: MessageType.IMAGE,
              file: event.target.files[i],
              url: e.target.result  //Base64 string for preview image
            });
          }
          reader.readAsDataURL(event.target.files[i]);
          this.chatForm.patchValue({
            messageType: MessageType.IMAGE
          });
        } else {
          this.fileList.push({
            messageType: MessageType.FILE,
            file: event.target.files[i],
            url: null
          });
          this.chatForm.patchValue({
            messageType: MessageType.FILE
          });
        }

        this.chatForm.patchValue({
          message: null
        });
      }
      // this.chatForm.patchValue({
      //   messageType: MessageType.FILE
      // });
    }
  }

  onScrollEvent() {
    this.scrollPosition = this.componentRef.directiveRef.position(true).y;
  }

  sendMessage() {
    // this.findInvalidControls();
    let isNullMessage = true;

    if (this.chatForm.invalid) {
      return;
    }

    const formData = new FormData();

    console.log(this.chatForm.value)
    formData.append("senderId", this.chatForm.value.senderId);
    formData.append("teamId", this.chatForm.value.teamId);
    formData.append("message", this.chatForm.value.message);
    formData.append("messageType", this.chatForm.value.messageType);
    formData.append("isBase64Image", 'false');

    if (this.chatForm.value.message != null) {
      isNullMessage = false;
    } else {
      isNullMessage = true;

    }

    if (this.fileList && this.fileList.length > 0) {
      this.fileList.forEach((value) => {
        formData.append("file", value.file);
      });
      isNullMessage = false;
    }

    if (!isNullMessage) {
      if (!this.isEditMessage) {
        this.teamService.sendMessage(formData).subscribe((res) => {
          if (res.statusCode === 200) {
            this.fileList = [];
            this.initMessageSentForm();
            this.scrollToBottom();
          }
        });
      } else {
        formData.append("id", this.chatForm.value.id);

        this.teamService.editMessage(formData).subscribe((res) => {
          if (res.statusCode === 200) {
            this.fileList = [];
            this.initMessageSentForm();
            this.isEditMessage = false;
          }
        });
      }
    }
  }

  scrollToBottom(): void {
    if (this.componentRef && this.componentRef.directiveRef) {
      setTimeout(() => {
        this.componentRef.directiveRef.scrollToBottom();
      }, 100)
    }
    this.cd.detectChanges();
  }

  loadMessage() {
    if ((this.totalRecord / this.limit) > this.offset) {
      this.offset += 1;
      this.setDefaultValueForMessage();
      this.getMessages();
    }
  }

  async getMessages() {
    return new Promise((resolve, reject) => {
      try {
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ];

        if ((this.totalRecord / this.limit) > this.offset || this.offset == 0) {
          this.teamService.getMessages(this.messageFormate).subscribe((res) => {
            if (res.statusCode === 200) {
              res.data.message.forEach((value) => {
                this.messageList = insert(this.messageList, 0, value);
              })
              this.addTodayYesterdayMessageLabel()
                .then(() => {
                  this.componentRef?.directiveRef.scrollToTop((res.data.message.length * 100), 10)
                  this.cd.detectChanges();
                  this.totalRecord = res.data.totalCount;
                  this.totalPages = Math.ceil(this.totalRecord / this.limit);
                  resolve('Done')
                })
            }
          })
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  addTodayYesterdayMessageLabel() {
    return new Promise((resolve, reject) => {
      try {
        if (this.messageList.length > 0) {
          this.resetTodayYesterdayMessageLabel();

          let today = moment().format("YYYY-MM-DD");
          let yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

          const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

          const findIndex = (date) => {
            return this.messageList.findIndex((e) => moment(e.updatedAt).format("YYYY-MM-DD") === date);
          };

          const setLabelToMessage = (index, label) => {
            if (index != -1) {
              this.messageList = insert(this.messageList, index, label);
            }
          };

          let yesterdayIndex = findIndex(yesterday);
          let todayIndex = findIndex(today);

          setLabelToMessage(todayIndex, { isToday: true });
          setLabelToMessage(yesterdayIndex, { isYesterday: true });

          this.messageList = this.messageList.map((e) => {
            e.messageDate = moment(e.updatedAt).format("YYYY-MM-DD");
            return e;
          });
          let uniqDateList = _.uniq(_.map(this.messageList, "messageDate"));

          uniqDateList = _.remove(uniqDateList, function (c) {
            delete c.messageDate;
            return c != today && c != yesterday;
          });

          uniqDateList.forEach((value) => {
            let index = findIndex(value);
            setLabelToMessage(index, { date: value });
          });

          resolve('Done');
        } else {
          resolve('Done');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  resetTodayYesterdayMessageLabel() {
    if (this.messageList.length > 0) {
      this.messageList = _.reject(this.messageList, (o) => o.date);
      this.messageList = _.reject(this.messageList, (o) => o.isToday);
      this.messageList = _.reject(this.messageList, (o) => o.isYesterday);
    }
  }

  fileUrl(file, bucketName) {
    if (bucketName === 'user') {
      return environment.s3BucketProfileUrl + "/" + file;
    } else if (bucketName === 'team') {
      return environment.s3BucketTeamUrl + "/" + file;
    }
  }

  /** Member methods define here */
  teamUserListOffset = 0;
  teamUserListLimit = 4;
  teamUserTotalCount = 0;

  allUserList = [];
  allUserOffset = 0;
  allUserLimit = 8;
  allUserTotalCount = 0;

  async openTeamMemberModal(content) {
    await this.modalService.open(content, { centered: true, modalDialogClass: 'modal-lg' });
    this.teamMembers = [];
    this.teamUserListOffset = 0;
    this.teamUserListLimit = 4;
    this.teamUserTotalCount = 0;

    this.getTeamMembers()
      .then(() => {
        if (this.componentRef && this.componentRef.directiveRef) {
          this.cd.detectChanges();
        }
      })

    this.allUserList = [];
    this.allUserOffset = 0;
    this.allUserLimit = 8;
    this.allUserTotalCount = 0;

    this.getAllUserList()
      .then(() => {
        if (this.componentRef && this.componentRef.directiveRef) {
          this.cd.detectChanges();
        }
      })
  }

  async getTeamMembers() {
    return new Promise((resolve) => {
      this.teamService.getTeamMembers({
        teamId: this.selectedTeam.id,
        offset: this.teamUserListOffset,
        limit: this.teamUserListLimit
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.teamUserTotalCount = res.data.totalCount;
            if (res.data.users.length > 0) {
              res.data.users.forEach((value) => {
                this.teamMembers.push(value);
              });

              let adminUser = res.data.users.find((e) => e.user.id === this.currentUser.id);
              if (adminUser) {
                this.isCurrentUserIsTeamAdmin = adminUser.userType === 'Admin' ? true : false;
              }
            }
          }
          resolve('Done');
        });
    });
  }

  async loadTeamUserList() {
    if (this.teamUserTotalCount > this.teamUserListLimit) {
      this.teamUserListOffset = this.teamUserListLimit;
      this.teamUserListLimit = this.teamUserListLimit + 4;
      this.getTeamMembers()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
    }
  }

  async changeTeamMemberRole(userId, type, index) {
    this.teamService.changeMemberRole({
      userId,
      teamId: this.selectedTeam.id,
      userType: type
    })
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.teamMembers[index].userType = type;
        }
      });
  }

  async removeTeamMember(id, index) {
    this.teamService.removeUser(id)
      .subscribe((res) => {
        // this.getTeamMembers();
        this.allUserList.push(this.teamMembers[index].user)
        this.teamMembers.splice(index, 1);
      });
  }

  getCurrentUserTypeInTeam() {
    return this.teamMembers.find((e) => {
      if (e.user.id === this.currentUser.id) {
        return e;
      }
    })
  }

  async getAllUserList() {
    return new Promise((resolve) => {
      this.teamService.getAllUserList({
        companyId: this.currentUser.companyId,
        teamId: this.selectedTeam.id,
        offset: this.allUserOffset,
        limit: this.allUserLimit
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.allUserTotalCount = res.data.totalCount;
            if (res.data.users.length > 0) {
              res.data.users.forEach((value) => {
                this.allUserList.push(value);
              });
              console.log('all users', this.allUserList)
            }
          }
          resolve('Done');
        });
    });
  }

  loadAllUserList() {
    if (this.allUserTotalCount > this.allUserLimit) {
      this.allUserOffset = this.allUserLimit;
      this.allUserLimit = this.allUserLimit + 8;
      this.getAllUserList()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
    }
  }

  addUserToTeam(user, index) {
    this.teamService.addUserToTeam({
      teamId: this.selectedTeam.id,
      userId: user.id,
      userType: 'Normal'
    })
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.allUserList.splice(index, 1);
          this.teamUserListOffset = 0;
          this.teamUserListLimit = 4;
          this.teamMembers = [];
          this.getTeamMembers()
        }
      });
  }

  showFileSection() {
    var showProfile = document.getElementById("team-files");
    var ChatWrapper = document.getElementById("user-teams-wrapper");
    if (showProfile.style.display === "none") {
      showProfile.style.display = "block";
      ChatWrapper.classList.add("open-files-drawer");
    } else {
      showProfile.style.display = "none";
      ChatWrapper.classList.remove("open-files-drawer");
    }
  }

  closeUserChat() {
    document.getElementById("chat-room").classList.remove("user-chat-show");
    document.getElementById("side-menu-block").classList.remove("hide-sidebar-mobile");
  }

  isURL(str) {
    return isWebUri(str)
  }

  openTeamModal() {
    let modal = this.modalService.open(CreateTeamModalComponent, {
      centered: true,
      modalDialogClass: 'create-file-modal',
    });
  }

  ngOnDestroy() {
    this.selectedTeamObservable.unsubscribe();
  }

  editMessage(data) {
    this.isEditMessage = true;
    this.chatForm = this.formBuilder.group({
      id: data.id || data.lastMessageId,
      message: [data.message],
      senderId: [data.senderId, [Validators.required]],
      teamId: [data.teamId, [Validators.required]],
      messageType: data.messageType,
      file: this.formBuilder.array([])
    });
  }

  deleteMessage(data, index) {
    this.teamService.deleteMessage(data.id || data.lastMessageId)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.messageList.splice(index, 1);
        }
      })
  }

  updateImportant(data, i) {
    this.teamService.updateImportantMessage({
      messageId: data.id,
      teamId: this.selectedTeam.id,
      isImportant: !data.isImportant,
      receiverId: this.currentUser.id,
    })
      .subscribe((res) => {
        this.messageList[i].isImportant = !data.isImportant;
      });
  }

  getImportantMessageList() {
    this.isImportantMessageOpen = true;

  }

}
