import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { User } from '../models/auth.models';
import { TokenStorageService } from './token.service';
import { UserProfileService } from './user.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    user: User;

    constructor(
        private http: HttpClient,
        private tokenService: TokenStorageService,
        private userService: UserProfileService,
        private router: Router,
    ) {
    }

    public getCurrentUser() {
        return this.tokenService.getUser();
    }

    login(data) {
        return this.http.post<any>(`@api-nest/auth/login`, data).pipe(retry(0), catchError(this.handleError));
    }

    googleLogin(data) {
        return this.http.post<any>(`@api-nest/auth/google-login`, data).pipe(retry(0), catchError(this.handleError));
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return new Promise((resolve, reject) => {

        })
    }

    /**
     * Reset password
     * @param email email
     */
    resetPasswordLink(data) {
        return this.http.post<any>(`@api-nest/auth/reset-password-link`, data).pipe(retry(0), catchError(this.handleError));
    }

    resetPassword(data) {
        return this.http.post<any>(`@api-nest/auth/reset-password`, data).pipe(retry(0), catchError(this.handleError));
    }

    tokenValidity(data) {
        return this.http.post<any>(`@api-nest/auth/check-token-validity`, data).pipe(retry(0), catchError(this.handleError));
    }

    verifyOtp(data) {
        return this.http.post<any>(`@api-nest/auth/verify-otp`, data).pipe(retry(0), catchError(this.handleError));
    }
    // getUserbyusername(username){
    //     return this.http.get<any>(`auth/get-user-by-username`, username).pipe(retry(1), catchError(this.handleError));    
    // }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }

    logout() {
        let currentUser = this.tokenService.getUser();

        this.http.post<any>(`@api-nest/auth/logout`, null).pipe(retry(0), catchError(this.handleError)).subscribe();

        this.userService.changeUserOnlineStatus({
            userId: currentUser.id,
            onlineStatus: 'Offline'
        }).subscribe((res) => {
            if (res.statusCode === 200) {
                this.tokenService.signOut();
                this.router.navigate(["/account/login"]);
            }
        })
    }
    
    public get currentUserEmail(): string {
        let email = this.tokenService.getCurrentUserEmail();
        return email || null;
    }
}

