import { Component, OnInit } from '@angular/core';
import { OnlineMeetingService } from 'src/app/core/services/online-meeting.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConferenceService } from 'src/app/core/services/conference.service';


@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
  selectedTab = 'schedule-meeting';
  upcomingMeeting = [];
  previousMeeting = [];
  limit = 4;
  offset = 0;
  webId;
  constructor(
    private modalService: NgbModal,
    public observableService: ObservableService,
    private OnlineMeetingService: OnlineMeetingService,
    private conferenceService: ConferenceService,

  ) { }

  ngOnInit(): void {
    this.getMeetings();
  }

  getMeetings() {
    this.OnlineMeetingService.getMeetings()
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            let today = new Date();
            var presentDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            this.upcomingMeeting = res.data.filter((info) => {
              return new Date(info.startDate).getTime() > presentDate.getTime() || new Date(info.startDate).getTime() == presentDate.getTime();
            });
            this.previousMeeting = res.data.filter((info) => {
              return new Date(info.startDate).getTime() < presentDate.getTime();
            });
          }
        }
      });
  }

  async startMeetingModal(startMeeting: any) {
    await this.modalService.open(startMeeting, { centered: true, modalDialogClass: 'create-folder-modal' });
    this.getWebId();
  }

  changeMeetingTab(tab: string) {
    this.selectedTab = tab;
    this.observableService.selectScheduleMeetingTab.next(tab);
    document.getElementById("chat-room").classList.add("user-chat-show");
    // document.getElementById("side-menu-block").classList.add("hide-sidebar-mobile");
  }

  selectedMeeting(meeting) {
    this.observableService.onMeetingChange.next(meeting);
  }

  async getWebId() {
    this.conferenceService.get_uniqueconferencemeetingnumber(null)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.webId = res.data[0].rnd

        }
      })
  }
}
