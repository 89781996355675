import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private toastr: ToastrService
  ) { }

  success(title, message) {
    this.toastr.success(title, message, {
      timeOut: 3000,
      progressBar: true,
      newestOnTop: true,
      positionClass: 'toast-top-right',
    });
  }

  error(title, message) {
    this.toastr.error(title, message, {
      timeOut: 3000,
      progressBar: true,
      newestOnTop: true,
      positionClass: 'toast-top-right',
    });
  }

  warining(title, message) {
    this.toastr.warning(title, message, {
      timeOut: 3000,
      progressBar: true,
      newestOnTop: true,
      positionClass: 'toast-top-right',
    });
  }
}
