import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { contacts } from './data';
import { Contacts } from './contacts.model';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'src/app/core/services/user.service';
import * as _ from 'lodash';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { ConferenceMiddlewareService } from '../../conference/service/conference.middleware.service';
import { InviteWithMessageComponent } from '../invite-with-message/invite-with-message.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit {
  currentUser: any;
  contacts: Contacts[];
  contactsList: any;
  userList: any = [];

  constructor(private modalService: NgbModal, 
    public translate: TranslateService , 
    private userService: UserProfileService,
      private tokenStorageService: TokenStorageService,
      private conferenceMiddlewareService: ConferenceMiddlewareService
    ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();

    const sorted = contacts.sort((a, b) => a.name > b.name ? 1 : -1);

    const grouped = sorted.reduce((groups, contact) => {
      const letter = this.translate.instant(contact.name).charAt(0);
      groups[letter] = groups[letter] || [];
      groups[letter].push(contact);

      return groups;
    }, {});

    // contacts list
    // this.contactsList = Object.keys(grouped).map(key => ({ key, contacts: grouped[key] }));
    this.getUserList();
  }


  /**
   * Contacts modal open
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openContactsModal(content) {
    // this.modalService.open(content, { centered: true });
    this.modalService.open(InviteWithMessageComponent, {
      centered: true,
      modalDialogClass: 'create-file-modal',
    });
  }


  async getUserList() {
    try {
      this.userService.getUserList().subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            this.userList = res.data;
            this.userList = _.groupBy(this.userList, function (item) { return item.firstname[0].toUpperCase(); });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }


  call_click(username: string) {
   
      this.conferenceMiddlewareService.makeAudioCall(null, [username]);
  }
  call_videoclick(username: string) {
   
      this.conferenceMiddlewareService.makeVideoCall(null, [username]);
  }
}
