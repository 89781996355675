<div class="layout-wrapper d-lg-flex">

    <app-side-bar></app-side-bar>

    <div class="chat-welcome-section">
        <div class="main-section">

            <mat-icon *ngIf="fileOpened" (click)="navigateBackFromFile()" class="pointer" style="cursor:pointer">
                close
            </mat-icon>
            <b>{{getFullFileName(fileName)}}</b>
            <div *ngIf="fileOpened && MSfileOpened">
                <div>
                    <form id="office_form" name="office_form" target="office_frame" [action]="urlsrc" method="post">
                        <input name="access_token" [(ngModel)]="access_token" type="hidden" />
                        <input name="access_token_ttl" [(ngModel)]="access_token_ttl" type="hidden" />
                    </form>
                    <span id="frameholder"></span>
                </div>
            </div>
            <div *ngIf="fileOpened && !MSfileOpened">
                <pdf-viewer  *ngIf="isPdf"  [src]="blobUrl" [render-text]="true" style="width: 200vh; height: 600px;"></pdf-viewer>

                <img *ngIf="isImage" [src]="blobImageUrl">

                <span *ngIf="!isImage&&!isPdf">
                    <pre *ngIf="!isImage&&!isPdf">{{blobfileContent}} </pre>
                </span>
            </div>
        </div>
    </div>
</div>