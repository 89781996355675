import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
@Injectable({
  providedIn: 'root'
})
export class FirebasePushNotificationService {

  constructor(private angularFireMessaging: AngularFireMessaging) {
  }

  requestPermission() {
    return this.angularFireMessaging.requestToken;
  }

}
