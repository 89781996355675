import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConferenceMiddlewareService } from '../../service/conference.middleware.service';
import { ConferenceDialogService } from '../../service/conference.dialog.service';
import { ConferenceSignalrJitsiOtt } from '../../service/conference.signalr.jitsi.ott.service';


@Component({
    selector: 'app-conference-common',
    template: '<div id="conferenceCommonComponent"> </div>'
})
export class ConferenceCommonComponent implements OnInit, OnDestroy {

    @Input() currentUser: any;
    @Input() token: string;


    myconnectionID: string
    setupHubCallbacksOneTime = false;
    ongoinConfInfo: any = null;

    dialogincomingCall: any;
    callWindowTimer = null;
    audioRinger = new Audio();
    confTabRef: any;

    constructor(
        private router: Router,
        private conferenceMiddlewareService: ConferenceMiddlewareService,
        private dialogService: ConferenceDialogService,
        private _hub: ConferenceSignalrJitsiOtt,
    ) {

      
        //dialogService.alert("test");
        this.conferenceMiddlewareService.$retryConnection.subscribe(async x => {

            if (x.login) {
                // { currentUser: this.currentUser, token: this.token }
                this.currentUser = x.currentUser;
                this.token = this.token;
                await this._hub.init(this.token);
            }
            else if (x.login == false) {
                await this._hub.stopConnection();
            }

        });

        window.addEventListener("storage", (ev) => {
            if (ev.key == 'browserTabAction') {
                var message = JSON.parse(ev.newValue);
                if (!message)
                    return message;

                if (message.action == "hubInit") {
                    if (!this.conferenceMiddlewareService.isCallActive()) {
                       
                        console.log('message.action == hubInit');
                        this._hub.init(this.token).then(x => {
                            if (message.confid)
                                this._hub.exitConferenceCall(message.confid).then();
                        });
                    }
                }
                if (message.action == "closetab") {
                    if (this.confTabRef)
                        this.confTabRef.close();
                }
            }
        });
    }

    async ngOnDestroy() {
        this._hub.stopConnection();
    }


    async ngOnInit() {
        //this.startHub();
        window.onbeforeunload = () => this.ngOnDestroy();


        this._hub.connectionEstablished.subscribe(async x => {
            this.conferenceMiddlewareService.$connectionStatus.next(x);
            if (!this._hub.IsConnected) {

                console.log("**************not Connected********");
            }
            if (x == true) {

                var connectionID = await this._hub.getConnectionId(this.currentUser.username);
                localStorage.setItem("hubconnectionID", connectionID);
                this.myconnectionID = connectionID;
                if (this.setupHubCallbacksOneTime == false) {
                    this.setupHubCallbacksOneTime = true;
                    console.log("**************setupHubCallbacksOneTime********");
                    this._setupHubCallbacks(this._hub);
                }


                setTimeout(async () => {
                    var _ongoinConfInfo = await this._hub.checkOnGoingCall(this.currentUser.username);
                    if (_ongoinConfInfo && _ongoinConfInfo.length)
                        this.ongoinConfInfo = _ongoinConfInfo;
                    else
                        this.ongoinConfInfo = null;

                }, 500);

            }
            else {
                console.log("signal server :" + x);
            }
        });
        if (this.currentUser)
            await this._hub.init(this.token);
    }



    _setupHubCallbacks(hub) {

        if (this._hub.error.observers.length != 0)
            return;




        this.conferenceMiddlewareService.$createCall.subscribe(async data => {
            //conferenceNumber,users, conferenceName, mode: "video", isSheduled

            await this._hub.init(this.token);

            setTimeout(() => {
                this._hub.createNewConference(this.currentUser.username, data.mode, data.conferenceNumber, data.conferenceName, data.isSheduled)
                    .then(conf => {
                        if (conf == null) {
                            this.dialogService.error("Ongoing call window is active");
                            return;
                        }
                        this._hub.addPartnersToConference(data.users, this.currentUser.username, conf.confid, conf.mode)
                            .then(x => {
                                //connectuser ,status = "offline", conuserconnectionid, mode , conuserconnectionuser }
                            });
                        // confid ,mode , conferenceNumber
                        setTimeout(() => {
                            this._hub.stopConnection();
                            this.conferenceMiddlewareService.$connectionStatus.next(false);
                            this.confTabRef = this.conferenceMiddlewareService.openJitsi(conf, this.currentUser.username, true);
                        }, 300);


                    }, err => {
                        console.log("signal server :" + err);
                    });
            }, 300);
        });

        this._hub.error.subscribe((data) => {
            console.log("error" + data.message);
            this.dialogService.error("error" + data.message);
        });

        this._hub.client.serverException.subscribe((data) => {
            console.log("error" + data.message);
            //   this.dialogService.error("serverException" + data.message);
        });

        this._hub.client.incomingcall.subscribe((data) => {
            this.showincomingCall(data);
        });

        this._hub.client.closeincomingcall.subscribe((confid) => {
            this.closeincomingcall(confid);
        });

        this._hub.client.receivedincomingCallSignal.subscribe((data) => {
            //todo start ringer  or change connecting to ringer
        });
        this._hub.client.partnerActionToincomingCall.subscribe((data) => {
            //invalid,busy,declined,accepted,offline
        });

        this._hub.client.exitConferenceCallNotify.subscribe((data) => {
            this._stopRingerAudio();
            this.dialogincomingCall.close();
        });

      
    }


    closeincomingcall(confid) {
        this.dialogincomingCall.close();
    }

    showincomingCall(data) {
        //confid, mode, conferenceName, creatorUserName
        console.log("showincomingCall");

        this.playRingerConnectingTone();
        var callerName = data.creatorUserName;

        this._hub.receivedincomingCall(data.confid, this.currentUser.username);

        if (this.callWindowTimer)
            clearTimeout(this.callWindowTimer);

        //auto stop
        this.callWindowTimer = setTimeout(() => {
            this._stopRingerAudio();
            this.dialogincomingCall.close();
        }, 1000 * 60 * 2);//2 mins

        this.dialogincomingCall = this.dialogService.callDialog("incomingCall", data.conferenceName, data.creatorUserName, (clickresult) => {
            console.log("showincomingCall-clicked ");
            this._stopRingerAudio();
            if (clickresult == true) {
                //for new widnows
                //setTimeout(() => { this._hub.stopConnection(); }, 100);

                this._hub.answerConfCall(true, data.confid, data.mode);
                setTimeout(() => {
                    setTimeout(() => { this._hub.stopConnection(); }, 100);
                    this.conferenceMiddlewareService.$connectionStatus.next(false);
                    this.confTabRef == this.conferenceMiddlewareService.openJitsi(data, this.currentUser.username);
                }, 500);

            } else {
                this._hub.answerConfCall(false, data.confid, data.mode);
            }
            this.dialogincomingCall.close();
        });

        //});
    }
    playRingerConnectingTone() {
        this._playRingerAudio("calling2.wav");
    }
    _playRingerAudio(file: string) {
        document.getElementById("conferenceCommonComponent").innerHTML = '<embed id="ringersound" src="../../../../assets/CallTones/calling2.wav" autostart=false loop=true style="opacity: 0;position: absolute; width: 0px; z-index:-1;" >';
        
        //this.audioRinger.volume = 1;
        //this.audioRinger.controls = false;
        //this.audioRinger.loop = true;
        //this.audioRinger = new Audio();
        //this._stopRingerAudio();
        //this.audioRinger.src = "../../../../assets/CallTones/" + file;//calling.wav
        //setTimeout(() => {
        //    this.audioRinger.play().catch(function (error) {
        //        console.log("Chrome cannot play sound without user interaction first")
        //    });
        //}, 1000);
    }
    _stopRingerAudio() {
        //this.audioRinger.volume = 0;
        //this.audioRinger.loop = false;
        //this.audioRinger.currentTime = 0;
        //setTimeout(() => {
        //    this.audioRinger.pause();
        //    //this.audioRinger.src = "";
        //}, 10);
        ////this.audioRinger = new Audio();
        document.getElementById("conferenceCommonComponent").innerHTML = "";
    }



    getParticipantsNames(conf) {
        var name = "";
        if (conf)
            if (conf.participants && conf.participants.length) {
                name = conf.participants[0].name;
                var _participants = conf.participants.filter(x => x.name != this.currentUser.userName);

                if (_participants.length > 1)
                    name = name + "," + _participants[1].name;
                if (_participants.length > 2)
                    name = name + " + " + (_participants.length - 2) + " others";
            }
        return name;
    }



}
