import {
    AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, TemplateRef,
    ViewChild
// tslint:disable-next-line:import-spacing
}
    from '@angular/core';
import {Router} from '@angular/router';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import * as moment from 'moment';
import * as _ from 'lodash';
import {isWebUri} from 'valid-url';
import {ObservableService} from 'src/app/core/services/observable.service';
import {SocketService} from 'src/app/core/services/socket.service';
import {UserStatusEnum} from 'src/app/core/models/userStatusEnum.models';
import {TokenStorageService} from 'src/app/core/services/token.service';
import {Signal} from 'src/app/core/services/signal.service';
import {ChatMessageModel, MessageType,} from 'src/app/core/models/chat-message.model';
import {ChatService} from 'src/app/core/services/chat.service';
import {PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective,} from 'ngx-perfect-scrollbar';
import {environment} from 'src/environments/environment';
import {CommanService} from 'src/app/core/services/comman.service';
import {InviteUserComponent} from '../../invite-user/invite-user.component';
import {ChatUserModel} from '../chatModel';
import Pusher from 'pusher-js';

@Component({
    selector: 'app-user-conversion',
    templateUrl: './user-conversion.component.html',
    styleUrls: ['./user-conversion.component.scss'],
})
export class UserConversionComponent implements OnInit {
    private room: any;
    private remoteStream: any;
    private configuration: {
        iceServers: [
            {
                urls: 'STUN:freestun.net:3479'
            },
            {
                urls: 'TURNS:freestun.net:5350',
                credential: 'free',
                username: 'free'
            }
        ],
    };
    private remoteOffer: any;

    constructor(
        public translate: TranslateService,
        private modalService: NgbModal,
        private observableService: ObservableService,
        private tokenService: TokenStorageService,
        private socketService: SocketService,
        private formBuilder: FormBuilder,
        private chatService: ChatService,
        private cd: ChangeDetectorRef,
        public commanService: CommanService,
        private signalService: Signal,
        private db: AngularFireDatabase
    ) {
    }

    @ViewChild('incomingCall') incomingCall: any;
    @ViewChild('callScreen') callScreen: any;
    @ViewChild('remotePlayer') remotePlayer: any;
    @ViewChild('localStream') localStream: any;
    callerName: string;
    callType: string;
    peerConnection: RTCPeerConnection;
    callStatus: string;
    isEditMessage = false;
    currentUser: any;
    chatForm: FormGroup;
    submitted = false;
    chatMessageScroll: any;
    emojiToggled = false;
    scrollPosition: any;
    selectedChatObservable: any;
    selectedUserMessageObservable: any;
    fileList = [];
    isImportantMessageOpen = false;
    offset = 1;
    limit = 10;
    totalRecord: number;
    totalPages: number;
    showIncomingCallModal: boolean;
    incomingCallRoomId: string;
    public type = 'component';
    public disabled = false;

    public config: PerfectScrollbarConfigInterface = {};
    @ViewChild(PerfectScrollbarComponent)
    componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, {static: false})
    directiveRef?: PerfectScrollbarDirective;

    imageSrc: any;
    images = [];

    selectedUser = new ChatUserModel();
    messageList = [];
    messageFormate = new ChatMessageModel();
    private pusher: any;

    handleSelection(event) {
        if (this.chatForm.value.message == null) {
            this.chatForm.value.message = event.emoji.native
                ? event.emoji.native
                : '';
        } else {
            this.chatForm.value.message += event.emoji.native
                ? event.emoji.native
                : '';
        }
        this.chatForm.patchValue({
            message: this.chatForm.value.message,
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        const emojiPanel = document.getElementById('emoji-palette'); // this is emoji panel element
        const buttonEmoji = document.getElementById('button-emoji'); // this is button to triggered emoji panel

        const path = event.composedPath();

        if (emojiPanel && !path.includes(buttonEmoji)) {
            if (!path.includes(emojiPanel)) {
                emojiPanel.style.display = 'none';
                this.emojiToggled = false;
            }
        }
    }

    onCallerOffer(data): void {
        console.log('oncalleroffer', data);
        this.remoteOffer = JSON.parse(data.offer);
    }

    // tslint:disable-next-line:typedef
    onCallerCandidate(data: any) {
        console.log('oncallercandidate', data);
        if (this.peerConnection) {
            this.peerConnection.addIceCandidate(JSON.parse(data.candidate))
                .then(() => console.log('remote candidate accepted'))
                .catch((e) => console.log(e));
            this.peerConnection.addEventListener('track', this.onCallerTrack);
        } else {
            this.peerConnection = new RTCPeerConnection(this.configuration);
            this.peerConnection.addEventListener('track', this.onCallerTrack);
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
                .then(stream => {
                    stream.getTracks()
                        .forEach(track => this.peerConnection.addTrack(track, stream));
                    this.peerConnection.setRemoteDescription(this.remoteOffer)
                        .then(() => console.log('remote offer accepted'))
                        .catch(() => console.log('failed to accept remote offer'));
                    this.peerConnection.createAnswer({offerToReceiveAudio: true, offerToReceiveVideo: true})
                        .then(answer => {
                            this.peerConnection.setLocalDescription(answer);
                            const body = new FormData();
                            body.append('room', data.room);
                            body.append('answer', JSON.stringify(answer));
                            fetch('https://harish.iokonic.in/signal.php?channel=' + data.room + '&event=' + 'callee-answer', {
                                method: 'POST',
                                body
                            });
                        });

                    this.peerConnection.addEventListener('icecandidate', async (event) => {
                        if (event.candidate) {
                            // tslint:disable-next-line:no-shadowed-variable
                            const body = new FormData();
                            body.append('room', data.room);
                            body.append('candidate', JSON.stringify(event.candidate));
                            await fetch('https://harish.iokonic.in/signal.php?channel=' + data.room + '&event=' + 'callee-candidate', {
                                method: 'POST',
                                body
                            });
                        }
                    });
                    this.peerConnection.addIceCandidate(JSON.parse(data.candidate))
                        .then(() => console.log('remote candidate accepted'))
                        // tslint:disable-next-line:no-debugger
                        .catch((e) => {
                            // tslint:disable-next-line:no-debugger
                            console.log(e);
                        });
                });
        }
    }

    onCallerTrack(event): void {
        console.log('oncallertrack');
        this.modalService.dismissAll();
        this.modalService.open(this.callScreen);
        const remoteStreamPlayer = document.getElementById('remoteStream');
        // @ts-ignore
        remoteStreamPlayer.srcObject = new MediaStream([event.track]);
    }

    ngOnInit(): void {
        this.currentUser = this.tokenService.getUser();

        this.observableService.loginUser.subscribe((value) => {
            this.currentUser = value;
        });

        this.pusher = new Pusher('2b25add0f61d74946a0e', {
            cluster: 'ap2'
        });
        const userChannel = this.pusher.subscribe('user@' + String(this.currentUser.id));
        userChannel.bind('call', (data: any) => {
            this.callerName = data.caller;
            this.callType = data.callType;
            this.room = data.room;
            this.incomingCallRoomId = data.room;
            this.modalService.open(this.incomingCall);
            const roomChannel = this.pusher.subscribe(this.room);
            roomChannel.bind('caller-offer', this.onCallerOffer);
            roomChannel.bind('caller-candidate', this.onCallerCandidate);
        });

        this.observableService.isOpenImportantMessage.subscribe((value) => {
            this.isImportantMessageOpen = value;
        });

        // if user change his online status then it will subscribe
        this.socketService.getUserStatus().subscribe((user: ChatUserModel) => {
            this.selectedUser = user;
            if (this.selectedUser.profilePic.indexOf('http') !== 0) {
                if (this.selectedUser && this.selectedUser != undefined) {
                    this.selectedUser.profilePic =
                        environment.s3BucketProfileUrl + '/' + this.selectedUser.profilePic;
                }
            }
        });

        new Promise((resolve) => {
            this.selectedChatObservable = this.observableService.selectUser.subscribe(
                (value: any) => {
                    this.selectedUser = value.user || value;
                    this.offset = 0;
                    this.limit = 10;
                    this.fileList = [];
                    this.setDefaultValueForMessage();
                    this.initMessageSentForm();
                    this.messageList = [];
                    this.getMessages().then(() => {
                        this.scrollToBottom();
                        resolve('Done');
                    });
                }
            );
            this.initMessageSentForm();
        }).then(() => {
            this.selectedUserMessageObservable = this.socketService
                .getUserMessage()
                .subscribe((messages: any) => {
                    if (messages.length > 0) {
                        if (messages[0].isEdit) {
                            const oldMessageIndex = this.messageList.findIndex(
                                (e) => (e.id || e.lastMessageId) === messages[0].lastMessageId
                            );
                            this.messageList[oldMessageIndex] = messages[0];
                        } else {
                            messages.forEach(async (message) => {
                                if (
                                    (message.senderId === this.selectedUser.id &&
                                        message.receiverId === this.currentUser.id) ||
                                    (message.senderId === this.currentUser.id &&
                                        message.receiverId === this.selectedUser.id)
                                ) {
                                    this.messageList.push(message);
                                    await this.addTodayYesterdayMessageLabel();
                                    this.scrollToBottom();
                                }
                            });
                        }
                    }
                });

            this.socketService.deleteUserMessage().subscribe((value) => {
                const index = this.messageList.findIndex(
                    (e) => (e.id || e.lastMessageId) === value
                );
                this.messageList.splice(index, 1);
            });
        });
    }

    call_click(username: string) {
        // window.open(location.origin+"/component?", "_blank");
        // this.conferenceMiddlewareService.publishData({ action: ConferenceAction.creatAudio, users: [username] });
    }

    initMessageSentForm() {
        this.chatForm = this.formBuilder.group({
            message: [null],
            id: [null],
            senderId: [this.messageFormate.senderId, [Validators.required]],
            receiverId: [null, [Validators.required]],
            messageType: this.messageFormate.messageType,
            file: this.formBuilder.array([]),
        });
    }

    setDefaultValueForMessage() {
        this.messageFormate.senderId = this.currentUser.id;
        this.messageFormate.receiverId = this.selectedUser.id;
        this.messageFormate.messageType = MessageType.TEXT;
        this.messageFormate.offset = this.offset;
        this.messageFormate.limit = this.limit;
    }

    showUserProfile() {
        const showProfile = document.getElementById('profile-detail');
        const chatWrapper = document.getElementById('user-chat-wrapper');
        if (showProfile.style.display === 'none') {
            showProfile.style.display = 'block';
            chatWrapper.classList.add('open-files-drawer');
        } else {
            showProfile.style.display = 'none';
            chatWrapper.classList.remove('open-files-drawer');
        }
    }

    closeUserChat() {
        document.getElementById('chat-room').classList.remove('user-chat-show');
        document
            .getElementById('side-menu-block')
            .classList.remove('hide-sidebar-mobile');
    }

    openCallModal(content) {
        this.modalService.open(content, {centered: true});
    }

    openVideoModal(content) {
        this.modalService.open(content, {centered: true});
    }

    findInvalidControls() {
        const invalid = [];
        const controls = this.chatForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log(invalid);
    }

    sendMessage() {
        this.submitted = true;
        let isNullMessage = true;
        this.chatForm.patchValue({receiverId: this.selectedUser.id});

        // this.findInvalidControls();
        if (this.chatForm.invalid) {
            return;
        }

        const formData = new FormData();
        formData.append('senderId', this.chatForm.value.senderId);
        formData.append('receiverId', this.chatForm.value.receiverId);
        formData.append('message', this.chatForm.value.message);
        formData.append('messageType', this.chatForm.value.messageType);
        formData.append('isBase64Image', 'false');

        if (this.chatForm.value.message != null) {
            isNullMessage = false;
        } else {
            isNullMessage = true;
        }

        if (this.fileList && this.fileList.length > 0) {
            this.fileList.forEach((value) => {
                formData.append('file', value.file);
            });
            isNullMessage = false;
        }

        if (!isNullMessage) {
            if (!this.isEditMessage) {
                this.chatService.sendMessage(formData).subscribe((res) => {
                    if (res.statusCode === 200) {
                        this.fileList = [];
                        this.initMessageSentForm();
                        this.scrollToBottom();
                    }
                });
            } else {
                formData.append('id', this.chatForm.value.id);

                this.chatService.editMessage(formData).subscribe((res) => {
                    if (res.statusCode === 200) {
                        this.isEditMessage = false;
                        this.fileList = [];
                        this.initMessageSentForm();
                    }
                });
            }
        }
    }

    async getMessages() {
        return new Promise((resolve, reject) => {
            try {
                const insert = (arr, index, newItem) => [
                    ...arr.slice(0, index),
                    newItem,
                    ...arr.slice(index),
                ];

                if (this.totalRecord / this.limit > this.offset || this.offset == 0) {
                    this.chatService.getMessages(this.messageFormate).subscribe((res) => {
                        if (res.statusCode === 200) {
                            res.data.message.forEach((value) => {
                                this.messageList = insert(this.messageList, 0, value);
                            });

                            this.addTodayYesterdayMessageLabel().then(() => {
                                if (this.componentRef != undefined) {
                                    this.componentRef.directiveRef.scrollToTop(
                                        res.data.message.length * 100,
                                        10
                                    );
                                }
                                this.cd.detectChanges();
                                this.totalRecord = res.data.totalCount;
                                this.totalPages = Math.ceil(this.totalRecord / this.limit);
                                resolve('Done');
                            });
                        }
                    });
                }
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

    addTodayYesterdayMessageLabel() {
        return new Promise((resolve, reject) => {
            try {
                if (this.messageList.length > 0) {
                    this.resetTodayYesterdayMessageLabel();

                    const today = moment().format('YYYY-MM-DD');
                    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');

                    const insert = (arr, index, newItem) => [
                        ...arr.slice(0, index),
                        newItem,
                        ...arr.slice(index),
                    ];

                    const findIndex = (date) => {
                        return this.messageList.findIndex(
                            (e) => moment(e.updatedAt).format('YYYY-MM-DD') === date
                        );
                    };

                    const setLabelToMessage = (index, label) => {
                        if (index != -1) {
                            this.messageList = insert(this.messageList, index, label);
                        }
                    };

                    const yesterdayIndex = findIndex(yesterday);
                    const todayIndex = findIndex(today);

                    setLabelToMessage(todayIndex, {isToday: true});
                    setLabelToMessage(yesterdayIndex, {isYesterday: true});

                    this.messageList = this.messageList.map((e) => {
                        e.messageDate = moment(e.updatedAt).format('YYYY-MM-DD');
                        return e;
                    });
                    let uniqDateList = _.uniq(_.map(this.messageList, 'messageDate'));

                    uniqDateList = _.remove(uniqDateList, function(c) {
                        delete c.messageDate;
                        return c != today && c != yesterday;
                    });

                    uniqDateList.forEach((value) => {
                        const index = findIndex(value);
                        setLabelToMessage(index, {date: value});
                    });

                    resolve('Done');
                } else {
                    resolve('Done');
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    resetTodayYesterdayMessageLabel() {
        if (this.messageList.length > 0) {
            this.messageList = _.reject(this.messageList, (o) => o.date);
            this.messageList = _.reject(this.messageList, (o) => o.isToday);
            this.messageList = _.reject(this.messageList, (o) => o.isYesterday);
        }
    }

    scrollToBottom(): void {
        if (this.componentRef && this.componentRef.directiveRef) {
            setTimeout(() => {
                this.componentRef.directiveRef.scrollToBottom(0, 100);
            }, 100);
            this.cd.detectChanges();
        }
    }

    loadMessage(event) {
        if (this.totalRecord / this.limit > this.offset) {
            this.offset += 1;
            this.setDefaultValueForMessage();
            this.getMessages();
        }
    }

    onScrollEvent() {
        this.scrollPosition = this.componentRef.directiveRef.position(true).y;
    }

    removeFile(index) {
        this.fileList.splice(index, 1);
    }

    readURL(event): void {
        if (event.target.files && event.target.files.length > 0) {
            const filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                if (this.commanService.isImageFile(event.target.files[i].name)) {
                    const reader = new FileReader();
                    reader.onload = (e: any) => {
                        this.fileList.push({
                            messageType: MessageType.IMAGE,
                            file: event.target.files[i],
                            url: e.target.result, // Base64 string for preview image
                        });
                    };
                    reader.readAsDataURL(event.target.files[i]);
                    this.chatForm.patchValue({
                        messageType: MessageType.IMAGE,
                    });
                } else {
                    this.fileList.push({
                        messageType: MessageType.FILE,
                        file: event.target.files[i],
                        url: null,
                    });
                    this.chatForm.patchValue({
                        messageType: MessageType.FILE,
                    });
                }
                this.chatForm.patchValue({
                    message: null,
                });
            }
            // this.chatForm.patchValue({
            //   messageType: MessageType.FILE
            // });
        }
    }

    fileUrl(file) {
        return environment.s3BucketChatUrl + '/' + file;
    }

    isURL(str) {
        return isWebUri(str);
    }

    ngOnDestroy() {
        this.selectedChatObservable.unsubscribe();
        // this.selectedUserMessageObservable.unsubscribe();
    }

    openInviteUserModal() {
        if (!environment.production) {
            this.modalService.open(InviteUserComponent, {
                centered: true,
                modalDialogClass: 'create-file-modal',
            });
        }
    }

    editMessage(data) {
        this.isEditMessage = true;
        this.chatForm = this.formBuilder.group({
            id: data.id || data.lastMessageId,
            message: [data.message],
            senderId: [data.senderId, [Validators.required]],
            receiverId: [data.receiverId, [Validators.required]],
            messageType: data.messageType,
            file: this.formBuilder.array([]),
        });
    }

    deleteMessage(data, index) {
        this.chatService
            .deleteMessage(data.id || data.lastMessageId)
            .subscribe((res) => {
                if (res.statusCode === 200) {
                    this.messageList.splice(index, 1);
                }
            });
    }

    // tslint:disable-next-line:typedef
    updateImportant(data, i) {
        this.chatService
            .updateImportantMessage({
                id: data.id || data.lastMessageId,
                isImportant: !data.isImportant,
            })
            .subscribe((res) => {
                this.messageList[i].isImportant = !data.isImportant;
            });
    }

    // tslint:disable-next-line:typedef
    getImportantMessageList() {
        this.isImportantMessageOpen = true;
    }

    // tslint:disable-next-line:typedef
    // @ts-ignore
    // tslint:disable-next-line:typedef
    async initCall(callType: string) {
        const body = new FormData();
        const roomID = 'room@' + String(this.selectedUser.id) + String(Date.now());
        body.append('caller', this.currentUser?.firstname);
        body.append('callType', callType);
        body.append('room', roomID);
        body.append('expiresIn', String(moment().add(1, 'minute').unix()));
        await fetch('https://harish.iokonic.in/signal.php?channel=user@' + String(this.selectedUser.id) + '&event=call', {
            method: 'POST',
            body
        });
        const room = this.pusher.subscribe(roomID);
        room.bind('callee-accepted-call', (data) => {
            this.callStatus = 'accepted';
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
                .then(stream => {
                    this.modalService.dismissAll();
                    this.peerConnection = new RTCPeerConnection(this.configuration);
                    // tslint:disable-next-line:triple-equals max-line-length
                    stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
                    // @ts-ignore
                    return this.peerConnection.createOffer({offerToReceiveAudio: 1, offerToReceiveVideo: 1});
                })
                .then(offer => {
                    this.peerConnection.setLocalDescription(offer);
                    // tslint:disable-next-line:no-shadowed-variable
                    const body = new FormData();
                    body.append('offer', JSON.stringify(offer));
                    body.append('room', roomID);
                    fetch('https://harish.iokonic.in/signal.php?channel=' + roomID + '&event=' + 'caller-offer', {
                        method: 'POST',
                        body
                    });
                    this.peerConnection.addEventListener('icecandidate', async (event) => {
                        console.log('local-ice-candidate', event.candidate);
                        if (event.candidate) {
                            // tslint:disable-next-line:no-shadowed-variable
                            const body = new FormData();
                            body.append('candidate', JSON.stringify(event.candidate));
                            body.append('room', roomID);
                            await fetch('https://harish.iokonic.in/signal.php?channel=' + roomID + '&event=caller-candidate', {
                                method: 'POST',
                                body
                            });
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        });
        room.bind('rejected', (data: any) => {
            this.callStatus = 'rejected';
        });
        room.bind('callee-answer', (data: any) => {
            console.log('callee-answer-received');
            this.peerConnection.setRemoteDescription(JSON.parse(data.answer));
            this.peerConnection.addEventListener('track', (event) => {
                this.modalService.open(this.callScreen);
                const remoteStreamPlayer = document.getElementById('remoteStream');
                const localStreamPlayer = document.getElementById('localStream');
                // @ts-ignore
                navigator.mediaDevices.getUserMedia({video: true}).then(stream => localStreamPlayer.srcObject = stream);
                // @ts-ignore
                remoteStreamPlayer.srcObject = new MediaStream([event.track]);
            });
        });
        room.bind('callee-candidate', async (data: any) => {
            const result = await this.peerConnection.addIceCandidate(JSON.parse(data.candidate));
            console.log(result);
        });
    }

    // tslint:disable-next-line:typedef
    async acceptCall() {
        const body = new FormData();
        body.append('room', this.incomingCallRoomId);
        await fetch('https://harish.iokonic.in/signal.php?channel=' + this.incomingCallRoomId + '&event=callee-accepted-call');
    }
}
