import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { domainEnvironment } from './actions';

export const initialState = { domainName: environment.domain };

export const domainReducer = createReducer(
  initialState,
  on(domainEnvironment, (state, { domainName }) => {
    return { ...state, domainName };
  })
);