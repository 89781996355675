<app-side-bar></app-side-bar>

<div *ngIf="fileOpened">
    <div class="main-section">

        <mat-icon *ngIf="fileOpened" (click)="navigateBackFromFile()" class="pointer" style="cursor:pointer">
            close
        </mat-icon>
        <b>{{getFullFileName(file_name)}}</b>
        <div *ngIf="fileOpened && MSfileOpened">
            <div>
                <form id="office_form" name="office_form" target="office_frame" [action]="urlsrc" method="post">
                    <input name="access_token" [(ngModel)]="access_token" type="hidden" />
                    <input name="access_token_ttl" [(ngModel)]="access_token_ttl" type="hidden" />
                </form>
                <span id="frameholder"></span>
            </div>
        </div>
        <div *ngIf="fileOpened && !MSfileOpened ">
            <div style="background-color:#fff;min-height: 90vh;">
                <pdf-viewer *ngIf="isPdf" [src]="blobUrl" [render-text]="true" style="display: block;"></pdf-viewer>
                <img *ngIf="isImage" [src]="blobimageUrl">
                <span *ngIf="!isImage&&!isPdf">
                    <pre *ngIf="!isImage&&!isPdf">{{blobfileContent}} </pre>
                </span>
            </div>
        </div>
    </div>
</div>