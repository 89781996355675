import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ObservableService } from 'src/app/core/services/observable.service';
import { SignupService } from 'src/app/core/services/signup.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
/**
 * Tabs-settings component
 */
export class SettingsComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  currentUser: any;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(
    private formBuilder: FormBuilder,
    public toasterService: ToasterService,
    private signupService: SignupService,
    private userService: UserProfileService,
    private tokenService: TokenStorageService,
    public observableService: ObservableService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.profileForm = this.formBuilder.group({
      userId: [this.currentUser.id],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email]],
      // mobile: ['', [Validators.required]],
    });

    this.getUserDetails();
  }
  get f() { return this.profileForm.controls; }

  setDefaultPic(event) {
    event.target.src = `assets/images/users/profile.png`;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    } else {
      try {
        if (typeof this.profileForm.value.mobile === 'object') {
          this.profileForm.patchValue({
            mobile: this.profileForm.value.mobile.e164Number
          });
        }
        await this.userService.updateProfile(this.profileForm.value)
          .subscribe((res) => {
            if (res.statusCode === 200) {
              this.getUserDetails();
              this.toasterService.success('User profile updated,', 'Success');
            }
          })
      } catch (error) {
        console.log(error);
        this.toasterService.error('Something went wrong.', 'Error');
      }
    }
  }

  async getUserDetails() {
    this.userService.getUserDetails(this.currentUser.id)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.profileForm.patchValue(res.data);
          
          if(res.data.profilePic) {
            res.data.profilePic = environment.s3BucketProfileUrl + "/" + res.data.profilePic;
          }

          this.currentUser = Object.assign(this.currentUser, res.data);

          this.tokenService.saveUser(this.currentUser);

          this.observableService.loginUser.next(this.currentUser);
          
          if(res.data.profilePic) {
            document.getElementById('profile').setAttribute('src', this.currentUser.profilePic);
          }
        }
      });
  }

  async checkMobileValidation() {
    await this.signupService.checkMobileDuplicate(this.profileForm.value.mobile.e164Number)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data != 0) {
            this.profileForm.get('mobile').setErrors({ isMobileUsed: true, msg: 'Mobile Number already used.' });
          }
        }
      });
  }

  async checkEmailValidation() {
    await this.signupService.checkEmailDuplicate(this.profileForm.value.email)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data != 0) {
            this.profileForm.get('email').setErrors({ isEmailUsed: true, msg: 'Email already used.' });
          }
        }
      });
  }

  uploadProfilePic(event) {
    const file: File = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", this.currentUser.id);
      formData.append("isBase64", 'false');
      this.userService.uploadProfilePic(formData)
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.getUserDetails();
            this.toasterService.success('User profile picture updated.,', 'Success');
          }
        })
    }
  }

  async updateOnlineStatus(status) {
    try {
      await this.userService.changeUserOnlineStatus({
        userId: this.currentUser.id,
        onlineStatus: status
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.currentUser = Object.assign(this.currentUser, { onlineStatus: status });
            this.tokenService.saveUser(this.currentUser);
          }
        })
    } catch (error) {
      console.log(error);
    }
  }
}
