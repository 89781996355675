import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient) { }

  checkEmailDuplicate(email) {
    return this.http.post<any>(`@api-nest/user/checked-duplicate-email`, { email }).pipe(retry(0), catchError(this.handleError));
  }

  checkIsCompanyAvailable(name) {
    return this.http.post<any>(`@api-nest/company/is-company-available`, { name }).pipe(retry(0), catchError(this.handleError));
  }

  checkMobileDuplicate(mobile) {
    return this.http.post<any>(`@api-nest/user/checked-duplicate-mobile`, { mobile }).pipe(retry(0), catchError(this.handleError));
  }

  sentOtp(data) {
    return this.http.post<any>(`@api-nest/auth/sent-otp`, data).pipe(retry(0), catchError(this.handleError));
  }

  resendOtp(data) {
    return this.http.post<any>(`@api-nest/auth/resend-otp`, data).pipe(retry(0), catchError(this.handleError));
  }

  getOtpLimit(data) {
    let params = new HttpParams();
    params = params.append('mobile', data.mobile);
    params = params.append('email', data.email);

    return this.http.get<any>(`@api-nest/auth/otp-limit`, { params }).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
