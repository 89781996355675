import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { TeamChatMessageModel } from 'src/app/core/models/chat-message.model';
import { ObservableService } from 'src/app/core/services/observable.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { TeamService } from 'src/app/core/services/team.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { isWebUri } from 'valid-url';
import * as moment from 'moment'
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-important-team-message',
  templateUrl: './important-team-message.component.html',
  styleUrls: ['./important-team-message.component.scss']
})
export class ImportantTeamMessageComponent implements OnInit {
  messageList = [];
  public currentUser: any;
  messageFormate = new TeamChatMessageModel();

  scrollPosition: any;
  offset = 1;
  limit = 10;
  totalRecord: number;
  totalPages: number;

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;


  constructor(
    private teamService: TeamService,
    private socketService: SocketService,
    private tokenService: TokenStorageService,
    private observableService: ObservableService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.offset = 0;
    this.limit = 10;
    this.getMessages();
  }

  closeImportant() {
    this.observableService.isOpenTeamImportantMessage.next(false);
  }

  getMessages() {
    return new Promise((resolve, reject) => {
      try {
        const insert = (arr, index, newItem) => [
          ...arr.slice(0, index),
          newItem,
          ...arr.slice(index)
        ];


        this.teamService.getImportantMessages({ offset: this.offset, limit: this.limit }).subscribe((res) => {
          if (res.statusCode === 200) {
            res.data.message.forEach((value) => {
              value.sender.profilePic = value.sender.profilePic != null ? environment.s3BucketProfileUrl + "/" + value.sender.profilePic : null;
              this.messageList = insert(this.messageList, 0, value);
            });

            this.addTodayYesterdayMessageLabel()
              .then(() => {
                this.componentRef.directiveRef.scrollToTop((res.data.message.length * 100), 10)
                this.cd.detectChanges();
                this.totalRecord = res.data.totalCount;
                this.totalPages = Math.ceil(this.totalRecord / this.limit);
                resolve('Done')
              })
          }
        });

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }


  resetTodayYesterdayMessageLabel() {
    if (this.messageList.length > 0) {
      this.messageList = _.reject(this.messageList, (o) => o.date);
      this.messageList = _.reject(this.messageList, (o) => o.isToday);
      this.messageList = _.reject(this.messageList, (o) => o.isYesterday);
    }
  }

  addTodayYesterdayMessageLabel() {
    return new Promise((resolve, reject) => {
      try {
        if (this.messageList.length > 0) {
          this.resetTodayYesterdayMessageLabel();

          let today = moment().format("YYYY-MM-DD");
          let yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

          const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

          const findIndex = (date) => {
            return this.messageList.findIndex((e) => moment(e.updatedAt).format("YYYY-MM-DD") === date);
          };

          const setLabelToMessage = (index, label) => {
            if (index != -1) {
              this.messageList = insert(this.messageList, index, label);
            }
          };

          let yesterdayIndex = findIndex(yesterday);
          let todayIndex = findIndex(today);

          setLabelToMessage(todayIndex, { isToday: true });
          setLabelToMessage(yesterdayIndex, { isYesterday: true });

          this.messageList = this.messageList.map((e) => {
            e.messageDate = moment(e.updatedAt).format("YYYY-MM-DD");
            return e;
          });
          let uniqDateList = _.uniq(_.map(this.messageList, "messageDate"));

          uniqDateList = _.remove(uniqDateList, function (c) {
            delete c.messageDate;
            return c != today && c != yesterday;
          });

          uniqDateList.forEach((value) => {
            let index = findIndex(value);
            setLabelToMessage(index, { date: value });
          });

          resolve('Done');
        } else {
          resolve('Done');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  loadMessage() {
    if ((this.totalRecord / this.limit) > this.offset) {
      this.offset += 11;
      this.limit = this.limit  + 10;
      this.getMessages();
    }
  }

  updateImportant(data, i) {
    this.teamService.updateImportantMessage({
      messageId: data.messageStatus.id,
      teamId: data.teamId,
      isImportant: !data.isImportant,
      receiverId: this.currentUser.id,
    })
      .subscribe((res) => {
        this.messageList.splice(i, 1);
      });
  }

  onScrollEvent() { }

  isURL(str) {
    return isWebUri(str)
  }

  fileUrl(file, bucketName) {
    if (bucketName === 'user') {
      return environment.s3BucketProfileUrl + "/" + file;
    } else if (bucketName === 'team') {
      return environment.s3BucketTeamUrl + "/" + file;
    }
  }


}
