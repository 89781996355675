import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class Signal {
    private base = 'https://harish.iokonic.in/signal.php';

    constructor(private http: HttpClient) {
    }

    // tslint:disable-next-line:typedef
    initCall(caller: string, callee: string) {
        const params = new FormData();
        params.append('caller', caller);
        params.append('callee', callee);
        params.append('signal', 'call');
        return fetch(this.base, {method: 'POST', body: params});
    }
}
