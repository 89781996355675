import { Component, EventEmitter, HostListener } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ConferenceMiddlewareService } from "./conference/service/conference.middleware.service";
import { TokenStorageService } from "./core/services/token.service";
import { UserProfileService } from "./core/services/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUser: any = {};
  isRefreshed = false;
  subscription: Subscription;
  token: string = "";
  constructor(
    public translate: TranslateService,
    private userService: UserProfileService,
    private tokenService: TokenStorageService,
    private router: Router,
    private conferenceMiddlewareService: ConferenceMiddlewareService
  ) {
    this.currentUser = this.tokenService.getUser();
    this.token = this.tokenService.getToken();

    translate.addLangs(["en", "es", "it", "ru", "de"]);
    translate.setDefaultLang("en");

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it|es|ru|de/) ? browserLang : "en");

    window.addEventListener("keydown", (event) => {
      if (event.key == "r" || event.key == "F5") this.isRefreshed = true;
    });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isRefreshed = !router.navigated;
      }
    });

    this.tokenService.loginSuccess.subscribe((login) => {
      setTimeout(async () => {
        this.currentUser = this.tokenService.getUser();
        this.token = this.tokenService.getToken();
        this.conferenceMiddlewareService.$retryConnection.next({
          login: login,
          currentUser: this.currentUser,
          token: this.token,
        });
      }, 500);
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  async onBeforeUnload(): Promise<void> {
    try {
      if (!this.isRefreshed) {
        this.currentUser = this.tokenService.getUser();

        // this.userService.changeUserOnlineStatus({
        //   userId: this.currentUser.id,
        //   onlineStatus: 'Away'
        // })
        //   .subscribe((res) => {
        //     if (res.statusCode === 200) {
        //       this.currentUser = Object.assign(this.currentUser, { onlineStatus: 'Away' });
        //       this.tokenService.saveUser(this.currentUser);
        //     }
        //   });
      }
    } catch (error) {
      console.log(error);
    }
  }
}
