import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, pairwise } from 'rxjs/operators';
import { runInThisContext } from 'vm';
import { FirebasePushNotificationService } from '../core/services/firebase-push-notification.service';
import { ObservableService } from '../core/services/observable.service';
import { SocketService } from '../core/services/socket.service';
import { ToasterService } from '../core/services/toaster.service';
import { TokenStorageService } from '../core/services/token.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  currentRoute: string;
  previousRoute: string;
  currentUser: any;
  postTab = 'all-post';
  meetingTab = 'schedule-meeting'
  isShowMeetingInfo = false;

  notificationType;
  public EMPTY = new Object();

  constructor(
    public router: Router,
    public observableService: ObservableService,
    private socketService: SocketService,
    private toasterService: ToasterService,
    private tokenService: TokenStorageService,
    private pushNotification: FirebasePushNotificationService,
    private deviceService: DeviceDetectorService
  ) {
    this.currentRoute = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.currentRoute != '/pages/setting' && this.currentRoute != '/pages/profile') {
          this.previousRoute = this.currentRoute;
        }
        this.currentRoute = event.url;
      };
      if (this.currentRoute != '/pages/notification') {
        this.observableService.notificationModule.next(this.EMPTY)
      }
    });

    this.observableService.selectUserPostTab.next('all-post');

    this.observableService.notificationModule.subscribe((value) => {
      this.notificationType = value;
    });
  }


  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.pushNotification.requestPermission().subscribe((token) => {
      if (token) {
        let deviceInfo = this.deviceService.getDeviceInfo()
        if (this.currentUser != null && this.currentUser) {
          this.socketService.sendFirebaseToken({
            deviceToken: token,
            browser: `${deviceInfo.browser} | ${deviceInfo.os_version} | ${deviceInfo.deviceType}`,
            userId: this.currentUser.id
          });
        }
      }
    });

    this.observableService.selectUserPostTab.subscribe((value: string) => {
      this.postTab = value;
    });

    this.observableService.selectScheduleMeetingTab.subscribe((value: string) => {
      this.meetingTab = value;
    })

    this.observableService.isShowMeetingInfo.subscribe((value) => {
      this.isShowMeetingInfo = value;
    })

    this.socketService.getNotification().subscribe((value: any) => {
      if (this.currentUser && this.currentUser != null) {
        if (value) {
          if (value.data[0]?.notifyUsers?.length === 0) { /** If single user notification */
            if (
              value.data[0].notificationType.type === 'chat_message' &&
              this.currentRoute != '/pages/chat' &&
              this.currentRoute != '/pages/notification') {
              if (value.data[0].notifyUser.id === this.currentUser.id) {
                this.toasterService.success(value.data[0].message, 'Notification')
              }
            } else if (
              (value.data[0].notificationType.type === 'activity_like' || value.data[0].notificationType.type === 'activity_comment') &&
              this.currentRoute != '/pages/activity' && this.currentRoute != '/pages/notification') {
              if (value.data[0].notifyUser.id === this.currentUser.id) {
                this.toasterService.success(value.data[0].message, 'Notification')
              }
            } else if (
              (value.data[0].notificationType.type === 'chat_sent_file' || value.data[0].notificationType.type === 'chat_sent_multiple_file') &&
              this.currentRoute != '/pages/chat' && this.currentRoute != '/pages/notification') {
              if (value.data[0].notifyUser.id === this.currentUser.id) {
                this.toasterService.success(value.data[0].message, 'Notification')
              }
            }
          } else { /** If multiple user notification */
            if (
              value.data[0].notificationType.type === 'team_message' &&
              this.currentRoute != '/pages/team' &&
              this.currentRoute != '/pages/notification') {
              value.data[0].notifyUsers.forEach((user) => {
                if (user.userId === this.currentUser.id) {
                  this.toasterService.success(value.data[0].message, 'Notification')
                }
              });
            } else if (
              value.data[0].notificationType.type === 'team_sent_file' &&
              this.currentRoute != '/pages/team' &&
              this.currentRoute != '/pages/notification') {
              value.data[0].notifyUsers.forEach((user) => {
                if (user.userId === this.currentUser.id) {
                  this.toasterService.success(value.data[0].message, 'Notification')
                }
              });
            } else if (
              value.data[0].notificationType.type === 'team_sent_multiple_file' &&
              this.currentRoute != '/pages/team' &&
              this.currentRoute != '/pages/notification') {
              value.data[0].notifyUsers.forEach((user) => {
                if (user.userId === this.currentUser.id) {
                  this.toasterService.success(value.data[0].message, 'Notification')
                }
              });
            }  else if (
              value.data[0].notificationType.type === 'create_meeting' &&
              this.currentRoute != '/pages/meetings' &&
              this.currentRoute != '/pages/notification') {
              value.data[0].notifyUsers.forEach((user) => {
                if (user.userId === this.currentUser.id) {
                  this.toasterService.success(value.data[0].message, 'Notification')
                }
              });
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.currentUser = null;
  }
}
