<div class="docs-body">
    <div class="add-user-search">
        <div class="input-group bg-light input-group-sm rounded-lg">
            <div class="input-group-prepend">
                <button type="button" class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                    <i class="ri-search-line search-icon font-size-18"></i>
                </button>
            </div>
            <input type="text" placeholder="Search..." class="form-control bg-light" (keyup.enter)="onSearch()"
                [(ngModel)]="searchText" name="searchText">
        </div>
    </div>

    <!-- <div *ngIf="fileOpened">
        <div class="main-section">

            <mat-icon *ngIf="fileOpened" (click)="navigateBackFromFile()" class="pointer" style="cursor:pointer">
                close
            </mat-icon>
            <b>{{getFullFileName(file_name)}}</b>
            <div *ngIf="fileOpened && MSfileOpened">
                <div>
                    <form id="office_form" name="office_form" target="office_frame" [action]="urlsrc" method="post">
                        <input name="access_token" [(ngModel)]="access_token" type="hidden" />
                        <input name="access_token_ttl" [(ngModel)]="access_token_ttl" type="hidden" />
                    </form>
                    <span id="frameholder"></span>
                </div>
            </div>
            <div *ngIf="fileOpened && !MSfileOpened ">
                <div style="background-color:#fff;min-height: 90vh;">
                    <pdf-viewer *ngIf="isPdf" [src]="blobUrl" [render-text]="true" style="display: block;"></pdf-viewer>
                    <img *ngIf="isImage" [src]="blobimageUrl">
                    <span *ngIf="!isImage&&!isPdf">
                        <pre *ngIf="!isImage&&!isPdf">{{blobfileContent}} </pre>
                    </span>
                </div>
            </div>
        </div>
    </div> -->

    <div class="file-list p-3 p-lg-4">
        <ul>
            <li *ngIf="showOption?.refresh">
                <a href="javascript:void(0);" (click)="refreshed()">
                    <i class="ri-refresh-line"></i>
                    Refresh
                </a>
            </li>
            <li *ngIf="showOption?.uploadFile">
                <a href="javascript:void(0);" data-toggle="modal" data-target="#addgroup-exampleModal"
                    (click)="openUploadDialog(uploadFile)">
                    <i class="ri-upload-2-line"></i>
                    Upload File
                </a>
            </li>
            <li *ngIf="showOption?.createNewFile">
                <a href="javascript:void(0);" data-toggle="modal" data-target="#addgroup-exampleModal"
                    (click)="createFileModal(createFile)">
                    <i class="ri-file-add-line"></i>
                    Create New File
                </a>
            </li>
            <li *ngIf="showOption?.createNewFolder">
                <a href="javascript:void(0);" data-toggle="modal" data-target="#addgroup-exampleModal"
                    (click)="createFolderModal(createFolder)">
                    <i class="ri-folder-add-line"></i>
                    Create New Folder
                </a>
            </li>
            <li *ngIf="showOption?.delete">
                <a href="javascript:void(0);">
                    <i class="ri-delete-bin-line"></i>
                    Permanently Delete
                </a>
            </li>
            <li *ngIf="showOption?.restore">
                <a href="javascript:void(0);" (click)="restoreFile()">
                    <i class="ri-restart-line"></i>
                    Restore
                </a>
            </li>

            <li *ngIf="showOption?.paste && cliboard?.id">
                <a href="javascript:void(0);" (click)="elementPaste()">
                    <i class="ri-restart-line"></i>
                    Paste
                </a>
            </li>
        </ul>
    </div>

    <app-file-explorer *ngIf="currentUser" [fileElements]="fileElements | async" [path]="currentPath"
        [clearSelection]="clearSelection" [clickOutSide]="clickOutSide" [favouriteElement]="clickfavouriteElement"
        [pathid]="currentFolderId" [canNavigateUp]="canNavigateUp" [isInRootDirectory]="isInRootDirectory"
        [currentfolderid]="currentFolderId" [isUnderSystemDirectory]="isUnderSystemDirectory"
        (folderAdded)="addFolder($event)" (elementRemoved)="removeElement($event)"
        (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
        (downloadElement)="downloadElement($event)" (elementRenamed)="renameElement($event)"
        (elementMoved)="moveElement($event)" (updateFolder)="updateFolder()" (uploadFile)="uploadFile($event)"
        (elementCut)="elementCut($event)" (elementCopied)="elementCopied($event)" (elementPaste)="elementPaste()"
        [cliboard]="cliboard" (refreshed)="refreshed()" (selectFileElementEmitter)="selectFileElementEmitter($event)">
    </app-file-explorer>

</div>

<ng-template #uploadFile let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">Upload Files</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
        <div class="upload-box">
            <input class="upload-file-teg" type="file" id="uploadFile" (change)="onFileSelected($event)">
            <p>Browse file to upload</p>
        </div>
    </div>
    <div class="modal-footer p-2">
        <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary m-2" (click)="onFileUpload()">Upload</button>
    </div>
</ng-template>

<ng-template #createFile let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">Create File</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="createFileForm" (ngSubmit)="createFileSubmit()">

        <div class="modal-body p-4">
            <div class="create-file-wrap">
                <div class="file-select mb-3">
                    <label class="radio-img">
                        <input type="radio" name="extension" value="docx" formControlName="extension" />
                        <div class="image">
                            <img src="assets/images/jpg.png" alt="">
                        </div>
                        <div *ngIf="createFileForm.controls['extension'].invalid && (createFileForm.controls['extension'].dirty || 
                        createFileForm.controls['extension'].touched) && createFileF.extension.errors"
                            class="invalid-feedback">
                            <div *ngIf="createFileF.extension.errors.required">File extension is required.
                            </div>
                        </div>
                    </label>

                    <label class="radio-img">
                        <input type="radio" name="extension" value="pptx" formControlName="extension" />
                        <div class="image">
                            <img src="assets/images/docx.png" alt="">
                        </div>
                        <div *ngIf="createFileForm.controls['extension'].invalid && (createFileForm.controls['extension'].dirty || 
                        createFileForm.controls['extension'].touched) && createFileF.extension.errors"
                            class="invalid-feedback">
                            <div *ngIf="createFileF.extension.errors.required">File extension is required.
                            </div>
                        </div>
                    </label>

                    <label class="radio-img">
                        <input type="radio" name="extension" value="exl" formControlName="extension" />
                        <div class="image">
                            <img src="assets/images/jpg.png" alt="">
                        </div>
                        <div *ngIf="createFileForm.controls['extension'].invalid && (createFileForm.controls['extension'].dirty || 
                        createFileForm.controls['extension'].touched) && createFileF.extension.errors"
                            class="invalid-feedback">
                            <div *ngIf="createFileF.extension.errors.required">File extension is required.
                            </div>
                        </div>
                    </label>

                    <label class="radio-img">
                        <input type="radio" name="extension" value="txt" formControlName="extension" />
                        <div class="image">
                            <img src="assets/images/jpg.png" alt="">
                        </div>
                        <div *ngIf="createFileForm.controls['extension'].invalid && (createFileForm.controls['extension'].dirty || 
                        createFileForm.controls['extension'].touched) && createFileF.extension.errors"
                            class="invalid-feedback">
                            <div *ngIf="createFileF.extension.errors.required">File extension is required.
                            </div>
                        </div>
                    </label>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="File Name" formControlName="name" [ngClass]="{ 
                        'is-invalid': createFileForm.controls['name'].invalid && (createFileForm.controls['name'].dirty || 
                        createFileForm.controls['name'].touched) && createFileF.name.errors}">
                    <span class="input-group-text" id="basic-addon2">{{createFileForm?.value?.extension}}</span>
                    <div *ngIf="createFileForm.controls['name'].invalid && (createFileForm.controls['name'].dirty || 
                            createFileForm.controls['name'].touched) && createFileF.name.errors"
                        class="invalid-feedback">
                        <div *ngIf="createFileF.name.errors.required">File name is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer p-2">
            <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Cancel</button>
            <button type="submit" [disabled]="createFileForm.invalid" class="btn btn-primary m-2">
                Create
            </button>
        </div>
    </form>
</ng-template>

<ng-template #createFolder let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">Create a new folder</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="createFolderForm" (ngSubmit)="createFolderSubmit()">
        <div class="modal-body p-4">
            <div class="create-folder-wrap">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Folder Name" formControlName="name" [ngClass]="{ 
                        'is-invalid': createFolderForm.controls['name'].invalid && (createFolderForm.controls['name'].dirty || 
                        createFolderForm.controls['name'].touched) && createFolderF.name.errors}">
                    <div *ngIf="createFolderForm.controls['name'].invalid && (createFolderForm.controls['name'].dirty || 
                        createFolderForm.controls['name'].touched) && createFolderF.name.errors"
                        class="invalid-feedback">
                        <div *ngIf="createFolderF.name.errors.required">Folder name is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-2">
            <button type="button" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Cancel</button>

            <button type="submit" [disabled]="createFolderForm.invalid" class="btn btn-primary m-2">Ok</button>
        </div>
    </form>

</ng-template>