import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from "jquery";
import { environment } from '../../../../environments/environment';
import { ConferenceMiddlewareService } from '../../service/conference.middleware.service';
import { ConferenceSignalrJitsiOtt } from '../../service/conference.signalr.jitsi.ott.service';
declare var JitsiMeetExternalAPI: any;

@Component({
    selector: 'app-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.scss']
})
export class JitsiComponent implements OnDestroy, OnInit, AfterViewInit {
    InvalidData: boolean = null;
    confData: any;
    domain: string;
    options: any;
    api: any;
    isValidData = false;
    // For Custom Controls
    isAudioMuted = false;
    isVideoMuted = false;

    _connectJitsiEventSubscription;
    constructor(
        private router: Router,
        private conferenceMiddlewareService: ConferenceMiddlewareService,
        private _hub: ConferenceSignalrJitsiOtt,
    ) {

        this.domain = environment.jitsi;

        window.addEventListener("beforeunload", (event) => {
            event.preventDefault();
            // event.returnValue = "Unsaved modifications";
            localStorage.setItem('browserTabAction', JSON.stringify({ action: "hubInit",confid: this.confData.confid }));
            localStorage.setItem('browserTabAction', JSON.stringify({ action: "call-locked", value: false }));

            return event;
        });
    }

    console(data, t1 = null) {
        if (!environment.production)
            console.log(data, t1);
    }
    ngOnInit(): void {

        this.conferenceMiddlewareService.$connectionStatus.subscribe(async x => {
            if (x == true)
                this.afterSignalConnect();
            //else
             //   this.InvalidData = true;
        });
    }

    validateConfData(data) {

        // var data = { action: "connectJitsi", confid: "3dsfsfs34324324", mode: "video", conferenceNumber: "232", username: "test", isModerator: true };
        if (data && data.conferenceNumber && data.username && data.confid && data.mode) {
            this.isValidData = true;
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this._connectJitsiEventSubscription.unsubscribe();




    }

    ngAfterViewInit(): void {
    }
    afterSignalConnect(): void {

        this._connectJitsiEventSubscription = this.conferenceMiddlewareService.actions.connectJitsi.subscribe(data => {
            //  this.conferenceMiddlewareService.actions.connectJitsi.unsubscribe();
            this.console("_connectJitsi_");
            //alert(data.conferenceNumber);
            this.console(data);

            if (this.validateConfData(data)) {
                this.InvalidData = false;
                this.confData = data;
                this.options = {
                    roomName: data.conferenceNumber,
                    //width: 700,
                    //height: 500,
                    configOverwrite: {
                        doNotStoreRoom: false,
                        enableWelcomePage: false,
                        toolbarConfig: {
                            alwaysVisible: true,
                        },

                        startAudioOnly: data.mode == "audio",
                        prejoinPageEnabled: false,
                        prejoinConfig: {
                            enabled: false
                        },

                    },
                    interfaceConfigOverwrite: {
                        APP_NAME: '8bytes Technology',
                        AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(51,51,51,0.7)',
                        AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(51,51,51,0.7)',
                        DEFAULT_BACKGROUND: '#f7f7ff',
                        //FILM_STRIP_MAX_HEIGHT: 240,
                        //VERTICAL_FILMSTRIP: false,
                        // DEFAULT_LOGO_URL: '/assets/images/logo.svg',
                        SHOW_JITSI_WATERMARK: false,
                        HIDE_INVITE_MORE_HEADER: true,
                        HIDE_DEEP_LINKING_LOGO: true,
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
                        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                        DISABLE_FOCUS_INDICATOR: true,

                        //DEFAULT_WELCOME_PAGE_LOGO_URL: '/assets/images/logo.svg',
                        SETTINGS_SECTIONS: data.isModerator ? ['devices', 'language', 'moderator', 'profile', 'calendar'] : "['devices', 'language', 'profile']",
                        TOOLBAR_BUTTONS: this._toolBar(data.mode, data.isModerator)
                        //["camera",
                        //    "chat",
                        //    "fullscreen",
                        //    "hangup",
                        //    "microphone",
                        //    "mute-everyone",
                        //    "mute-video-everyone",
                        //    "participants-pane",
                        //    "raisehand",
                        //    "recording",
                        //    "toggle-camera",
                        //    "videoquality",
                        //    "__end",
                        //    "select-background",
                        //    "shareaudio",
                        //    "sharedvideo"]
                    },
                    parentNode: document.querySelector('#jitsi-iframe'),
                    userInfo: {
                        moderator: data.isModerator,
                        displayName: data.username
                    }
                }

                this.api = new JitsiMeetExternalAPI(this.domain, this.options);

                this.api.addEventListeners({

                    readyToClose: this.handleClose,
                    participantLeft: this.handleParticipantLeft,
                    participantJoined: this.handleParticipantJoined,
                    videoConferenceJoined: this.handleVideoConferenceJoined,
                    videoConferenceLeft: this.handleVideoConferenceLeft,
                    audioMuteStatusChanged: this.handleMuteStatus,
                    videoMuteStatusChanged: this.handleVideoStatus
                });

                localStorage.setItem('browserTabAction', JSON.stringify({ action: "call-locked", value: true }));
               // alert("ok");

                this._hub.JoinConf(data.confid, data.username, data.mode, data.isModerator).then(() => { });
            }
            else {
                this.InvalidData = true;
                this.console("Invlaid Data");
            }
            setTimeout(() => this.fullscreenUI(), 1000);

        });
    }

    fullscreenUI() {

        var iframe = $('#jitsi-iframe iframe');
        if (iframe.length > 0) {
            $('#jitsi-iframe').css('iframeCustom');
        }
        else
            setTimeout(() => this.fullscreenUI(), 1000);
    }

    handleClose = () => {
        this.console("handleClose");
      
        setTimeout(() => {
            localStorage.setItem('browserTabAction', JSON.stringify({ action: "hubInit", confid: this.confData.confid }));
            localStorage.setItem('browserTabAction', JSON.stringify({ action: "call-locked", value: false }));
        }, 2000);

    }

    handleParticipantLeft = async (participant) => {
        this.console("handleParticipantLeft", participant); // { id: "2baa184e" }
        const data = await this.getParticipants();
    }

    handleParticipantJoined = async (participant) => {
        this.console("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
        const data = await this.getParticipants();
    }

    handleVideoConferenceJoined = async (participant) => {
        this.console("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
        const data = await this.getParticipants();
    }

    handleVideoConferenceLeft = () => {
        this.console("handleVideoConferenceLeft");
        this.router.navigate(['conference/thank-you']);
    }

    handleMuteStatus = (audio) => {
        this.console("handleMuteStatus", audio); // { muted: true }
    }

    handleVideoStatus = (video) => {
        this.console("handleVideoStatus", video); // { muted: true }
    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo()); // get all participants
            }, 500)
        });
    }

    // custom events
    executeCommand(command: string) {
        this.api.executeCommand(command);;
        if (command == 'hangup') {
            this.router.navigate(['/thank-you']);
            return;
        }

        if (command == 'toggleAudio') {
            this.isAudioMuted = !this.isAudioMuted;
        }

        if (command == 'toggleVideo') {
            this.isVideoMuted = !this.isVideoMuted;
        }
    }

    _toolBar(mode, isModerator) {
        var selectedOptions = [];
        var options = [
            { value: 'camera', mode: 'video', role: 'all', disabled: false },
            { value: 'chat', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'fullscreen', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'hangup', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'microphone', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'mute-everyone', mode: 'video/audio', role: 'moderator', disabled: false },
            { value: 'mute-video-everyone', mode: 'video/audio', role: 'moderator', disabled: false },
            { value: 'participants-pane', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'raisehand', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'recording', mode: 'video/audio', role: 'moderator', disabled: false },
            { value: 'toggle-camera', mode: 'video', role: 'all', disabled: false },
            { value: 'videoquality', mode: 'video', role: 'all', disabled: false },
            { value: '__end', mode: 'video/audio', role: 'moderator', disabled: false },
            { value: 'select-background', mode: 'video', role: 'all', disabled: false },
            { value: 'shareaudio', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'sharedvideo', mode: 'video/audio', role: 'all', disabled: false },
            { value: 'desktop', mode: 'video/audio', role: 'all', disabled: false },

            { value: 'closedcaptions', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'download', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'embedmeeting', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'etherpad', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'feedback', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'filmstrip', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'help', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'invite', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'livestreaming', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'profile', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'settings', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'shareaudio', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'sharedvideo', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'shortcuts', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'stats', mode: 'video/audio', role: 'all', disabled: true },
            { value: 'tileview', mode: 'video/audio', role: 'all', disabled: true },

        ]
        var roles = ["all"];
        if (isModerator)
            roles.push("moderator");

        for (var i = 0; i < options.length; i++) {
            var opt = options[i];
            if (opt.disabled == false && opt.mode.indexOf(mode) != -1 && roles.some(r => opt.role == r))
                selectedOptions.push(opt.value);
        }

        this.console(selectedOptions);
        return selectedOptions;
    }
}
