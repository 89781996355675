<perfect-scrollbar class="notification-leftsidebar me-lg-1" (psYReachEnd)="loadNotification()">
    <div class="notification-inner">
        <div class="notification-list-wrap pb-3 p-4">
            <h4 class="mb-4">Notification</h4>
            <div class="notification-btn mb-4">
                <ul>
                    <li  [ngClass]="{'active': tableValue === 1}">
                        <a href="javascript:void(0);" (click)="changeTab(1)" >Read</a>
                    </li>
                    <li [ngClass]="{'active': tableValue === 0}">
                        <a href="javascript:void(0);" (click)="changeTab(2)">Unread</a>
                    </li>
                </ul>
            </div>
            <ul>
                <li *ngFor="let notification of allNotification; let i = index" (click)="updateNotificationStatus(notification, i)">
                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'activity_like'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                            </div>
                        </div>
                    </a>

                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'activity_comment'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                            </div>
                        </div>
                    </a>

                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'chat_message'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                            </div>
                        </div>
                    </a>

                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'team_message'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                            </div>
                        </div>
                    </a>
                    
                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'create_meeting'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                            </div>
                        </div>
                    </a>


                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'team_sent_file'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                                <a [href]="fileUrl(notification.s3FileName, notification)"
                                    download="{{notification.s3FileName}}">
                                    <span class="btn btn-primary schedule-submit-button">Download</span>
                                </a>
                            </div>
                        </div>
                        <div class="notification-icon">
                            <i class="ri-folder-zip-fill"></i>
                        </div>
                    </a>

                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'chat_sent_file'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                                {{notification.s3FileName}}
                                <a [href]="fileUrl(notification.s3FileName, notification)"
                                    download="{{notification.s3FileName}}">
                                    <span class="btn btn-primary schedule-submit-button">Download</span>
                                </a>
                            </div>
                        </div>
                        <div class="notification-icon">
                            <i class="ri-folder-zip-fill"></i>
                        </div>
                    </a>

                    <a href="javascript:void(0);" *ngIf="notification?.notificationType?.type === 'chat_sent_multiple_file'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                                <a href="javascript:void(0);">
                                    <span class="btn btn-primary schedule-submit-button">Preview</span>
                                </a>
                                <!-- <button class="btn btn-primary schedule-submit-button">Preview</button> -->
                            </div>
                        </div>
                        <div class="notification-icon">
                            <i class="ri-folder-zip-fill"></i>
                        </div>
                    </a>

                    <a href="javascript:void(0);"
                        *ngIf="notification?.notificationType?.type === 'team_sent_multiple_file'">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary" [ngClass]="{'active': notification?.isRead === false}">
                                    {{notification?.whoFireNotification.firstname | firstCharacter}}
                                </span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">
                                    {{notification?.whoFireNotification.firstname}}
                                    {{notification?.whoFireNotification?.lastname}}
                                    <span>{{notification?.createdAt | dateAgo}}</span>
                                </h5>
                                <h5 class="font-size-14 mb-0">
                                    <span>{{notification?.message}}</span>
                                </h5>
                                <a href="javascript:void(0);">
                                    <span class="btn btn-primary schedule-submit-button">Preview</span>
                                </a>
                                <!-- <button class="btn btn-primary schedule-submit-button">Preview</button> -->
                            </div>
                        </div>
                        <div class="notification-icon">
                            <i class="ri-folder-zip-fill"></i>
                        </div>
                    </a>

                </li>
                <!-- <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">C</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Srujal <span>4h</span></h5>
                                <h5 class="font-size-14 mb-0">Harish <span>has sent a message to you</span></h5>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">S</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Pradip <span>just now</span></h5>
                                <h5 class="font-size-14 mb-3"><span>harish has shared a file in </span> aws <span>team</span></h5>
                            </div>
                        </div>
                    </a>
                </li>

                
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">P</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Sandip <span>3h</span></h5>
                                <h5 class="font-size-14 mb-0"><span>harish</span> liked <span>your activity</span></h5>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">C</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Srujal <span>4h</span></h5>
                                <h5 class="font-size-14 mb-0">Harish <span>has sent a message to you</span></h5>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">S</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Pradip <span>just now</span></h5>
                                <h5 class="font-size-14 mb-3"><span>harish has shared a file in </span> aws <span>team</span></h5>
                            </div>
                        </div>
                    </a>
                </li>

                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">D</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Chirag <span>2h</span></h5>
                                <h5 class="font-size-14 mb-0"><span>Harish has</span> commented <span>on your activity</span></h5>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">P</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Sandip <span>3h</span></h5>
                                <h5 class="font-size-14 mb-0"><span>harish</span> liked <span>your activity</span></h5>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <div class="notification-list">
                            <div class="notification-list-profile me-2 ms-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">C</span>
                            </div>
                            <div class="notification-list-content">
                                <h5 class="font-size-14">Srujal <span>4h</span></h5>
                                <h5 class="font-size-14 mb-0">Harish <span>has sent a message to you</span></h5>
                            </div>
                        </div>
                    </a>
                </li> -->
            </ul>
        </div>
    </div>
</perfect-scrollbar>