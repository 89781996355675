import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlineMeetingService } from 'src/app/core/services/online-meeting.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ObservableService } from 'src/app/core/services/observable.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-meeting-information',
  templateUrl: './meeting-information.component.html',
  styleUrls: ['./meeting-information.component.scss']
})
export class MeetingInformationComponent implements OnInit {
  meetingInfo: any = {};
  emails: any;
  public validators = [this.must_be_email];
  public currentUser: any = {};
  public errorMessages = {
    'must_be_email': 'Please be sure to use a valid email format'
  };

  private must_be_email(control: FormControl) {
    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
    if (control.value.length != "" && !EMAIL_REGEXP.test(control.value)) {
      return { "must_be_email": true };
    }
    return null;
  }
  // public conferenceMiddlewareService: ConferenceMiddlewareService;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private observableService: ObservableService,
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
    public toasterService: ToasterService,
    private onlineMeetingService: OnlineMeetingService,


  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();


    this.observableService.onMeetingChange.subscribe((value) => {
      this.meetingInfo = value;
    });
  }

  async shareDetailsModal(shareDetails: any) {
    await this.modalService.open(shareDetails, { centered: true, modalDialogClass: 'create-folder-modal' });
  }

  onSubmit() {
    if (this.emails.length > 0) {
      let emailList = this.emails.map((value) => {
        return value.value
      });

      let data = {
        emails: emailList,
        meetingId: this.meetingInfo.id
      }

      this.onlineMeetingService.sendMeetingMail(data)
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.modalService.dismissAll();
          }
        });
    }
  }

  startMeeting() {
    var confNumber = this.meetingInfo.webId;
    // confNumber = confNumber.replace(" ", "").replace(" ", "");
    this.onlineMeetingService.conferenceByNumber({ confnumber: confNumber }).subscribe((res) => {
      if (res) {
       

        //var url = "?conftoken=" + res.confid + '%23' + res.mediaServerconnectionId + '%23' + res.mediaServerconnectionId + "%23" + this.conferenceMiddlewareService.getjoinMode(JSON.parse(res.mode).action);

        //this.confTabRef 
       //setTimeout(() => { this.conferenceMiddlewareService.openConfMatDialogWindow(url); }, 500);
      } else {
        this.onlineMeetingService.validateConferenceMeetingByNumber(confNumber, this.currentUser.companyId, this.currentUser.username, (message, isSchedule) => {
          if (isSchedule == 1) {
            if (message == "success") {
              var users = this.meetingInfo.members;
            //  this.conferenceMiddlewareService.publishData({ action: ConferenceAction.creatVideo, users: users, confnumber: confNumber });
            } else {
              console.log(this.meetingInfo)
              this.toasterService.error(message, 'Error')
            }
          } else {
            this.toasterService.error('Invalid conference numbers', 'Error')
          }
        })
      }
    });
  }
}
