import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { SideBarComponent } from './side-bar/side-bar.component';
import { RouterModule } from '@angular/router';
import { PageComponent } from './page.component';
import { SettingsComponent } from './settings/settings.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ProfileComponent } from './profile/profile.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FirstCharacterPipe } from '../core/pipe/first-character.pipe';
import { UserListComponent } from './user-list/user-list.component';
import { UserConversionComponent } from './user-list/user-conversion/user-conversion.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { TeamListComponent } from './team-list/team-list.component';
import { TeamConversionComponent } from './team-list/team-conversion/team-conversion.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ClientActivityComponent } from './client-activity/client-activity.component';
import { TeamFilesComponent } from './team-list/team-files/team-files.component';
import { ActivityContentComponent } from './client-activity/activity-content/activity-content.component';
import { UserFilesComponent } from './user-list/user-files/user-files.component';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { MeetingsComponent } from './meetings/meetings.component';
import { ScheduleMeetingComponent } from './meetings/schedule-meeting/schedule-meeting.component';
import { JoinMeetingComponent } from './meetings/join-meeting/join-meeting.component';
import { DocsComponent } from './docs/docs.component';
import { FilesComponent } from './docs/files/files.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';
import { CallHistoryComponent } from './contacts/call-history/call-history.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MeetingInformationComponent } from './meetings/meeting-information/meeting-information.component';
import { NotificationComponent } from './notification/notification.component';
import { DateAgoPipe } from '../core/pipe/date-ago.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';


import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { FileExplorerComponent } from './docs/file-explorer/file-explorer.component';
import { PropertyModalComponent } from './docs/property-modal/property-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateTeamModalComponent } from './team-list/create-team-modal/create-team-modal.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { PreviewFileComponent } from './docs/preview-file/preview-file.component';
import { FilePreviewComponent } from './docs/file-preview/file-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InviteWithMessageComponent } from './invite-with-message/invite-with-message.component';
import { ImportantMessagesComponent } from './user-list/important-messages/important-messages.component';
import { ImportantTeamMessageComponent } from './team-list/important-team-message/important-team-message.component';

@NgModule({
  declarations: [
    PageComponent,
    SideBarComponent,
    SettingsComponent,
    ProfileComponent,
    ContactsComponent,
    FirstCharacterPipe,
    DateAgoPipe,
    UserListComponent,
    UserConversionComponent,
    ProfileDetailComponent,
    TeamListComponent,
    TeamConversionComponent,
    ClientActivityComponent,
    TeamFilesComponent,
    ActivityContentComponent,
    UserFilesComponent,
    ScheduleMeetingComponent,
    MeetingsComponent,
    JoinMeetingComponent,
    DocsComponent,
    FilesComponent,
    WhiteboardComponent,
    CallHistoryComponent,
    MeetingInformationComponent,
    NotificationComponent,
    FileExplorerComponent,
    PropertyModalComponent,
    CreateTeamModalComponent,
    InviteUserComponent,
    PreviewFileComponent,
    FilePreviewComponent,
    InviteWithMessageComponent,
    ImportantMessagesComponent,
    ImportantTeamMessageComponent,
  ],
  imports: [
    TagInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PagesRoutingModule,
    RouterModule,
    PerfectScrollbarModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    PickerModule,
    DragScrollModule,
    NgbModule,
    CrystalLightboxModule,
    SlickCarouselModule,
    MatDialogModule,
    MatSnackBarModule,
    NgSelectModule,
      PdfViewerModule,
    InfiniteScrollModule
  ],
  providers: [{
    provide: MatDialogRef,
    useValue: {}
  },]
})
export class PagesModule { }
