import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ChatService } from 'src/app/core/services/chat.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-files',
  templateUrl: './user-files.component.html',
  styleUrls: ['./user-files.component.scss']
})
export class UserFilesComponent implements OnInit {
  currentUser;
  selectedUser: any = {};
  files: any = [];

  selectedUserObservable: any;
  selectedSelectUserObservable: any;


  offset = 0;
  limit = 7;
  totalRecord: 0;



  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  
  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'file') {
      $event.preventDefault();
    }
  }

  constructor(
    private tokenService: TokenStorageService,
    private observableService: ObservableService,
    private chatService: ChatService,
    private cd: ChangeDetectorRef
  ) { }

  

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();

    this.selectedUserObservable = this.observableService.loginUser.subscribe((value) => {
      this.currentUser = value
    });

    this.selectedSelectUserObservable = this.observableService.selectUser.subscribe((value: any) => {

      this.selectedUser = value;
      this.files = [];
      this.offset = 0;
      this.limit = 7;
      this.totalRecord = 0;
      this.getFileList();
    });

  }

  hideUserProfile() {
    document.getElementById("profile-detail").style.display = "none";
    document.getElementById("user-chat-wrapper").classList.remove("open-files-drawer");
  }

  async getFileList() {
    return new Promise((resolve, reject) => {
      this.chatService.getFileList({
        senderId: this.currentUser.id,
        receiverId: (this.selectedUser?.user?.id || this.selectedUser.id),
        limit: this.limit,
        offset: this.offset
      })
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.totalRecord = res.data.totalCount;
            if (res.data.files.length > 0) {
              res.data.files.forEach((value) => {
                this.files.push(value);
                this.cd.detectChanges();
              });
            }
          }
        });
    });
  }

  async loadFiles() {
    if (this.totalRecord > this.limit) {
      this.offset = this.limit;
      this.limit = this.limit + 7;
      this.getFileList()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
    }
  }

  fileUrl(file) {
    return environment.s3BucketChatUrl + "/" + file;
  }

  ngOnDestroy() {
    this.selectedUserObservable.unsubscribe();
    this.selectedSelectUserObservable.unsubscribe();
  }
}
