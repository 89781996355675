<div class="layout-wrapper d-lg-flex">
    <div class="chat-leftsidebar pb-3 p-4 mr-lg-1">
        <h4 class="mb-lg-4 mb-3 px-3 px-lg-0 pt-3 pt-lg-0">Whiteboard</h4>
        <div class="tab-content">
            <button class="btn btn-flating-action" (click)="addEraserOrBrush('brush');">
                <i class="ri-pencil-line"></i> Brush
            </button>
            <button class="btn btn-flating-action" (click)="addCircle();">
                <i class="ri-checkbox-blank-circle-line"></i>
                Circle
            </button>
            <button class="btn btn-flating-action" (click)="addRectangle();">
                <i class="ri-checkbox-blank-line"></i>
                Rectangle
            </button>
            <button class="btn btn-flating-action" (click)="addLine();">
                <i class="ri-subtract-line"></i>
                Line
            </button>
            <button class="btn btn-flating-action" (click)="addText()">
                <i class="ri-text"></i>
                Text
            </button>
            <button class="btn btn-flating-action" (click)="addEraserOrBrush('eraser');">
                <i class="ri-eraser-line"></i> Eraser
            </button>
            <div class="btn btn-flating-action">
                <input type="color" class="color-picker" name="backgroundColor" [(ngModel)]="backgroundColor">
                Background Color
            </div>
            <div class="btn btn-flating-action">
                <input type="color" class="color-picker" name="backgroundColor" [(ngModel)]="strokeColor"> Stroke Color
            </div>
            <button class="btn btn-flating-action" (click)="undo()">
                <i class="ri-arrow-go-back-line"></i> Undo
            </button>
            <button class="btn btn-flating-action" (click)="redo()">
                <i class="ri-arrow-go-forward-line"></i> Redo
            </button>
        </div>
    </div>
    <div class="user-chat whiteboard-box w-100">
        <div class="whiteboard-header p-2 p-lg-4 border-bottom">
            <ul>
                <li>
                    <select class="select-btn form-control" id="fontSize" (change)="changeTextFontSize()">
                        <option value="normal">Normal</option>
                        <option value="h1">heading 1</option>
                        <option value="h2">heading 2</option>
                    </select>
                </li>
                <!-- <li>
                    <button class="btn btn-flating-action" (click)="changeFontStyle('bold')">
                        <i class="ri-bold"></i>
                    </button>
                </li> -->
                <li>
                    <button class="btn btn-flating-action" (click)="addBold()">
                        <i class="ri-bold"></i>
                    </button>
                </li>
                <li>
                    <button class="btn btn-flating-action" (click)="addItalic()">
                        <i class="ri-italic"></i>
                    </button>
                </li>
                <li>
                    <button class="btn btn-flating-action" (click)="addUnderLine()">
                        <i class="ri-underline"></i>
                    </button>
                </li>
                <li>
                    <select class="select-btn form-control" id="fontFamily" (change)="changeFontFamily()">
                        <option value="Arial">Arial</option>
                        <option value="Helvetica">Helvetica</option>
                        <option value="Gill Sans">Gill Sans</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="cursive">cursive</option>
                        <option value="Apple Chancery">Apple Chancery</option>
                    </select>
                </li>
                <li>
                    <button class="btn btn-flating-action">
                        <i for="selectFile" class="ri-image-fill"></i>
                        <input id="selectFile" type="file" class="file-input" onclick="this.value = null"
                            (change)="readURL($event);" />
                    </button>
                </li>
            </ul>

            <div class="whiteboard-download-btn">
                <div class="zoom-btn">
                    <ul>
                        <li>
                            <button class="btn btn-flating-action" (click)="increaseEraserSize()"
                                [disabled]="eraserSize >= 15">
                                <i class="ri-add-line"></i>
                            </button>
                        </li>
                        <li>
                            <button class="btn btn-flating-action" (click)="decreaseEraserSize()"
                                [disabled]="eraserSize <= 3">
                                <i class="ri-subtract-line"></i>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="dropdown" dropdownClass="file-aection-dropdown" container="body" placement="bottom-end"
                    ngbDropdown>
                    <button class="btn btn-flating-action" type="button" title="Download" ngbDropdownToggle
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Download <i class="ri-download-2-line"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="saveAsImage()"> Images <i
                                class="ri-image-fill float-end text-muted"></i></a>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="convertToPDF()"> PDF <i
                                class="ri-file-pdf-line float-end text-muted"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="whiteboard-wrap">
            <div id="whiteboard"></div>
        </div>
    </div>
</div>