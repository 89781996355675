import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../services/token.service';
import { Store } from '@ngrx/store';
import { selectDomain } from 'src/app/store/selector';
const TOKEN_HEADER_KEY = 'Authorization';


@Injectable()
export class APIInterceptor implements HttpInterceptor {
  whiteListAPI = [];

  constructor(
    private token: TokenStorageService,
    private store: Store
  ) {
    this.whiteListAPI = [
      `${environment.domain}/assets/i18n/de.json`,
      `${environment.domain}/assets/i18n/en.json`,
      `${environment.domain}/assets/i18n/es.json`,
      `${environment.domain}/assets/i18n/it.json`,
      `${environment.domain}/assets/i18n/ru.json`,
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.select(selectDomain).subscribe((domain) => {
      this.whiteListAPI[0] = `${domain.domainName}/assets/i18n/de.json`;
      this.whiteListAPI[1] = `${domain.domainName}/assets/i18n/en.json`;
      this.whiteListAPI[2] = `${domain.domainName}/assets/i18n/es.json`;
      this.whiteListAPI[3] = `${domain.domainName}/assets/i18n/it.json`;
      this.whiteListAPI[4] = `${domain.domainName}/assets/i18n/ru.json`;
    });
    if (req.url === this.whiteListAPI.find(i => req.url === i)) {
      const apiReq = req.clone({ url: req.url });
      return next.handle(apiReq);
    } else {
      const token = this.token.getToken();
      if (req.url.indexOf(`@api-nest`) !== -1) {
        const url = req.url.replace(`@api-nest/`, '');
        let apiReq = req.clone({
          url: `${environment.apiEndPoint}/${url}`,
          headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)
        });
        return next.handle(apiReq);
      }

      if (req.url.indexOf(`@api-document`) !== -1) {
        const url = req.url.replace(`@api-document/`, '');
        let apiReq = req.clone({
          url: `${environment.documentApiEndPoint}/${url}`,
          headers: req.headers.set('access_token', token)
            .set('Content-Type', 'application/json')
        });
        return next.handle(apiReq);
      }
      
      if (req.url.indexOf(`@api-meeting`) !== -1) {
        const url = req.url.replace(`@api-meeting/`, '');
        let apiReq = req.clone({
          url: `${environment.signalRUrl}${url}`,
          headers: req.headers.set('access_token', token)
            .set('Content-Type', 'application/json')
        });
        return next.handle(apiReq);
      }
    }
  }
}

export const apiInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true }
];