import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { FileService } from 'src/app/core/services/comman/file.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { runInThisContext } from 'vm';
import { FilesComponent } from './files/files.component';

@Component({
  providers: [FilesComponent],
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss'],
})
export class DocsComponent implements OnInit {
  recentActivities = [];
  recentActivitiesFiles = [];
  recentDeletedFiles = [];
  favouriteFiles = [];
  tabName = "all";
  currentUser: any;

  constructor(
    private tokenService: TokenStorageService,
    private fileService: FileService,
    private documentService: DocumentService,
    private documentUtils: DocumentUtilsService,
    private serverDocumentService: ServerDocumentService,
    private toasterService: ToasterService,
    private docComponent: FilesComponent,
    private obService: ObservableService,
    private observableService: ObservableService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.getRecentDocument();
  }

  getRecentDocument() {
    this.documentService.getRecentDocument({
      limit: 10,
      companyId: this.currentUser.companyId,
      action: "created,renamed,deleted,restored"
    }).subscribe(log => {
      log = log.data;
      this.recentActivities = log;
      this.recentActivities.forEach(x => {
        this.documentUtils.cacheDecodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });


      this.recentActivities.filter(x => !x.isfolder).forEach(x => {
        // var deletedEntry = this.recentActivities.find(ra => ra.fileId == x.fileId && (ra.action == 'deleted' || ra.action == 'copy-paste' || ra.action == 'moved'));
        // if (!deletedEntry)
        if (!this.recentActivitiesFiles.find(r => r.fileId == x.fileId))
          this.recentActivitiesFiles.push(x);
      });
    });

    this.documentService.getRecentDocument({
      limit: 10,
      companyId: this.currentUser.companyId,
      action: "deleted"
    }).subscribe(log => {
      log = log.data;
      this.recentDeletedFiles = log;
      this.recentDeletedFiles.forEach(x => {
        this.documentUtils.cacheDecodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });
    });

    this.documentService.getAllFavoritesFilesByCompanyId({
      favoriteBy: this.currentUser.username,
      companyId: this.currentUser.companyId
    }).subscribe(log => {
      log = log.data;

      this.favouriteFiles = log;
      this.favouriteFiles.forEach(x => {

        this.documentUtils.cacheDecodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });
    });

  }

  displayFullFileName(name: string) {
    return this.documentUtils.displayFullFileName(name);
  }

  getExtensionIcon(ext: string) {
    return this.documentUtils.getExtensionIcon(ext);
  }

  showFileFromRecent(file: any): void {
    if (file.action == "deleted") {
      // this.toasterService.error('Cannot open deleted File', 'Error')
      this.observableService._success.next('Cannot open deleted File');
      return;
    }
    if (file.isFolder) {
      // this.toasterService.error('Cannot open Folder', 'Error')
      this.observableService._success.next('Cannot open Folder');
      return;
    }

    var fileEle = { id: file.fileId, isFolder: file.isFolder, name: file.fileName, parent: '' };
    this.obService.fileEleObs.next(fileEle);
  }

  selectTab(tab) {
    this.tabName = tab;
    if (tab === 'favorite') {
      this.getRecentDocument();
      setTimeout(() => {
        this.observableService.docsSidebarTab.next({
          tab: 'favorite',
          data: this.favouriteFiles
        })
      }, 500)
    } else if (tab === 'recent deleted') {
      this.getRecentDocument();
      setTimeout(() => {
        this.observableService.docsSidebarTab.next({
          tab: 'recent delete',
          data: this.recentDeletedFiles
        })
      }, 500)
    } else if (tab === 'file') {
      this.observableService.docsSidebarTab.next({
        tab: 'file',
        data: []
      })
    } else if (tab === 'folder') {
      this.observableService.docsSidebarTab.next({
        tab: 'folder',
        data: []
      })
    } else if (tab === 'all') {
      this.observableService.docsSidebarTab.next({
        tab: 'all',
        data: []
      })
    }
  }
}
