<perfect-scrollbar class="folders-body-scroll p-3 p-lg-4">
    <ngb-alert class="alert" #selfClosingAlert *ngIf="successMessage" type="success" (closed)="successMessage = ''"> {{
        successMessage }}
    </ngb-alert>
    
    <div class="folders-body">
        <div class="folders-body-title mb-3">
            <h4 class="mb-4">
                <i class="ri-arrow-left-circle-line" (click)="navigateUp()" *ngIf="canNavigateUp"></i> Recent
            </h4>
        </div>

        <div class="folders-box">
            <ul>
                <li *ngFor="let element of fileElements; let i = index" (dblclick)="navigate(element)"
                    (click)="selectElement($event,element)">
                    <input class="input-check" name="radio" type="radio" id="file-{{i}}">
                    <label for="file-{{i}}" class="folders-row">
                        <div class="dropdown" dropdownClass="file-action-dropdown" container="body"
                            placement="bottom-end" ngbDropdown>
                            <a href="javascript: void(0);" class="text-muted dropdown-toggle" ngbDropdownToggle
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="ri-more-fill"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="navigate(element)"
                                    *ngIf="rightClickMenuOption?.open">
                                    Open
                                    <i class="ri-file-copy-line float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="selectFileElement && !isUnderSystemDirectory && rightClickMenuOption?.copy"
                                    (click)="copyElementDialog(element)">
                                    Copy
                                    <i class="ri-scissors-cut-fill float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="selectFileElement && !isUnderSystemDirectory  && rightClickMenuOption?.cut"
                                    (click)="cutElement(element)">
                                    Cut
                                    <i class="ri-scissors-cut-fill float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="cliboard && !isUnderSystemDirectory  && rightClickMenuOption?.paste"
                                    (click)="pasteElement(element)">
                                    Paste
                                    <i class="ri-scissors-cut-fill float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="rightClickMenuOption?.favorite" (click)="dofavouriteElement(element)">
                                    Favorite
                                    <i class="ri-star-line float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);" *ngIf="rightClickMenuOption?.rename"
                                    (click)="openRenameDialog(element, renameElement)">
                                    Rename
                                    <i class="ri-edit-line float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);" (click)="deleteElement(element)"
                                    *ngIf="rightClickMenuOption?.delete">
                                    Delete
                                    <i class="ri-delete-bin-line float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);" (click)="copyFileLink(element)"
                                    *ngIf="rightClickMenuOption?.copyLink">
                                    Copy File Link
                                    <i class="ri-link float-end text-muted"></i></a>

                                <a class="dropdown-item" href="javascript:void(0);" data-toggle="modal"
                                    data-target="#addgroup-exampleModal" (click)="propertiesModal()"
                                    *ngIf="rightClickMenuOption?.properties">
                                    Properties
                                    <i class="ri-information-line float-end text-muted"></i> </a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="element.isFolder == false && rightClickMenuOption?.download"
                                    (click)="downloadClick(element)">
                                    Download
                                    <i class="ri-download-2-line float-end text-muted"></i> </a>

                                <a class="dropdown-item" href="javascript:void(0);"
                                    *ngIf="rightClickMenuOption?.restore">
                                    Restore
                                    <i class="ri-restart-line float-end text-muted"></i> </a>
                            </div>
                        </div>
                        <img src="assets/file-system-icons/sys-folder.png"
                            *ngIf="(element.isFolder) && isSystemFolder(element.id)" alt="folder"
                            style="cursor:pointer">

                        <img src="assets/file-system-icons/folder.png"
                            *ngIf="(element.isFolder )&& !isSystemFolder(element.id)" alt="folder"
                            style="cursor:pointer">

                        <img src="assets/file-system-icons/{{getExtensionIcon(element.name.split('.').pop())}}.png"
                            *ngIf="!element.isFolder" alt="folder" style="cursor:pointer">

                        <span class="folder-icon-text cut-text">{{displayFullFileName(element.name)}} </span>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</perfect-scrollbar>

<ng-template #createproProperties let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">Properties</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
        <perfect-scrollbar class="properties-scroll">
            <div class="properties-details border-bottom pb-2">
                <ul>
                    <li>
                        <span class="properties-details-icon"><i class="ri-user-2-line"></i></span>
                        <p class="mb-0">Name : Test</p>
                    </li>
                    <li>
                        <span class="properties-details-icon"><i class="ri-map-pin-line"></i></span>
                        <p class="mb-0">Location : root\Test5</p>
                    </li>
                    <li>
                        <span class="properties-details-icon"><i class="ri-layout-5-line"></i></span>
                        <p class="mb-0">Size : (0 KB)</p>
                    </li>
                    <li>
                        <span class="properties-details-icon"><i class="ri-lock-line"></i></span>
                        <p class="mb-0">Security :</p>
                    </li>
                </ul>
            </div>
            <div class="properties-table-wrap pt-2">
                <div class="properties-table mb-3">
                    <table>
                        <tr>
                            <th><span ngbTooltip="User Name" container="body"><i class="ri-shield-user-fill"></i></span>
                            </th>
                            <th><span ngbTooltip="Can Read" container="body"><i class="ri-file-list-fill"></i></span>
                            </th>
                            <th><span ngbTooltip="Can Write" container="body"><i class="ri-pencil-fill"></i></span></th>
                            <th><span ngbTooltip="Can Delete" container="body"><i class="ri-delete-bin-fill"></i></span>
                            </th>
                            <th><span ngbTooltip="Can Download" container="body"><i
                                        class="ri-download-cloud-fill"></i></span></th>
                            <th><span ngbTooltip="Can Change Permission" container="body"><i
                                        class="ri-settings-5-fill"></i></span></th>
                            <th><span><i class="ri-more-fill"></i></span></th>
                        </tr>
                        <tr>
                            <td>
                                everyone
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canRead"
                                        container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canWrite"
                                        container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canDelete" container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canDownload" container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canChangePermission" container="body">
                                </div>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                manikanta
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canRead"
                                        container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canWrite"
                                        container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canDelete" container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canDownload" container="body">
                                </div>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id=""
                                        ngbTooltip="canChangePermission" container="body">
                                </div>
                            </td>
                            <td>
                                <span type="checkbox" value="" id="" ngbTooltip="Click to Remove" container="body"><i
                                        class="ri-delete-bin-2-fill"></i></span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="properties-select-search">
                    <div class="properties-select-btn">
                        <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                            (click)="isCollapsed = !isCollapsed">
                            Select User
                        </button>
                    </div>
                    <div class="collapse mt-1" [ngClass]="{'show':  isCollapsed ? 'show' : ''}">
                        <div class="card border">
                            <div class="card-header d-flex card-header-search">
                                <h5 class="font-size-15 mb-0 me-2">Add User</h5>
                                <div class="input-group bg-light input-group-sm rounded-lg">
                                    <div class="input-group-prepend">
                                        <button type="button"
                                            class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                                            <i class="ri-search-line search-icon font-size-18"></i>
                                        </button>
                                    </div>
                                    <input type="text" class="form-control bg-light" placeholder="Search members...">
                                </div>
                            </div>
                            <div class="card-body p-2">
                                <perfect-scrollbar data-simplebar style="height: 150px;">
                                    <div>
                                        <div class="px-3 font-weight-bold text-primary">
                                            A
                                        </div>
                                        <ul class="list-unstyled contact-list">
                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="" value="">
                                                    <label class="form-check-label">
                                                        Pradip
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="px-3 font-weight-bold text-primary">
                                            b
                                        </div>
                                        <ul class="list-unstyled contact-list">
                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="" value="">
                                                    <label class="form-check-label">
                                                        Pradip
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </perfect-scrollbar>
                            </div>
                        </div>
                        <button type="submit" class="add-btn btn btn-primary">Add</button>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="modal-footer p-2">
        <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="submit" class="btn btn-primary m-2">Ok</button>
    </div>
</ng-template>

<ng-template #renameElement let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">Create File</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="renameForm" (ngSubmit)="submitRenameDialog()">

        <div class="modal-body p-4">
            <div class="create-file-wrap">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="File Name" formControlName="name" [ngClass]="{ 
                        'is-invalid': renameForm.controls['name'].invalid && (renameForm.controls['name'].dirty || 
                        renameForm.controls['name'].touched) && renameF.name.errors}">
                    <span class="input-group-text" id="basic-addon2">{{renameForm?.value?.extension}}</span>
                    <div *ngIf="renameForm.controls['name'].invalid && (renameForm.controls['name'].dirty || 
                            renameForm.controls['name'].touched) && renameF.name.errors" class="invalid-feedback">
                        <div *ngIf="renameF.name.errors.required">Name is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer p-2">
            <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Cancel</button>
            <button type="submit" [disabled]="renameForm.invalid" class="btn btn-primary m-2">
                Create
            </button>
        </div>
    </form>
</ng-template>