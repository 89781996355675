export const environment = {
  production: false,
  version: 'sfu_1.0',
  callinit: 'singalr',// "firebase"
    jitsi: 'logo.dhumi.com',
  domain: 'http://localhost:4300',
  subDomain: 'localhost:4300',
  cookieUrl: null,
  docFileUrl: "https://192.168.29.161:4021",
  apiEndPoint: 'http://54.175.252.111:3000',
  documentApiEndPoint: 'http://54.160.114.109',

  s3BucketProfileUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/profile',
  s3BucketTeamUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/teams',
  s3BucketChatUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/chat',
  s3BucketUserPostUrl: 'https://zerts-upload.s3.ap-south-1.amazonaws.com/newsfeed',

  firebase: {
    apiKey: "AIzaSyBg8FGZI1sgtJGa6oQso_n_Pu0q4XubV3I",
    authDomain: "dhumi-app.firebaseapp.com",
    databaseURL: "https://dhumi-app.firebaseio.com",
    projectId: "dhumi-app",
    storageBucket: "dhumi-app.appspot.com",
    messagingSenderId: "1017562562721",
    appId: "1:1017562562721:web:b8ac3a7d1691b1ceb1f0e0",
    measurementId: "G-02ZH1VB40F"
  },
  
    signalRUrl: 'http://localhost:5001/',
  wopiUrl: "http://localhost:81/",
  ICE_SERVERS: [
    /*{ urls: 'stun:stun.l.google.com:19302' }*/
   {
      urls: ['stun:34.227.21.117:3478'],
      credential: 'root',
      username: 'hardik123'
     },
     {
      urls: ['turn:34.227.21.117:3478'],
      credential: 'root',
      username: 'hardik123'
     }

  ],
};
