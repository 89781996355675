import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientActivityComponent } from './client-activity/client-activity.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DocsComponent } from './docs/docs.component';
import { FilePreviewComponent } from './docs/file-preview/file-preview.component';
import { PreviewFileComponent } from './docs/preview-file/preview-file.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { NotificationComponent } from './notification/notification.component';
import { PageComponent } from './page.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { TeamListComponent } from './team-list/team-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    children: [{
      path: 'setting',
      component: SettingsComponent
    }, {
      path: 'profile',
      component: ProfileComponent
    }, {
      path: 'contact',
      component: ContactsComponent
    }, {
      path: 'chat',
      component: UserListComponent
    }, {
      path: 'team',
      component: TeamListComponent
    }, {
      path: 'activity',
      component: ClientActivityComponent
    }, {
      path: 'meetings',
      component: MeetingsComponent
    }, {
      path: 'docs',
      component: DocsComponent
    }, {
      path: 'whiteboard',
      component: WhiteboardComponent
    }, {
      path: 'notification',
      component: NotificationComponent
    }]
  }, {
    path: 'activity',
    component: ClientActivityComponent
  }, {
    path: 'whiteboard',
    component: WhiteboardComponent
  }, {
    path: 'file-preview',
    component: FilePreviewComponent
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
