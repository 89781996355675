<div class="join-meeting">
    <div class="join-meeting-box">
        <div class="join-meeting-box-head">
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close"></button>
        </div>
        <div class="join-meeting-box-body ">
            <form [formGroup]="meetingForm" (ngSubmit)="submit()">

                <div class="form-group mb-4">
                    <h5 class="font-size-18 mb-4">Join Meeting</h5>
                    <div class="form-input-group">
                        <input type="number" formControlName="meetingId" placeholder="Enter Meeting ID "
                            class="form-control" #ccNumber
                            [ngClass]="{ 'is-invalid': submitted && f.meetingId.errors }">
                        <div *ngIf="submitted && f.meetingId.errors" class="invalid-feedback">
                            <div *ngIf="f.meetingId.errors.required">meetingId is required</div>
                        </div>
                        <p class="text-danger">{{fault}}</p>
                        <p class="text-danger">{{meetingIdError}}</p>
                        <p *ngIf="success" class="text-success">Valid and Success</p>
                    </div>
                </div>
                <div class="login-btn-group d-flex justify-content-center">
                    <button class="btn btn btn-primary" type="submit">Join</button>
                </div>
            </form>
        </div>
    </div>
</div>