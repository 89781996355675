import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ObservableService } from 'src/app/core/services/observable.service';
import { OnlineMeetingService } from 'src/app/core/services/online-meeting.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { UserPostService } from 'src/app/core/services/user-post.service';

@Component({
  selector: 'app-client-activity',
  templateUrl: './client-activity.component.html',
  styleUrls: ['./client-activity.component.scss']
})
export class ClientActivityComponent implements OnInit {
  selectedTab = 'all-post';
  eventForm: FormGroup;
  submitted = false;
  upcomingMeeting = [];

  constructor(
    private modalService: NgbModal,
    private observableService: ObservableService,
    private formBuilder: FormBuilder,
    private userPostService: UserPostService,
    private toasterService: ToasterService,
    private OnlineMeetingService: OnlineMeetingService,
    private router: Router,
  ) {
    this.observableService.selectUserPostTab.next('all-post');
  }

  ngOnInit(): void {
    this.eventForm = this.formBuilder.group({
      eventName: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      category: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
    this.getMeetings();
  }

  get f() { return this.eventForm.controls; }

  async openCreateTeamModal(content: any) {
    await this.modalService.open(content, { centered: true, modalDialogClass: 'create-event-modal' });
  }


  changeUserPostTab(tab: string) {
    this.selectedTab = tab;
    this.observableService.selectUserPostTab.next(tab);

    document.getElementById("user-activity").classList.add("user-chat-show");
    document.getElementById("side-menu-block").classList.add("hide-sidebar-mobile");
  }

  onSubmit() {
    this.submitted = true;
    if (this.eventForm.invalid) {
      return;
    } else {
      this.userPostService.createEvent(this.eventForm.value)
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.eventForm.reset();
            this.toasterService.success('Event created.', 'Success')
            this.modalService.dismissAll();
          }
        });
    }
  }

  getMeetings() {
    this.OnlineMeetingService.getMeetings()
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            let today = new Date();
            var presentDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            );

            this.upcomingMeeting = res.data.filter((info) => {
              return new Date(info.startDate).getTime() > presentDate.getTime() || new Date(info.startDate).getTime() == presentDate.getTime();
            });

            console.log(this.upcomingMeeting)
          }
        }
      });
  }

  showMeetingInfo(data) {
    this.router.navigate(['/pages/meetings']);
    this.observableService.selectScheduleMeetingTab.next('meeting-info');
    this.observableService.onMeetingChange.next(data);
  }



}
