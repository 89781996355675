import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServerDocumentService {

  constructor(
    private http: HttpClient
  ) { }

  getRootFolder(companyId) {
    return this.http.get<any>(`@api-document/api/getrootfolder/${companyId}`).pipe(retry(0), catchError(this.handleError));
  }

  createFolder(data) {
    return this.http.post<any>(`@api-document/api/addfolder`, data).pipe(retry(0), catchError(this.handleError));
  }

  createFile(data) {
    return this.http.post<any>(`@api-document/api/addfile`, data).pipe(retry(0), catchError(this.handleError));
  }

  getWopiFolders(id) {
    return this.http.get<any>(`@api-document/api/getwopifolders/${id}`).pipe(retry(0), catchError(this.handleError));
  }

  getwopifolderdetail(id) {
    return this.http.get<any>(`@api-document/api/getwopifolderdetail/${id}`).pipe(retry(0), catchError(this.handleError));
  }

  getWopiFiles(id) {
    return this.http.get<any>(`@api-document/api/getwopifiles/${id}`).pipe(retry(0), catchError(this.handleError));
  }

  getFile(id) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(`@api-document/api/getfile/${id}`, { responseType: 'blob'}).pipe(retry(0), catchError(this.handleError));
  }

  getWopiDetail(id, username, email) {
     var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get<any>(`@api-document/api/getwopidetail/${id}/${username}/${email}`).pipe(retry(0), catchError(this.handleError));
  }

  decodeIdentifier(data) {
    return this.http.post<any>(`@api-document/api/decodeIdentifier`, { data: data }).pipe(retry(0), catchError(this.handleError));
  }
  
  uploadFile(file) {
    return this.http.post<any>(`@api-document/api/postfile`, file).pipe(retry(0), catchError(this.handleError));
  }

  moveItem(data) {
    return this.http.post<any>(`@api-document/api/movefolder`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  searchItem(data) {
    return this.http.post<any>(`@api-document/api/searchItem`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  moveItemToTrash(data) {
    return this.http.post<any>(`@api-document/api/movefoldertotrash`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  renameItem(data) {
    return this.http.post<any>(`@api-document/api/renamefolder`, data).pipe(retry(0), catchError(this.handleError));
  }

  copyItem(data) {
    return this.http.post<any>(`@api-document/api/copyfolder`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  restoreitem(data) {
    return this.http.post<any>(`@api-document/api/restoreitem`, data).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
