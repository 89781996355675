import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from 'src/app/core/services/team.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-team-modal',
  templateUrl: './create-team-modal.component.html',
  styleUrls: ['./create-team-modal.component.scss']
})
export class CreateTeamModalComponent implements OnInit {
  createTeamForm: FormGroup;
  public currentUser: any;
  public isCollapsed: any;
  userList: any = [];

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;


  constructor(
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
    private modalService: NgbModal,
    private teamService: TeamService,
    private toasterService: ToasterService,
    private userService: UserProfileService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateTeamModalComponent>,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.isCollapsed = true;
    this.currentUser = this.tokenService.getUser();

    this.createTeamForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(100)]],
      createdBy: [this.currentUser.id],
      members: this.formBuilder.array([]),
    });

    setTimeout(() => {
      this.getUserList();
    }, 500)
  }

  get f() { return this.createTeamForm.controls; }

  async checkDuplicateTeamName() {
    await this.teamService.checkDuplicateTeamName(this.createTeamForm.value.name)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data != 0) {
            this.createTeamForm.get('name').setErrors({ isTeamAvailable: true, msg: 'Team exits.' });
          }
        }
      });
  }

  async getUserList() {
    try {
      this.userService.getUserList().subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            this.userList = res.data;
            this.userList = this.userList.filter((e) => {
              return this.currentUser.id != e.id;
            });
            this.userList = _.groupBy(this.userList, function (item) { return item.firstname[0].toUpperCase(); });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  onSubmit() {
    if (this.createTeamForm.invalid) {
      return;
    }
    const checkArray: FormArray = this.createTeamForm.get('members') as FormArray;

    checkArray.push(new FormControl({
      userId: this.currentUser.id,
      userType: 'Admin'
    }));

    this.teamService.createTeam(this.createTeamForm.value)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.modalService.dismissAll({
            isUpdate: true
          });
          this.toasterService.success('Team created.', 'Success');
        }
      });
  }

  onCheckboxChange(e, id) {
    const checkArray: FormArray = this.createTeamForm.get('members') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl({
        userId: id,
        userType: 'Normal'
      }));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value.userId == id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  closeModal() {
    this.modalService.dismissAll({
      isUpdate: false
    })
  }
}
