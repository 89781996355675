import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(data) {
    return this.http.post<any>(`@api-nest/chat/send-message`, data).pipe(retry(0), catchError(this.handleError));
  }

  editMessage(data) {
    return this.http.post<any>(`@api-nest/chat/edit-message`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  updateImportantMessage(data) {
    return this.http.post<any>(`@api-nest/chat/is-important`, data).pipe(retry(0), catchError(this.handleError));
  }

  deleteMessage(data) {
    let params = new HttpParams().set("id", data);
    return this.http.delete<any>(`@api-nest/chat/delete-message`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  updateMessageStatus(data) {
    return this.http.put<any>(`@api-nest/chat/update-message-status`, data).pipe(retry(0), catchError(this.handleError));
  }

  getMessages(data) {
    let params = new HttpParams().set("senderId", data.senderId);
    params = params.set('receiverId', data.receiverId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);

    return this.http.get<any>(`@api-nest/chat/get-messages`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }
  
  getImportantMessages(data) {
    let params = new HttpParams().set("userId", data.userId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);

    return this.http.get<any>(`@api-nest/chat/get-important-messages`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  findTotalOffset(data) {
    let params = new HttpParams().set("senderId", data.senderId);
    params = params.set('receiverId', data.receiverId);
    params = params.set('id', data.id);

    return this.http.get<any>(`@api-nest/chat/find-total-offset`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getFileList(data) {
    let params = new HttpParams().set("senderId", data.senderId);
    params = params.set('receiverId', data.receiverId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/chat/get-file-list`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getTotalNumberOfUnreadMessage() {
    return this.http.get<any>(`@api-nest/chat/get-total-unread-message`).pipe(retry(0), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }



}
