import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  allNotification = [];
  currentUser: any;
  tableValue = 0;

  offset = 0;
  limit = 7;
  totalRecord: 0;
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  constructor(
    private notificationService: NotificationService,
    private tokenStorageService: TokenStorageService,
    private cd: ChangeDetectorRef,
    private observableService: ObservableService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    this.getNotification();
  }

  async changeTab(value) {
    this.allNotification = [];
    this.offset = 0;
    this.limit = 7;
    this.totalRecord = 0;

    if (value === 1) {
      this.tableValue = 1
    } else {
      this.tableValue = 0
    }
    this.getNotification();
  }

  async getNotification() {
    return new Promise((resolve, reject) => {
      try {
        this.notificationService.getNotification({
          limit: this.limit,
          offset: this.offset,
          sorting: this.tableValue
        }).subscribe((res) => {
          if (res.statusCode === 200 && res.data != null) {

            this.totalRecord = res.data.totalCount;
            if (res.data.data && res.data.data.length > 0) {
              res.data.data.forEach((value) => {
                this.allNotification.push(value);
                this.cd.detectChanges();
              });
              console.log(this.allNotification)
            } else {
              this.allNotification = [];
            }

            resolve('Done');
          }
        });
      } catch (error) {
        reject(error)
      }
    });
  }

  fileUrl(file, notification) {
    if (notification.tableName === 'Chats') {
      return environment.s3BucketChatUrl + "/" + file;
    } else if (notification.tableName === 'TeamChats') {
      return environment.s3BucketTeamUrl + "/" + file;
    }
  }

  updateNotificationStatus(notification, index) {
    let temp: any = {};
    if (notification.notifyUsers.length > 0) {
      let notificationForCurrentUser = notification.notifyUsers.find((e) => e.userId === this.currentUser.id)
      temp.isMultipleNotification = true;
      temp.notificationId = notificationForCurrentUser.id;
    } else {
      temp.isMultipleNotification = false
      temp.notificationId = notification.id
    }

    this.notificationService.updateNotificationReadStatus(temp)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          if (!notification.isRead) {
            this.allNotification.splice(index, 1);
          }
          notification.isRead = true;
          this.observableService.notificationModule.next({
            type: notification.notificationType.type,
            id: notification.moduleRedirectId
          });
        }
      });
  }

  async loadNotification() {
    if (this.totalRecord > this.limit) {
      this.offset = this.limit;
      this.limit = this.limit + 7;
      this.getNotification()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
    }
  }

}
