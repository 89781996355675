<div class="schedule-meeting">
    <div class="schedule-meeting-head p-3 p-lg-4 border-bottom">
        <h4 class="mb-0">Meeting Information</h4>
    </div>
    <perfect-scrollbar class="details-body-scroll">
        <div class="details-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Topic</label>
                        <p>{{meetingInfo.topic}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group conference">
                        <label class="form-label">Conference Room</label>
                        <p>{{meetingInfo.conference}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group desc">
                        <label class="form-label">Description</label>
                        <p>{{meetingInfo.description}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group multiselect-group">
                        <label class="form-label">Add Restrictions</label>
                        <p>{{meetingInfo.members}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Date</label>
                        <p>{{meetingInfo.startDate | date: 'mediumDate': 'UTC'}}</p>
                        <!---->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Start Time</label>
                        <p>{{meetingInfo.startTime}}</p>
                        <!---->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Duration</label>
                        <p>Hours:- {{meetingInfo.hours}} Minutes:- {{meetingInfo.minutes}}</p>
                        <!---->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Time Zone</label>
                        <p>{{meetingInfo.timezone}}</p>
                        <!---->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-label">Webinar ID</label>
                        <p>{{meetingInfo.webId}}</p>
                        <!---->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group webid">
                        <label class="form-label">Meeting URL</label>
                        <p>https://app.dhumi.io/#/auth/guest-user</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-label">Video</label>
                        <div class="d-flex align-items-center radio-group-wapper mb-3">
                            <span class="me-4 radio-title">Host</span>
                            <div class="form-check form-check-inline" *ngIf="meetingInfo.host === 'off'">
                                <div class="radio-circle circle-red"></div>
                                <label class="form-check-label" for="inlineRadio1">Off</label>
                            </div>
                            <div class="form-check form-check-inline" *ngIf="meetingInfo.host === 'on'">
                                <div class="radio-circle circle-green"></div>
                                <label class="form-check-label" for="inlineRadio1">On</label>
                            </div>
                        </div>
                        <!-- <div class="d-flex align-items-center radio-group-wapper">
                            <span class="me-4 radio-title">Attendee</span>
                            <div class="form-check form-check-inline">
                                <div class="radio-circle circle-red"></div>
                                <label class="form-check-label" for="inlineRadio2">Of</label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="details-body-bg-images">
                <img src="assets/images/join-meeting-image.png" alt="" />
            </div>
        </div>
    </perfect-scrollbar>

    <div class="schedule-meeting-foot p-3 p-lg-4 border-top mb-0 text-center">
        <button class="btn btn-primary me-3 schedule-details-button" data-toggle="modal"
            data-target="#addgroup-exampleModal" (click)="shareDetailsModal(shareDetails)">Share Details To
            Mail</button>
        <button class="btn btn-cancel btn-outline-primary" tabindex="0">Go Back</button>
        <button (click)="startMeeting()" class="btn btn-primary ms-3 schedule-meeting-button">Start Meeting</button>
    </div>
</div>


<ng-template #shareDetails let-modal>
    <form  (ngSubmit)="onSubmit()">

        <div class="modal-header">
            <h5 class="modal-title font-size-16" id="">Enter your email ID</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body p-4">
            <div class="share-details-wrap">
                <div class="input-group">
                    <label class="form-label">Email</label>
                    <!-- <input type="text" class="form-control" placeholder="Email address" formControlName="emails"> -->
                    <tag-input [(ngModel)]='emails' name="emails" #email="ngModel" [errorMessages]="errorMessages"
                        [validators]="validators" [editable]='true' (onTagEdited)="onTagEdited($event)"
                        [separatorKeyCodes]="[32,188,186,13,9]" [placeholder]="'Add email'"
                        [secondaryPlaceholder]="'Enter email address(es)'" [clearOnBlur]="true" [addOnPaste]="true"
                        [addOnBlur]="true" [pasteSplitPattern]="splitPattern" theme='bootstrap' required>
                    </tag-input>

                </div>
            </div>
        </div>
        <div class="modal-footer p-2">
            <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Cancel</button>
            <button type="submit" class="btn btn-primary m-2">Send Mail</button>
        </div>
    </form>
</ng-template>