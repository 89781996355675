<perfect-scrollbar class="client-activity-leftsidebar me-lg-1">
  <div class="client-activity-inner">
    <div class="activity-list-wrap pb-3 p-4">
      <h4 class="mb-4">Meetings</h4>
      <ul>
        <li>
          <a class="activity-list-btn" href="javascript:void(0);" (click)="changeMeetingTab('join-meeting')">
            <span>
              <img src="../../../assets/images/join-meeting.png" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">Join Meeting</h5>
          </a>
        </li>
        <li>
          <a class="activity-list-btn" href="javascript:void(0);" (click)="changeMeetingTab('schedule-meeting')">
            <span>
              <img src="../../../assets/images/schedule_meeting-removebg-preview.png" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">Schedule Meeting</h5>
          </a>
        </li>
        <li>
          <a class="activity-list-btn" href="javascript:void(0);" data-toggle="modal"
            data-target="#addgroup-exampleModal" (click)="startMeetingModal(startMeeting)">
            <span>
              <img src="../../../assets/images/start meeting.png" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">Start Meeting Now</h5>
          </a>
        </li>
      </ul>
    </div>

    <div class="meetings-list-main">
      <div class="meetings-list-wrap p-4 pb-0">

        <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
          <ngb-panel cardClass="card shadow-none border mb-2" id="upcoming">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                Upcoming Meeting
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <perfect-scrollbar>
                <ul>
                  <div *ngIf="upcomingMeeting?.length > 0">
                    <li *ngFor="let meeting of upcomingMeeting">
                      <button value="" type="button" class="meetings-list-btn"
                        (click)="changeMeetingTab('meeting-info');selectedMeeting(meeting)">
                        Meeting on {{meeting.topic}} at {{meeting.startDate |
                        date: 'mediumDate': 'UTC'}} {{meeting.startTime}}
                      </button>
                    </li>
                  </div>
                  <div *ngIf="upcomingMeeting?.length <= 0" class="meeting-card-height">
                    <li>No upcoming meeting</li>
                  </div>
                </ul>
              </perfect-scrollbar>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="card shadow-none border mb-2" id="previous">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                Previous Meetings
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <perfect-scrollbar>
                <ul>
                  <div *ngIf="previousMeeting?.length > 0;">
                    <li *ngFor="let meeting of previousMeeting">
                      <button value="" type="button" class="meetings-list-btn"
                        (click)="changeMeetingTab('meeting-info');selectedMeeting(meeting)">
                        Meeting on {{meeting.topic}} at {{meeting.startDate | date: 'mediumDate': 'UTC'}} {{meeting.startTime}}
                      </button>
                    </li>
                  </div>
                  <div *ngIf="previousMeeting?.length <= 0;" class="meeting-card-height">
                    <span>No previous meeting</span>
                  </div>
                </ul>
              </perfect-scrollbar>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</perfect-scrollbar>


<ng-template #startMeeting let-modal>
  <div class="modal-header">
    <h5 class="modal-title font-size-16" id="">Start Meeting Now</h5>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body p-4">
    <div class="start-meeting-wrap">
      <div class="form-group">
        <label class="form-label">Webinar ID</label>
        <input type="text" class="form-control" placeholder="Webinar ID" name="webId" [(ngModel)]="webId">
      </div>
      <div class="form-group">
        <label class="form-label">Add Restrictions</label>
        <select class="form-select form-control">
          <option value="Africa/Abidjan"> Allow Internal Users </option>
          <option value="Africa/Accra"> Allow External Users </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label">Meeting URL</label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="URL" value="https://app.dhumi.io/#/auth/guest-user"
            disabled aria-label="Recipient's username" aria-describedby="basic-addon2">
          <div class="input-group-append">
            <button class="input-group-text" id="basic-addon2"><i class="ri-file-copy-line"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-2">
    <button type="submit" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="submit" class="btn btn-primary m-2"
      onclick="window.open('/call/video','name','width=auto,height=auto')">Start Meeting</button>
  </div>
</ng-template>