<!-- Start Groups content -->
<div class="chat-leftsidebar me-lg-1">
  <div class="tab-content">
    <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
      <div>
        <div class="p-4">
          <div class="user-chat-nav float-end">
            <div ngbTooltip="Create team">
              <!-- Button trigger modal -->
              <button type="button" class="btn btn-link text-decoration-none text-muted py-0"
                data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openCreateTeamModal()">
                <i class="ri-group-line me-1"></i>
                Create Team
              </button>
            </div>
          </div>
          <h4 class="mb-4">Teams</h4>

          <!-- Start add group Modal -->
          <!-- <ng-template #content let-modal>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
                  Create New Team</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss('Cross click')">
                </button>
              </div>
              <form [formGroup]="createTeamForm" (ngSubmit)="onSubmit()">
                <div class="modal-body p-4">
                  <div class="mb-4">
                    <label class="form-label" for="addgroupname-input">Team Name</label>
                    <input type="text" class="form-control" id="addgroupname-input" formControlName="name" [ngClass]="{ 
                      'is-invalid': createTeamForm.controls['name'].invalid && (createTeamForm.controls['name'].dirty || 
                      createTeamForm.controls['name'].touched) && f.name.errors}" placeholder="Enter team name"
                      (focusout)="checkDuplicateTeamName()">

                    <div *ngIf="createTeamForm.controls['name'].invalid && (createTeamForm.controls['name'].dirty || 
                    createTeamForm.controls['name'].touched) && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Team name is required field
                      </div>
                      <div *ngIf="f.name.errors.isTeamAvailable">This team available.</div>
                    </div>
                  </div>

                  <div class="mb-4">
                    <label class="form-label">Team Members</label>

                    <div class="mb-3">
                      <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                        (click)="isCollapsed = !isCollapsed">
                        {{'chat.tabs.groups.modal.form.members.button.text' | translate}}
                      </button>
                    </div>
                    <div class="collapse" [ngClass]="{'show':  isCollapsed ? 'show' : ''}">
                      <div class="card border">
                        <div class="card-header d-flex card-header-search">
                          <h5 class="font-size-15 mb-0">{{'chat.tabs.contacts.title' | translate}}</h5>

                          <div class="input-group bg-light input-group-sm rounded-lg">
                            <div class="input-group-prepend">
                              <button type="button" class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                                <i class="ri-search-line search-icon font-size-18"></i>
                              </button>
                            </div>
                            <input type="text" class="form-control bg-light" placeholder="Search members...">
                          </div>
                        </div>
                        <div class="card-body p-2">
                          <perfect-scrollbar data-simplebar style="height: 150px;">
                            <div *ngFor="let user of userList  | keyvalue">
                              <div class="px-3 font-weight-bold text-primary">
                                {{user.key | uppercase}}
                              </div>
                              <ul class="list-unstyled contact-list" *ngFor="let userDetails of user.value">
                                <li>
                                  <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="{{userDetails.id}}"
                                      [value]="user.id" (change)="onCheckboxChange($event, userDetails.id)">
                                    <label class="form-check-label" [for]="userDetails.id">
                                      {{userDetails.firstname | titlecase}} {{userDetails.lastname | lowercase}}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </perfect-scrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="addgroupdescription-input">Description</label>
                    <textarea class="form-control" id="addgroupdescription-input" rows="3" #description maxlength="100"
                      placeholder="Enter description" formControlName="description" [ngClass]="{ 
                        'is-invalid': createTeamForm.controls['description'].invalid && (createTeamForm.controls['description'].dirty || 
                        createTeamForm.controls['description'].touched) && f.description.errors}"></textarea>
                        <div id="the-count">
                          <span>{{description.value.length}}</span>
                          <span>/ 100</span>
                        </div>
                    <div *ngIf="createTeamForm.controls['description'].invalid && (createTeamForm.controls['description'].dirty || 
                        createTeamForm.controls['description'].touched) && f.description.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">Description is required field.
                      </div>
                      <div *ngIf="f.description.errors.maxlength">Description can be max 100 characters long.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-link" data-dismiss="modal"
                    (click)="modal.dismiss('Cross click')">{{'chat.tabs.groups.modal.form.button.close' |
                    translate}}</button>
                  <button type="submit" [disabled]="createTeamForm.invalid" class="btn btn-primary">Create Team</button>
                </div>
              </form>
            </div>
          </ng-template> -->
          <!-- End add group Modal -->

          <div class="search-box chat-search-box">
            <div class="input-group mb-3 rounded-3">
              <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
                <i class="ri-search-line search-icon font-size-18"></i>
              </button>
              <!-- <input type="text" class="form-control bg-light" placeholder="Search teams..."> -->
              <input (search)="searchTeam()" type="search" class="form-control bg-light"
                placeholder="Search team..." aria-label="Search team"
                aria-describedby="basic-addon1" name="search" [(ngModel)]="searchTeamKeyword">
            </div>
          </div>
          <!-- end search-box -->
        </div>

        <!-- Start chat-group-list -->
        <div class="px-2">
          <div class="chat-message-list chat-group-list container" ngxInfiniteScroll
          [immediateCheck]="false" [scrollWindow]="false" (scroll)="fetchTeam()"
            *ngIf="teamList.length > 0">
            <ul class="list-unstyled chat-list">
              <li *ngFor="let data of teamList" (click)="showChat()">
                <a href="javascript: void(0);" (click)="onSelectTeam(data)">
                  <div class="d-flex align-items-center">
                    <div class="chat-user-img me-3 ms-0">
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{data.name.charAt(0)}}
                        </span>
                      </div>
                    </div>
                    <div class="flex-1 overflow-hidden">
                      <h5 class="text-truncate font-size-14 mb-0">{{data?.name}}
                        <!-- <span class="badge badge-soft-danger rounded-pill float-end">1</span> -->
                      </h5>
                      <!-- {{data?.lastMessage | json}} -->
                      <p class="chat-user-message text-truncate mb-0" *ngIf="data?.lastMessage?.messageType === 'text'">
                        {{data?.lastMessage?.message}}
                      </p>
                      <p class="chat-user-message text-truncate mb-0" *ngIf="data?.lastMessage?.messageType === 'file' || data?.lastMessage?.messageType === 'image'">
                        {{data?.lastMessage?.userFileName}}
                      </p>
                    </div>
                    <div class="chat-message-block">
                      <div class="font-size-11">{{data?.lastMessage?.createdAt | date: 'mediumDate': 'UTC'}}</div>
                      <div class="unread-message" *ngIf="data?.totalUnreadMessage > 0">
                        <span class="badge badge-soft-danger rounded-pill">
                          {{data?.totalUnreadMessage}}
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- End chat-group-list -->
      </div>
      <!-- End Groups content -->
    </div>
  </div>
</div>