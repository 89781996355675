import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstCharacter'
})
export class FirstCharacterPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase()
    }
    return;
  }

}
