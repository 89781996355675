<div class="call-video-box">
    <div class="call-tabs">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs border-bottom" (navChange)="changeTab($event)">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Audio Call</a>
                <ng-template ngbNavContent>
                    <div class="call-wrap p-3 p-lg-3">
                        <h4 class="mb-4">Recent</h4>
                        <div class="user-call-list mb-4">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                                <div ngxSlickItem class="slide" *ngFor="let log of conferenceList;">
                                    <div class="user-call-box ">
                                        <div class="user-call-box-inner">
                                            <div style="display: inline-block;">
                                                <div class="online-users-list-item">
                                                    <a href="javascript:void(0);" class="user-status-box">
                                                        <div class="avatar-sm mx-auto d-block chat-user-img online">
                                                            <span class="user-status"></span>
                                                            <div class="avatar-sm">
                                                                <img *ngIf="log?.profilePic != null"
                                                                    src="{{userProfileImageUrl(log?.profilePic)}}"
                                                                    alt="" class="avatar-xs" [ngClass]="{
                                                                'online': log?.user?.onlineStatus === 'Online',
                                                                'away': log?.user?.onlineStatus === 'Away',
                                                                'dnd': log?.user?.onlineStatus === 'Do not disturb',
                                                                'offline': log?.user?.onlineStatus === 'Offline'}">

                                                                <span *ngIf="log?.profilePic === null"
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                    {{log?.firstname | firstCharacter}} </span>
                                                            </div>
                                                        </div>
                                                        <h5 class="font-size-16 text-truncate mb-0">
                                                            {{log?.firstname}} {{log?.lastname}}</h5>
                                                        <p class="mb-3">{{log.createdAt | dateAgo}}</p>
                                                        <button type="submit" class="btn btn-primary">
                                                            <i class="ri-vidicon-line"></i>
                                                            Call
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>

                        <div class="user-call-details-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>All Calls</th>
                                        <th>Missed</th>
                                        <th>Outgoing</th>
                                        <th>Answered</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let log of uiCallLogs">
                                        <td>
                                            <div class="user-table-profile">
                                                <div class="user-table-profile-img">
                                                    <span>
                                                        <img *ngIf="log?.user?.profilePic != null"
                                                            src="{{userProfileImageUrl(log?.user?.profilePic)}}" alt=""
                                                            class="avatar-xs" [ngClass]="{
                                                                'online': log?.user?.onlineStatus === 'Online',
                                                                'away': log?.user?.onlineStatus === 'Away',
                                                                'dnd': log?.user?.onlineStatus === 'Do not disturb',
                                                                'offline': log?.user?.onlineStatus === 'Offline'}">

                                                        <div class="profile-status"></div>

                                                        <div class="avatar-xs" *ngIf="log?.user?.profilePic === null">
                                                            <span
                                                                class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                {{log?.user?.firstname | firstCharacter}}
                                                            </span>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="user-table-profile-title">
                                                    <span>{{log?.user?.firstname}}</span>
                                                    <p>{{log?.user?.email}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" data-toggle="modal"
                                                data-target="#addgroup-exampleModal"
                                                (click)="allCallModal(log?.user?.confId, allCallModalHtml)">{{log?.allCalls}}</a>
                                            <p>{{log?.createdAt | dateAgo}}</p>
                                        </td>
                                        <td>
                                            <div class="missed-call">
                                                <span>{{log?.missedCalls}}</span>
                                                <i class="ri-arrow-left-up-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="outgoing-call">
                                                <span>{{log?.outgoingCalls}}</span>
                                                <i class="ri-arrow-left-down-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="answered-call">
                                                <span>{{log?.answeredCalls}}</span>
                                                <i class="ri-arrow-right-up-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="submit" class="btn btn-primary">
                                                <i class="ri-phone-fill"></i>
                                                Call
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Video Call</a>
                <ng-template ngbNavContent>
                    <div class="call-wrap p-3 p-lg-3">
                        <h4 class="mb-4">Recent</h4>
                        <div class="user-call-list mb-4">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                                <div ngxSlickItem class="slide" *ngFor="let log of conferenceList;">
                                    <div class="user-call-box ">
                                        <div class="user-call-box-inner">
                                            <div style="display: inline-block;">
                                                <div class="online-users-list-item">
                                                    <a href="javascript:void(0);" class="user-status-box">
                                                        <div class="avatar-sm mx-auto d-block chat-user-img online">
                                                            <span class="user-status"></span>
                                                            <div class="avatar-sm">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                    P </span>
                                                            </div>
                                                        </div>
                                                        <h5 class="font-size-16 text-truncate mb-0">
                                                            {{log?.firstname}} {{log?.lastname}}</h5>
                                                        <p class="mb-3">{{log.createdAt | dateAgo}}</p>
                                                        <button type="submit" class="btn btn-primary">
                                                            <i class="ri-vidicon-line"></i>
                                                            Call
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>

                        <div class="user-call-details-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>All Calls</th>
                                        <th>Missed</th>
                                        <th>Outgoing</th>
                                        <th>Answered</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let log of uiCallLogs">
                                        <td>
                                            <div class="user-table-profile">
                                                <div class="user-table-profile-img">
                                                    <span>
                                                        <img *ngIf="log?.user?.profilePic != null"
                                                            src="{{userProfileImageUrl(log?.user?.profilePic)}}" alt=""
                                                            class="avatar-xs" [ngClass]="{
                                                                'online': log?.user?.onlineStatus === 'Online',
                                                                'away': log?.user?.onlineStatus === 'Away',
                                                                'dnd': log?.user?.onlineStatus === 'Do not disturb',
                                                                'offline': log?.user?.onlineStatus === 'Offline'}">

                                                        <div class="profile-status"></div>

                                                        <div class="avatar-xs" *ngIf="log?.user?.profilePic === null">
                                                            <span
                                                                class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                {{log?.user?.firstname | firstCharacter}}
                                                            </span>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="user-table-profile-title">
                                                    <span>{{log?.user?.firstname}}</span>
                                                    <p>{{log?.user?.email}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" data-toggle="modal"
                                                data-target="#addgroup-exampleModal"
                                                (click)="allCallModal(log?.user?.confId, allCallModalHtml)">{{log?.allCalls}}</a>
                                            <p>{{log?.createdAt | dateAgo}}</p>
                                        </td>
                                        <td>
                                            <div class="missed-call">
                                                <span>{{log?.missedCalls}}</span>
                                                <i class="ri-arrow-left-up-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="outgoing-call">
                                                <span>{{log?.outgoingCalls}}</span>
                                                <i class="ri-arrow-left-down-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="answered-call">
                                                <span>{{log?.answeredCalls}}</span>
                                                <i class="ri-arrow-right-up-line"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="submit" class="btn btn-primary">
                                                <i class="ri-phone-fill"></i>
                                                Call
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>

<ng-template #allCallModalHtml let-modal>
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="">All Calls</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
        <div class="all-call-wrap">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th scope="col">
                            <span><i class="ri-more-fill"></i></span>
                        </th>
                        <th scope="col">
                            <span><i class="ri-more-fill"></i></span>
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Joined At</th>
                        <th scope="col">Duration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of conferenceList">
                        <td>
                            <div class="calls-time">
                                <!-- <i [class]="country.calltimeicon"></i> {{ country.calltime }} -->
                                <i class="ri-arrow-right-up-line"></i>
                                <i class="ri-arrow-right-down-line"></i>
                                <i class="ri-arrow-left-up-line"></i>
                                <i class="ri-arrow-left-down-line"></i>
                            </div>
                        </td>
                        <td>
                            <i class="ri-group-fill" *ngIf="log?.totalParticipant > 3"></i>
                            <i class="ri-user-3-fill" *ngIf="log?.totalParticipant <= 3"></i>
                        </td>
                        <td scope="row">
                            {{log?.username == currentUser.username ? log?.firstname==currentUser.username ? "" :
                            log?.firstname :log?.username }}
                            <p *ngIf="log?.totalParticipant>3"> & Others..</p>
                        </td>
                        <td scope="row">
                            <div class="joined-details">
                                <span>{{ log?.joinedAt | dateAgo}}</span>
                                <p>{{ log?.createdAt }}</p>
                            </div>
                        </td>
                        <td scope="row" *ngIf="log?.exitAt">
                            {{getDuration(log?.joinedAt,log?.exitAt)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between pt-2 pagination-wrap">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refreshCountries()"></ngb-pagination>
        </div>
    </div>
    <div class="modal-footer p-2">
        <button type="submit" class="btn btn-primary m-2" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-template>