<!-- Start chat-message-list -->
<div class="d-lg-flex" *ngIf="!isImportantMessageOpen">
    <!-- start chat conversation section -->
    <div
            class="w-100 user-chat-wrapper"
            id="user-chat-wrapper"
            *ngIf="(selectedUser | json) != ({} | json)"
    >
        <!-- start chat user head -->
        <div class="p-2 p-lg-4 border-bottom">
            <div class="row align-items-center">
                <div class="col-sm-4 col-8">
                    <div class="d-flex align-items-center">
                        <div class="d-block d-lg-none me-1 ms-0">
                            <a
                                    href="javascript: void(0);"
                                    class="user-chat-remove text-muted font-size-16 p-0"
                                    (click)="closeUserChat()"
                            ><i class="ri-arrow-left-s-line"></i
                            ></a>
                        </div>
                        <div class="me-3 ms-0" *ngIf="selectedUser?.profilePic">
                            <img
                                    src="{{ selectedUser?.profilePic }}"
                                    class="rounded-circle flex-shrink-0 avatar-xs user-image-fit"
                                    alt=""
                            />
                        </div>
                        <div
                                *ngIf="selectedUser?.profilePic === null"
                                class="me-3 ms-0 avatar-xs flex-shrink-0"
                        >
              <span
                      class="avatar-title rounded-circle bg-soft-primary text-primary"
              >
                {{ selectedUser?.firstname | firstCharacter }}
              </span>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                            <h5 class="font-size-16 mb-0 text-truncate">
                                <a
                                        href="javascript: void(0);"
                                        class="text-reset user-profile-show"
                                >
                                    {{ selectedUser?.firstname }} {{ selectedUser?.lastname }}</a
                                >
                                <i
                                        class="ri-record-circle-fill font-size-10 text-danger d-inline-block ms-1"
                                        container="body"
                                        [ngbTooltip]="selectedUser?.onlineStatus"
                                        [ngClass]="{
                    'text-success': selectedUser?.onlineStatus === 'Online',
                    'text-warning': selectedUser?.onlineStatus === 'Away',
                    'text-danger':
                      selectedUser?.onlineStatus === 'Do not disturb',
                    'text-body': selectedUser?.onlineStatus === 'Offline'
                  }"
                                >
                                </i>
                                <p class="font-size-12 mb-0 text-truncate">
                                    {{ selectedUser?.onlineStatus }}
                                </p>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8 col-4">
                    <ul class="list-inline user-chat-nav user-chat-action text-end mb-0">
                        <li class="list-inline-item d-none d-lg-inline-block">
                            <button
                                    type="button"
                                    class="btn nav-btn"
                                    (click)="getImportantMessageList()"
                            >
                                <i class="ri-bookmark-3-line"></i>
                                <span class="text">Bookmark</span>
                            </button>
                        </li>

                        <li class="list-inline-item d-none d-lg-inline-block">
                            <button
                                    type="button"
                                    class="btn nav-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#audiocallModal"
                                    (click)="openCallModal(callContent)"
                            >
                                <i class="ri-phone-line"></i>
                                <span class="text">Audio Call</span>
                            </button>
                        </li>
                        <ng-template #callContent>
                            <div class="modal-body">
                                <div class="text-center p-4">
                                    <div class="avatar-lg mx-auto mb-4">
                                        <img src="{{ selectedUser?.profilePic }}"
                                             class="rounded-circle flex-shrink-0 avatar-xs user-image-fit" alt=""/>
                                    </div>

                                    <h5 class="text-truncate">{{ selectedUser?.firstname}}</h5>
                                    <p class="text-muted">Start Audio Call</p>

                                    <div class="mt-5">
                                        <ul class="list-inline mb-1">
                                            <li class="list-inline-item px-2 me-2 ms-0">
                                                <button
                                                        type="button"
                                                        class="btn btn-danger avatar-sm rounded-circle"
                                                        data-bs-dismiss="modal"
                                                >
                          <span class="avatar-title bg-transparent font-size-20">
                            <i class="ri-close-fill"></i>
                          </span>
                                                </button>
                                            </li>
                                            <li class="list-inline-item px-2">
                                                <button
                                                        type="button"
                                                        class="btn btn-success avatar-sm rounded-circle"
                                                        (click)="initCall('audio')"
                                                >
                          <span
                                  class="avatar-title bg-transparent font-size-20"
                          >
                            <i class="ri-phone-fill"></i>
                          </span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <li class="list-inline-item d-none d-lg-inline-block">
                            <button
                                    type="button"
                                    class="btn nav-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#videocallModal"
                                    (click)="openVideoModal(videoContent)"
                            >
                                <i class="ri-vidicon-line"></i>
                                <span class="text">Video Call</span>
                            </button>
                        </li>
                        <ng-template #videoContent>
                            <div class="modal-body">
                                <div class="text-center p-4">
                                    <div class="avatar-lg mx-auto mb-4">
                                        <img src="{{ selectedUser?.profilePic }}"
                                             class="rounded-circle flex-shrink-0 avatar-xs user-image-fit" alt=""/>
                                    </div>

                                    <h5 class="text-truncate">{{ selectedUser?.firstname}}</h5>
                                    <p class="text-muted mb-0">Start Video Call</p>

                                    <div class="mt-5">
                                        <ul class="list-inline mb-1">
                                            <li class="list-inline-item px-2 me-2 ms-0">
                                                <button
                                                        type="button"
                                                        class="btn btn-danger avatar-sm rounded-circle"
                                                        data-bs-dismiss="modal"
                                                >
                          <span
                                  class="avatar-title bg-transparent font-size-20"
                          >
                            <i class="ri-close-fill"></i>
                          </span>
                                                </button>
                                            </li>
                                            <li class="list-inline-item px-2">
                                                <button type="button" class="btn btn-success avatar-sm rounded-circle"
                                                        (click)="initCall('video')">
                          <span
                                  class="avatar-title bg-transparent font-size-20"
                          >
                            <i class="ri-vidicon-fill"></i>
                          </span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #incomingCall>
                            <div class="modal-body">
                                <div class="text-center p-4">
                                    <h2 class="text-truncate">{{ callerName }}</h2>
                                    <p class="text-muted mb-0">incoming {{ callType}} call</p>

                                    <div class="mt-5">
                                        <ul class="list-inline mb-1">
                                            <li class="list-inline-item px-2 me-2 ms-0">
                                                <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                                                        data-bs-dismiss="modal">
                                                    <span class="avatar-title bg-transparent font-size-20"><i
                                                            class="ri-close-fill"></i></span>
                                                </button>
                                            </li>

                                            <li class="list-inline-item px-2">
                                                <button type="button" class="btn btn-success avatar-sm rounded-circle" (click)="acceptCall()">
                                                    <span class="avatar-title bg-transparent font-size-20"><i
                                                            class="ri-phone-fill"></i></span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #callScreen>
                            <div class="modal-body">
                                <div class="text-center p-4">
                                    <h2 class="text-truncate"></h2>
                                    <p class="text-muted mb-0"></p>
                                    <div>
                                        <video autoplay id="localStream" controls></video>
                                        <video autoplay #remotePlayer id="remoteStream" controls></video>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <li class="list-inline-item d-none d-lg-inline-block">
                            <button
                                    type="button"
                                    class="btn nav-btn user-profile-show"
                                    (click)="showUserProfile()"
                            >
                                <i class="ri-file-text-line"></i>
                                <span class="text">Files</span>
                            </button>
                        </li>

                        <!-- <li class="list-inline-item">
                          <div class="dropdown" ngbDropdown>
                            <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <i class="ri-more-fill"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                              <a class="dropdown-item  user-profile-show" (click)="showUserProfile()" href="javascript: void(0);">
                                {{"chat.chatpanelheader.dropdown.viewprofile" | translate }}
                                <i class="ri-user-2-line float-end text-muted"></i>
                              </a>
                              <a class="dropdown-item d-block d-lg-none user-profile-show" href="javascript: void(0);" (click)="showUserProfile()">
                                Files
                                <i class="ri-file-line float-end text-muted"></i>
                              </a>
                              <a class="dropdown-item d-block d-lg-none" href="javascript: void(0);" data-bs-toggle="modal" data-bs-target="#audiocallModal" (click)="openCallModal(callContent)">
                                Audio Call <i class="ri-phone-line float-end text-muted"></i>
                              </a>
                              <a class="dropdown-item d-block d-lg-none" href="javascript: void(0);" data-bs-toggle="modal" data-bs-target="#videocallModal" (click)="openVideoModal(videoContent)">
                                Video Call <i class="ri-vidicon-line float-end text-muted"></i>
                              </a>
                            </div>
                          </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <!-- end chat user head -->

        <!-- start chat conversation -->
        <perfect-scrollbar
                class="chat-conversation p-3 p-lg-4"
                (psYReachStart)="loadMessage()"
                (psScrollY)="onScrollEvent()"
                [config]="config"
        >
            <ul class="list-unstyled mb-0">
                <li
                        *ngFor="let data of messageList; let i = index"
                        [ngClass]="{ right: data.senderId == currentUser?.id }"
                >
                    <div
                            class="conversation-list"
                            *ngIf="!data.isToday && !data.isYesterday && !data.date"
                    >
                        <div class="chat-avatar">
                            <img
                                    *ngIf="
                  currentUser.id === data.senderId && currentUser?.profilePic
                "
                                    src="{{ currentUser?.profilePic }}"
                                    alt=""
                            />
                            <div
                                    *ngIf="
                  currentUser.id === data.senderId &&
                  currentUser?.profilePic === null
                "
                                    class="ms-0 avatar-xs flex-shrink-0"
                            >
                <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                >
                  {{ currentUser?.firstname | firstCharacter }}
                </span>
                            </div>

                            <img
                                    *ngIf="
                  currentUser.id != data.senderId && selectedUser?.profilePic
                "
                                    src="{{ selectedUser?.profilePic }}"
                                    alt=""
                            />
                            <div
                                    *ngIf="
                  currentUser.id != data.senderId &&
                  selectedUser?.profilePic === null
                "
                                    class="ms-0 avatar-xs flex-shrink-0"
                            >
                <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                >
                  {{ selectedUser?.firstname | firstCharacter }}
                </span>
                            </div>
                        </div>
                        <div class="user-chat-content">
                            <div class="ctext-wrap">
                                <div class="ctext-wrap-content">
                                    <p class="mb-0" *ngIf="isURL(data?.message)">
                                        <a [href]="data?.message" target="_blank">
                                            {{ data?.message }}
                                        </a>
                                    </p>
                                    <p class="mb-0" *ngIf="!isURL(data.message)">
                                        {{ data?.message }}
                                    </p>

                                    <ul
                                            class="list-inline message-img mb-0"
                                            *ngIf="data.messageType === 'image'"
                                    >
                                        <li class="list-inline-item message-img-list">
                                            <div>
                                                <a
                                                        class="popup-img d-inline-block m-1"
                                                        href="javascript: void(0);"
                                                        title="dhumi"
                                                >
                                                    <img
                                                            :src="{{ fileUrl(data.s3FileName) }}"
                                                            alt=""
                                                            class="rounded border"
                                                            lightbox
                                                            backgroundOpacity="0.8"
                                                            [counter]="true"
                                                            [hideThumbnail]="false"
                                                            counterSeparator=" of "
                                                    />
                                                </a>
                                            </div>
                                            <div class="message-img-link">
                                                <ul class="list-inline mb-0">
                                                    <li class="list-inline-item">
                                                        <a
                                                                [href]="fileUrl(data.s3FileName)"
                                                                download="{{ data.userFileName }}"
                                                        >
                                                            <i class="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>

                                    <div
                                            class="card p-2 mb-2"
                                            *ngIf="data.messageType === 'file'"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-sm me-3 ms-0 flex-shrink-0">
                                                <div
                                                        class="avatar-title bg-soft-primary text-primary rounded font-size-20"
                                                >
                                                    <i class="ri-file-text-fill"></i>
                                                </div>
                                            </div>
                                            <div class="d-flex-body">
                                                <div class="text-start">
                                                    <h5
                                                            class="font-size-14 chat-file-name text-truncate mb-1"
                                                    >
                                                        {{ data.userFileName }}
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="ms-4">
                                                <ul class="list-inline mb-0 font-size-20">
                                                    <li class="list-inline-item">
                                                        <a
                                                                [href]="fileUrl(data.s3FileName)"
                                                                [download]="data.userFileName"
                                                                class="text-muted"
                                                        >
                                                            <i class="ri-download-2-line"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="chat-time mb-0">
                                        <i class="ri-time-line align-middle"></i>
                                        <span class="align-middle">{{
                                            data.updatedAt | date : "shortTime" : "UTC"
                                            }}</span>
                                    </p>
                                </div>
                                <div class="dropdown align-self-start" ngbDropdown>
                                    <a
                                            class="dropdown-toggle"
                                            role="button"
                                            ngbDropdownToggle
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        <i class="ri-more-2-fill"></i>
                                    </a>
                                    <div class="dropdown-menu" ngbDropdownMenu>
                                        <div *ngIf="currentUser.id === data.senderId">
                                            <a
                                                    class="dropdown-item"
                                                    *ngIf="
                          data.messageType != 'file' &&
                          data.messageType != 'image'
                        "
                                                    href="javascript: void(0);"
                                                    (click)="editMessage(data)"
                                            >
                                                Edit
                                                <i class="ri-pencil-line float-end text-muted"></i>
                                            </a>
                                            <a
                                                    class="dropdown-item"
                                                    href="javascript: void(0);"
                                                    (click)="deleteMessage(data, i)"
                                            >Delete
                                                <i class="ri-delete-bin-line float-end text-muted"></i>
                                            </a>
                                        </div>
                                        <a
                                                class="dropdown-item"
                                                href="javascript: void(0);"
                                                (click)="updateImportant(data, i)"
                                        >
                                            Important
                                            <i class="ri-bookmark-3-line float-end text-muted"></i>
                                        </a>
                                        <!-- <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                                            class="ri-save-line float-end text-muted"></i></a>
                                        <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                                            class="ri-chat-forward-line float-end text-muted"></i></a> -->
                                    </div>
                                </div>
                            </div>
                            <div
                                    _ngcontent-fgg-c56=""
                                    class="conversation-name"
                                    *ngIf="currentUser.id === data.senderId"
                            >
                                {{ currentUser?.firstname }} {{ currentUser?.lastname }}
                            </div>
                            <div
                                    _ngcontent-fgg-c56=""
                                    class="conversation-name"
                                    *ngIf="currentUser.id != data.senderId"
                            >
                                {{ selectedUser?.firstname }} {{ selectedUser?.lastname }}
                            </div>
                        </div>
                    </div>
                    <div class="chat-day-title" *ngIf="data?.isToday">
                        <span class="title">Today</span>
                    </div>
                    <div class="chat-day-title" *ngIf="data?.isYesterday">
                        <span class="title">Yesterday</span>
                    </div>
                    <div class="chat-day-title" *ngIf="data?.date">
                        <span class="title">{{ data.date }}</span>
                    </div>
                </li>
            </ul>
        </perfect-scrollbar>
        <!-- end chat conversation end -->

        <!-- start chat input section -->
        <div class="p-2 p-lg-4 border-top mb-0">
            <form [formGroup]="chatForm" (ngSubmit)="sendMessage()">
                <div class="row gx-2 gx-lg-3 no-gutters align-items-end">
                    <div class="col">
                        <div>
                            <input
                                    type="text"
                                    class="form-control form-control-lg bg-light border-light"
                                    formControlName="message"
                                    placeholder="Enter Message..."
                                    [useButton]="true"
                                    *ngIf="fileList.length === 0"
                            />
                            <div class="preview-image-row" *ngIf="fileList.length > 0">
                                <div
                                        class="preview-image-item"
                                        *ngFor="let file of fileList; let i = index"
                                >
                                    <div
                                            class="preview-image-thumb"
                                            *ngIf="file.messageType === 'image'"
                                    >
                                        <button
                                                class="remove-image-btn"
                                                type="button"
                                                (click)="removeFile(i)"
                                        >
                                            <i class="ri-close-line"></i>
                                        </button>
                                        <img id="blah" [src]="file.url" alt="your image"/>
                                    </div>

                                    <div
                                            class="preview-file-thumb"
                                            *ngIf="file.messageType === 'file'"
                                    >
                                        <button
                                                class="remove-image-btn"
                                                type="button"
                                                (click)="removeFile(i)"
                                        >
                                            <i class="ri-close-line"></i>
                                        </button>
                                        <div class="file-icon">
                                            <i class="ri-file-line"></i>
                                        </div>
                                        <div class="file-name">
                                            {{ file.file.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="add-image-file-input">
                                    <input
                                            id="selectFile"
                                            type="file"
                                            class="file-input"
                                            onclick="this.value = null"
                                            (change)="readURL($event)"
                                            multiple
                                    />
                                    <label for="selectFile" class="add-file-btn">
                                        <i class="ri-add-line"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="chat-input-links ms-md-2">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <button
                                            type="button"
                                            id="button-emoji"
                                            class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect pe-2 ps-2 pe-md-3 ps-md-3"
                                            data-toggle="tooltip"
                                            (click)="emojiToggled = !emojiToggled"
                                            data-placement="top"
                                            ngbTooltip="Emoji"
                                    >
                                        <i class="ri-emotion-happy-line"></i>
                                    </button>
                                    <emoji-mart
                                            class="emoji-dropdown"
                                            (emojiClick)="handleSelection($event)"
                                            id="emoji-palette"
                                            *ngIf="emojiToggled"
                                            title="Pick your emoji…"
                                            emoji="point_up"
                                    ></emoji-mart>
                                </li>
                                <li class="list-inline-item">
                                    <label
                                            for="file-upload"
                                            class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect pe-2 ps-2 pe-md-3 ps-md-3"
                                    >
                                        <i class="ri-attachment-line"></i>
                                    </label>
                                    <input
                                            id="file-upload"
                                            type="file"
                                            onclick="this.value = null"
                                            (change)="readURL($event)"
                                            multiple
                                    />
                                </li>
                                <li class="list-inline-item">
                                    <button
                                            type="submit"
                                            class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
                                    >
                                        <i class="ri-send-plane-2-fill"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- end chat input section -->
    </div>
    <!-- end chat conversation section -->

    <div
            class="w-100 no-user-selected"
            *ngIf="(selectedUser | json) === ({} | json)"
    >
        <!-- <p>
          <label class="display-4">
            Please select a user to chat.
          </label>
        </p> -->
        <div class="teams-body-card">
            <div class="teams-body-card-inner">
                <span><i class="ri-image-2-fill"></i></span>
                <h4 class="mb-4">Invite your team and start your conversation</h4>
                <ul>
                    <li>
                        <div class="user-team">
                            <i class="ri-user-6-fill"></i>
                        </div>
                    </li>
                    <li>
                        <div class="user-team">
                            <i class="ri-user-6-fill"></i>
                        </div>
                    </li>
                    <li>
                        <div class="user-team">
                            <i class="ri-user-6-fill"></i>
                        </div>
                    </li>
                </ul>
                <div class="create-team-btn">
                    <button
                            type="submit"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#addgroup-exampleModal"
                            (click)="openInviteUserModal()"
                    >
                        <i class="ri-user-add-fill"></i> Invite user
                    </button>
                </div>
            </div>
        </div>
    </div>

    <app-user-files></app-user-files>
</div>
<!-- End chat-message-list -->

<div *ngIf="isImportantMessageOpen">
    <app-important-messages></app-important-messages>
</div>
