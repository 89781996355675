import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/core/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { OnlineMeetingService } from 'src/app/core/services/online-meeting.service';
import { validateArgCount } from '@firebase/util';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ConferenceService } from 'src/app/core/services/conference.service';

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.scss']
})
export class ScheduleMeetingComponent implements OnInit {
  userList: any = [];
  public isCollapsed: boolean;
  public meetingForm: FormGroup;
  submitted = false;

  constructor(private userService: UserProfileService,
    private formBuilder: FormBuilder,
    private OnlineMeetingService: OnlineMeetingService,
    private conferenceService: ConferenceService,
    private modalService: NgbModal,
    public toasterService: ToasterService,
    private observableService: ObservableService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isCollapsed = false;
    this.getUserList();

    this.meetingForm = this.formBuilder.group({
      topic: ['', Validators.required],
      conference: ['', Validators.required],
      description: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      members: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      timezone: ['', Validators.required],
      webId: ['', Validators.required],
      host: ['on', Validators.required],
      // attendees: ['off', Validators.required]
    });

    this.getWebId();

    this.userList.push({
      username: 'Allow Internal Users',
      firstname: 'Allow Internal Users',
    }, {
      username: 'Allow External Users',
      firstname: 'Allow External Users',
    });

  }

  get f() { return this.meetingForm.controls; }

  async getWebId() {
    this.conferenceService.get_uniqueconferencemeetingnumber(null)
      .subscribe((res) => {
        if (res.statusCode === 200) {
          this.meetingForm.patchValue({
            webId: res.data[0].rnd
          });
        }
      })
  }

  async getUserList() {
    try {
      this.userService.getUserList().subscribe((res) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            this.userList = this.userList.concat(res.data);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.meetingForm.invalid) {
      return;
    } else {
      this.OnlineMeetingService.createMeeting(this.meetingForm.value)
        .subscribe((res) => {
          if (res.statusCode === 200) {
            this.modalService.dismissAll();
            this.toasterService.success('Your Meeting Scheduled', 'Success');
            this.observableService.selectScheduleMeetingTab.next('meeting-info');
            this.observableService.onMeetingChange.next(res.data);
          }
        });
    }
  }

  onSelectAll() {
    const selected = this.userList.map(item => item.username);
    this.meetingForm.get('members').patchValue(selected);
  }

  onClearAll() {
    this.meetingForm.get('members').patchValue([]);
  }


}
