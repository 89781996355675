import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private httpClient: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
    const apiAddress = environment.domain + `/assets/i18n/${lang}.json`;
    return this.httpClient.get(apiAddress, { headers: this.contentHeader })
      .pipe(
        catchError(_ => this.httpClient.get(`/assets/i18n/${lang}.json`))
      );
  }
}
