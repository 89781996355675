import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, retry } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { ConferenceService } from './conference.service';

@Injectable({
    providedIn: 'root'
})
export class OnlineMeetingService {
    public _data: BehaviorSubject<any> = new BehaviorSubject<any>({ data: null, readOnce: false });

    constructor(private http: HttpClient, private conferenceService: ConferenceService) { }

    getMeetings() {
        return this.http.get<any>(`@api-nest/online-meeting/schedule-meeting`).pipe(retry(0), catchError(this.handleError));
    }

    createMeeting(data) {
        return this.http.post<any>(`@api-nest/online-meeting/create-meeting`, data).pipe(retry(0), catchError(this.handleError));
    }

    sendMeetingMail(data) {
        return this.http.post<any>(`@api-nest/online-meeting/send-meeting-mail`, data).pipe(retry(0), catchError(this.handleError));
    }

    conferenceByNumber(data) {
        console.log(data)
        return this.http.post<any>(`@api-meeting/users/conferencebynumber`, { data: data.confnumber }).pipe(retry(0), catchError(this.handleError));
    }

  

    validateConferenceMeetingByNumber(conferenceNumber, company_id, username, callback) {
        this.conferenceService.getConferenceMeetingByNumber(conferenceNumber).subscribe(meetings => {
            var meeting = meetings.data[0] ? meetings.data[0] : null;

            if (!meeting) {
                return callback("Invalid conftoken|id|number Error#996.1", 0);
            }

            if (meeting.endAt) {
                return callback('Cannot join to closed meeting', meeting.isSchedule);
            }

            var utcnow = new Date();
            var startAt = new Date(meeting.startAt);// db utc but alfer it become local time zone.

            if (startAt > utcnow) {
                //this.dialogService.alert('Meeting not yet started ');
                return callback('Meeting not yet started', meeting.isSchedule);
            }


            this.conferenceService.getConferenceMeetingRestrictionsByNumber(conferenceNumber).subscribe(meetingRestrictions => {
                var meetingRestrictions = meetingRestrictions.data ? meetingRestrictions.data : null;

                if (!meetingRestrictions) {
                    return callback('Invalid conftoken|id|number Error#996', meeting.isSchedule);
                }

                var isCompanyPresent = false;
                var isUserPresent = false;
                if (!meetingRestrictions || !meetingRestrictions.length) {
                    return callback("success", meeting.isSchedule);
                }

                for (var imr = 0; imr < meetingRestrictions.length; imr++) {

                    if (meetingRestrictions[imr].companyId == company_id && !meetingRestrictions[imr].userId) {

                        isCompanyPresent = true;
                        if (meetingRestrictions.length == 1) {
                            isUserPresent = true;
                            continue; //check another leading records
                        }
                    }

                    if (!meetingRestrictions[imr].companyId && !meetingRestrictions[imr].userId) {
                        isCompanyPresent = true;
                        isUserPresent = true;
                        break;
                    }

                    if (!meetingRestrictions[imr].companyId && meetingRestrictions[imr].userId == username) {
                        isUserPresent = true;
                    }

                }

                if (!isCompanyPresent || !isUserPresent) {
                    return callback("Access Restricted", meeting.isSchedule);
                }

                return callback("success", meeting.isSchedule);

            });
        });
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(() => {
            return errorMessage;
        });
    }
}
