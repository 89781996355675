
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ConferenceCallLogDetailsDialogModel } from '../../../model/conference.calllogdetail.dialog.model'
@Component({
    templateUrl: './conference.calllogsdetials.dialog.component.html',
    styleUrls: ['./conference.calllogsdetials.dialog.component.css']
})
export class ConferenceCallLogDetilsDialogComponent implements OnInit {
  //'All Calls','allcall',_calllog.name
  title: string;
  type: string;
  username: string;
  currentusename: string;
  public logs: any[];

    constructor(public dialogRef: MatDialogRef<ConferenceCallLogDetilsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConferenceCallLogDetailsDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.type = data.type;
    this.logs = data.logs;
    this.username = data.username;
    this.currentusename = data.currentusename;
  }


  ngOnInit() {

    
    this.logs = this.logs.filter(x => x.username == this.username || x.createdby == this.username )

    


  }



  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  public getDiration(fromDate, toDate): string {

    if (!fromDate || !toDate)
      return "";
    let date1 = new Date(fromDate);
    let date2 = new Date(toDate);
    var duration = date2.valueOf() - date1.valueOf();



    var milliseconds = parseInt(((duration % 1000) / 100) as any),
      seconds = parseInt(((duration / 1000) % 60) as any),
      minutes = parseInt(((duration / (1000 * 60)) % 60) as any),
      hours = parseInt( ((duration / (1000 * 60 * 60)) % 24) as any);

    var hoursStr = (hours < 10) ? "0" + hours : hours;
    var minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    var secondsStr = (seconds < 10) ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }

}


