<div class="p-2 p-lg-4 border-bottom">
  <div class="row align-items-center">
    <div class="col-sm-4 col-8">
      <div class="d-flex align-items-center conversation-head">
        <div class="d-block d-lg-none me-1 ms-0">
          <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-0"><i
              class="ri-arrow-left-s-line"></i></a>
        </div>
        <div class="me-3 ms-0 avatar-xs flex-shrink-0">
          <span class="avatar-title rounded-circle bg-soft-primary text-primary">
            <!-- {{selectedTeam?.name | firstCharacter}} -->I
          </span>
        </div>
        <div class="flex-grow-1 overflow-hidden">
          <h5 class="font-size-16 mb-0 text-truncate">
            <a href="javascript: void(0);" id="teamName" class="text-reset user-profile-show">
              <!-- {{ selectedTeam?.name }} --> Instagram
            </a>
          </h5>
          <small class="text-truncate d-block" for="teamName">Insta</small>
        </div>
      </div>
    </div>
    <div class="col-sm-8 col-4">
      <div class="col-sm-8 col-4">
        <ul class="list-inline user-chat-nav user-chat-action text-end mb-0">
          <li class="list-inline-item d-none d-lg-inline-block">
            <button type="button" class="btn nav-btn" (click)="closeImportant()">
              <i class="ri-close-fill"></i>
              <span class="text">close</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<perfect-scrollbar class="chat-conversation p-3 p-lg-4" (psYReachStart)="loadMessage()" (psScrollY)="onScrollEvent()"
  [config]="config">
  <ul class="list-unstyled mb-0">
    <li *ngFor="let data of messageList; let i = index;" class="left">
      <div class="conversation-list" *ngIf="!data.isToday && !data.isYesterday && !data.date">
        <div class="chat-avatar">
          <img src="{{data.sender.profilePic}}" alt="" *ngIf="data.sender.profilePic">
          <div *ngIf="data.sender.profilePic === null" class="ms-0 avatar-xs flex-shrink-0">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary">
              {{data.sender.firstname | firstCharacter}}
            </span>
          </div>
        </div>
        <div class="user-chat-content">
          <div class="ctext-wrap">
            <div class="ctext-wrap-content">
              <p class="mb-0" *ngIf="isURL(data?.messageStatus?.message) && data?.message != 'null'">
                <a [href]="data?.messageStatus?.message" target="_blank">
                  {{data?.messageStatus?.message}}
                </a>
              </p>
              <p class="mb-0" *ngIf="!isURL(data?.messageStatus?.message)  && data?.messageStatus?.message != 'null'">
                {{i}} {{ data?.messageStatus?.message}}
              </p>

              <ul class="list-inline message-img mb-0" *ngIf="data?.messageStatus?.messageType === 'image'">
                <li class="list-inline-item message-img-list">
                  <div>
                    <a class="popup-img d-inline-block m-1" href="javascript: void(0);" title="Project 1">
                      <img :src="{{fileUrl(data?.messageStatus?.s3FileName, 'team')}}" alt="" class="rounded border"
                        lightbox backgroundOpacity="0.8" [counter]="true" [hideThumbnail]="false"
                        counterSeparator=" of ">
                    </a>
                  </div>
                  <div class="message-img-link">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a [href]="fileUrl(data?.messageStatus?.s3FileName, 'team')"
                          download="{{data.messageStatus?.userFileName}}">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>


              <div class="card p-2 mb-2" *ngIf="data?.messageStatus?.messageType === 'file'">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0 flex-shrink-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>
                  <div class="d-flex-body">
                    <div class="text-start">
                      <h5 class="font-size-14 chat-file-name text-truncate mb-1">{{data?.messageStatus?.userFileName}}
                      </h5>
                    </div>
                  </div>

                  <div class="ms-4">
                    <ul class="list-inline mb-0 font-size-20">
                      <li class="list-inline-item">
                        <a [href]="fileUrl(data?.messageStatus?.s3FileName, 'team')"
                          [download]="data.messageStatus?.userFileName" class="text-muted">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p class="chat-time mb-0">
                <i class="ri-time-line align-middle"></i>
                <span class="align-middle">{{data.updatedAt | date: 'shortTime': 'UTC'}}</span>
              </p>
            </div>
            <div class="dropdown align-self-start" ngbDropdown>
              <a class="dropdown-toggle" role="button" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);" (click)="updateImportant(data, i)">
                  UnImportant
                  <i class="ri-bookmark-3-line float-end text-muted"></i>
                </a>
                <!-- <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                        class="ri-save-line float-end text-muted"></i></a>
                    <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                        class="ri-chat-forward-line float-end text-muted"></i></a> -->
              </div>
            </div>
          </div>
          <div class="conversation-name">
            {{data?.sender?.firstname}} {{data?.sender?.lastname}} ( <small>{{data?.messageStatus?.createdAt | date:
              'shortDate': 'UTC'}}</small> )
          </div>
        </div>
      </div>
      <div class="chat-day-title" *ngIf="data?.isToday">
        <span class="title">Today</span>
      </div>
      <div class="chat-day-title" *ngIf="data?.isYesterday">
        <span class="title">Yesterday</span>
      </div>
      <div class="chat-day-title" *ngIf="data?.date">
        <span class="title">{{data.date}}</span>
      </div>
    </li>
  </ul>
</perfect-scrollbar>