import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ObservableService } from 'src/app/core/services/observable.service';
import { TeamService } from 'src/app/core/services/team.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team-files',
  templateUrl: './team-files.component.html',
  styleUrls: ['./team-files.component.scss']
})
export class TeamFilesComponent implements OnInit {
  public currentUser: any;
  selectedTeamObservable;
  scrollPosition: any;

  selectedTeam: any = {};

  offset = 0;
  limit = 7;
  totalRecord: 0;

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'file') {
      $event.preventDefault();
    }
  }

  fileList = [];
  constructor(
    private observableService: ObservableService,
    private teamService: TeamService,
    private tokenService: TokenStorageService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.observableService.setFileArrayNullOnTeamChange.subscribe((value) => {
      this.fileList = [];
      this.offset = 0;
      this.limit = 7;
    });

    this.currentUser = this.tokenService.getUser();

    this.selectedTeamObservable = this.observableService.selectedTeam.subscribe((value) => {
      this.selectedTeam = value;
      this.offset = 0;
      this.limit = 7;
      this.getFileList()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.componentRef.directiveRef.scrollToTop();
            this.cd.detectChanges();
          }
        });
    });
  }

  async getFileList() {
    return new Promise((resolve, reject) => {
      try {
        this.teamService.getFileList({
          teamId: this.selectedTeam.id,
          limit: this.limit,
          offset: this.offset
        }).subscribe((res) => {
          if (res.statusCode === 200) {
            this.totalRecord = res.data.totalCount;
            if (res.data.files.length > 0) {
              res.data.files.forEach((value) => {
                this.fileList.push(value)
              });
            }
            resolve('Done')
          }
        })
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  hideUserProfile() {
    document.getElementById("team-files").style.display = "none";
    document.getElementById("user-teams-wrapper").classList.remove("open-files-drawer");
  }

  fileUrl(file) {
    return environment.s3BucketTeamUrl + "/" + file;
  }

  async loadFiles() {

    if (this.totalRecord > this.limit) {
      this.offset = this.limit;
      this.limit = this.limit + 7;
      this.getFileList()
        .then(() => {
          if (this.componentRef && this.componentRef.directiveRef) {
            this.cd.detectChanges();
          }
        });
    }
  }
}
