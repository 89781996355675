
import {  NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConferenceCommonComponent } from './component/conference-common-component/conference.common.component';
import { ConferenceAlertDialogComponent } from './component/dialog/conference-alert-dialog/conference.alert.dialog.component';
import { ConferenceCallDialogComponent } from './component/dialog/conference-call-dialog/conference.call.dialog.component';
import { ConferenceCallLogDetilsDialogComponent } from './component/dialog/conference-call-logs-detials-dialog/conference.calllogsdetials.dialog.component';
import { ConferenceSelectUserDialogComponent } from './component/dialog/conference-select-user-dialog/conference.selectuserdialogdialog.component';
import { JitsiComponent } from './component/jitsi-component/jitsi.component';
import { ConferenceCallEndComponent } from './component/conference-callend-component/conference.callend.component.component';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
    declarations: [
        ConferenceCommonComponent,
        ConferenceAlertDialogComponent,
        ConferenceCallDialogComponent,
        ConferenceCallLogDetilsDialogComponent,
        ConferenceSelectUserDialogComponent,
        JitsiComponent,
        ConferenceCallEndComponent,

    ],
    imports: [
        CommonModule,
        NgxPaginationModule ,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: 'conference/call',
                component: JitsiComponent
            },
            {
                path: 'conference/thank-you',
                component: ConferenceCallEndComponent
            }
        ])
    ]   ,
    providers: [],

    exports: [
        ConferenceCommonComponent,
        ConferenceAlertDialogComponent,
        ConferenceCallDialogComponent,
        ConferenceCallLogDetilsDialogComponent,
        ConferenceSelectUserDialogComponent,
        JitsiComponent,
        ConferenceCallEndComponent,
        RouterModule]
})
export class ConferenceModule {
    //JitsiIFrameConferenceModule
    constructor() {
        console.log("ConferenceModule loaded");
    }
}
