<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
            Create New Team</h5>
        <button type="button" class="btn-close" (click)="closeModal()">
        </button>
    </div>

    <form [formGroup]="createTeamForm" (ngSubmit)="onSubmit()">
        <div class="modal-body p-4">
            <div class="mb-4">
                <label class="form-label" for="addgroupname-input">Team Name</label>
                <input type="text" class="form-control" id="addgroupname-input" formControlName="name" [ngClass]="{ 
              'is-invalid': createTeamForm.controls['name'].invalid && (createTeamForm.controls['name'].dirty || 
              createTeamForm.controls['name'].touched) && f.name.errors}" placeholder="Enter team name"
                    (focusout)="checkDuplicateTeamName()">

                <div *ngIf="createTeamForm.controls['name'].invalid && (createTeamForm.controls['name'].dirty || 
            createTeamForm.controls['name'].touched) && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Team name is required field
                    </div>
                    <div *ngIf="f.name.errors.isTeamAvailable">This team available.</div>
                </div>
            </div>

            <div class="mb-4">
                <label class="form-label">Team Members</label>
                <div class="mb-3">
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                        (click)="isCollapsed = !isCollapsed">
                        Select Members
                    </button>
                </div>

                <div class="collapse" [ngClass]="{'show':  isCollapsed ? 'show' : ''}">
                    <div class="card border">
                        <div class="card-header d-flex card-header-search">
                            <h5 class="font-size-15 mb-0">{{'chat.tabs.contacts.title' | translate}}</h5>

                            <div class="input-group bg-light input-group-sm rounded-lg">
                                <div class="input-group-prepend">
                                    <button type="button"
                                        class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                                        <i class="ri-search-line search-icon font-size-18"></i>
                                    </button>
                                </div>
                                <input type="text" class="form-control bg-light" placeholder="Search members...">
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <perfect-scrollbar data-simplebar style="height: 150px;">
                                <div *ngFor="let user of userList  | keyvalue">
                                    <div class="px-3 font-weight-bold text-primary">
                                        {{user.key | uppercase}}
                                    </div>
                                    <ul class="list-unstyled contact-list" *ngFor="let userDetails of user.value">
                                        <li>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="{{userDetails.id}}"
                                                    [value]="user.id"
                                                    (change)="onCheckboxChange($event, userDetails.id)">
                                                <label class="form-check-label" [for]="userDetails.id">
                                                    {{userDetails.firstname | titlecase}} {{userDetails.lastname
                                                    |
                                                    lowercase}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="addgroupdescription-input">Description</label>
                <textarea class="form-control" id="addgroupdescription-input" rows="3" #description maxlength="100"
                    placeholder="Enter description" formControlName="description" [ngClass]="{ 
                'is-invalid': createTeamForm.controls['description'].invalid && (createTeamForm.controls['description'].dirty || 
                createTeamForm.controls['description'].touched) && f.description.errors}"></textarea>
                <div id="the-count">
                    <span>{{description.value.length}}</span>
                    <span>/ 100</span>
                </div>
                <div *ngIf="createTeamForm.controls['description'].invalid && (createTeamForm.controls['description'].dirty || 
                createTeamForm.controls['description'].touched) && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Description is required field.
                    </div>
                    <div *ngIf="f.description.errors.maxlength">Description can be max 100 characters long.
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-link"
                (click)="closeModal()">{{'chat.tabs.groups.modal.form.button.close'
                |
                translate}}</button>
            <button type="submit" [disabled]="createTeamForm.invalid" class="btn btn-primary">Create
                Team</button>
        </div>
    </form>
</div>