export class ChatMessageModel {
    senderId: number;
    receiverId: number;
    message: string;
    messageType: MessageType;
    limit: number;
    offset: number;
}

export class TeamChatMessageModel {
    teamId: number;
    senderId: number;
    message: string;
    messageType: MessageType;
    limit: number;
    offset: number;
}

export enum MessageType {
    TEXT = "text",
    FILE = "file",
    IMAGE = 'image'
}


export class BookmarkMessage {
    userId: number;
    limit: number;
    offset: number;
}