import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
// import { DialogService } from '../common.dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class ConferenceMiddlewareService {

    private _confdialogueWindow: BehaviorSubject<any> = new BehaviorSubject<any>('');
    private _data: BehaviorSubject<any> = new BehaviorSubject<any>({ data: null, readOnce: false });

    public $createCall: EventEmitter<any> = new EventEmitter<any>();
    public $retryConnection: EventEmitter<any> = new EventEmitter<any>();
    public $connectionStatus = new BehaviorSubject<boolean>(false);//--OutSide

    public actions: { connectJitsi: BehaviorSubject<any> } = {
        connectJitsi: new BehaviorSubject<any>({})
    }

    constructor(private dialog: MatDialog,
        private router: Router) {


        this._readDataAsync().subscribe(msg => {
            if (!msg.data)
                return;

            if (msg.data.action == "connectJitsi") {
                this.actions.connectJitsi.next(msg.data);
            }
            
        });

        var lastData = this._readData();
        if (lastData)
        { 
            if (!lastData.data)
                return;

            if (lastData.data.action == "connectJitsi") {
                this.actions.connectJitsi.next(lastData.data);
            }
           
        }

       

    }
    public makeAudioCall(conferenceNumber: string, users: string[], conferenceName = "", isSheduled = false) {
        this.$createCall.emit({ conferenceNumber, users, conferenceName, mode: "audio", isSheduled });
    }
    public makeVideoCall(conferenceNumber: string, users: string[], conferenceName = "", isSheduled = false) {
        this.$createCall.emit({ conferenceNumber, users, conferenceName, mode: "video", isSheduled });
    }

    public openJitsi(conf: { confid: string, mode: string, conferenceNumber: string }, username: string, isModerator: boolean = false): any {
        this._publishData({ action: "connectJitsi", confid: conf.confid, mode: conf.mode, conferenceNumber: conf.conferenceNumber, username: username, isModerator: isModerator })
        return this._openConfMatDialogWindow();
    }

  

    public getMode(conferenceAction: ConferenceAction): ConferenceUIMode {
        if (conferenceAction.toString().toLowerCase().includes("audio"))
            return ConferenceUIMode.Audio;
        else
            return ConferenceUIMode.Video;
    }

    private _readDataAsync(): Observable<any> {
        return this._data.asObservable();
    }

    private _openConfMatDialogWindow(): any {
        // this.stopRingerAudio();

        if (!this.isCallActive()) {
            var windoRef = null;//
            windoRef = window.open("/#/conference/call", 'Conference', 'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=0,resizable=0 , dialog=1');
            //this.router.navigateByUrl("/conference/call" );
            return windoRef;
        }
        else {
            alert("Another call is in active or Call window closed abnormally!!");
        }
    }


    private _publishData(item: any, readOnce = false) {
        var data = { data: item, readOnce: readOnce };
        localStorage.setItem("_conferenceAction_", JSON.stringify(data));
        this._data.next(data);
    }

    private _readData(): any {
        let result = <any>JSON.parse(localStorage.getItem("_conferenceAction_"));
        localStorage.removeItem("_conferenceAction_");
        return result
    }








    public isCallActive() {
        var browserTabAction = JSON.parse(localStorage.getItem('browserTabAction'));
        if (!browserTabAction || (browserTabAction.action == "call-locked" && browserTabAction.value == false)) {
            return false;
        }
        else {
            return true;
        }
    }


}

export enum ConferenceUIMode {
    Video = "Video",
    Audio = "Audio",
}

export enum ConferenceAction {
    creatVideo = "create-conference-video",
    creatAudio = "create-conference-audio",

    joinVideo = "join-conference-video",
    joinAudio = "join-conference-audio",

}

export enum CallStatus {
    Connecting = "Connecting",
    Connected = "Connected",
    Ringing = "Ringing",
    Accepted = "Accepted",
    Declined = "Declined",
    NoAnswer = "NoAnswer",
    NotAvailable = "NotAvailable",
    Missed = "Missed",
    Ended = "Ended",
    Busy = "Busy",
    Error = "Error",
}


