<perfect-scrollbar class="activity-scroll" (psYReachEnd)="loadPost()">
    <div class="activity-content pt-4 pb-4">

        <div class="activity-top-card mb-3 mb-lg-5" *ngIf="isNewPostSectionShow">
            <div class="activity-content-inner p-3 p-lg-4 border-bottom">
                <div class="back-btn d-block d-lg-none">
                    <a href="javascript:void(0);" (click)="closeActivitySection()">
                        <i class="ri-arrow-left-s-line"></i>
                    </a>
                </div>
                <div class="activity-btn-profile" *ngIf="currentUser?.profilePic">
                    <img [src]="currentUser?.profilePic" alt="" err class="rounded-circle avatar-xs user-image-fit">
                </div>
                <div *ngIf="currentUser?.profilePic === null" class="ms-0 me-3 avatar-xs flex-shrink-0">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                        {{currentUser?.firstname | firstCharacter}}
                    </span>
                </div>
                <div class="activity-top-btn">
                    <button class="popup-btn form-control bg-light rounded-pill text-decoration-none btn-link " value=""
                        type="button" data-toggle="modal" data-target="#addgroup-exampleModal"
                        (click)="openCreatePostModal(content)">
                        what's on your mind, {{currentUser?.firstname}}?
                    </button>
                </div>
            </div>

            <div class="activity-row p-3">
                <ul>
                    <li>
                        <a class="activity-btn" href="javascript:void(0);" (click)="openCreatePostModal(content)">
                            <span>
                                <img src="../../../../assets/images/video-icon.svg" alt="" />
                            </span>
                            <h5 class="font-size-14 m-0">Photo/Video</h5>
                        </a>
                    </li>
                    <li>
                        <a class="activity-btn" href="javascript:void(0);" (click)="openCreatePostModal(content)">
                            <span>
                                <img src="../../../../assets/images/activity-icon.svg" alt="" />
                            </span>
                            <h5 class="font-size-14 m-0">Feeling/activity</h5>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="postData.length === 0">
            <div class="activity-page-title mb-3 mb-lg-4">
                <h5>My Post</h5>
            </div>
            <div class="activity-card-row">
                <div class="activity-post-card mb-3 mb-lg-5 p-4">
                    <div class="add-post">
                        <span class="mb-1"><img src="assets/images/upload-rafiki.svg"></span>
                        <h5 class="font-size-16 mb-4">No Post Available</h5>
                        <button type="button" (click)="openCreatePostModal(content)" class="btn btn-primary">Create
                            Post</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity-card-row" *ngFor="let post of postData;let i = index;" [id]="'post' + post.id">
            <div class="activity-post-card mb-3 mb-lg-5">
                <div class="activity-post-profile-row mb-3">
                    <div class="activity-post-profile">
                        <div class="activity-post-profile-img">
                            <img *ngIf="post?.user?.profilePic" [src]="userProfilePath(post?.user?.profilePic)" alt=""
                                class="rounded-circle avatar-xs user-image-fit">
                            <div *ngIf="!post?.user?.profilePic" class="me-lg-3 ms-0 me-0 avatar-xs flex-shrink-0">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {{post?.user?.firstname | firstCharacter}}
                                </span>
                            </div>
                        </div>
                        <div class="activity-post-profile-title">
                            <h5 class="font-size-14 m-0">{{post?.user?.firstname}} {{post?.user.lastname}}</h5>
                            <span>{{post?.updatedAt| date: 'medium': 'UTC'}}<span class="devider-dots"></span>
                                <i class="ri-earth-fill"></i>
                            </span>
                        </div>
                    </div>
                    <div class="activity-post-icon" *ngIf="currentUser?.id === post?.user?.id">
                        <div class="dropdown" ngbDropdown>
                            <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="ri-more-fill"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a href="javascript: void(0);" class="dropdown-item"
                                    (click)="editPost(post, i, content)">
                                    Edit <i class="ri-pencil-fill text-muted"></i>
                                </a>
                                <a href="javascript: void(0);" class="dropdown-item" (click)="deleteUserPost(post, i)">
                                    Delete <i class="ri-delete-bin-fill text-muted"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="activity-post-profile-content mb-3" *ngIf="post?.postType === 'Post'">
                    <h4 class="mb-2" *ngIf="post?.message && post?.message != 'null'">{{post?.message}}</h4>
                    <div class="post-images" *ngIf="post?.images.length > 0">
                        <div class="post-img-col" lightbox-group>
                            <div class="post-img-item" *ngFor="let image of post?.images; let i = index">
                                <img [src]="userPostImagePath(image?.s3FileName)" lightbox backgroundOpacity="0.8"
                                    [counter]="true" [hideThumbnail]="false" counterSeparator=" of " />
                                <div class="more-img-counter" *ngIf="i===1">
                                    +{{post?.images?.length-1}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="activity-post-profile-content mb-3" *ngIf="post?.postType === 'Event'">
                    <div class="activity-details-card">
                        <h4 class="mb-3">{{post?.event?.eventName}}</h4>
                        <div class="activity-details">
                            <div class="activity-details-row">
                                <div class="activity-details-icon">
                                    <i class="ri-calendar-fill"></i>
                                </div>
                                <div class="activity-details-list">
                                    <h5 class="font-size-18 m-0">{{post?.event?.startDate | date: 'shortDate': 'UTC'}} -
                                        {{post?.event?.endDate | date: 'shortDate': 'UTC'}}</h5>
                                </div>
                            </div>
                            <div class="activity-details-row">
                                <div class="activity-details-icon">
                                    <i class="ri-time-fill"></i>
                                </div>
                                <div class="activity-details-list">
                                    <h5 class="font-size-18 m-0">{{post?.event?.startTime}} -
                                        {{post?.event?.endTime}}
                                    </h5>
                                </div>
                            </div>
                            <div class="activity-details-row">
                                <div class="activity-details-icon">
                                    <i class="ri-file-list-3-fill"></i>
                                </div>
                                <div class="activity-details-list">
                                    <h5 class="font-size-18 m-0">{{post?.event?.category}}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="activity-details-content">
                            <p>
                                {{post?.event?.description}}
                                <a href="javascript: void(0);">See less</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="activity-comment">
                    <ul>
                        <li>
                            <a [ngClass]="{'active': postReactionClass(post)}" href="javascript:void(0);"
                                (click)="updateReaction(post)">
                                <i class="ri-thumb-up-line"></i>
                                <span class="d-none d-sm-inline me-1">Like</span>
                                <span class="count-text" *ngIf="post?.like?.length > 0">
                                    ({{post?.like?.length}})
                                </span>
                                <span class="count-text" *ngIf="post?.like?.length <= 0">
                                    (0)
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [class.open]="!post?.activityComment"
                                (click)="setCollapseValueOfComment(post)" [attr.aria-expanded]="!post.activityComment">
                                <i class="ri-message-2-line"></i>
                                <span class="d-none d-sm-inline me-1">Comment</span><span
                                    class="count-text">({{post?.comments?.length}})</span>
                            </a>
                        </li>
                        <li>
                            <a class="" href="javascript:void(0);" [ngClass]="{'active': savePostClass(post)}"
                                (click)="updateSavedPost(post, i)">
                                <i class="ri-save-line"></i>
                                <span class="d-none d-sm-inline">Save</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="activity-comment-box pb-3" #collapse="ngbCollapse" [(ngbCollapse)]="post.activityComment">
                    <div class="activity-comment-box-wrap" *ngIf="post?.comments?.length > 0">
                        <a href="javascript:void(0);" class="comment-show-link mb-3" [class.open]="!post.isCollapsed"
                            (click)="post.isCollapsed = !post.isCollapsed" [attr.aria-expanded]="!post.isCollapsed">
                            <p *ngIf="!post.isCollapsed">Show less comments</p>
                            <p *ngIf="post.isCollapsed">Show comments</p>
                            <span id="downArrow" class="down-icons"><i class="ri-arrow-down-s-line"></i></span>
                            <span id="upArrow" class="up-icons d-none"><i class="ri-arrow-up-s-line"></i></span>
                        </a>
                        <div id="" class="custom-scrollbar mb-3" #collapse="ngbCollapse"
                            [(ngbCollapse)]="post.isCollapsed">
                            <perfect-scrollbar class="chat-conversation comment-conversation">
                                <ul class="list-unstyled mb-0">
                                    <li *ngFor="let comment of post?.comments">
                                        <div class="conversation-list">
                                            <div class="activity-btn-profile chat-avatar"
                                                *ngIf="comment?.user?.profilePic">
                                                <img [src]="userProfilePath(comment?.user?.profilePic)" alt=""
                                                    class="rounded-circle avatar-xs user-image-fit">
                                            </div>
                                            <div class="">
                                                <div *ngIf="comment?.user?.profilePic === null"
                                                    class="ms-0 me-3 avatar-xs flex-shrink-0">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        {{comment?.user?.firstname | firstCharacter}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="user-chat-content">
                                                <div class="ctext-wrap">
                                                    <div class="ctext-wrap-content">
                                                        <p class="mb-0">
                                                            {{comment?.comment}}
                                                        </p>
                                                        <p class="chat-time mb-0">
                                                            <i class="ri-time-line align-middle"></i>
                                                            <span class="align-middle">
                                                                {{comment?.updatedAt | date:'shortTime': 'UTC'}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="conversation-name">
                                                    {{comment?.user?.firstname}} {{comment?.user?.lastname}}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="activity-comment-add-box">
                        <form>
                            <div class="user-avtar-image">
                                <!-- <i class="ri-instagram-line"></i> -->
                                <div class="activity-btn-profile" *ngIf="currentUser?.profilePic">
                                    <img [src]="currentUser?.profilePic" alt=""
                                        class="rounded-circle avatar-xs user-image-fit">
                                </div>
                                <div *ngIf="currentUser?.profilePic === null"
                                    class="me-lg-3 ms-0 me-0 avatar-xs flex-shrink-0">
                                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                        {{currentUser?.firstname | firstCharacter}}
                                    </span>
                                </div>
                            </div>
                            <input type="text" placeholder="Type here" name="comment" [(ngModel)]="comment"
                                (keyup.enter)="addComment(post?.id, post)"
                                class="comment-text form-control bg-light rounded-pill " />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</perfect-scrollbar>

<!-- Start add group Modal -->
<ng-template #content let-modal>
    <div class="post-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-size-16" id="">Create Post</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <form [formGroup]="userPostForm" (ngSubmit)="uploadPost()">
                <div class="modal-body p-3">
                    <div class="post-profile mb-3">
                        <div class="post-profile-img" *ngIf="currentUser?.profilePic">
                            <img [src]="currentUser?.profilePic" alt="" class="rounded-circle avatar-xs user-image-fit">
                        </div>
                        <div *ngIf="currentUser?.profilePic === null" class="ms-0 me-3 avatar-xs flex-shrink-0">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                {{currentUser?.firstname | firstCharacter}}
                            </span>
                        </div>

                        <div class="post-profile-title">
                            <h5 class="font-size-14 m-0">{{currentUser?.firstname}}</h5>
                        </div>
                    </div>
                    <div class="post-type-content mb-3">
                        <textarea id="" name="" rows="" cols=""
                            placeholder="What's on your mind, {{currentUser?.firstname}}?"
                            formControlName="message"></textarea>
                        <div class="post-emotion mb-2">
                            <button type="button" id="button-emoji"
                                class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                                data-toggle="tooltip" (click)="emojiToggled = !emojiToggled" data-placement="top"
                                ngbTooltip="Emoji">
                                <i class="ri-emotion-happy-line"></i>
                            </button>
                            <emoji-mart class="emoji-dropdown" (emojiClick)="handleSelection($event)" id="emoji-palette"
                                *ngIf="emojiToggled" title="Pick your emoji…" emoji="point_up"></emoji-mart>
                        </div>
                        <div class="post-img-priview" *ngIf="fileList.length > 0">
                            <div class="post-img-priview-inner" *ngFor="let file of fileList;let i = index">
                                <div class="priview-inner">
                                    <img id="" [src]="file?.url" alt="">
                                </div>
                                <div class="post-img-priview-close">
                                    <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close"
                                        (click)="removeFile(i)"></button>
                                </div>
                            </div>
                        </div>
                        <div class="post-img-priview" *ngIf="updatePost?.images?.length > 0 ">
                            <div class="post-img-priview-inner" *ngFor="let image of updatePost?.images;let i = index">
                                <div class="priview-inner">
                                    <img id="" [src]="userPostImagePath(image?.s3FileName)" alt="">
                                </div>
                                <div class="post-img-priview-close">
                                    <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close"
                                        (click)="removePostImage(i)"></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="post-add-box">
                        <div class="post-add-row mb-3">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">
                                        <h5 class="font-size-14 m-0">Add to your post</h5>
                                    </a>
                                </li>
                            </ul>
                            <ul class="post-emotion-row">
                                <li>
                                    <div class="input-priview">
                                        <input class="d-none" type="file" id="select-post-img" multiple
                                            (change)="userImagePostPreview($event)">
                                        <label class="m-0" for="select-post-img">
                                            <img src="../../../../assets/images/video-icon.svg" alt="">
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="post-btn-row">
                            <button type="submit" class="btn post-link btn-primary"
                                [disabled]="userPostForm.invalid">Post</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- End add group Modal -->