
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConferenceCallDialogModel } from '../../../model/conference.call.dialog.model';

@Component({
    templateUrl: './conference.call.dialog.component.html',
    styleUrls: ['./conference.call.dialog.component.css']
})
export class ConferenceCallDialogComponent implements OnInit {
    conferenceName: any;
    creatorUserName: any;
    message: string;

    constructor(public dialogRef: MatDialogRef<ConferenceCallDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConferenceCallDialogModel) {
        // Update view with given values
        this.conferenceName = data.conferenceName;
        this.creatorUserName = data.creatorUserName;
        this.message = "Call from " + (data.conferenceName ? data.conferenceName : data.creatorUserName);

    }

    ngOnInit() {
    }

    onConfirm(): void {
        // Close the dialog, return true
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
    }
}


