import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConferenceService } from 'src/app/core/services/conference.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

interface Country {
  calltimeicon: string;
  calltime: string;
  profileicon: string;
  name: string;
  Joined: string;
  Joinedtext: string;
  duration: string;
}
@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent implements OnInit {
  active = 1;
  mode = '@Audio'

  pageIndex = 1;
  pageSize = 10;
  // collectionSize = COUNTRIES.length;
  countries: Country[];

  slideConfig = {
    "slidesToShow": 8,
    "slidesToScroll": 1,
    "infinite": false,
    responsive: [{
      breakpoint: 1650,
      settings: {
        slidesToShow: 5,
        infinite: false
      }
    }, {
      breakpoint: 1365,
      settings: {
        slidesToShow: 4,
        infinite: false
      }
    }, {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        infinite: false
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        infinite: false
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        infinite: false
      }
    }, {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        infinite: false
      }
    }, {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        infinite: false
      }
    }]
  };

  currentUser: any;
  conferenceList = [];

  uiCallLogs: any = [];

  constructor(
    private modalService: NgbModal,
    private conferenceService: ConferenceService,
    private tokenStorageService: TokenStorageService,
  ) {
    // this.refreshCountries();
  }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    this.getConferenceLog();
  }

  // refreshCountries() {
  //   this.countries = COUNTRIES
  //     .map((country, i) => ({ id: i + 1, ...country }))
  //     .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  // }

  async allCallModal(confId, allCallModalHtml) {
    this.conferenceService.getConferenceLogByConfId({
      confId, mode: this.mode
    })
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
        }
      })
    await this.modalService.open(allCallModalHtml, {
      centered: true,
      modalDialogClass: 'all-call-modal'
    });
  }

  async getConferenceLog() {
    try {
      this.conferenceList = [];
      this.uiCallLogs = [];

      this.conferenceService.getConferenceLog({
        username: this.currentUser.username,
        mode: this.mode,
        offset: this.pageIndex,
        sorting: this.pageSize
      }).subscribe((res: any) => {
        if (res.statusCode === 200) {
          if (res.data.length > 0) {
            this.conferenceList = res.data;
            // this.uiCallLogs = [];
            var allUsers = [];
            this.conferenceList.forEach(user => {
              if (user.createdBy != 'media-defailt-user' && user.createdBy != '' && user.username != 'media-defailt-user' && user.username != '') {

                if (user.createdBy != this.currentUser.username) {
                  if (!allUsers.find(x => x.username == user.createdBy))
                    allUsers.push(user);
                }

                if (user.username != this.currentUser.username) {
                  if (!allUsers.find(x => x.username == user.username))
                    allUsers.push(user);
                }
              }
            });


            

            allUsers.forEach(user => {
              if (user.username != undefined) {
                var callRecord: any = {};
                callRecord.user = user;
                callRecord.createdAt = this.conferenceList.filter(x => x.username == user.username || x.createdBy == user.username)[0].createdAt;
                callRecord.allCalls = this.conferenceList.filter(x => x.username == user.username || x.createdBy == user.username).length;
                callRecord.allCallsDate = (this.conferenceList.find(x => x.username == user.username || x.createdBy == user.username) as any).createdAt;
                callRecord.missedCalls = this.conferenceList.filter(x => x.createdBy == user.username && !x.joinedAt).length;
                callRecord.outgoingCalls = this.conferenceList.filter(x => x.createdBy == this.currentUser.username && x.username == user.username).length;
                callRecord.answeredCalls = this.conferenceList.filter(x => x.createdBy == user.username && x.joinedAt && x.exitAt).length;

                if (callRecord) {
                  this.uiCallLogs.push(callRecord);
                }
              }
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeTab(value) {
    this.mode = value.activeId === 1 ? '@Video' : '@Audio';
    this.getConferenceLog()
  }

  getDuration(fromDate, toDate): string {

    if (!fromDate || !toDate)
      return "";
    let date1 = new Date(fromDate);
    let date2 = new Date(toDate);
    var duration = date2.valueOf() - date1.valueOf();


    var milliseconds = parseInt(((duration % 1000) / 100) as any),
      seconds = parseInt(((duration / 1000) % 60) as any),
      minutes = parseInt(((duration / (1000 * 60)) % 60) as any),
      hours = parseInt(((duration / (1000 * 60 * 60)) % 24) as any);

    var hoursStr = (hours < 10) ? "0" + hours : hours;
    var minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    var secondsStr = (seconds < 10) ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }

  userProfileImageUrl(image) {
    return environment.s3BucketProfileUrl + "/" + image;
  }


}
