import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommanService {

  constructor() { }

  getFileUrl(file, folder) {
    if (folder === 'profile') {
      return environment.s3BucketProfileUrl + "/" + file;
    }
  }

  isImageFile(fileName) {
    return (fileName.match(/\.(jpeg|jpg|gif|png|JPG|JPEG|GIF|PNG)$/) != null);
  }
}
