<!-- Start chat-message-list -->
<div class="d-lg-flex">
  <!-- start chat conversation section -->
  <div class="w-100 user-chat-wrapper" id="user-teams-wrapper" *ngIf="(selectedTeam | json) != ({} | json) && !isImportantMessageOpen">
    <!-- start chat user head -->
    <div class="p-2 p-lg-4 border-bottom">
      <div class="row align-items-center">
        <div class="col-sm-4 col-8">
          <div class="d-flex align-items-center conversation-head">
            <div class="d-block d-lg-none me-1 ms-0">
              <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-0"
                (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
            </div>
            <div class="me-3 ms-0 avatar-xs flex-shrink-0">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                {{selectedTeam?.name | firstCharacter}}
              </span>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <h5 class="font-size-16 mb-0 text-truncate">
                <a href="javascript: void(0);" id="teamName" (click)="openTeamEditModal(content)"
                  class="text-reset user-profile-show">
                  {{ selectedTeam?.name }}
                </a>
              </h5>
              <small class="text-truncate d-block" for="teamName">{{selectedTeam?.description}}</small>
            </div>
          </div>
        </div>
        <div class="col-sm-8 col-4">
          <ul class="list-inline user-chat-nav user-chat-action text-end mb-0">
            <li class="list-inline-item d-none d-lg-inline-block">
              <button type="button" class="btn nav-btn" (click)="getImportantMessageList()">
                <i class="ri-bookmark-3-line"></i>
                <span class="text">Bookmark</span>
              </button>
            </li>
            
            <li class="list-inline-item d-none d-lg-inline-block ms-0">
              <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#audiocallModal"
                (click)="openCallModal(callContent)">
                <i class="ri-phone-line" onclick="window.open('/call/video','name','width=auto,height=auto')"></i>
                <span class="text">Audio Call</span>
              </button>
            </li>

            <li class="list-inline-item d-none d-lg-inline-block ms-0">
              <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal"
                (click)="openVideoModal(videoContent)">
                <i class="ri-vidicon-line" onclick="window.open('/call/audio','name','width=auto,height=auto')"></i>
                <span class="text">Video Call</span>
              </button>
            </li>

            <li class="list-inline-item d-none d-lg-inline-block">
              <button type="button" class="btn nav-btn user-profile-show" data-bs-target="#editMembers"
                (click)="openTeamMemberModal(editMembers)">
                <i class="ri-settings-4-fill"></i>
                <span class="text">Users List</span>
              </button>
            </li>

            <li class="list-inline-item d-none d-lg-inline-block">
              <button type="button" class="btn nav-btn user-profile-show" (click)="showFileSection()">
                <i class="ri-file-text-line"></i>
                <span class="text">Files</span>
              </button>
            </li>

            <li class="list-inline-item">
              <div class="dropdown" ngbDropdown>
                <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="ri-more-fill"></i>
                  <span class="text">More</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item d-block d-lg-none user-profile-show" (click)="showUserProfile()"
                    href="javascript: void(0);">{{ "chat.chatpanelheader.dropdown.viewprofile" | translate }}
                    <i class="ri-user-2-line float-end text-muted"></i></a>
                  <a class="dropdown-item" href="javascript: void(0);">{{
                    "chat.chatpanelheader.dropdown.archive"
                    | translate
                    }}
                    <i class="ri-archive-line float-end text-muted"></i></a>
                  <a class="dropdown-item" href="javascript: void(0);">{{
                    "chat.chatpanelheader.dropdown.muted"
                    | translate
                    }}
                    <i class="ri-volume-mute-line float-end text-muted"></i></a>
                  <a class="dropdown-item" href="javascript: void(0);">{{
                    "chat.chatpanelheader.dropdown.delete"
                    | translate
                    }}
                    <i class="ri-delete-bin-line float-end text-muted"></i></a>
                    
                    <a class="dropdown-item d-block d-lg-none user-profile-show" href="javascript: void(0);" data-bs-target="#editMembers" (click)="openTeamMemberModal(editMembers)">
                      Users List
                      <i class="ri-settings-4-fill float-end text-muted"></i> 
                    </a> 
                    <a class="dropdown-item d-block d-lg-none user-profile-show" href="javascript: void(0);" (click)="showFileSection()">
                      Files
                      <i class="ri-file-line float-end text-muted"></i> 
                    </a> 
                    <a class="dropdown-item d-block d-lg-none" href="javascript: void(0);" data-bs-toggle="modal" data-bs-target="#audiocallModal" (click)="openCallModal(callContent)">
                      Audio Call <i class="ri-phone-line float-end text-muted"></i>
                    </a>
                    <a class="dropdown-item d-block d-lg-none" href="javascript: void(0);" data-bs-toggle="modal" data-bs-target="#videocallModal" (click)="openVideoModal(videoContent)"> 
                      Video Call <i class="ri-vidicon-line float-end text-muted"></i>
                    </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end chat user head -->

    <perfect-scrollbar class="chat-conversation p-3 p-lg-4" (psYReachStart)="loadMessage()"
      (psScrollY)="onScrollEvent()" [config]="config">
      <ul class="list-unstyled mb-0">
        <li *ngFor="let data of messageList; let i = index" [ngClass]="{ right: data?.user?.id == currentUser?.id }">
          <div class="conversation-list" *ngIf="!data.isToday && !data.isYesterday && !data.date">
            <div class="chat-avatar">
              <img *ngIf="currentUser.id === data?.user?.id && currentUser?.profilePic"
                src="{{ currentUser?.profilePic }}" class="user-image-fit" alt="" />
              <div *ngIf="currentUser.id === data?.user?.id && currentUser?.profilePic === null"
                class="ms-0 avatar-xs flex-shrink-0">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                  {{currentUser?.firstname | firstCharacter}}
                </span>
              </div>

              <img *ngIf="currentUser.id != data?.user?.id && data?.user?.profilePic"
                src="{{ fileUrl(data?.user?.profilePic, 'user') }}"  class="user-image-fit" alt="" />

              <div *ngIf="currentUser.id != data?.user?.id  && data?.user?.profilePic === null"
                class="ms-0 avatar-xs flex-shrink-0">
                <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                  {{data?.user?.firstname | firstCharacter}}
                </span>
              </div>
            </div>
            <div class="user-chat-content">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <p class="mb-0" *ngIf="isURL(data?.message) && data?.message != 'null'">
                    <a [href]="data?.message" target="_blank">
                      {{data?.message}}
                    </a>
                  </p>
                  <p class="mb-0" *ngIf="!isURL(data.message)  && data?.message != 'null'">
                    {{ data?.message}}
                  </p>

                  <ul class="list-inline message-img mb-0" *ngIf="data.messageType === 'image'">
                    <li class="list-inline-item message-img-list">
                      <div>
                        <a class="popup-img d-inline-block m-1" href="javascript: void(0);" title="Project 1">
                          <img :src="{{fileUrl(data.s3FileName, 'team')}}" alt="" class="rounded border" lightbox backgroundOpacity="0.8"
                          [counter]="true" [hideThumbnail]="false" counterSeparator=" of ">
                        </a>
                      </div>
                      <div class="message-img-link">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a [href]="fileUrl(data.s3FileName, 'team')" download="{{data.userFileName}}">
                              <i class="ri-download-2-line"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>


                  <div class="card p-2 mb-2" *ngIf="data.messageType === 'file'">
                    <div class="d-flex align-items-center">
                      <div class="avatar-sm me-3 ms-0 flex-shrink-0">
                        <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                          <i class="ri-file-text-fill"></i>
                        </div>
                      </div>
                      <div class="d-flex-body">
                        <div class="text-start">
                          <h5 class="font-size-14 chat-file-name text-truncate mb-1">{{data.userFileName}}</h5>
                        </div>
                      </div>

                      <div class="ms-4">
                        <ul class="list-inline mb-0 font-size-20">
                          <li class="list-inline-item">
                            <a [href]="fileUrl(data.s3FileName, 'team')" [download]="data.userFileName"
                              class="text-muted">
                              <i class="ri-download-2-line"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <p class="chat-time mb-0">
                    <i class="ri-time-line align-middle"></i>
                    <span class="align-middle">{{data.updatedAt | date: 'shortTime': 'UTC'}}</span>
                  </p>
                </div>
                <div class="dropdown align-self-start" ngbDropdown >
                  <a class="dropdown-toggle" role="button" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <div *ngIf="(currentUser.id === data.senderId )">
                      <a class="dropdown-item" *ngIf="(data.messageType != 'file' && data.messageType != 'image')"
                        href="javascript: void(0);" (click)="editMessage(data)">
                        Edit
                        <i class="ri-pencil-line float-end text-muted"></i>
                      </a>
                      <a class="dropdown-item" href="javascript: void(0);" (click)="deleteMessage(data, i)">Delete <i class="
                        ri-delete-bin-line float-end text-muted"></i>
                      </a>
                    </div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="updateImportant(data, i)">
                      Important 
                      <i class="ri-bookmark-3-line float-end text-muted"></i>
                    </a>
                    <!-- <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                        class="ri-save-line float-end text-muted"></i></a>
                    <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                        class="ri-chat-forward-line float-end text-muted"></i></a> -->
                  </div>
                </div>
              </div>
              <div _ngcontent-fgg-c56="" class="conversation-name" *ngIf="currentUser.id === data.senderId">
                {{currentUser?.firstname}} {{currentUser?.lastname}}
              </div>
              <div _ngcontent-fgg-c56="" class="conversation-name" *ngIf="currentUser.id != data.senderId">
                {{data?.user?.firstname}} {{data?.user.lastname}}
              </div>
            </div>
          </div>
          <div class="chat-day-title" *ngIf="data?.isToday">
            <span class="title">Today</span>
          </div>
          <div class="chat-day-title" *ngIf="data?.isYesterday">
            <span class="title">Yesterday</span>
          </div>
          <div class="chat-day-title" *ngIf="data?.date">
            <span class="title">{{data.date}}</span>
          </div>
        </li>
      </ul>
    </perfect-scrollbar>
    <!-- end chat conversation end -->

    <!-- start chat input section -->
    <div class="p-2 p-lg-4 border-top mb-0">
      <form [formGroup]="chatForm" (ngSubmit)="sendMessage()">
        <div class="row gx-2 gx-lg-3 no-gutters align-items-end">
          <div class="col">
            <div>
              <input type="text" class="form-control form-control-lg bg-light border-light" formControlName="message"
                placeholder="Enter Message..." [useButton]="true" *ngIf="fileList.length === 0 " />
              <div class="preview-image-row" *ngIf="fileList.length > 0 ">
                <div class="preview-image-item" *ngFor="let file of fileList; let i=index;">
                  <div class="preview-image-thumb" *ngIf="file.messageType === 'image'">
                    <button class="remove-image-btn" type="button" (click)="removeFile(i)">
                      <i class="ri-close-line"></i>
                    </button>
                    <img id="blah" [src]="file.url" alt="your image" />
                  </div>

                  <div class="preview-file-thumb" *ngIf="file.messageType === 'file'">
                    <button class="remove-image-btn" type="button" onclick="this.value = null" (click)="removeFile(i)">
                      <i class="ri-close-line"></i>
                    </button>
                    <div class="file-icon">
                      <i class="ri-file-line"></i>
                    </div>
                    <div class="file-name">
                      {{file.file.name}}
                    </div>
                  </div>
                </div>
                <div class="add-image-file-input">
                  <input id="selectFile" type="file" class="file-input" onclick="this.value = null"
                    (change)="readURL($event);" multiple />
                  <label for="selectFile" class="add-file-btn">
                    <i class="ri-add-line"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="chat-input-links ms-md-2">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <button type="button" id="button-emoji"
                    class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect pe-2 ps-2 pe-md-3 ps-md-3" data-toggle="tooltip"
                    (click)="emojiToggled = !emojiToggled" data-placement="top" ngbTooltip="Emoji">
                    <i class="ri-emotion-happy-line"></i>
                  </button>
                  <emoji-mart class="emoji-dropdown" (emojiClick)="handleSelection($event)" id="emoji-palette"
                    *ngIf="emojiToggled" title="Pick your emoji…" emoji="point_up"></emoji-mart>
                </li>
                <li class="list-inline-item">
                  <label for="file-upload" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect pe-2 ps-2 pe-md-3 ps-md-3">
                    <i class="ri-attachment-line"></i>
                  </label>
                  <input id="file-upload" type="file" (change)="readURL($event);" multiple />
                </li>
                <li class="list-inline-item">
                  <button type="submit" class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                    <i class="ri-send-plane-2-fill"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- end chat input section -->
  </div>

  <div *ngIf="isImportantMessageOpen" class="w-100 user-chat-wrapper" id="user-teams-wrapper" >
    <app-important-team-message></app-important-team-message>
  </div>
  <!-- end chat conversation section -->



  <div class="w-100 no-user-selected" *ngIf="(selectedTeam | json) == ({} | json)">
    <!-- <p>
      <label class="display-4">
        
      </label>
    </p> -->
    <div class="teams-body-card">
      <div class="teams-body-card-inner">
        <span><i class="ri-image-2-fill"></i></span>
        <h4 class="mb-4">Create a team</h4>
        <ul>
          <li>
            <div class="user-team">
              <i class="ri-user-6-fill"></i>
            </div>
          </li>
          <li>
            <div class="user-team">
              <i class="ri-user-6-fill"></i>
            </div>
          </li>
          <li>
            <div class="user-team">
              <i class="ri-user-6-fill"></i>
            </div>
          </li>
        </ul>
        <div class="create-team-btn">
          <button type="button" class="btn btn-primary" (click)="openTeamModal()"> <i class="ri-user-add-fill"></i> Create team</button>
        </div>
      </div>
    </div>
      
  </div>
  <app-team-files></app-team-files>
</div>
<!-- End chat-message-list -->


<ng-template #videoContent>
  <div class="modal-body">
    <div class="text-center p-4">
      <div class="avatar-lg mx-auto mb-4">
        <img src="assets/images/users/avatar-4.jpg" alt="" class="img-thumbnail rounded-circle" />
      </div>

      <h5 class="text-truncate">
        Doris Brown
      </h5>
      <p class="text-muted mb-0">
        Start Video Call
      </p>

      <div class="mt-5">
        <ul class="list-inline mb-1">
          <li class="list-inline-item px-2 me-2 ms-0">
            <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
              <span class="avatar-title bg-transparent font-size-20">
                <i class="ri-close-fill"></i>
              </span>
            </button>
          </li>
          <li class="list-inline-item px-2">
            <button type="button" onclick="window.open('/call/video','name','width=auto,height=auto')" class="btn btn-success avatar-sm rounded-circle">
              <span class="avatar-title bg-transparent font-size-20">
                <i class="ri-vidicon-fill"></i>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
        Update team details</h5>
      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
      </button>
    </div>
    <form [formGroup]="updateTeamForm" (ngSubmit)="onTeamInfoUpdate()">
      <div class="modal-body p-4">
        <div class="mb-4">
          <label class="form-label" for="addgroupname-input">Team Name</label>
          <input type="text" class="form-control" id="addgroupname-input" formControlName="name" [ngClass]="{ 
              'is-invalid': updateTeamForm.controls['name'].invalid && (updateTeamForm.controls['name'].dirty || 
              updateTeamForm.controls['name'].touched) && f.name.errors}" placeholder="Enter team name"
            (focusout)="checkDuplicateTeamName()">

          <div *ngIf="updateTeamForm.controls['name'].invalid && (updateTeamForm.controls['name'].dirty || 
            updateTeamForm.controls['name'].touched) && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Team name is required field
            </div>
            <div *ngIf="f.name.errors.isTeamAvailable">This team available.</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="addgroupdescription-input">Description</label>
          <textarea class="form-control" id="addgroupdescription-input" rows="3" placeholder="Enter description"
            formControlName="description" [ngClass]="{ 
                'is-invalid': updateTeamForm.controls['description'].invalid && (updateTeamForm.controls['description'].dirty || 
                updateTeamForm.controls['description'].touched) && f.description.errors}"></textarea>

          <div *ngIf="updateTeamForm.controls['description'].invalid && (updateTeamForm.controls['description'].dirty || 
                updateTeamForm.controls['description'].touched) && f.description.errors" class="invalid-feedback">
            <div *ngIf="f.description.errors.required">Description is required field.
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal"
          (click)="modal.dismiss('Cross click')">{{'chat.tabs.groups.modal.form.button.close' |
          translate}}</button>
        <button type="submit" [disabled]="updateTeamForm.invalid" class="btn btn-primary">Update
          Team</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #callContent>
  <div class="modal-body">
    <div class="text-center p-4">
      <div class="avatar-lg mx-auto mb-4">
        <img src="assets/images/users/avatar-4.jpg" alt="" class="img-thumbnail rounded-circle" />
      </div>

      <h5 class="text-truncate">
        Doris Brown
      </h5>
      <p class="text-muted">
        Start Audio Call
      </p>

      <div class="mt-5">
        <ul class="list-inline mb-1">
          <li class="list-inline-item px-2 me-2 ms-0">
            <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
              <span class="avatar-title bg-transparent font-size-20">
                <i class="ri-close-fill"></i>
              </span>
            </button>
          </li>
          <li class="list-inline-item px-2">
            <button type="button" onclick="window.open('/call/audio','name','width=auto,height=auto')" class="btn btn-success avatar-sm rounded-circle">
              <span class="avatar-title bg-transparent font-size-20">
                <i class="ri-phone-fill"></i>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #editMembers let-modal>
  <div class="user-list-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
          User list</h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss('Cross click')">
        </button>
      </div>
      <div class="modal-body">
        <div class="user-list-table">
          <perfect-scrollbar class="chat-message-list chat-group-list" (psYReachEnd)="loadTeamUserList()">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Role</th>
                  <th scope="col" *ngIf="isCurrentUserIsTeamAdmin">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of teamMembers; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>
                    {{ user?.user?.firstname }} {{user?.lastname}}
                  </td>
                  <td>{{ user?.userType}}</td>
                  <td *ngIf="isCurrentUserIsTeamAdmin">
                    <div *ngIf="user.userType === 'Normal' && getCurrentUserTypeInTeam().userType === 'Admin'">
                      <button type="button" class="btn btn-link p-0 me-1" data-dismiss="modal"
                        (click)="changeTeamMemberRole(user.user.id, 'Admin', i)">Assign as Admin</button>
                      <button type="button" class="btn btn-link p-0 me-1" data-dismiss="modal"
                        (click)="removeTeamMember(user?.id, i)">Delete</button>
                    </div>
                    <div
                      *ngIf="user?.userType === 'Admin' && getCurrentUserTypeInTeam().userType === 'Admin' && user?.team?.createdBy != user?.user?.id">
                      <button *ngIf="user.userType !== 'Admin'" type="button" class="btn btn-link p-0 me-1"
                        data-dismiss="modal" (click)="changeTeamMemberRole(user?.id, 'Admin', i)">Assign as
                        Admin</button>
                      <button type="button" class="btn btn-link p-0 me-1" data-dismiss="modal"
                        (click)="changeTeamMemberRole(user?.user?.id, 'Normal', i)">Remove Admin</button>
                      <button *ngIf="getCurrentUserTypeInTeam().user.id != user?.user?.id" type="button"
                        class="btn btn-link p-0 me-1" data-dismiss="modal"
                        (click)="removeTeamMember(user?.id, i)">Delete</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>


          </perfect-scrollbar>


        </div><br>

        <div class="add-user-list" *ngIf="isCurrentUserIsTeamAdmin">
          <div class="modal-header bg-light">
            <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
              Add User Into List
            </h5>
            <div class="input-group bg-light input-group-sm rounded-lg">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </button>
              </div>
              <input type="text" class="form-control bg-light" placeholder="Search members...">
            </div>
          </div>
          <div class="all-user-list-table">
            <perfect-scrollbar class="chat-message-list chat-group-list" (psYReachEnd)="loadAllUserList()">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="allUserList.length > 0">
                  <tr *ngFor="let user of allUserList; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{user?.firstname}} {{user?.lastname}}</td>
                    <td>{{user?.email}}</td>
                    <td>
                      <button type="button" class="btn btn-link p-0" data-dismiss="modal" *ngIf="isCurrentUserIsTeamAdmin"
                        (click)="addUserToTeam(user, i)">Add User</button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="allUserList.length === 0">
                  <span class="text-center"> No User Found</span>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal"
          (click)="modal.dismiss('Cross click')">{{'chat.tabs.groups.modal.form.button.close' |
          translate}}</button>
      </div>
    </div>
  </div>

</ng-template>