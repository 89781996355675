import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/core/services/observable.service';
import { TokenStorageService } from 'src/app/core/services/token.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
/**
 * Tabs-Profile component
 */
export class ProfileComponent implements OnInit {
  currentUser: any;

  constructor(
    private observableService: ObservableService,
    private tokenService: TokenStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
  }

  editProfile() {
    this.router.navigate(["/pages/setting"]);
  }

  setDefaultPic(event) {
    event.target.src = `assets/images/users/profile.png`;
  }


}
