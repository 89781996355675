import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnlineMeetingService } from 'src/app/core/services/online-meeting.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.scss']
})
export class JoinMeetingComponent implements OnInit {
  submitted = false;
  public meetingForm: FormGroup;
  @ViewChild('ccNumber') ccNumberField: ElementRef;
  public currentUser: any = {};

  fault;
  success = false;
  meetingId: any;
  meetingIdError;

  constructor(
    private formBuilder: FormBuilder,
    public toasterService: ToasterService,
    private onlineMeetingService: OnlineMeetingService,
    private tokenService: TokenStorageService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();


    this.meetingForm = this.formBuilder.group({
      meetingId: ['', Validators.required]
    })
  }

  get f() { return this.meetingForm.controls; }

  // @HostListener('input', ['$event'])
  // onKeyDown(event: KeyboardEvent) {
  //   const input = this.ccNumberField.nativeElement;
  //   // const input = event.target as HTMLInputElement;

  //   let trimmed = input.value.replace(/\s+/g, '');
  //   if (trimmed.length > 9) {
  //     trimmed = trimmed.substr(0, 9);
  //   }

  //   let numbers = [];
  //   for (let i = 0; i < trimmed.length; i += 9) {
  //     numbers.push(trimmed.substr(i, 9));
  //   }
  //   input.value = numbers.join(' ');
  // }

  // numericOnly(event): boolean {
  //   let patt = /^([0-9])$/;
  //   let result = patt.test(event.key);

  //   if (result === false) {
  //     this.fault = 'please enter only numbers';
  //   }
  //   return result;
  // }

  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.meetingForm.invalid) {
      return;
    }
    
    var confnumber = this.meetingForm.value.meetingId
    this.onlineMeetingService.conferenceByNumber({ confnumber: confnumber.toString() }).subscribe((data) => {
      if (data) {

        this.success = true;
       

       // var url = "?conftoken=" + data.confid + '%23' + data.mediaServerconnectionId + '%23' + data.mediaServerconnectionId + "%23" + this.conferenceMiddlewareService.getjoinMode(JSON.parse(data.mode).action);

        //this.confTabRef 
        
      } else {
        this.onlineMeetingService.validateConferenceMeetingByNumber(confnumber, this.currentUser.company_id, this.currentUser.username, (message, isSchedule) => {
          if (isSchedule == 1) {
            //no conferece created on singal server but entry is valid in db
            if (message == "success") {
              //create conferecne
              //audio video todo
              this.fault = "";
              //createdby i s leader but still first user will create a conference
             // this.conferenceMiddlewareService.publishData({ action: ConferenceAction.creatVideo, users: [], confnumber: confnumber });
            }
            else {
              this.fault = message;
              this.success = false;
            }
          } else {
            this.fault = 'Invalid conference numbers';
            this.toasterService.error('Invalid conference numbers', 'Error')
            this.success = false;
          }
        });
      }
    });
  }

}
