<h1 mat-dialog-title>{{title}}</h1>
<!--<div mat-dialog-content>
  <p>{{type}}</p>
</div>-->
<div class="mb-3">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>...</th>
        <th>...</th>
        <th>Name</th>
        <th>Joined At</th>
        <th>Duration</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let _calllog of logs | paginate: { itemsPerPage: 8, currentPage: 0, id: 'callList' }; let i = index">
        <td>
          <div class="d-flex align-items-center">
            <mat-icon *ngIf="_calllog.username==currentusename && !_calllog.joinedAt " title="Missed Call">call_missed
            </mat-icon>
            <mat-icon *ngIf="_calllog.username==currentusename && _calllog.joinedAt && _calllog.exitAt "
              title="Received Call">call_received</mat-icon>
            <mat-icon *ngIf="_calllog.username==currentusename && _calllog.joinedAt && !_calllog.exitAt "
              title="On going In Call">call</mat-icon>
            <mat-icon *ngIf="_calllog.createdby==currentusename && !_calllog.joinedAt " title="Out Going Missed Call">
              call_missed_outgoing</mat-icon>
            <mat-icon *ngIf="_calllog.createdby==currentusename && _calllog.joinedAt  && _calllog.exitAt "
              title="Out Going Received Call">call_made</mat-icon>
            <mat-icon *ngIf="_calllog.createdby==currentusename && _calllog.joinedAt  && !_calllog.exitAt "
              title="On going Out Call">call</mat-icon>
            <p class="mb-0 ml-2">{{_calllog.createdAt  | timeAgo}}</p>
          </div>
        </td>
        <td>
          <mat-icon *ngIf="_calllog.participentCount >3 " title="Group Call">group</mat-icon>
          <mat-icon *ngIf="_calllog.participentCount <=3" title="One2 One Call">account_circle</mat-icon>
        </td>
        <td>
          <p class="m-0">
            {{_calllog.username==currentusename?_calllog.createdby==currentusename?"":_calllog.createdby  :_calllog.username }}
          </p>
          <p *ngIf="_calllog.participentCount>3"> & Others..</p>
        </td>
        <td>
          <p *ngIf="_calllog.joinedAt" class="mb-0">{{_calllog.joinedAt  | timeAgo}}</p>
          <p *ngIf="_calllog.joinedAt" class="small mb-0">{{_calllog.createdAt}}</p>
        </td>
        <td>
          <p class="mb-0" *ngIf="_calllog.exitAt">{{getDiration(_calllog.joinedAt,_calllog.exitAt)}}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- <div>
  <pagination-controls (pageChange)="p = $event" id="callList"></pagination-controls>
</div> -->
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onConfirm()">Close</button>
</div>
