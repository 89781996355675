<div class="w-100">
  <div class="p-3 p-lg-4 border-bottom">
    <div class="row align-items-center">
      <div class="col-sm-4 col-8">
        <div class="d-flex align-items-center">
          <div class="d-block d-lg-none me-2 ms-0">
            <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"><i
                class="ri-arrow-left-s-line"></i></a>
          </div>
          <div class="flex-grow-1 overflow-hidden">
            <h5 class="font-size-16 mb-0 text-truncate"><a href="#" class="text-reset user-profile-show">Imported
                message</a> </h5>
          </div>
        </div>
      </div>
      <div class="col-sm-8 col-4">
        <ul class="list-inline user-chat-nav user-chat-action text-end mb-0">
          <li class="list-inline-item d-none d-lg-inline-block">
            <button type="button" class="btn nav-btn" (click)="closeImportantMessage()">
              <i class="ri-close-fill"></i>
              <span class="text">close</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- end chat user head -->

  <!-- start chat conversation -->
  <perfect-scrollbar class="chat-conversation p-3 p-lg-4">
    <ul class="list-unstyled mb-0">
      <li *ngFor="let data of messageList; let i = index" class="left">
        <div class="conversation-list" *ngIf="!data.isToday && !data.isYesterday && !data.date">
          <div class="chat-avatar">
            <img src="{{data.user.profilePic}}" alt="" *ngIf="data.user.profilePic">
            <div *ngIf="data.user.profilePic === null" class="ms-0 avatar-xs flex-shrink-0">
              <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                {{data.user?.firstname | firstCharacter}}
              </span>
            </div>
          </div>

          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                <p class="mb-0">
                  {{data.message}}
                </p>

                <ul class="list-inline message-img mb-0" *ngIf="data.messageType === 'image'">
                  <li class="list-inline-item message-img-list">
                    <div>
                      <a class="popup-img d-inline-block m-1" href="javascript: void(0);" title="Project 1">
                        <img :src="{{fileUrl(data.s3FileName)}}" alt="" class="rounded border" lightbox
                          backgroundOpacity="0.8" [counter]="true" [hideThumbnail]="false" counterSeparator=" of ">
                      </a>
                    </div>
                    <div class="message-img-link">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a href="#">
                            <i class="ri-download-2-line"></i>
                          </a>
                        </li>
                        <li class="list-inline-item dropdown" ngbDropdown>
                          <a class="dropdown-toggle" href="#" role="button" ngbDropdownToggle data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="ri-more-fill"></i>
                          </a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="#">{{'chat.messages.dropdown.copy' | translate}} <i
                                class="ri-file-copy-line float-end text-muted"></i></a>
                            <a class="dropdown-item" href="#">{{'chat.messages.dropdown.save' | translate}} <i
                                class="ri-save-line float-end text-muted"></i></a>
                            <a class="dropdown-item" href="#">{{'chat.messages.dropdown.forward' | translate}} <i
                                class="ri-chat-forward-line float-end text-muted"></i></a>
                            <a class="dropdown-item" href="#">{{'chat.messages.dropdown.delete' | translate}} <i
                                class="ri-delete-bin-line float-end text-muted"></i></a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>

                <div class="card p-2 mb-2" *ngIf="data.messageType === 'file'">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm me-3 ms-0">
                      <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                        <i class="ri-file-text-fill"></i>
                      </div>
                    </div>
                    <div class="d-flex-body">
                      <div class="text-start">
                        <h5 class="font-size-14 mb-1">{{data.userFileName}}</h5>
                        <!-- <p class="text-muted font-size-13 mb-0">{{data.fileSize}}</p> -->
                      </div>
                    </div>

                    <div class="ms-4">
                      <ul class="list-inline mb-0 font-size-20">
                        <li class="list-inline-item">
                          <a [href]="fileUrl(data?.s3FileName)"
                          download="{{data.messageStatus?.userFileName}}">
                            <i class="ri-download-2-line"></i>
                          </a>
                        </li>
                        <!-- <li class="list-inline-item dropdown" ngbDropdown>
                          <a class="dropdown-toggle text-muted" href="#" ngbDropdownToggle role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ri-more-fill"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a class="dropdown-item" href="#">{{'chat.messages.filedropdown.share' | translate}}
                              <i class="ri-share-line float-end text-muted"></i></a>
                            <a class="dropdown-item" href="#">{{'chat.messages.filedropdown.delete' | translate}}
                              <i class="ri-delete-bin-line float-end text-muted"></i></a>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>

                <p class="chat-time mb-0">
                  <i class="ri-time-line align-middle"></i>
                  <span class="align-middle">{{data.updatedAt | date: 'shortTime': 'UTC'}}</span>
                </p>

              </div>

              <div class="dropdown align-self-start" ngbDropdown>
                <a class="dropdown-toggle" role="button" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);" (click)="removeBookmark(data, i)">Remove Bookmark
                    <i class="ri-file-copy-line float-end text-muted"></i></a>
                </div>
              </div>
            </div>

            <div _ngcontent-fgg-c56="" class="conversation-name">
              {{data.user?.firstname}} {{data.user?.lastname}}
            </div>

          </div>
        </div>

        <div class="chat-day-title" *ngIf="data.isToday">
          <span class="title">Today</span>
        </div>
      </li>
    </ul>
  </perfect-scrollbar>
  <!-- end chat conversation end -->

  <!-- start chat input section -->
  <div class="p-3 p-lg-4 border-top mb-0">
    <div class="row no-gutters">
      <div class="col">
        <div>
          <input type="text" class="form-control form-control-lg bg-light border-light" placeholder="Enter Message...">
        </div>
      </div>
      <div class="col-auto">
        <div class="chat-input-links ms-md-2">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                data-toggle="tooltip" data-placement="top" ngbTooltip="Emoji">
                <i class="ri-emotion-happy-line"></i>
              </button>
            </li>
            <li class="list-inline-item">
              <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                data-toggle="tooltip" data-placement="top" ngbTooltip="Attached File">
                <i class="ri-attachment-line"></i>
              </button>
            </li>
            <li class="list-inline-item">
              <button type="submit" class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                <i class="ri-send-plane-2-fill"></i>
              </button>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
  <!-- end chat input section -->
</div>