import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ToasterService } from 'src/app/core/services/toaster.service';
import { Contacts } from '../../contacts/contacts.model';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/core/services/token.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { FileService } from 'src/app/core/services/comman/file.service';
import { debounceTime, Observable, Subject } from 'rxjs';
import { FileElement } from 'src/app/core/services/comman/file.model';
import { AccessType, DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObservableService } from 'src/app/core/services/observable.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})

export class FilesComponent implements OnInit {

  createFolderForm: FormGroup;
  createFileForm: FormGroup;
  currentUser: any;
  rootFolderId: any;
  currentFolderId: any;
  public fileElements: Observable<FileElement[]>;
  clickOutSide = new EventEmitter<any>();
  currentRoot: FileElement;
  selectFileElement: FileElement = null;
  clearSelection = new EventEmitter<any>();
  clickfavouriteElement = new EventEmitter<any>();
  cliboard: FileElement = null;
  cliboardIsCut: boolean = false;

  isUnderSystemDirectory = false;
  isInRootDirectory = true;
  canNavigateUp = false;
  currentPath: string = "";
  fileOpened: boolean = false;
  public blob: any;
  public blobUrl: string;
  public blobfileContent: any;
  public blobimageUrl: any;
  public urlsrc: string;

  access_token: any;
  access_token_ttl: any;
  public favicon: string;

  MSfileOpened: boolean = false;
  isPdf: boolean = false;
  isImage: boolean = false;
  file_name = "";

  rootFolder: any = {};
  recentActivities = [];
  recentActivitiesFiles = [];
  recentDeletedFiles = [];
  favouriteFiles = [];

  file: any;
  searchText: any;

  public showOption = {
    refresh: true,
    uploadFile: true,
    createNewFolder: true,
    createNewFile: true,
    delete: true,
    restore: false,
    paste: true
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private serverDocumentService: ServerDocumentService,
    private documentService: DocumentService,
    private tokenService: TokenStorageService,
    private fileService: FileService,
    private documentUtils: DocumentUtilsService,
    private _sanitizer: DomSanitizer,
    private obService: ObservableService,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();
    this.getRootFolder();
    this.obService.fileEleObs.subscribe((value: FileElement) => {
      this.showFile(value);
    })

    this.obService.docsSidebarTab.subscribe((value: any) => {
      if (value.tab === 'all' || value.tab === 'file' || value.tab === 'folder') {
        this.showOption = {
          refresh: true,
          uploadFile: true,
          createNewFolder: true,
          createNewFile: true,
          delete: true,
          restore: false,
          paste: true
        }
      } else if (value.tab === 'favorite') {
        this.showOption = {
          refresh: true,
          uploadFile: false,
          createNewFolder: false,
          createNewFile: false,
          delete: true,
          restore: false,
          paste: false
        }
      } else if (value.tab === "recent delete") {
        this.showOption = {
          refresh: true,
          uploadFile: false,
          createNewFolder: false,
          createNewFile: false,
          delete: true,
          restore: true,
          paste: false
        }
      }
    });
  }

  async addFileModal(addFile: any) {
    await this.modalService.open(addFile, { centered: true, modalDialogClass: 'add-file-modal' });
  }

  get createFolderF() { return this.createFolderForm.controls; }
  get createFileF() { return this.createFileForm.controls; }

  getRootFolder() {
    this.serverDocumentService.getRootFolder(this.currentUser.companyId)
      .subscribe((res) => {
        if (res) {
          if (res.iscreated) {
            this.documentService.createDocumentsAccess({
              fileId: res.rootid,
              isFolder: false,
              username: 'everyone',
              companyId: this.currentUser.companyId,
              canRead: true,
              canWrite: true,
              canDelete: true,
              canDownload: true,
              canChangePermission: true,
            }).subscribe();
          }

          this.rootFolderId = res.rootid;
          this.currentFolderId = res.rootid;
          this.constructFolderStructure(this.rootFolderId, true);
        }
      })
  }

  constructFolderStructure(id, isRoot) {
    this.getItems(id, items => {
      items.forEach((x) => {

        this.fileService.add({
          id: x.item.Identifier, name: x.item.Name, isFolder: x.type == 'folder' ? true : false, parent: isRoot ? 'root' : id
        });
      });
      this.updateFileElementQuery();
    });
  }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  getItems(id, callback) {
    var items = [];

    this.serverDocumentService.getWopiFolders(id).subscribe(folders => {
      folders.forEach((x) => {
        items.push({ type: "folder", item: x });
      });

      this.serverDocumentService.getWopiFiles(id).subscribe(files => {
        //  this.files = files;

        files.forEach((x) => {
          items.push({ type: "file", item: x });
        });

        callback(items);
      });
    });
  }

  async createFolderModal(createFolder: any) {
    this.selectFileElement = null;
    this.documentService.getDocumentAccessByFileId(this.currentFolderId).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanWrite, async () => {
        await this.modalService.open(createFolder, { centered: true, modalDialogClass: 'create-folder-modal' });
        this.createFolderForm = this.formBuilder.group({
          name: ['', [Validators.required]],
        });
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    });
  }

  createFolderSubmit() {
    if (this.createFolderForm.invalid) {
      return;
    }

    this.selectFileElement = null;
    this.clearSelection.next({});

    this.serverDocumentService.createFolder({
      currentId: this.currentFolderId,
      name: this.createFolderForm.value.name
    }).subscribe(data => {
      this.documentService.createDocumentLog({
        name: this.createFolderForm.value.name,
        isFolder: true, fileId: data.identifier, companyId: this.currentUser.companyId,
        action: "created", actionParam: "", createdBy: this.currentUser.username
      }).subscribe();

      this.documentService.createDocumentsAccess([{
        fileId: data.identifier, isFolder: false, username: "everyone",
        companyId: this.currentUser.companyId, canRead: true, canWrite: false, canDelete: false, canDownload: false, canChangePermission: false
      }, {
        fileId: data.identifier, isFolder: false, username: this.currentUser.username,
        companyId: this.currentUser.companyId, canRead: true, canWrite: true, canDelete: true, canDownload: true, canChangePermission: true
      }]).subscribe();

      this.fileService.add({ id: data.identifier, isFolder: true, name: this.createFolderForm.value.name, parent: this.currentRoot ? this.currentRoot.id : 'root' });
      this.updateFileElementQuery();
    });
  }

  createFileModal(createFile: any) {
    this.selectFileElement = null;
    this.clearSelection.next({});

    this.documentService.getDocumentAccessByFileId(this.currentFolderId).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanWrite, async () => {
        await this.modalService.open(createFile, { centered: true, modalDialogClass: 'create-file-modal' });
        this.createFileForm = this.formBuilder.group({
          name: ['', [Validators.required]],
          extension: ['docx', [Validators.required]],
        });
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    })
  }

  createFileSubmit() {
    if (this.createFileForm.invalid) {
      return;
    }

    let fileName = this.createFileForm.value.name;
    this.serverDocumentService.createFile({
      currentId: this.currentFolderId,
      name: fileName,
      ext: this.createFileForm.value.extension
    }).subscribe((data) => {
      this.documentService.createDocumentLog({
        name: fileName,
        isFolder: false, fileId: data.identifier, companyId: this.currentUser.companyId,
        action: "created", actionParam: "", createdBy: this.currentUser.username
      }).subscribe();

      this.documentService.createDocumentsAccess([{
        fileId: data.identifier, isFolder: false, username: "everyone",
        companyId: this.currentUser.companyId, canRead: true, canWrite: false, canDelete: false, canDownload: false, canChangePermission: false
      }, {
        fileId: data.identifier, isFolder: false, username: this.currentUser.username,
        companyId: this.currentUser.companyId, canRead: true, canWrite: true, canDelete: true, canDownload: true, canChangePermission: true
      }]).subscribe();
    });
  }

  async propertiesModal(createproProperties: any) {
    await this.modalService.open(createproProperties, { centered: true, modalDialogClass: 'create-properties-modal' });
  }

  navigateUp() {
    this.selectFileElement = null;
    this.clearSelection.next({});
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.isUnderSystemDirectory = false;
      this.isInRootDirectory = true;
      this.currentRoot = null;
      this.currentFolderId = this.rootFolderId;
      this.canNavigateUp = false;
      this.constructFolderStructure(this.rootFolderId, true);
    } else {
      this.isInRootDirectory = false;
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.currentFolderId = this.currentRoot.id;
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  navigateToFolder(element: FileElement) {
    this.selectFileElement = null;
    this.clearSelection.next({});

    this.documentService.getDocumentAccessByFileId(element.id).subscribe(accessList => {
      this.documentUtils.checkAccess(accessList.data, this.currentUser.username, AccessType.CanRead, () => {

        if (element.isFolder) {
          this.isInRootDirectory = false;
          this.isUnderSystemDirectory = false;

          this.currentRoot = element;
          this.currentFolderId = element.id;
          this.updateFileElementQuery();
          this.currentPath = this.pushToPath(this.currentPath, element.name);
          this.canNavigateUp = true;

          this.constructFolderStructure(element.id, false);
        }
        else {
          this.fileOpened = true;
          this.showFile(element);
        }
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    });
  }

  showFileFromRecent(file: any) {
    if (file.action == "deleted") {
      // this.toasterService.error('Cannot open deleted File', 'Error')
      this.obService._success.next('Cannot open deleted File');
      return;
    }
    if (file.isFolder) {
      // this.toasterService.error('Cannot open Folder', 'Error')
      this.obService._success.next('Cannot open Folder');
      return;
    }
    var fileEle = { id: file.fileId, isFolder: file.isfolder, name: file.fileName, parent: '' };
    this.showFile(fileEle);
  }

  navigateBackFromFile() {
    this.fileOpened = false;
    this.MSfileOpened = false;
    var frameholder = document.getElementById('frameholder');
    var ifr = document.getElementById('office_frame');
    if (ifr)
      frameholder.removeChild(ifr);
  }

  showFile(file: FileElement) {
    this.selectFileElement = null;
    this.clearSelection.next({});

    this.router.navigate(['/pages/file-preview']);
    setTimeout(() => {
      this.obService.previewFile.next({
        file: file,
        fileOpened: this.fileOpened
      });
    }, 500);

    // this.blobfileContent = "loading..";
    // this.urlsrc = "loading..";
    // this.file_name = file.name;

    // var _fileSplit = file.name.split('.');
    // var ext = _fileSplit.length == 1 ? "" : _fileSplit[_fileSplit.length - 1];

    // ext = ext.toLowerCase();
    // if (!this.documentUtils.msOfficeExts.find(x => x == ext)) {
    //   this.isPdf = false;
    //   this.isImage = false;
    //   if (ext == "pdf") {
    //     this.isPdf = true;
    //   } else if (this.documentUtils.imageExts.find(x => x == ext)) {
    //     this.isImage = true;
    //   }

    //   this.fileOpened = false;
    //   this.serverDocumentService.getFile(file.id).subscribe(blob => {
    //     this.blob = blob;
    //     this.MSfileOpened = false;
    //     this.fileOpened = true;
    //     // return this._sanitizer.bypassSecurityTrustUrl(this.blol_url);
    //     this.blobUrl = URL.createObjectURL(this.blob);
    //     if (this.isPdf)
    //       this.blob = null;
    //     else if (this.isImage) {
    //       var safeUrl = this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.blob));
    //       //unsafe:blob:http://localhost:4200/3f41bdcb-5c26-47ac-bf8b-3b960fd5eae6
    //       this.blob = null;
    //       this.blobimageUrl = safeUrl["changingThisBreaksApplicationSecurity"];
    //       //this.blobimageUrl = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl);
    //     } else {
    //       let fileReader: FileReader = new FileReader();
    //       let _self = this;
    //       fileReader.onloadend = function (x) {
    //         (_self as any).blob = null;
    //         (_self as any).blobfileContent = fileReader.result;
    //       }
    //       if (this.blob)
    //         fileReader.readAsText(this.blob);
    //       this.blob = null;

    //       return true;
    //     }
    //   });
    // } else {
    //   this.MSfileOpened = true;
    //   this.fileOpened = true;
    //   this.serverDocumentService.getWopiDetail(file.id, this.currentUser.username, this.currentUser.email).subscribe(fileinfo => {
    //     //AccessToken, Urlsrc,Favicon
    //     this.urlsrc = fileinfo.Urlsrc;
    //     this.access_token = fileinfo.AccessToken;
    //     this.favicon = fileinfo.Favicon;
    //     this.access_token_ttl = "60";

    //     setTimeout((x) => {
    //       var frameholder = document.getElementById('frameholder');

    //       var ifr = document.getElementById('office_frame');
    //       if (ifr)
    //         frameholder.removeChild(ifr);


    //       var office_frame = document.createElement('iframe');

    //       office_frame.name = 'office_frame';
    //       office_frame.id = 'office_frame';
    //       // The title should be set for accessibility
    //       office_frame.title = 'Office Online Frame';
    //       // This attribute allows true fullscreen mode in slideshow view
    //       // when using PowerPoint Online's 'view' action.
    //       office_frame.setAttribute('allowfullscreen', 'true');

    //       frameholder.appendChild(office_frame);
    //       // document.getElementById('office_form').submit();
    //       (document.getElementById('office_form') as HTMLFormElement).submit();
    //       setTimeout((x) => {
    //         document.querySelector('#office_frame').className = "office_frame";
    //       }, 200);
    //     }, 1000);
    //   });
    // }
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  closeCreateFileModal() {
    // this.toasterService.success('File created.', 'Success')
    this.obService._success.next('File created.');
    this.modalService.dismissAll();
  }

  getFullFileName(name: string) {
    if (!name)
      return "error";
    var nameHash = name.split('#');
    if (nameHash.length > 1)
      return nameHash[1];
    else
      return nameHash[0];
  }

  onclickOutSide() {
    this.clickOutSide.next({});
  }

  removeElement(element: FileElement) {
    // this.dialogService.alertDialog("Delete", "Do you wanted to delete this item?", true, (x) => {
    // if (x == true) {
    this.selectFileElement = null;
    this.clearSelection.next({});

    this.documentService.getDocumentAccessByFileId(element.id).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanDelete, async () => {
        this.serverDocumentService.moveItemToTrash({
          isFolder: element.isFolder,
          id: element.id
        }).subscribe(data => {
          this.documentService.deleteDocumentShared(element.id).subscribe();
          this.fileService.delete(element.id);

          this.updateFileElementQuery();
          console.log('data.binpath', data.binpath)
          this.documentService.createDocumentLog({
            name: element.name,
            isFolder: element.isFolder, fileId: element.id, companyId: this.currentUser.companyId,
            action: "deleted", actionParam: data.binpath, createdBy: this.currentUser.username
          }).subscribe();
          this.refreshed();
        });
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    });
    // }
    // });
  }

  downloadElement(element: FileElement) {
    this.documentService.getDocumentAccessByFileId(this.currentFolderId).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanDownload, async () => {

        this.documentService.createDocumentLog({
          name: element.name,
          isFolder: element.isFolder, fileId: element.id, companyId: this.currentUser.companyId,
          action: "downloaded", actionParam: "", createdBy: this.currentUser.username
        }).subscribe();

        this.serverDocumentService.getFile(element.id).subscribe(blob => {
          this.blob = blob;
          var blobUrl = URL.createObjectURL(this.blob);

          var a = document.createElement('a');// $("<a style='display: none;'/>");
          // var url = window.URL.createObjectURL(new Blob([data], { type: type }));
          a.href = blobUrl;
          a.download = element.name;
          document.getElementsByTagName("body")[0].appendChild(a);
          a.click();
          window.URL.revokeObjectURL(blobUrl);
          a.remove();

        });

      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    });

  }

  renameElement(element: FileElement) {
    this.documentService.getDocumentAccessByFileId(this.currentFolderId).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanWrite, async () => {
        if (element.isFolder)
          this.serverDocumentService.renameItem({
            isFolder: element.isFolder,
            fromId: element.id,
            newName: element.name
          })
            .subscribe(data => {
              var oldid = element.id;

              this.documentService.deleteDocumentShared({ fileId: oldid }).subscribe();
              this.fileService.delete(element.id);
              this.fileService.add({ id: data.identifier, isFolder: element.isFolder, name: element.name, parent: element.parent });

              this.updateFileElementQuery();
              this.documentService.updateDocumentAccess({
                fileId: oldid, newFileId: data.identifier
              }).subscribe();

              this.documentService.createDocumentLog({
                name: element.name,
                isFolder: element.isFolder, fileId: data.identifier, companyId: this.currentUser.companyId,
                action: "renamed", actionParam: "", createdBy: this.currentUser.username
              }).subscribe();
            });
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');

      });
    });
  }

  moveElement(event: { element: FileElement; moveTo: FileElement }) {

    // this.documentApi.getAllDocumentAcessByFileId(event.element.id).subscribe(accesslist => {
    //   this.documentUtil.checkAccess(accesslist.data, this.currentUser.username, AccessType.CanDelete, () => {

    //     this.documentApi.getAllDocumentAcessByFileId(event.moveTo.id).subscribe(accesslist2 => {
    //       this.documentUtil.checkAccess(accesslist2, this.currentUser.username, AccessType.CanWrite, () => {

    //         this._processingStart();
    //         this.fileService.moveItem(event.element.isFolder, event.element.id, event.moveTo.id).subscribe(data => {

    //           this.documentApi.deleteDocumentShared({ fileId: event.element.id }).subscribe();

    //           this.documentApi.createDocumentsLog({
    //             name: moveTo.name,
    //             isfolder: event.element.isFolder, fileId: event.element.id, companyId: this.currentUser.company_id,
    //             action: "moved", actionparam: event.moveTo.id, createdBy: this.currentUser.username
    //           }).subscribe();


    //           // this.fileService.update(event.element.id, { parent: event.moveTo.id });

    //           this.documentApi.renameitem_recreatdocumentaccess({
    //             fileId: data.oldid, newFileId: data.newid
    //           }).subscribe();

    //           this.fileService.delete(event.element.id);
    //           this.updateFileElementQuery();
    //           this._processingCompleted();

    //           this.selectFileElement = null;
    //           this.clearSelection.next({});

    //         });
    //       }, () => {

    //         this.dialogService.error("Permission denied");
    //       });
    //     });

    //   }, () => {

    //     this.dialogService.error("error" + "Permission denied");
    //   });
    // });
  }

  elementCut(element: FileElement) {
    this.documentService.getDocumentAccessByFileId(element.id).subscribe(async accesslist => {
      await this.documentUtils.checkAccess(accesslist.data, this.currentUser.username, AccessType.CanDelete, () => {
        this.cliboardIsCut = true;
        this.cliboard = element;
      }, () => {
        // this.toasterService.error("Permission denied", "Error");
        this.obService._success.next('Permission denied');
      });
    });
  }

  elementCopied(element: FileElement) {
    this.documentService.getDocumentAccessByFileId(element.id).subscribe(async accesslist => {
      await this.documentUtils.checkAccess(accesslist.data, this.currentUser.username, AccessType.CanWrite, () => {
        this.cliboardIsCut = false;
        this.cliboard = element;
      }, () => {
        // this.toasterService.error("Permission denied", "Error");
        this.obService._success.next('Permission denied');
      });
    });
  }

  elementPaste() {
    this.selectFileElement = null;
    this.clearSelection.next({});
    this.documentService.getDocumentAccessByFileId(this.cliboard.id).subscribe(async accesslist => {
      await this.documentUtils.checkAccess(accesslist.data, this.currentUser.username, AccessType.CanWrite, () => {
        if (this.cliboardIsCut) {
          this.serverDocumentService.moveItem({
            isFolder: this.cliboard.isFolder,
            fromId: this.cliboard.id,
            toId: this.currentFolderId
          }).subscribe(data => {
            this.documentService.deleteDocumentShared({ fileId: this.cliboard.id }).subscribe();

            this.documentService.updateDocumentAccess({
              fileId: data.oldid, newFileId: data.newid
            }).subscribe();

            this.fileService.delete(this.cliboard.id);

            this.constructFolderStructure(this.currentFolderId, this.currentFolderId == this.rootFolderId);

            this.documentService.createDocumentLog({
              name: this.cliboard.name,
              isFolder: this.cliboard.isFolder, fileId: this.cliboard.id, companyId: this.currentUser.companyId,
              action: "cut-paste", actionParam: "", createdBy: this.currentUser.username
            }).subscribe();
            this.cliboard = null;


          });
        } else {//copy
          this.serverDocumentService.copyItem({
            isFolder: this.cliboard.isFolder,
            fromId: this.cliboard.id,
            toId: this.currentFolderId
          }).subscribe(data => {
            // this.fileService.update(event.element.id, { parent: event.moveTo.id });
            //this.fileService.delete(this.cliboard.id);
            this.constructFolderStructure(this.currentFolderId, this.currentFolderId == this.rootFolderId);

            this.documentService.updateDocumentAccess({
              fileId: data.oldid, newFileId: data.newid
            }).subscribe();

            this.documentService.createDocumentLog({
              name: this.cliboard.name,
              isFolder: this.cliboard.isFolder, fileId: this.cliboard.id, companyId: this.currentUser.companyId,
              action: "cut-paste", actionParam: "", createdBy: this.currentUser.username
            }).subscribe();
            this.cliboard = null;

          });
        }
      }, () => {
        // this.toasterService.error("Permission denied", "Error");
        this.obService._success.next('Permission denied');
      });
    });
  }

  refreshed() {
    this.selectFileElement = null;
    this.updateFolder();
    this.clearSelection.next({});
  }

  selectFileElementEmitter(element: FileElement) {
    this.selectFileElement = element;
  }

  openUploadDialog(html) {
    this.selectFileElement = null;
    this.clearSelection.next({});

    this.documentService.getDocumentAccessByFileId(this.currentFolderId).subscribe(async (res) => {
      await this.documentUtils.checkAccess(res.data, this.currentUser.username, AccessType.CanWrite, async () => {
        await this.modalService.open(html, { centered: true, modalDialogClass: 'create-file-modal' });
      }, () => {
        // this.toasterService.error('Permission denied', 'Error')
        this.obService._success.next('Permission denied');
      });
    });
  }

  getitems(id, callback) {
    var items = [];
    this.serverDocumentService.getWopiFolders(id).subscribe(folders => {
      folders.forEach((x) => {
        items.push({ type: "folder", item: x });
      });

      this.serverDocumentService.getWopiFiles(id).subscribe(files => {
        //  this.files = files;

        files.forEach((x) => {
          items.push({ type: "file", item: x });
        });

        callback(items);
      });
    });
  }

  getrecetFiles() {
    this.documentService.getRecentDocument({
      limit: 10,
      companyId: this.currentUser.companyId,
      action: "created,renamed,deleted,restored"
    }).subscribe(log => {
      this.recentActivities = log.data;
      this.recentActivities.forEach(x => {
        this.documentUtils.cache_decodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });

      this.recentActivities.filter(x => !x.isFolder).forEach(x => {
        var deletedEntry = this.recentActivities.find(ra => ra.fileId == x.fileId && (ra.action == 'deleted' || ra.action == 'copy-paste' || ra.action == 'moved'));
        if (!deletedEntry)
          if (!this.recentActivitiesFiles.find(r => r.fileId == x.fileId))
            this.recentActivitiesFiles.push(x);
      });
    });

    this.documentService.getRecentDocument({
      limit: 10,
      companyId: this.currentUser.companyId,
      action: "deleted"
    }).subscribe(log => {
      this.recentDeletedFiles = log.data;
      this.recentDeletedFiles.forEach(x => {
        this.documentUtils.cache_decodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });
    });


    this.documentService.getAllFavoritesFilesByCompanyId({
      favoriteBy: this.currentUser.username,
      companyId: this.currentUser.companyId
    }).subscribe(log => {
      log = log.data;

      this.favouriteFiles = log;
      this.favouriteFiles.forEach(x => {

        this.documentUtils.cacheDecodeIdentifier(x.fileId, this.serverDocumentService.decodeIdentifier(x.fileId))
          .subscribe(res => {
            x.fileName = this.documentUtils.getNameOfHashedPath(this.currentUser.companyId, res.path);
          });
      });
    });
  }

  updateFolder() {
    this.selectFileElement = null;
    this.clearSelection.next({});
    this.constructFolderStructure(this.currentFolderId, this.currentPath == "" ? true : false);
    this.getrecetFiles();
  }

  uploadFile(file: { id: string, name: string }) {
    this.selectFileElement = null;
    this.clearSelection.next({});
    this.documentService.createDocumentLog({
      name: file.name,
      isFolder: false, fileId: file.id, companyId: this.currentUser.companyId,
      action: "created", actionParam: "", createdBy: this.currentUser.username
    }).subscribe();

    this.documentService.createDocumentsAccess([{
      fileId: file.id, isfolder: false, username: "everyone",
      companyId: this.currentUser.companyId, canRead: true, canWrite: false, canDelete: false, canDownload: false, canChangePermission: false
    },
    {
      fileId: file.id, isfolder: false, username: this.currentUser.username,
      companyId: this.currentUser.companyId, canRead: true, canWrite: true, canDelete: true, canDownload: true, canChangePermission: true
    }
    ]).subscribe();
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }

  onFileUpload() {
    let files: FileToUpload = new FileToUpload();

    files.id = this.currentFolderId;
    files.fileName = this.file.name;
    files.fileSize = this.file.size;
    files.fileType = this.file.type;
    files.lastModifiedTime = this.file.lastModified;
    files.lastModifiedDate = this.file.lastModifiedDate;

    let reader = new FileReader();
    reader.onload = () => {
      files.fileAsBase64 = reader.result.toString();

      this.serverDocumentService.uploadFile(files)
        .subscribe(resp => {
          this.updateFolder();
          this.uploadFile({ id: resp.identifier, name: files.fileName });
        });
    }
    reader.readAsDataURL(this.file);
  }

  onSearch() {
    var value = this.searchText;
    this.selectFileElement = null;
    this.clearSelection.next({});

    if (value) {
      this.fileService.clear();
      this.serverDocumentService.searchItem({ searchString: value, companyId: this.currentUser.companyId }).subscribe(data => {
        data.forEach(x => {
          this.fileService.add({
            id: x.Identifier, name: x.Name, isFolder: false, parent: 'root'
          });
        });
        this.updateFileElementQuery();
      });
    } else {
      this.fileService.clear();
      this.updateFolder();
    }
  }

  restoreFile() {

    if (this.selectFileElement) {

      let temp: any = this.selectFileElement;
      // console.log(temp)
      this.serverDocumentService.restoreitem({
        isFolder: this.selectFileElement.isFolder,
        originalFileId: temp.fileId,
        binPath: temp.actionParam,
      }).subscribe(() => {
        this.documentService.updateDocumentLog({
          action: 'restored',
          fileId: temp.fileId,
          actionParam: temp.actionParam,
          id: temp.id
        }).subscribe(() => {
          this.refreshed();
        });
      });
    }
  }
}

class FileToUpload {
  id: string = "";
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  lastModifiedTime: number = 0;
  lastModifiedDate: Date = null;
  fileAsBase64: string = "";
}
