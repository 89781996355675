<perfect-scrollbar class="client-activity-leftsidebar me-lg-1">
  <div class="client-activity-inner">
    <div class="activity-list-wrap pb-3 p-4">
      <h4 class="mb-4">Activity</h4>
      <ul>
        <li>
          <a class="activity-list-btn" [ngClass]="{'active': selectedTab === 'all-post'}" href="javascript:void(0);"
            (click)="changeUserPostTab('all-post')">
            <span>
              <img src="../../../assets/images/my-post-icon.svg" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">All Posts</h5>
          </a>
        </li>
        <li>
          <a class="activity-list-btn" [ngClass]="{'active': selectedTab === 'user-post'}" href="javascript:void(0);"
            (click)="changeUserPostTab('user-post')">
            <span>
              <img src="../../../assets/images/all-post-icon.svg" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">My Posts</h5>
          </a>
        </li>
        <li>
          <a class="activity-list-btn" href="javascript:void(0);" data-toggle="modal"
            data-target="#addgroup-exampleModal" (click)="openCreateTeamModal(content)">
            <span>
              <img src="../../../assets/images/create-event-icon.svg" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">Create Event</h5>
          </a>
        </li>
        <li>
          <a class="activity-list-btn" href="javascript:void(0);" [ngClass]="{'active': selectedTab === 'my-post'}"
            (click)="changeUserPostTab('my-post')">
            <span>
              <img src="../../../assets/images/collection-icon.png" alt="" />
            </span>
            <h5 class="text-truncate font-size-14 mb-0">My Collection</h5>
          </a>
        </li>
      </ul>
    </div>

    <div class="meetings-list-main">
      <div class="meetings-list-wrap p-4 pb-0">
        <h4 class="mb-4">Your Meetings</h4>
        <perfect-scrollbar>
          <ul>
            <div *ngIf="upcomingMeeting?.length > 0;">
              <li *ngFor="let meeting of upcomingMeeting">
                <button class="meetings-list-btn" value="" type="button" (click)="showMeetingInfo(meeting)">
                  Meeting on {{meeting.topic}} at {{meeting.startDate | date: 'mediumDate': 'UTC'}} {{meeting.startTime}}
                </button>
              </li>
            </div>

            <div *ngIf="upcomingMeeting?.length <= 0;">
              <span>No upcoming meeting</span>
            </div>

          </ul>
        </perfect-scrollbar>
      </div>
    </div>

  </div>

  <!-- Start add group Modal -->
  <ng-template #content let-modal>
    <div class="creat-event-modal-row">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-size-16" id=""><span><i class="ri-calendar-2-line"></i></span> Create an event
          </h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
        </div>
        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="modal-body p-3">
            <div class="">
              <div class="form-group mb-3">
                <label class="form-label">Event name</label>
                <div class="form-input-group">
                  <input type="text" formControlName="eventName" placeholder="Enter the event name"
                    class="form-control" />
                  <div *ngIf="submitted && f.eventName.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Event name is required.</div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">Start date</label>
                    <div class="form-input-group">
                      <input type="date" formControlName="startDate" id="txtDate"
                        class="form-control date custom-form-styles date-icon" min="2022-06-16" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Start time</label>
                    <div class="form-input-group">
                      <input type="time" formControlName="startTime"
                        class="form-control custom-form-styles date date-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">End date</label>
                    <div class="form-input-group">
                      <input type="date" formControlName="endDate" id="to"
                        class="form-control date custom-form-styles date-icon" />
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label">End time</label>
                    <div class="form-input-group">
                      <input type="time" formControlName="endTime"
                        class="form-control custom-form-styles date date-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Category</label>
                <select formControlName="category" class="form-control custom-form-styles category custom-select">
                  <option value="Arts &amp; Marketing">Arts &amp; Marketing</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Business Services">Business Services</option>
                  <option value="Event Planning">Event Planning</option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Lifestyle Services">Lifestyle Services</option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label">Description</label>
                <textarea formControlName="description" placeholder="Description" class="form-control"></textarea>
              </div>
              <div class="login-btn-group d-flex justify-content-end">
                <button class="btn btn btn-primary btn-create-event">Create</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <!-- End add group Modal -->
</perfect-scrollbar>