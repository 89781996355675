import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(private http: HttpClient) { }

    getUserListWithLastMessage(data) {
        let params = new HttpParams().set("limit", data.limit);
        params = params.set('offset', data.offset);
        params = params.set('receiverId', data.receiverId)

        return this.http.get<any>(`@api-nest/user/user-list-with-last-message`, { params: params }).pipe(retry(0), catchError(this.handleError));
    }

    getUserList() {
        return this.http.get<any>(`@api-nest/user/user-list`).pipe(retry(0), catchError(this.handleError));
    }

    searchUser(keyword) {
        let params = new HttpParams().set("keyword", keyword);
        return this.http.get<any>(`@api-nest/user/search-user`, { params: params }).pipe(retry(0), catchError(this.handleError));
    }

    getOnlineUser() {
        return this.http.get<any>(`@api-nest/user/online-user-list`).pipe(retry(0), catchError(this.handleError));
    }

    getUserDetails(id) {
        let params = new HttpParams().set("id", id);
        return this.http.get<any>(`@api-nest/user/get-user-by-id`, { params: params }).pipe(retry(0), catchError(this.handleError));
    }

    updateProfile(data) {
        return this.http.put<any>(`@api-nest/user/update-profile`, data).pipe(retry(0), catchError(this.handleError));
    }

    uploadProfilePic(data) {
        return this.http.put<any>(`@api-nest/user/update-profile-pic`, data).pipe(retry(0), catchError(this.handleError));
    }

    changeUserOnlineStatus(data) {
        return this.http.put<any>(`@api-nest/user/update-online-status`, data).pipe(retry(0), catchError(this.handleError));
    }

    inviteUser(data) {
        return this.http.post<any>(`@api-nest/user/invite-user`, data).pipe(retry(0), catchError(this.handleError));
    }
    
    inviteContact(data) {
        return this.http.post<any>(`@api-nest/user/invite-contact`, data).pipe(retry(0), catchError(this.handleError));
    }

    getCompanyDataBasedOnInvitedUserToken(token) {
        let params = new HttpParams().set("token", token);
        return this.http.get<any>(`@api-nest/user/company-details-by-user-invited-token`, { params: params }).pipe(retry(0), catchError(this.handleError));
    }
    getUserbyusername(username, cache= true){
        return this.http.get<any>(`@api-nest/user/get-user-by-username`, username).pipe(retry(1), catchError(this.handleError));    
    }

    handleError(error: any) {
        console.log(error)
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }
}