<h1 mat-dialog-title>

</h1>

<div mat-dialog-content>
  <div class="text-center">
    <h3 class="whitetext">{{message}}</h3>



  </div>
</div>

<div mat-dialog-actions>
  <div class="button-center ">
    <!--<button mat-button (click)="onDismiss()" class="reject">Reject</button>
  <button mat-raised-button color="primary" (click)="onConfirm()" class="accept">Accept</button>-->
    <div class="row">

      <div class="col-6"> 
          <input type="button" (click)="onConfirm()"  value="Confirm" />
        <!--<img src="../../../../../assets/icons/PNG/incoming_call_accept.png" (click)="onConfirm()" class="button-img " alt="Accept" />-->

      </div>
      <div class="col-6">
          <input type="button" (click)="onDismiss()"  value="Cancel" />
          <!--<img src="../../../../../assets/icons/PNG/incoming_call_reject.png" (click)="onDismiss()" class="button-img " alt="Reject" />-->
      </div>
    </div>

   


  </div>


</div>
