export class ChatUserModel {
    id: number;
    username: string
    firstname: string;
    lastname: string;
    email: string;
    mobile: string;
    isActive: boolean;
    onlineStatus: boolean;
    profilePic: string;
    status: boolean;
    createdAt: Date;
}

export class LastMessage {
    id: string
    userFileName: string
    message: string | null
    messageType: string
    isRead: boolean
}

export class UserListWithLastMessage {
    user: ChatUserModel
    lastMessage: LastMessage
    totalUnreadMessage: number
}