<div class="modal-header">
    <h5 class="modal-title font-size-16" id="">Properties</h5>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body p-4">
    <perfect-scrollbar class="properties-scroll">
        <div class="properties-details border-bottom pb-2">
            <ul>
                <li>
                    <span class="properties-details-icon"><i class="ri-user-2-line"></i></span>
                    <p class="mb-0">Name : {{displayFileName(name)}}</p>
                </li>
                <li>
                    <span class="properties-details-icon"><i class="ri-map-pin-line"></i></span>
                    <p class="mb-0">Location : {{actualPath}}</p>
                </li>
                <li>
                    <span class="properties-details-icon"><i class="ri-layout-5-line"></i></span>
                    <p class="mb-0">Size : ({{displayFileSize(size)}})</p>
                </li>
                <li>
                    <span class="properties-details-icon"><i class="ri-lock-line"></i></span>
                    <p class="mb-0">Security :</p>
                </li>
            </ul>
        </div>
        <div class="properties-table-wrap pt-2">
            <div class="properties-table mb-3">
                <table>
                    <tr>
                        <th><span ngbTooltip="User Name" container="body"><i class="ri-shield-user-fill"></i></span>
                        </th>
                        <th><span ngbTooltip="Can Read" container="body"><i class="ri-file-list-fill"></i></span>
                        </th>
                        <th><span ngbTooltip="Can Write" container="body"><i class="ri-pencil-fill"></i></span></th>
                        <th><span ngbTooltip="Can Delete" container="body"><i class="ri-delete-bin-fill"></i></span>
                        </th>
                        <th><span ngbTooltip="Can Download" container="body"><i
                                    class="ri-download-cloud-fill"></i></span></th>
                        <th><span ngbTooltip="Can Change Permission" container="body"><i
                                    class="ri-settings-5-fill"></i></span></th>
                        <th><span><i class="ri-more-fill"></i></span></th>
                    </tr>
                    <tr *ngFor="let access of accessList">
                        <td>
                            {{access.username}}
                        </td>
                        <td>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canRead"
                                    container="body" [(ngModel)]="access.canRead" [disabled]="!canChangePermission">
                            </div>
                        </td>
                        <td>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canWrite"
                                    container="body" [(ngModel)]="access.canWrite" [disabled]="!canChangePermission">
                            </div>
                        </td>
                        <td>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canDelete"
                                    container="body" [(ngModel)]="access.canDelete" [disabled]="!canChangePermission">
                            </div>
                        </td>
                        <td>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="" ngbTooltip="canDownload"
                                    container="body" [(ngModel)]="access.canDownload" [disabled]="!canChangePermission">
                            </div>
                        </td>
                        <td>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id=""
                                    ngbTooltip="canChangePermission" container="body"
                                    [(ngModel)]="access.canChangePermission"
                                    [disabled]="!canChangePermission ||  access.username==currentUser.username">
                            </div>
                        </td>
                        <td>
                            <span *ngIf="canChangePermission">
                                <i class="ri-delete-bin-fill" (click)="removeUser(access.username)"
                                    *ngIf="access.username!=currentUser.username && access.username!='everyone'"></i>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="properties-select-search" *ngIf="canChangePermission">
                <div class="properties-select-btn">
                    <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                        (click)="isCollapsed = !isCollapsed">
                        Select User
                    </button>
                </div>
                <div class="collapse mt-1" [ngClass]="{'show':  isCollapsed ? 'show' : ''}">
                    <div class="card border">
                        <div class="card-header d-flex card-header-search">
                            <h5 class="font-size-15 mb-0 me-2">Add User</h5>
                            <div class="input-group bg-light input-group-sm rounded-lg">
                                <div class="input-group-prepend">
                                    <button type="button"
                                        class="btn btn-link btn-sm text-decoration-none text-muted pr-1">
                                        <i class="ri-search-line search-icon font-size-18"></i>
                                    </button>
                                </div>
                                <input type="text" class="form-control bg-light" placeholder="Search members...">
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <perfect-scrollbar data-simplebar style="height: 150px;">
                                <div *ngFor="let user of userList  | keyvalue">
                                    <div class="px-3 font-weight-bold text-primary">
                                        {{user.key | uppercase}}
                                    </div>
                                    <ul class="list-unstyled contact-list" *ngFor="let userDetails of user.value">
                                        <li>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="{{userDetails.id}}"
                                                    [value]="user.id"
                                                    (change)="onCheckboxChange($event, userDetails)">
                                                <label class="form-check-label">
                                                    {{userDetails.firstname | titlecase}} {{userDetails.lastname |
                                                    lowercase}}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <!-- <button type="submit" class="add-btn btn btn-primary">Add</button> -->
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</div>
<div class="modal-footer p-2">
    <button type="button" class="btn btn-outline-primary m-2" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary m-2" (click)="onConfirm()">Ok</button>
</div>