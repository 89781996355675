<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel"> Invite users</h5>
        <button type="button" class="btn-close" (click)="closeModal()">
        </button>
    </div>

    <form (ngSubmit)="onSubmit()">
        <div class="modal-body p-4">
            <div class="mb-4">
                <label class="form-label" for="addgroupname-input">Emails</label>
                <tag-input [(ngModel)]='emails' name="emails" #email="ngModel" [errorMessages]="errorMessages"
                    [validators]="validators" [editable]='true' [separatorKeyCodes]="[32,188,186,13,9]"
                    [placeholder]="'Add email'" [secondaryPlaceholder]="'Enter email address(es)'" [clearOnBlur]="true"
                    [addOnPaste]="true" [addOnBlur]="true" theme='bootstrap' required>
                </tag-input>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="closeModal()">Cancel</button>
            <button type="submit" class="btn btn-primary">Invite User</button>
        </div>
    </form>
</div>