import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { TokenStorageService } from 'src/app/core/services/token.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {
  currentUser;
  access_token: any;
  access_token_ttl: any;
  public favicon: string;

  MSfileOpened: boolean = true;
  isPdf: boolean = false;
  isImage: boolean = false;
  fileName = "";
  fileOpened: boolean = false;
  public blob: any;
  public blobUrl: string;
  public blobfileContent: any;
  public blobImageUrl: any;
  public blobPdfUrl: any;
  public urlsrc: string;

  constructor(
    private obService: ObservableService,
    private documentUtils: DocumentUtilsService,
    private serverDocumentService: ServerDocumentService,
    private _sanitizer: DomSanitizer,
    private tokenService: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();

    this.obService.previewFile.subscribe((value: any) => {
      this.fileOpened = value.fileOpened;
      this.filePreview(value.file);
    })
  }

  filePreview(file) {
    this.blobfileContent = "loading..";
    this.urlsrc = "loading..";
    this.fileName = file.name;
    var _fileSplit = file.name.split('.');
    var ext = _fileSplit.length == 1 ? "" : _fileSplit[_fileSplit.length - 1];

    ext = ext.toLowerCase();
    if (!this.documentUtils.msOfficeExts.find(x => x == ext)) {
      this.isPdf = false;
      this.isImage = false;
      if (ext == "pdf") {
        this.isPdf = true;
      } else if (this.documentUtils.imageExts.find(x => x == ext)) {
        this.isImage = true;
      }

      this.fileOpened = false;
      this.serverDocumentService.getFile(file.id).subscribe(blob => {
        this.blob = blob;
        this.MSfileOpened = false;
        this.fileOpened = true;
        this.blobUrl = URL.createObjectURL(this.blob);

        if (this.isPdf) {
          this.blob = null;
        } else if (this.isImage) {
          this.blobImageUrl = this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        } else {
          let fileReader: FileReader = new FileReader();
          let _self = this;
          fileReader.onloadend = function (x) {
            (_self as any).blob = null;
            (_self as any).blobfileContent = fileReader.result;
          }
          if (this.blob)
            fileReader.readAsText(this.blob);
          this.blob = null;

          return true;
        }
      });
    } else {
      this.MSfileOpened = true;
      this.fileOpened = true;
      this.serverDocumentService.getWopiDetail(file.id, this.currentUser.username, this.currentUser.email).subscribe(fileinfo => {
        //AccessToken, Urlsrc,Favicon
        this.urlsrc = fileinfo.Urlsrc;
        this.access_token = fileinfo.AccessToken;
        this.favicon = fileinfo.Favicon;
        this.access_token_ttl = "60";

        setTimeout(() => {
          var frameholder = document.getElementById('frameholder');

          var ifr = document.getElementById('office_frame');
          if (ifr)
            frameholder.removeChild(ifr);


          var office_frame = document.createElement('iframe');

          office_frame.name = 'office_frame';
          office_frame.id = 'office_frame';
          // The title should be set for accessibility
          office_frame.title = 'Office Online Frame';
          // This attribute allows true fullscreen mode in slideshow view
          // when using PowerPoint Online's 'view' action.
          office_frame.setAttribute('allowfullscreen', 'true');

          frameholder.appendChild(office_frame);
          // document.getElementById('office_form').submit();
          (document.getElementById('office_form') as HTMLFormElement).submit();
          setTimeout(() => {
            document.querySelector('#office_frame').className = "office_frame";
          }, 200);
        }, 1000);
      });
    }
  }


  getFullFileName(name: string) {
    if (!name)
      return "error";
    var nameHash = name.split('#');
    if (nameHash.length > 1)
      return nameHash[1];
    else
      return nameHash[0];
  }

}
