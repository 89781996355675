import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Konva from 'konva';
import { CommanService } from 'src/app/core/services/comman.service';
import { ShapeService } from 'src/app/core/services/shape.service';
declare let Quill: any;
@Component({
  selector: 'app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss']
})
export class WhiteboardComponent implements OnInit {
  stage: Konva.Stage;
  layer: Konva.Layer;
  tr;
  addedShapes: any = [];
  shapesHistory: any = [];
  selectedShape = 'brush';
  backgroundColor = '#f00505';
  strokeColor = "#030303";
  eraserSize = 6;
  imageUrl;
  text;

  constructor(
    private shapeService: ShapeService,
    private commonService: CommanService
  ) { }

  ngOnInit(): void {
    var width = window.innerWidth;
    var height = window.innerHeight;
    this.stage = new Konva.Stage({
      container: 'whiteboard',
      width: width,
      height: height
    });
    this.layer = new Konva.Layer();
    this.stage.add(this.layer);
  }

  async convertToPDF() {
    var data = document.getElementById('whiteboard');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('whiteboard.pdf'); // Generated PDF
    });
  }

  async saveAsImage() {
    let dataURL = this.stage.toDataURL({ pixelRatio: 3 });
    let link: any = document.createElement('a');
    link.download = 'whiteboard.png';
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  }

  async addCircle() {
    var circle = this.shapeService.circle(this.backgroundColor, this.strokeColor);
    this.layer.add(circle);
    this.stage.add(this.layer);
    this.addedShapes.push(circle);
    this.shapesHistory.push(circle);
    this.selectedShape = 'circle';
    this.addTransformer();
  }

  async addRectangle() {
    var rectangle = this.shapeService.rectangle(this.backgroundColor, this.strokeColor);
    this.layer.add(rectangle);
    this.stage.add(this.layer);
    this.addedShapes.push(rectangle);
    this.shapesHistory.push(rectangle);
    this.selectedShape = 'rectangle';
    this.addTransformer();
  }

  async addLine() {
    var line = new Konva.Line({
      x: 100,
      y: 100,
      points: [5, 100, 5, 120, 5, 80, 5, 40],
      stroke: this.strokeColor,
      strokeWidth: 5,
      lineCap: 'round',
      lineJoin: 'round',
      draggable: true
    });
    this.layer.add(line);
    this.stage.add(this.layer);
    this.addedShapes.push(line);
    this.shapesHistory.push(line);
    this.selectedShape = 'line';
    this.addTransformer();
  }

  increaseEraserSize() {
    this.eraserSize += 3;
  }

  decreaseEraserSize() {
    this.eraserSize -= 3;
    if (this.eraserSize <= 0) {
      this.eraserSize = 6;
    }
  }

  async addEraserOrBrush(mode) {
    this.selectedShape = mode;
    let component = this;
    var isPaint = false;
    var lastLine;
    this.stage.on('mousedown touchstart', function (e) {
      if (component.selectedShape === 'brush' || component.selectedShape === 'eraser') {
        isPaint = true;
        var pos = component.stage.getPointerPosition();
        lastLine = new Konva.Line({
          stroke: component.selectedShape === 'brush' ? component.strokeColor : '#fff',
          strokeWidth: component.eraserSize,
          globalCompositeOperation: mode === 'brush' ? 'source-over' : 'destination-out',
          lineCap: 'round',
          lineJoin: 'round',
          points: [pos.x, pos.y, pos.x, pos.y],
        });
        component.addedShapes.push(lastLine);
        component.shapesHistory.push(lastLine);
        component.layer.add(lastLine);
      }
    });

    this.stage.on('mouseup touchend', function () {
      if (component.selectedShape === 'brush' || component.selectedShape === 'eraser') {
        isPaint = false;
      }
    });

    this.stage.on('mousemove touchmove', function (e) {
      if (component.selectedShape === 'brush' || component.selectedShape === 'eraser') {
        if (!isPaint) {
          return;
        }
        // prevent scrolling on touch devices
        e.evt.preventDefault();

        const pos = component.stage.getPointerPosition();
        var newPoints = lastLine.points().concat([pos.x, pos.y]);
        lastLine.points(newPoints);
      }
    });
  }

  async addText() {
    this.text = this.shapeService.textNode(this.stage, this.layer, this.backgroundColor);
    this.layer.add(this.text.textNode);
    this.stage.add(this.layer);
    this.addedShapes.push(this.text.textNode);
    this.shapesHistory.push(this.text.textNode);
    this.selectedShape = 'text';
    let component = this;

    this.stage.on('click', (e) => {
      if (component.selectedShape === 'text' && this.text) {
        const isClickedOnEmptySpace = e.target === e.target.getStage();
        if (isClickedOnEmptySpace) {
          this.text.tr.nodes([]);
          this.text = null;
        } else {
          this.text.tr.nodes([e.target]);
        }
      }
    });
  }

  async addUnderLine() {
    if (this.text != null) {
      this.text.textNode.textDecoration('underline')
    }
  }

  async addItalic() {
    if (this.text != null) {
      this.text.textNode.fontStyle('italic')
    }
  }

  async addBold() {
    if (this.text != null) {
      this.text.textNode.fontStyle('bold')
    }
  }

  async changeFontFamily() {
    if (this.text != null) {
      let value = (document.getElementById('fontFamily') as HTMLInputElement).value;
      this.text.textNode.fontFamily(value);
    }
  }

  async changeTextFontSize() {
    if (this.text != null) {
      let value = (document.getElementById('fontSize') as HTMLInputElement).value;
      if (value === 'h1') {
        this.text.textNode.fontSize(32)
      } else if (value === 'h2') {
        this.text.textNode.fontSize(24)
      } else {
        this.text.textNode.fontSize(20)
      }
    }
  }

  async addTransformer() {
    let component = this;
    let tr;
    if (this.selectedShape === 'line') {
      tr = new Konva.Transformer({
        enabledAnchors: ['top-center', 'bottom-center'],
      });
    } else if (this.selectedShape != 'line' && this.selectedShape != 'text') {
      tr = new Konva.Transformer();
    }
    this.layer.add(tr);

    this.stage.on('click', (e) => {
      if (component.selectedShape != 'brush' && component.selectedShape != 'eraser' && component.selectedShape != 'text') {
        const isClickedOnEmptySpace = e.target === e.target.getStage();
        if (isClickedOnEmptySpace) {
          tr.nodes([])
        } else {
          tr.nodes([e.target]);
        }
      }

      if (component.selectedShape === 'text' && this.text) {
        const isClickedOnEmptySpace = e.target === e.target.getStage();
        if (isClickedOnEmptySpace) {
          this.text.tr.nodes([]);
          this.text = null;
        } else {
          this.text.tr.nodes([e.target]);
        }
      }
    });
  }

  async undo() {
    if (this.addedShapes.length > 0) {
      this.shapesHistory.push(this.addedShapes[this.addedShapes.length - 1])
      const removedShape = this.addedShapes.pop();
      if (removedShape) {
        removedShape.remove();
      }
      this.layer.draw();
    }
  }

  async redo() {
    if (this.shapesHistory.length > 0) {
      let shape = this.shapesHistory[this.shapesHistory.length - 1];

      this.addedShapes.push(this.shapesHistory[this.shapesHistory.length - 1]);
      this.shapesHistory.splice(this.shapesHistory.length - 1, 1);

      this.layer.add(shape);
      this.stage.add(this.layer);
    }
  }

  readURL(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
        this.addImage(this.imageUrl)
      };
      reader.readAsDataURL(file);
    }
  }

  addImage(Base64Image) {
    let component = this;
    var imageObj = new Image();
    var image = new Konva.Image({
      x: 50,
      y: 50,
      image: imageObj,
      width: 200,
      height: 200,
      draggable: true
    });
    component.layer.add(image);
    this.stage.add(this.layer);
    this.addedShapes.push(image);
    this.addTransformer();
    imageObj.src = Base64Image;
    this.selectedShape = 'image';
  }
}
