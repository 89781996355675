import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentUtilsService } from 'src/app/core/services/comman/document-utils.service';
import { FileElement } from 'src/app/core/services/comman/file.model';
import { ObservableService } from 'src/app/core/services/observable.service';
import { ServerDocumentService } from 'src/app/core/services/server-document.service';
import { TokenStorageService } from 'src/app/core/services/token.service';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.scss']
})
export class PreviewFileComponent implements OnInit {
  fileOpened: boolean = false;
  public blob: any;
  public blobUrl: string;
  public blobfileContent: any;
  public blobimageUrl: any;
  public urlsrc: string;

  access_token: any;
  access_token_ttl: any;
  public favicon: string;

  MSfileOpened: boolean = false;
  isPdf: boolean = false;
  isImage: boolean = false;
  file_name = "";


  currentUser: any;

  constructor(
    public documentUtils: DocumentUtilsService,
    public serverDocumentService: ServerDocumentService,
    private _sanitizer: DomSanitizer,
    private tokenService: TokenStorageService,
    private obService: ObservableService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenService.getUser();

    this.obService.previewFile.subscribe((value) => {
      this.fileOpened = value.fileOpened
      this.showFile(value)
    })

  }

  showFile(file: FileElement) {
    this.blobfileContent = "loading..";
    this.urlsrc = "loading..";
    this.file_name = file.name;

    var _fileSplit = file.name.split('.');
    var ext = _fileSplit.length == 1 ? "" : _fileSplit[_fileSplit.length - 1];

    ext = ext.toLowerCase();
    if (!this.documentUtils.msOfficeExts.find(x => x == ext)) {
      this.isPdf = false;
      this.isImage = false;
      if (ext == "pdf") {
        this.isPdf = true;
      } else if (this.documentUtils.imageExts.find(x => x == ext)) {
        this.isImage = true;
      }

      this.fileOpened = false;
      this.serverDocumentService.getFile(file.id).subscribe(blob => {
        this.blob = blob;
        this.MSfileOpened = false;
        this.fileOpened = true;
        this.blobUrl = URL.createObjectURL(this.blob);

        if (this.isPdf) {
          this.blob = null;
        } else if (this.isImage) {
          var safeUrl = this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.blob));
          this.blob = null;
          this.blobimageUrl = safeUrl["changingThisBreaksApplicationSecurity"];
        } else {
          let fileReader: FileReader = new FileReader();
          let _self = this;
          fileReader.onloadend = function (x) {
            (_self as any).blob = null;
            (_self as any).blobfileContent = fileReader.result;
          }
          if (this.blob) {
            fileReader.readAsText(this.blob);
          }

          this.blob = null;
          return true;
        }
      });
    } else {
      this.MSfileOpened = true;
      this.fileOpened = true;

      this.serverDocumentService.getWopiDetail(file.id, this.currentUser.username, this.currentUser.email).subscribe(fileinfo => {
        this.urlsrc = fileinfo.Urlsrc;
        this.access_token = fileinfo.AccessToken;
        this.favicon = fileinfo.Favicon;
        this.access_token_ttl = "60";

        setTimeout((x) => {
          var frameholder = document.getElementById('frameholder');

          var ifr = document.getElementById('office_frame');
          if (ifr) {
            frameholder.removeChild(ifr);
          }

          var office_frame = document.createElement('iframe');

          office_frame.name = 'office_frame';
          office_frame.id = 'office_frame';
          office_frame.title = 'Office Online Frame';
          office_frame.setAttribute('allowfullscreen', 'true');

          frameholder.appendChild(office_frame);
          (document.getElementById('office_form') as HTMLFormElement).submit();
          setTimeout((x) => {
            document.querySelector('#office_frame').className = "office_frame";
          }, 200);
        }, 1000);
      });
    }
  }



}
