import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private http: HttpClient) { }

  createTeam(data) {
    console.log('twisss...')
    return this.http.post<any>(`@api-nest/team/create-team`, data).pipe(retry(0), catchError(this.handleError));
  }

  updateTeam(data) {
    return this.http.put<any>(`@api-nest/team/update-team-info`, data).pipe(retry(0), catchError(this.handleError));
  }

  checkDuplicateTeamName(name) {
    return this.http.post<any>(`@api-nest/team/check-duplicate-team-name`, { name }).pipe(retry(0), catchError(this.handleError));
  }


  sendMessage(data) {
    return this.http.post<any>(`@api-nest/team/send-message`, data).pipe(retry(0), catchError(this.handleError));
  }
  
  editMessage(data) {
    return this.http.put<any>(`@api-nest/team/edit-message`, data).pipe(retry(0), catchError(this.handleError));
  }

  updateImportantMessage(data) {
    return this.http.post<any>(`@api-nest/team/is-important`, data).pipe(retry(0), catchError(this.handleError));
  }


  getMessages(data) {
    let params = new HttpParams().set("senderId", data.senderId);
    params = params.set('teamId', data.teamId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/get-messages`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }
  
  getImportantMessages(data) {
    let params = new HttpParams().set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/get-important-messages`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  deleteMessage(data) {
    let params = new HttpParams().set("id", data);
    return this.http.delete<any>(`@api-nest/team/delete-message`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }



  updateMessageStatus(data) {
    return this.http.put<any>(`@api-nest/team/update-message-status`, data).pipe(retry(0), catchError(this.handleError));
  }

  getTeamList(data) {
    let params = new HttpParams().set("limit", data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/team-list`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getTeamMembers(data) {
    let params = new HttpParams().set("teamId", data.teamId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/get-team-users`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getAllUserList(data) {
    let params = new HttpParams().set("teamId", data.teamId);
    params = params.set('companyId', data.companyId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/all-user-list`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  changeMemberRole(data) {
    return this.http.put<any>(`@api-nest/team/change-member-role`, data).pipe(retry(0), catchError(this.handleError));
  }

  removeUser(id) {
    return this.http.put<any>(`@api-nest/team/remove-team-member`, { id }).pipe(retry(0), catchError(this.handleError));
  }

  addUserToTeam(data) {
    return this.http.post<any>(`@api-nest/team/add-user-to-team`, data).pipe(retry(0), catchError(this.handleError));
  }

  getFileList(data) {
    let params = new HttpParams().set("teamId", data.teamId);
    params = params.set('limit', data.limit);
    params = params.set('offset', data.offset);
    return this.http.get<any>(`@api-nest/team/get-file-list`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  searchTeam(keyword) {
    let params = new HttpParams().set("keyword", keyword);
    return this.http.get<any>(`@api-nest/team/search-team`, { params: params }).pipe(retry(0), catchError(this.handleError));
  }

  getTotalNumberOfUnreadMessage() {
    return this.http.get<any>(`@api-nest/team/get-total-unread-message`).pipe(retry(0), catchError(this.handleError));
  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
