<div class="ng-star-inserted">
    <div class="layout-wrapper d-lg-flex">
        <app-side-bar></app-side-bar>
        <router-outlet></router-outlet>
        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/chat' || 
            (previousRoute ==='/pages/chat' && currentRoute === '/pages/setting') || 
            (previousRoute ==='/pages/chat' && currentRoute === '/pages/profile') || 
            (previousRoute ==='/pages/chat' && currentRoute === '/pages/notification') || 
            (currentRoute.match('/pages/chat')?.length > 0)">
            <app-user-conversion></app-user-conversion>
        </div>

        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/team' || 
        (previousRoute ==='/pages/team' && currentRoute === '/pages/setting') || 
        (previousRoute ==='/pages/team' && currentRoute === '/pages/notification') || 
        (previousRoute ==='/pages/team' && currentRoute === '/pages/profile')">
            <app-team-conversion></app-team-conversion>
        </div>

        <div class="user-chat w-100" id="user-activity" *ngIf="currentRoute === '/pages/activity' || 
        (previousRoute ==='/pages/activity' && currentRoute === '/pages/setting') || 
        (previousRoute ==='/pages/activity' && currentRoute === '/pages/profile') ||
        (previousRoute ==='/pages/activity' && currentRoute === '/pages/notification') ||
        (notificationType.type === 'activity_like' || notificationType.type === 'activity_comment')">
            <app-activity-content></app-activity-content>
        </div>

        <div class="user-chat w-100" id="chat-room" *ngIf="(
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/docs') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/chat') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/setting') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/team') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/activity') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/contact') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/notification') && 
        (previousRoute ==='/pages/meetings' && currentRoute != '/pages/profile')
        ) || currentRoute === '/pages/meetings'">
            <div id="chat-room" *ngIf="(currentRoute === '/pages/meetings' && meetingTab === 'schedule-meeting')">
                <app-schedule-meeting></app-schedule-meeting>
            </div>

            <div id="chat-room" *ngIf="(currentRoute === '/pages/meetings' && meetingTab === 'meeting-info')">
                <app-meeting-information></app-meeting-information>
            </div>

            <div id="meeting" *ngIf="(currentRoute === '/pages/meetings' && meetingTab === 'join-meeting')">
                <app-join-meeting></app-join-meeting>
            </div>
        </div>


        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/docs' || 
        (previousRoute ==='/pages/docs' && currentRoute === '/pages/setting') || 
        (previousRoute ==='/pages/docs' && currentRoute === '/pages/notification') || 
        (previousRoute ==='/pages/docs' && currentRoute === '/pages/profile')">
            <app-files class=""></app-files>
        </div>

        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/meetings' && isShowMeetingInfo">
            <app-meeting-information class=""></app-meeting-information>
        </div>

        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/notification' && isShowMeetingInfo">
            <app-notification class=""></app-notification>
        </div>

        <div class="user-chat w-100" id="chat-room" *ngIf="currentRoute === '/pages/contact' || 
        (previousRoute ==='/pages/contact' && currentRoute === '/pages/setting') || 
        (previousRoute ==='/pages/contact' && currentRoute === '/pages/notification') || 
        (previousRoute ==='/pages/contact' && currentRoute === '/pages/profile')">
            <app-call-history class=""></app-call-history>
        </div>
    </div>
</div>